import React from 'react';
import PropTypes from 'prop-types';
import { getUUIDResource } from 'rapidfab/selectors';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import { connect } from 'react-redux';
import { lineItemResourceType } from 'rapidfab/types';
import { API_RESOURCES } from 'rapidfab/constants';
import LineItemEstimationViewModal from 'rapidfab/components/records/order/edit/LineItemEstimationViewModal';

const LineItemEstimationViewModalContainer = ({
  lineItem,
  onClose,
  isLoading,
}) =>

  (
    <LineItemEstimationViewModal
      show
      onHide={onClose}
      loading={isLoading}
      lineItem={lineItem}
    />
  );
LineItemEstimationViewModalContainer.propTypes = {
  lineItem: lineItemResourceType.isRequired,
  // Used in mapStateToProps
  // eslint-disable-next-line react/no-unused-prop-types
  lineItemUri: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state, { lineItemUri }) => {
  const lineItem = getUUIDResource(state, extractUuid(lineItemUri));
  const isLoading = state.ui.nautilus[API_RESOURCES.LINE_ITEM].list.fetching ||
    state.ui.nautilus[API_RESOURCES.MODEL].list.fetching;
  return {
    lineItem,
    isLoading,
  };
};

export default connect(mapStateToProps)(LineItemEstimationViewModalContainer);
