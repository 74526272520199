import BureauBarcodeIcon from 'rapidfab/components/BureauBarcodeIcon';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab, Container, Row, Col } from 'react-bootstrap';
import { ROUTES } from 'rapidfab/constants';
import { MATERIAL_LOT_STATUS_MAP } from 'rapidfab/mappings';

import TableWithSearching from 'rapidfab/components/Tables/TableWithSearching';
import { Link } from 'react-router-dom';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import { extractUuid } from 'rapidfab/utils/uuidUtils';

const TABS = {
  INVENTORY: 'inventory',
  ON_ORDER: 'on-order',
};

const MaterialLotsTable = ({
  onOrderMaterialLots,
  inventoryMaterialLots,
  listStore,
  filters,
  offset,
  limit,
  search,
  onSort,
  onPageChange,
  onLimitChange,
  onSearch,
  fetching,
  locationsState,
}) => {
  const [activeTab, setActiveTab] = useState(TABS.INVENTORY);
  // override data passed to RecordList by filtering by statuses
  const data = activeTab === TABS.INVENTORY
    ? inventoryMaterialLots
    : onOrderMaterialLots;

  const { locations, subLocations } = locationsState;

  const base = [
    {
      type: 'uuid',
      uid: 'field.id',
      accessor: 'uuid',
      defaultMessage: 'ID',
      resource: 'material-lot',
      route: ROUTES.MATERIAL_LOT,
    },
    {
      type: 'text',
      uid: 'field.name',
      accessor: 'name',
      defaultMessage: 'Name',
      isSortable: true,
    },
    {
      type: 'text',
      uid: 'field.material',
      accessor: 'material_name',
      defaultMessage: 'Material',
      isSortable: true,
    },
    {
      type: 'text',
      uid: 'field.deliveryId',
      accessor: 'delivery_id',
      defaultMessage: 'Delivery ID',
      isSortable: true,
    },
    {
      type: 'custom',
      uid: 'field.location',
      accessor: 'location_name',
      defaultMessage: 'Location',
      isSortable: true,
      Cell: ({ row: { original } }) => {
        const location = locations.find(location =>
          location.uri === original.location);
        const subLocation = subLocations.find(subLocation => subLocation.uri === original.sub_location);
        return <span>{location?.name} {subLocation && `(${subLocation?.name})`}</span>;
      },
    },
    {
      type: 'suffixed',
      uid: 'field.quantity',
      accessor: 'quantity',
      defaultMessage: 'Quantity',
      suffix: 'units',
      isSortable: true,
    },
    {
      type: 'translatable',
      uid: 'field.status',
      accessor: 'status',
      defaultMessage: 'Status',
      mapping: MATERIAL_LOT_STATUS_MAP,
      isSortable: true,
    },
    {
      type: 'custom',
      uid: 'field.containers',
      accessor: 'uri',
      defaultMessage: 'Containers',
      route: ROUTES.PRINT_MATERIAL_LOT_CONTAINERS_QR_CODES,
      Cell: ({ row: { original } }) => {
        if (!original.number_of_containers) {
          return <div className="text-right">None</div>;
        }

        return (
          <Link
            className="pull-right"
            to={getRouteURI(ROUTES.PRINT_MATERIAL_LOT_CONTAINERS_QR_CODES,
              { uuid: extractUuid(original.uri) }, {}, true)}
          >
            <BureauBarcodeIcon className="spacer-right" />
            {original.number_of_containers} {`${original.number_of_containers === 1 ? 'Container' : 'Containers'}`}
          </Link>
        );
      },
    },
  ];

  const inventoryColumns = [
    ...base,
    {
      type: 'date',
      uid: 'field.expirationDate',
      accessor: 'expiration_date',
      defaultMessage: 'Expiration Date',
      isSortable: true,
    },
    {
      type: 'date',
      uid: 'field.deliveryDate',
      accessor: 'actual_delivery_date',
      defaultMessage: 'Delivery Date',
      isSortable: true,
    },
  ];

  const onOrderColumns = [
    ...base,
    {
      type: 'text',
      uid: 'field.poNumber',
      accessor: 'material_purchase_order_external_id',
      defaultMessage: 'PO Number',
      isSortable: true,
    },
    {
      type: 'date',
      uid: 'field.estimatedDeliveryDate',
      accessor: 'estimated_delivery_date',
      defaultMessage: 'Estimated Delivery Date',
      isSortable: true,
    },
  ];

  const c = activeTab === TABS.INVENTORY ? inventoryColumns : onOrderColumns;

  return (
    <>
      <Container fluid>

        <Row>
          <Col sm={{ span: 12 }} lg={{ span: 6, offset: 1 }} className="mb8">

            <Tabs
              activeKey={activeTab}
              onSelect={setActiveTab}
              className="mb15"
              id="material_lots_tabs"
            >
              <Tab eventKey={TABS.INVENTORY} title="Inventory" />
              <Tab eventKey={TABS.ON_ORDER} title="On Order" />
            </Tabs>
          </Col>
        </Row>
      </Container>
      <TableWithSearching
        data={data}
        columns={c}
        listStore={listStore}
        filters={filters}
        offset={offset}
        limit={limit}
        search={search}
        onPageChange={onPageChange}
        onLimitChange={onLimitChange}
        onSearch={onSearch}
        isFetching={fetching}
        isManualSoringEnabled
        manualSortingFunc={onSort}
        initialSortedDesc
        initialSortedColumn="updated"
        withoutDivider
      />
    </>
  );
};

MaterialLotsTable.defaultProps = {
  search: '',
};

MaterialLotsTable.propTypes = {
  onOrderMaterialLots: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  inventoryMaterialLots: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  listStore: PropTypes.shape({}).isRequired,
  onPageChange: PropTypes.func.isRequired,
  onLimitChange: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onSort: PropTypes.func.isRequired,
  search: PropTypes.string,
  filters: PropTypes.shape({}).isRequired,
  offset: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  fetching: PropTypes.bool.isRequired,
  row: PropTypes.shape({
    original: PropTypes.shape({
      sub_location: PropTypes.string,
      location: PropTypes.string,
      number_of_containers: PropTypes.number,
      uri: PropTypes.string,
    }),
  }).isRequired,
  locationsState: PropTypes.shape({
    subLocations: PropTypes.arrayOf(
      PropTypes.shape({
        uri: PropTypes.string,
      })).isRequired,
    locations: PropTypes.arrayOf(
      PropTypes.shape({
        uri: PropTypes.string,
      })).isRequired,
  }).isRequired,

};

export default MaterialLotsTable;
