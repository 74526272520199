import {
  ALLOWED_MODEL_EXTENSIONS,
  FILE_EXTENSIONS,
  BASIC_CONVERSION_EXTENSIONS,
  EXPERT_CONVERSION_EXTENSIONS,
  NATIVE_CAD_CONVERSION_EXTENSIONS,
  ZVERSE_CONVERSION_EXTENSIONS,
} from 'rapidfab/constants';

/**
 * @description Get valid file upload extensions based on feature flags.
 *
 * @param {boolean} isCADToSTLConversionFeatureEnabled
 * @param {boolean} isZverseConversionFeatureEnabled
 * @param {boolean} isAnatomicalModelToProductFeatureEnabled
 * @param {boolean} isExpertConversionFeatureEnabled
 * @param {boolean} isBasicModelConversionFeatureEnabled
 * @returns {string[]}
*/
export default function getValidExtensionsToUploadModel(
  isCADToSTLConversionFeatureEnabled = false,
  isZverseConversionFeatureEnabled = false,
  isAnatomicalModelToProductFeatureEnabled = false,
  isExpertConversionFeatureEnabled = false,
  isBasicModelConversionFeatureEnabled = false,
) {
  let validExtensions = [...ALLOWED_MODEL_EXTENSIONS];
  if (isCADToSTLConversionFeatureEnabled && !isBasicModelConversionFeatureEnabled) {
    validExtensions = [...validExtensions, ...NATIVE_CAD_CONVERSION_EXTENSIONS];
  }
  if (isBasicModelConversionFeatureEnabled) {
    validExtensions = [...validExtensions, ...BASIC_CONVERSION_EXTENSIONS];
  }
  if (isZverseConversionFeatureEnabled) {
    validExtensions = [...validExtensions, ...ZVERSE_CONVERSION_EXTENSIONS];
  }
  if (isAnatomicalModelToProductFeatureEnabled) {
    validExtensions = [...validExtensions, FILE_EXTENSIONS.ZIP];
  }
  if (isExpertConversionFeatureEnabled) {
    validExtensions = [...validExtensions, ...EXPERT_CONVERSION_EXTENSIONS];
  }
  return validExtensions;
}
