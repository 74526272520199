import TableCellLabels from 'rapidfab/components/TableCellLabels';
import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import { ROUTES, WORKFLOW_USAGE_STATES } from 'rapidfab/constants';
import WorkflowTabs, { WORKFLOW_TABS } from 'rapidfab/components/plan/WorkflowTabs';
import TableWithSearching from 'rapidfab/components/Tables/TableWithSearching';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { getRouteURI } from 'rapidfab/utils/uriUtils';

const ProductionWorkflows = componentProps => {
  const {
    specimenWorkflowFeatureEnabled,
    data,
    fetching,
    onSort,
    isAssembly,
    isDebugModeEnabled,
    isGeneralMFGLanguageEnabled,
    labelsByUri,
  } = componentProps;

  const tabActiveKey = isAssembly ? WORKFLOW_TABS.ASSEMBLY_WORKFLOWS : WORKFLOW_TABS.PRODUCTION_WORKFLOWS;

  const columns = [
    {
      type: 'uuid',
      uid: 'field.id',
      accessor: 'uuid',
      defaultMessage: 'ID',
      resource: 'workflow',
    },
    {
      type: 'text',
      uid: 'field.name',
      accessor: 'name',
      defaultMessage: 'Name',
      isSortable: true,
    },
    specimenWorkflowFeatureEnabled && (
      {
        type: 'custom',
        uid: 'field.type',
        accessor: 'type',
        defaultMessage: 'Type',
        isSortable: true,
        Cell: cellData => {
          const { row: { original: data } } = cellData;
          if (isGeneralMFGLanguageEnabled) {
            return <FormattedMessage id="workflow.type.manufacturing" />;
          }
          return (
            <FormattedMessage id={`workflow.type.${data.type}`} />
          );
        },
      }
    ),
    {
      type: 'custom',
      uid: 'field.labels',
      accessor: 'labels',
      defaultMessage: 'Labels',
      Cell: ({ row: { original } }) => {
        const { labels: labelUris } = original;
        if (!labelUris || !labelUris.length) {
          return (
            <FormattedMessage id="notAvailable" defaultMessage="N/A" />
          );
        }
        return (
          <TableCellLabels
            labelUris={labelUris}
            labelsByUri={labelsByUri}
          />
        );
      },
    },
    {
      type: 'bool',
      uid: 'field.archived',
      accessor: 'usage_state',
      defaultMessage: 'Archived',
      equals: WORKFLOW_USAGE_STATES.ARCHIVED,
    },
  ];

  const NavbarLinks = (
    <Button
      variant="primary"
      size="sm"
      href={getRouteURI(
        ROUTES.WORKFLOW_CREATE,
        {},
        // using `isAssembly` check here to prevent adding `isAssembly=false` to the URI for better UX.
        isAssembly ? { isAssembly: true } : null,
      )}
      className="pull-right"
    >
      <FontAwesomeIcon icon={faPlus} />{' '}
      <FormattedMessage
        id="record.workflow.add"
        defaultMessage="Add Workflow"
      />
    </Button>
  );

  return (
    <TableWithSearching
      {...componentProps}
      breadcrumbs={['plan', 'workflows']}
      withBreadcrumbs
      navbar={NavbarLinks}
      subheader={<WorkflowTabs activeKey={tabActiveKey} />}
      data={data}
      columns={columns}
      isFetching={fetching}
      isFilteringEnabled={false}
      withDefaultPagination={false}
      isManualSoringEnabled
      manualSortingFunc={onSort}
      initialSortedDesc
      initialSortedColumn="updated"
      isDebugModeEnabled={isDebugModeEnabled}
    />
  );
};

ProductionWorkflows.defaultProps = {
  sort: '',
  search: '',
};

ProductionWorkflows.propTypes = {
  onPageChange: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onFilterUpdate: PropTypes.func.isRequired,
  onSort: PropTypes.func.isRequired,
  row: PropTypes.shape({
    original: PropTypes.shape({
      labels: PropTypes.arrayOf(PropTypes.string),
    }),
  }).isRequired,
  sort: PropTypes.string,
  search: PropTypes.string,
  recordListItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  filters: PropTypes.shape({}).isRequired,
  recordListStore: PropTypes.shape({}).isRequired,
  offset: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  specimenWorkflowFeatureEnabled: PropTypes.bool.isRequired,
  isAssembly: PropTypes.bool.isRequired,
  isGeneralMFGLanguageEnabled: PropTypes.bool.isRequired,
};

export default ProductionWorkflows;
