import BureauBarcodeIcon from 'rapidfab/components/BureauBarcodeIcon';
import React, { useState } from 'react';
import Config from 'rapidfab/config';
import { PIECE_STATUS_MAP } from 'rapidfab/mappings';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Feature from 'rapidfab/components/Feature';
import { FEATURES } from 'rapidfab/constants';
import {
  Col,
  ListGroup,
  ListGroupItem,
  Card,
  Row,
  Button,
} from 'react-bootstrap';
import { faEdit, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import Loading from 'rapidfab/components/Loading';
import ResourceLink from 'rapidfab/components/ResourceLink';
import { FormattedMessage } from 'rapidfab/i18n';
import { pieceResourceType } from 'rapidfab/types';
import PieceEditNameModal from 'rapidfab/components/records/piece/PieceEditNameModal';
import RCTooltip from 'rc-tooltip';
import LineItemPiecesHeader from './LineItemPiecesHeader';

const LineItemPieces = ({
  pieces,
  printsByPieceUri,
  workflowsByUri,
  printsByUri,
  isFetching,
  onUpdate,
  piecesModifiedWorkFlowList,
}) => {
  const [editingPiece, setEditingPiece] = useState(null);
  return (
    <>
      <Card bg="dark" className="m-b">
        <Card.Header className="pd-exp accent">{LineItemPiecesHeader(pieces)}</Card.Header>
        <div className="card-body-wrapper-accent">
          <ListGroup className="panel--scrollable" fill>
            <ListGroupItem key="header" className="header-item">
              <Row>
                <Col xs={3}>
                  <b>
                    <FormattedMessage id="field.name" defaultMessage="Name" />
                  </b>
                </Col>
                <Col xs={3}>
                  <b>
                    <FormattedMessage
                      id="field.status"
                      defaultMessage="Status"
                    />
                  </b>
                </Col>
                <Col xs={3}>
                  <b>
                    <FormattedMessage
                      id="currentStep"
                      defaultMessage="Current Step"
                    />
                  </b>
                </Col>
                <Col xs={3}>
                  <b>
                    <FormattedMessage id="field.run" defaultMessage="Run" />
                  </b>
                </Col>
              </Row>
            </ListGroupItem>

            {isFetching ? (
              <Loading className="mt15 mb15" />
            ) : (
              pieces.map(piece => {
                let exclamationIconWarning;
                const workflow = workflowsByUri[piece.workflow];
                const currentPrint = printsByUri[piece.current_print];
                const currentRunUri = currentPrint && currentPrint.run;
                const printingPrint =
                  printsByPieceUri[piece.uri] && printsByPieceUri[piece.uri][0];

                if (piecesModifiedWorkFlowList[piece.workflow]) {
                  exclamationIconWarning = (
                    <RCTooltip
                      placement="right"
                      destroyTooltipOnHide
                      overlayInnerStyle={{ padding: '10px', wordBreak: 'break-word' }}
                      mouseLeaveDelay={0.4}
                      overlay={(
                        <p>This pieces for this line-item <br />
                          was manufactured with a different workflow
                        </p>

                      )}
                    >
                      <FontAwesomeIcon icon={faTriangleExclamation} className="spacer-left" style={{ color: '#ffd500' }} />
                    </RCTooltip>
                  );
                }
                return (
                  <ListGroupItem key={piece.uuid}>
                    <Row>
                      <Col xs={3} className="truncate-data">
                        <ResourceLink uri={piece.uri} />
                        <Button
                          variant="primary"
                          size="xs"
                          onClick={() => setEditingPiece(piece)}
                          className="spacer-left"
                        >
                          <FontAwesomeIcon icon={faEdit} />
                        </Button>
                      </Col>
                      <Col xs={3}>
                        {piece.status ? (
                          <FormattedMessage
                            {...PIECE_STATUS_MAP[piece.status]}
                          />
                        ) : (
                          <FormattedMessage
                            id="notAvailable"
                            defaultMessage="N/A"
                          />
                        )}
                        {printingPrint && (
                          <Feature featureName={FEATURES.QR_PRINTS_TRAVELER}>
                            {
                              // TODO: Replace with piece UUID for the QR code print page, once implemented
                              // https://app.clubhouse.io/authentise/story/13987/update-qr-app-to-handle-pieces
                            }
                            <a
                              href={`${Config.HOST.QR}/traveler/print/${
                                printsByPieceUri[piece.uri][0].uuid
                              }`}
                              target="_blank"
                              rel="noopener noreferrer"
                              type="download"
                              className="spacer-left"
                            >
                              <BureauBarcodeIcon />
                            </a>
                          </Feature>
                        )}
                      </Col>
                      <Col xs={3}>
                        {workflow &&
                          `(${piece.current_step_position}/${workflow.process_steps.length})`}

                        {exclamationIconWarning}

                      </Col>
                      <Col xs={3}>
                        {currentRunUri ? (
                          <ResourceLink uri={currentRunUri} linkIconOnly />
                        ) : (
                          <FormattedMessage
                            defaultMessage="N/A"
                            id="notAvailable"
                          />
                        )}
                      </Col>
                    </Row>
                  </ListGroupItem>
                );
              })
            )}
          </ListGroup>
        </div>
      </Card>
      {editingPiece && (
        <PieceEditNameModal
          onUpdate={onUpdate}
          piece={editingPiece}
          onClose={() => setEditingPiece(null)}
        />
      )}
    </>
  );
};

LineItemPieces.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  pieces: PropTypes.arrayOf(pieceResourceType).isRequired,
  printsByPieceUri: PropTypes.shape({}).isRequired,
  piecesModifiedWorkFlowList: PropTypes.shape({}).isRequired,
  workflowsByUri: PropTypes.shape({}).isRequired,
  printsByUri: PropTypes.shape({}).isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export default LineItemPieces;
