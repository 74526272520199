// eslint-disable-next-line import/prefer-default-export
export const FEATURES = {
  AUTORUN: 'autorun',
  AUTOLOGOUT: 'autologout',
  IP_SENSITIVITY: 'ip-sensitivity',
  NO_MODEL_UPLOAD: 'no_model_upload',
  ORDER_ORDER_OWNER: 'order-order-owner',
  ORDER_DOCUMENTS: 'order-documents',
  ORDER_DUE_DATE: 'order-due-date',
  BOEING_ORDER_FIELDS: 'boeing-order-fields',
  THREE_M_MAIN_BUREAU_ORDER_FIELDS: '3M-main-bureau-order-fields',
  BEEHIVE3D_ORDER_FIELDS: 'beehive3d-order-fields',
  XEROX3DP_FIELDS: 'xerox-3dp-production-order-fields',
  AUTHENTISE_PDM: 'authentise-pdm',
  GKN_POWERBED_ORDER_FIELDS: 'gkn-powderbed-order-fields',
  THREEDPC_ORDER_FIELDS: '3dpc-order-fields',
  DESIGN_COST: 'design-cost',
  NETFABB: 'netfabb',
  TRAVELER: 'traveler',
  ORDER_BUSINESS_SEGMENT: 'order-business-segment',
  ORDER_LOCATION: 'order-location',
  ORDER_ORDER_TYPE: 'order-order-type',
  ORDER_QUOTE: 'order-quote',
  PRIORITY: 'priority',
  SERVICE_PROVIDERS: 'service-providers',
  THIRD_PARTY_PROVIDERS: 'third-party-providers',
  MANUFACTURING_EXECUTION_SYSTEM: 'manufacturing-execution-system',
  MACHINE_ANALYTICS: 'machine-analytics',
  EXTERNAL_PRODUCTION_ESTIMATE: 'external_production_estimate',
  RECALCULATION_TRIGGER: 'recalculation_trigger',
  QR_PRINTS_TRAVELER: 'qr-prints-traveler',
  USER_CAN_UPDATE_ESTIMATES: 'user-can-update-estimates',
  MODEL_UNIT_DEFAULT_MM: 'model-unit-default-mm',
  SPECIMEN_LIBRARY: 'specimen-library',
  MODEL_LIBRARY: 'model-library',
  AUTO_SCHEDULE_RUNS: 'auto-schedule-runs',
  USE_100_INFILL: 'use_100_infill',
  EXTERNAL_MATERIAL_DB: 'external-material-db',
  RESTRICTED_USER_UPLOAD_FROM_MODEL_LIBRARY_ONLY: 'restricted-user-upload-from-model-library-only',
  LINE_ITEM_EXTENDED_DETAILS: 'line-item-extended-details',
  ORDER_PACKING_SLIP: 'order-packing-slip',
  LINE_ITEM_WORK_STEP_QUOTE_DETAILS: 'line-item-work-step-quote-details',
  ORDER_QUOTE_AVAILABLE_FOR_RESTRICTED: 'order-quote-available-for-restricted',
  MATERIAL_MANAGEMENT: 'material-management',
  SELF_REGISTRATION_ALLOWED: 'self-registration-allowed',
  SERVICE_PROVIDER_AT_STEPS: 'service-provider-at-steps',
  PREP_WORKFLOW: 'prep-workflow',
  RICOH_REASON_CODE: 'ricoh-reason-code',
  HAWKING_DEPLOYMENT: 'hawking-deployment',
  GROUP_QUALIFICATIONS: 'group-qualifications',
  ANATOMICAL_MODEL_TO_PRODUCT: 'anatomical-model-to-product',
  SHIPMENT_FOR_ORDER: 'shipment-for-order',
  SHIPMENT_PACKING_SLIP: 'shipment-packing-slip',
  RICOH_USA_ORDER_FIELDS: 'ricoh-usa-order-fields',
  ZVERSE_CONVERSION: 'zverse-conversion',
  INTEGRATION_ZVERSE: 'integration-zverse',
  NATIVE_CAD_TO_STL_CONVERSION: 'native-cad-to-stl-conversion',
  BASIC_MODEL_CONVERSION: 'integration-basic-model-conversion',
  GENERAL_MFG_LANGUAGE: 'general-mfg-language',
  NEBUMIND: 'nebumind',
  STANLEY_X_DEPLOYMENT: 'stanley-x-deployment',
  CONTACTLESS_LOGON: 'contactless_logon_enabled',
  ORDER_CUSTOMER_PO: 'order-customer-po',
  ORDER_QUOTE_NUMBER: 'order-quote-number',
  ORDER_SALES_REPRESENTATIVE: 'order-sales-representative',
  EOS_ORDER_FIELDS: 'eos-order-fields',
  FASTRADIUS_ORDER_FIELDS: 'fastradius-order-fields',
  SINTAVIA_ORDER_FIELDS: 'sintavia-order-fields',
  MERCK_ORDER_FIELDS: 'merck-order-fields',
  THREE_M_AQUA_ORDER_FIELDS: '3M-aqua-order-fields',
  OERLIKON_ORDER_FIELDS: 'oerlikon-order-fields',
  // Temporary feature. Will be removed once functionality is no longer needed
  ANATOMICAL_MODEL: 'anatomical-model',
  MESH_HEALING: 'mesh-healing',
  GUIDELINES_ENGINE: 'rules-engine',
  CERTIFIED_BUILDS: 'build-file-library',

  WORK_SCHEDULE: 'work-schedule',
  WORKSTEP_ESTIMATE: 'workstep-estimate',
  WIP_MATRIX: 'wip-matrix',
  DIGITAL_DESIGN_WAREHOUSE: 'digital-design-warehouse',
  MATERIAL_TEST_PANEL: 'material-test-panel',
  PACK3D_PREMIUM: 'pack3d-premium',
  THREADS_INTEGRATION: 'threads-integration',
  INTEGRATION_CASTOR: 'integration-castor',
  ADDITIVE_WORKFLOW: 'additive-workflow',
  POWDER_WORKFLOW: 'powder-workflow',
  MSNAV_IMPORTER: 'msnav-importer',
  ROBOZE_DDW: 'roboze-ddw',
  ROBOZE_GIGAFACTORY_BUREAU_ORDER_FIELDS: 'roboze-gigafactory-bureau-order-fields',
  AMAZON_ORDER_FIELDS: 'amazon-order-fields',
  SENSORS: 'sensors',
  PAYMENT_SYSTEM: 'payment-system',
  INTEGRATION_STRIPE: 'integration-stripe',
  JENI_CLUSTER: 'jeni-cluster',
  POC_UK_ORDER_FIELDS: 'the-poc-uk-order-fields',
  AUTO_CREATE_SLICE: 'slicing',
  PRESS_TO_PRINT: 'press-to-print',
  RESTRICTED_USER_ORDER_DOCUMENTS: 'restricted-user-order-documents',
  SECURE_FILE_CHECKOUT: 'secure-file-checkout',
  TOOLING_STOCK: 'tooling-stock',
  VENDOR_SERVICE_PROVIDER: 'vendor-service-provider',
  DESIGN_CONVERSION_BY_EXPERT: 'design-conversion-by-expert',
  THREADS_COMMENTS_IN_FLOWS: 'threads-comments-in-flows',
  EATON_ORDER_FIELDS: 'eaton-order-fields',
};
