import Loading from 'rapidfab/components/Loading';
import React, { useEffect, useRef } from 'react';

import 'rapidfab/styles/hawking/main.scss';
import {
  Button,
  FormControl,
  FormGroup,
  FormLabel,
  Modal,
} from 'react-bootstrap';
import { Field, Form } from 'react-final-form';
import { FormattedMessage } from 'rapidfab/i18n';
import { MACHINE_TYPE_OPTIONS_VALUES } from 'rapidfab/constants/machineTypes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faUpload, faSave } from '@fortawesome/free-solid-svg-icons';
import _capitalize from 'lodash/capitalize';
import PropTypes from 'prop-types';
import { finalFormInputTypes } from 'rapidfab/types';
import { SLICER_TYPES_LIST } from 'rapidfab/constants/slicerTypes';
import Select from 'react-select';

const CreateSlicerConfigurationModal = ({
  handleSlicerFormSubmit,
  isOpen,
  onClose,
  fileSelected: { fileValue, setFile },
  machineResources,
  isFetching,
  isSubmitting,
  getAllSlicerConfigs,
  allSlicerConfigsFetched,
}) => {
  const fileInputRef = useRef(null);
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async event => {
    const file = event.target.files[0];
    setFile(file);
  };

  useEffect(() => {
    if (isOpen && !allSlicerConfigsFetched) {
      getAllSlicerConfigs();
    }
  }, [isOpen, allSlicerConfigsFetched]);

  return (
    <>
      <Modal
        show={isOpen}
        onHide={onClose}
        backdrop="static"
      >
        <Form
          onSubmit={handleSlicerFormSubmit}
          render={({ handleSubmit, values }) => {
            const formattedMachineOptions = machineResources[values.machine_type]?.map(machineOption => ({
              label: machineOption.name,
              value: machineOption.uri,
            })).filter(Boolean);

            return (
              <form onSubmit={handleSubmit}>
                <Modal.Header closeButton={!isSubmitting}>
                  <Modal.Title>
                    Create Slicer Configuration
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {
                    <>
                      <FormGroup className="form-group" controlId="slicerName">
                        <FormLabel>
                          <FormattedMessage id="field.slicerName" defaultMessage="Slicer Configuration Name" />: *
                        </FormLabel>
                        <Field
                          name="name"
                          type="text"
                          render={props => (
                            <FormControl
                              required
                              {...props.input}
                            />
                          )}
                        />
                      </FormGroup>
                      <FormGroup className="form-group" controlId="slicer_description">
                        <FormLabel>
                          <FormattedMessage id="field.description" defaultMessage="Description" />: *
                        </FormLabel>
                        <Field
                          name="description"
                          render={props => (
                            <FormControl
                              required
                              {...props.input}
                              as="textarea"
                            />
                          )}
                        />
                      </FormGroup>
                      {
                        values.machine_type && (
                          <FormGroup className="form-group" controlId="machineType">
                            <FormLabel>
                              <FormattedMessage
                                id="field.machine"
                                defaultMessage={`Select A ${MACHINE_TYPE_OPTIONS_VALUES[values.machine_type]}`}
                                values={{ machineType: MACHINE_TYPE_OPTIONS_VALUES[values.machine_type] }}
                              />:
                              *
                            </FormLabel>
                            <Field
                              name={values.machine_type}
                              render={props => (
                                <Select
                                  {...props.input}
                                  key={props.input.value}
                                  aria-label="Default select example"
                                  placeholder="Select an option"
                                  value={formattedMachineOptions
                                    .find(option => option.value === props.input.value) || null}
                                  options={formattedMachineOptions}
                                  required
                                  onChange={option =>
                                    props.input.onChange(option ? option.value : '')}
                                  isClearable
                                />
                              )}
                            />
                          </FormGroup>
                        )
                      }
                      {/* Will be inputed in slicer 1.0
                <FormGroup className="form-group" controlId="material">
                  <FormLabel>
                    <FormattedMessage id="field.machineTypes" defaultMessage="Select A Material" />: *
                  </FormLabel>
                  <Field
                    name="material"
                    render={props => (
                      <FormControl
                        as="select"
                        aria-label="Default select example"
                        required
                        {...props.input}
                      >
                        <option value="">Select an option</option>
                        {MACHINE_TYPE_LIST.map(type => (
                          <option key={type} value={type}>{type}</option>
                        ))}
                      </FormControl>
                    )}
                  />
                </FormGroup> */}
                      <FormGroup className="form-group" controlId="slicer_type">
                        <FormLabel>
                          <FormattedMessage id="field.slicerType" defaultMessage="Select A Slicer Type" />: *
                        </FormLabel>
                        <Field
                          name="engine"
                          render={props => (
                            <FormControl
                              required
                              as="select"
                              aria-label="Default select example"
                              {...props.input}
                            >
                              <option value="">Select an option</option>
                              {SLICER_TYPES_LIST.map(type => (
                                <option key={type} value={type}>{_capitalize(type)}</option>
                              ))}
                            </FormControl>
                          )}
                        />
                      </FormGroup>
                    </>
                  }
                  <FormGroup
                    className="form-group"
                    controlId="slicer_config"
                  >
                    <Field
                      type="file"
                      name="content"
                      render={props => (
                        <FormControl
                          {...props.input}
                          type="file"
                          name="content"
                          ref={fileInputRef}
                          className="visually-hidden"
                          onChange={event => {
                            handleFileChange(event);
                            props.input.onChange(event);
                          }}
                          required
                        />
                      )}
                    />
                  </FormGroup>
                  {fileValue && (
                    <div className="m-b-md">
                      {fileValue.name}
                      <button
                        className="spacer-left btn-xs btn-danger mr15"
                        type="button"
                        onClick={() => setFile(null)}
                        tabIndex={0}
                      >
                        <FontAwesomeIcon icon={faTimes} />
                      </button>
                    </div>
                  )}
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="primary"
                    disabled={isSubmitting}
                    onClick={handleButtonClick}
                  >
                    <FontAwesomeIcon className="spacer-right" icon={faUpload} />
                    <FormattedMessage id="button.upload" defaultMessage="Upload" />
                  </Button>

                  <Button
                    variant="success"
                    type="submit"
                    disabled={isFetching || isSubmitting}
                  >
                    {
                      isSubmitting ? (
                        <>
                          <Loading className="spacer-right" inline />
                          <FormattedMessage id="status.uploading" defaultMessage="Uploading" />
                        </>
                      ) : (
                        <>
                          <FontAwesomeIcon className="spacer-right" icon={faSave} />
                          <FormattedMessage id="button.save" defaultMessage="Save" />
                        </>
                      )
                    }
                  </Button>
                </Modal.Footer>
              </form>
            );
          }}
        />
      </Modal>

    </>
  );
};

CreateSlicerConfigurationModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  handleSlicerFormSubmit: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  fileSelected: PropTypes.shape({
    fileValue: PropTypes.string.isRequired,
    setFile: PropTypes.func.isRequired,

  }).isRequired,
  machineResources: PropTypes.shape({}).isRequired,
  isFetching: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  input: finalFormInputTypes.isRequired,
  getAllSlicerConfigs: PropTypes.func.isRequired,
  allSlicerConfigsFetched: PropTypes.bool.isRequired,
};

export default CreateSlicerConfigurationModal;
