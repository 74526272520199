import React from 'react';
import PropTypes from 'prop-types';
import { assemblyPartMetaResourceType } from 'rapidfab/types';
import { Card, ListGroup, ListGroupItem } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import CoPrintPartRowContainer from 'rapidfab/containers/records/order/CoPrintPartRowContainer';

const CoPrintParts = ({ assemblyPartsMeta, isReadOnly }) => (
  <Card bg="dark" className="m-b">
    <Card.Header className="pd-exp accent">
      <FormattedMessage id="parts" defaultMessage="Parts" />
    </Card.Header>
    <div className="card-body-wrapper-accent">
      <Card.Body>
        <ListGroup fill>
          {assemblyPartsMeta.map(({ part_line_item: partLineItemUri }) => (
            <ListGroupItem key={partLineItemUri} className="header-item">
              <CoPrintPartRowContainer lineItemUri={partLineItemUri} isReadOnly={isReadOnly} />
            </ListGroupItem>
          ))}
        </ListGroup>
      </Card.Body>
    </div>
  </Card>
);

CoPrintParts.propTypes = {
  assemblyPartsMeta: PropTypes.arrayOf(assemblyPartMetaResourceType).isRequired,
  isReadOnly: PropTypes.bool.isRequired,
};

export default CoPrintParts;
