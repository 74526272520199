import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Card, Tab, Tabs } from 'react-bootstrap';
import Estimates from 'rapidfab/components/records/order/edit/Estimates';
import ActualsContainer from 'rapidfab/containers/records/order/ActualsContainer';
import { FEATURES, USER_HIDE_INFO_TYPES } from 'rapidfab/constants';
import Feature from 'rapidfab/components/Feature';
import {
  getBaseAndSupportMaterialsForLineItem,
  getBureauSettings,
  getCurrentUserRoleMax,
  getModelForLineItem,
  getUUIDResource,
} from 'rapidfab/selectors';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import { connect, useSelector } from 'react-redux';
import EditLineItemEstimationModal from 'rapidfab/components/records/order/edit/EditLineItemEstimationModal';
import LineItemEstimationViewModalContainer
  from 'rapidfab/containers/records/order/LineItemEstimationViewModalContainer';

const ESTIMATE_MODAL = {
  EDIT: 'edit',
  VIEW: 'view',
};

const TABS = {
  ESTIMATES: 'estimates',
  ACTUALS: 'actuals',
};

const EstimatesAndActuals = ({
  lineItemUri,
  noEstimatesAvailable,
}) => {
  const lineItem = useSelector(state => getUUIDResource(state, extractUuid(lineItemUri)));
  const bureauSettings = useSelector(getBureauSettings);
  const model = useSelector(state => getModelForLineItem(state, lineItem));
  const baseAndSupportMaterials = useSelector(state => getBaseAndSupportMaterialsForLineItem(state, lineItem));
  const role = useSelector(getCurrentUserRoleMax);
  const hideFinancial = role?.hide_info === USER_HIDE_INFO_TYPES.FINANCIAL;

  const {
    estimates,
    design_time: designTime,
  } = lineItem;

  const estimatesWithDesignCost = { ...estimates };

  const hourlyDesignCost = bureauSettings ? bureauSettings.hourly_design_cost : null;

  if (designTime && hourlyDesignCost) {
    const designHours = designTime / 60 / 60;
    estimatesWithDesignCost.design_cost = (designHours * hourlyDesignCost);
  }

  const [activeTab, setActiveTab] = useState(TABS.ESTIMATES);
  const [estimationModal, setEstimationModal] = useState(null);

  return (
    <>
      <Tabs
        id={`estimates-and-actuals-tabs-${lineItemUri}`}
        activeKey={activeTab}
        onSelect={tabName => setActiveTab(tabName)}
        className="mt15"
      >
        <Tab eventKey={TABS.ESTIMATES} title="Estimates" />
        <Tab eventKey={TABS.ACTUALS} title="Actuals" />
      </Tabs>
      <Card bg="dark" className="m-b" data-cy="estimates-actuals-card">
        <div className="card-body-wrapper-info">
          {activeTab === TABS.ESTIMATES && (
            <>
              <Estimates
                estimates={estimates}
                model={model}
                lineItem={lineItem}
                onEstimationEditClick={() => setEstimationModal(ESTIMATE_MODAL.EDIT)}
                onEstimationViewClick={() => setEstimationModal(ESTIMATE_MODAL.VIEW)}
                baseAndSupportMaterials={baseAndSupportMaterials}
                hideFinancial={hideFinancial}
                noEstimatesAvailable={noEstimatesAvailable}
              />
              {estimationModal === ESTIMATE_MODAL.EDIT && (
                <Feature featureName={FEATURES.USER_CAN_UPDATE_ESTIMATES}>
                  <EditLineItemEstimationModal
                    lineItemUri={lineItemUri}
                    onClose={() => setEstimationModal(null)}
                    hideFinancial={hideFinancial}
                  />
                </Feature>
              )}
              {!hideFinancial && estimationModal === ESTIMATE_MODAL.VIEW && (
                <LineItemEstimationViewModalContainer
                  lineItemUri={lineItemUri}
                  onClose={() => setEstimationModal(null)}
                />
              )}
            </>
          )}
          {activeTab === TABS.ACTUALS && (
            <ActualsContainer
              lineItemUri={lineItemUri}
            />
          )}
        </div>
      </Card>
    </>
  );
};

EstimatesAndActuals.propTypes = {
  lineItemUri: PropTypes.string.isRequired,
  baseAndSupportMaterials: PropTypes.shape({}).isRequired,
  noEstimatesAvailable: PropTypes.bool,
};

EstimatesAndActuals.defaultProps = {
  noEstimatesAvailable: false,
};

const mapStateToProps = (state, { lineItemUri }) => {
  const lineItem = getUUIDResource(state, extractUuid(lineItemUri));
  const bureauSettings = getBureauSettings(state);
  const model = getModelForLineItem(state, lineItem);
  const baseAndSupportMaterials = getBaseAndSupportMaterialsForLineItem(state, lineItem);

  const role = getCurrentUserRoleMax(state);
  const hideFinancial = role?.hide_info === USER_HIDE_INFO_TYPES.FINANCIAL;

  const {
    estimates,
    design_time: designTime,
  } = lineItem;

  const estimatesWithDesignCost = { ...estimates };

  const hourlyDesignCost = bureauSettings ? bureauSettings.hourly_design_cost : null;

  if (designTime && hourlyDesignCost) {
    const designHours = designTime / 60 / 60;
    estimatesWithDesignCost.design_cost = (designHours * hourlyDesignCost);
  }

  return {
    lineItem,
    model,
    estimates: estimatesWithDesignCost,
    hideFinancial,
    baseAndSupportMaterials,
  };
};

export default connect(mapStateToProps)(EstimatesAndActuals);
