import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import {
  Button, ButtonGroup,
  ButtonToolbar,
  Col,
  Container,
  Dropdown,
  FormControl,
  FormGroup,
  FormLabel,
  InputGroup,
  Row,
  SplitButton,
} from 'react-bootstrap';
import Tooltip from 'rapidfab/components/Tooltip';
import SaveButtonTitle from 'rapidfab/components/SaveButtonTitle';
import Loading from 'rapidfab/components/Loading';

import { finalFormInputTypes } from 'rapidfab/types';
import { FormControlTextArea } from 'rapidfab/components/formTools';

import BreadcrumbNav from 'rapidfab/components/BreadcrumbNav';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import { ANATOMICAL_MODEL_STATUSES, ROUTES } from 'rapidfab/constants';
import { getShortUUID } from 'rapidfab/utils/uuidUtils';
import AnatomicalModelAssembliesContainer from 'rapidfab/containers/AnatomicalModel/AnatomicalModelAssembliesContainer';

import { Field, Form } from 'react-final-form';
import isEqual from 'lodash/isEqual';
import { faBan, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import _uniq from 'lodash/uniq';
import Fa from 'react-fontawesome';

// No new translations/breadcrumbs/etc. were created for this component since it is a temporary one
// and it will be deleted once functionality is no longer needed

const DownloadButton = ({
  anatomicalModel,
  recreateBuildFile,
  isSubmitting,
  invalidParts,
}) => {
  if (!anatomicalModel || isSubmitting) {
    return null;
  }

  const recreateBuildFileButton = (
    <Button
      onClick={() => recreateBuildFile(anatomicalModel.uuid)}
      size="sm"
      className="spacer-right spacer-left"
      variant="default"
      disabled={
        anatomicalModel.build_file_status ===
        ANATOMICAL_MODEL_STATUSES.PROCESSING
      }
    >
      {(anatomicalModel.build_file_status === ANATOMICAL_MODEL_STATUSES.PROCESSING || isSubmitting) && (
        <Loading inline className="spacer-right" />
      )}
      Recreate Build File
    </Button>
  );
  if (
    [
      ANATOMICAL_MODEL_STATUSES.PENDING,
      ANATOMICAL_MODEL_STATUSES.PROCESSING,
    ].includes(anatomicalModel.build_file_status)
  ) {
    return recreateBuildFileButton;
  }

  if (invalidParts.length) {
    return (
      <>
        <Tooltip
          id="zip-failed"
          trigger={(
            <span className="btn btn-warning-outline btn-sm">
              <FontAwesomeIcon icon={faExclamationCircle} />
            </span>
          )}
        >
          Some parts are invalid due to changed printer technology.
        </Tooltip>
      </>
    );
  }

  if (anatomicalModel.build_file_status === ANATOMICAL_MODEL_STATUSES.ERROR) {
    return (
      <>
        <Tooltip
          id="zip-failed"
          trigger={(
            <span className="btn btn-warning-outline btn-sm">
              <FontAwesomeIcon
                icon={faExclamationCircle}
                className="btn btn-warning-outline btn-sm"
              />
            </span>
          )}
        >
          {anatomicalModel.notes || 'Failed to generate ZIP file'}
        </Tooltip>
        {recreateBuildFileButton}
      </>
    );
  }

  // For all other statuses - render Download link
  return (
    <>
      <a
        className="btn btn-info-outline btn-sm"
        href={anatomicalModel.build_file}
      >
        Download ZIP
      </a>
      {recreateBuildFileButton}
    </>
  );
};

DownloadButton.propTypes = {
  anatomicalModel: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    notes: PropTypes.string,
    build_file: PropTypes.string,
    build_file_status: PropTypes.string.isRequired,
  }),
  recreateBuildFile: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  invalidParts: PropTypes.arrayOf(PropTypes.string).isRequired,
};

DownloadButton.defaultProps = {
  anatomicalModel: null,
};

const AnatomicalModel = ({
  onSave,
  onDelete,
  isSubmitting,
  anatomicalModel,
  recreateBuildFile,
  initialFormValues,
  invalidParts,
  setInvalidParts,
}) => (
  <Container fluid>
    <BreadcrumbNav
      breadcrumbs={[
        {
          href: getRouteURI(ROUTES.ANATOMICAL_MODELS),
          message: 'Assemblies',
        },
        {
          href: '',
          message: initialFormValues.uuid ? getShortUUID(initialFormValues.uuid) : 'New',
        },
      ]}
    />
    <Form
      onSubmit={onSave}
      initialValues={initialFormValues}
      initialValuesEqual={isEqual}
      validate={values => {
        const errors = {};
        const re = /["*/:<>?\\|]/g;
        if (values.case_id) {
          const matches = [...values.case_id.matchAll(re)].flatMap(match => match);
          if (matches.length) {
            errors.case_id = `Invalid character(s): ${_uniq(matches.map(match => match[0])).join(', ')}`;
          }
        }
        if (values.version && values.version < 0) {
          errors.version = 'Version must be equal or greater than 0';
        }
        return errors;
      }}
      render={({ handleSubmit, errors, invalid }) => (
        <form onSubmit={handleSubmit}>
          <div className="clearfix">
            <ButtonToolbar className="pull-right">
              <ButtonGroup>
                <DownloadButton
                  anatomicalModel={anatomicalModel}
                  recreateBuildFile={recreateBuildFile}
                  isSubmitting={isSubmitting}
                  invalidParts={invalidParts}
                />
              </ButtonGroup>
              <SplitButton
                id="uxSaveDropdown"
                type="submit"
                variant="success"
                size="sm"
                title={isSubmitting ? <Loading inline /> : <SaveButtonTitle />}
                pullRight
                disabled={isSubmitting || invalid}
              >
                <Dropdown.Item
                  eventKey={1}
                  onClick={() => onDelete(initialFormValues.uuid)}
                  disabled={!initialFormValues.uuid}
                >
                  <FontAwesomeIcon icon={faBan} />{' '}
                  <FormattedMessage id="button.delete" defaultMessage="Delete" />
                </Dropdown.Item>
              </SplitButton>
            </ButtonToolbar>
          </div>

          <hr />

          <Row>
            <Col xs={6}>
              <FormGroup className="mb-2">
                <FormLabel>
                  <FormattedMessage id="field.name" defaultMessage="Name" />: *
                </FormLabel>
                <Field
                  name="name"
                  type="text"
                  render={props => (
                    <FormControl {...props.input} required />
                  )}
                />
              </FormGroup>
              <FormGroup className="mb-2">
                <FormLabel>
                  <FormattedMessage
                    id="field.description"
                    defaultMessage="Description"
                  />
                  :
                </FormLabel>
                <FormControlTextArea name="description" />
              </FormGroup>
              <FormGroup className="mb-2">
                <FormLabel>Product / Package Version:</FormLabel>
                <Field
                  name="version"
                  type="number"
                  render={props => (
                    <FormControl {...props.input} />
                  )}
                />
                {errors.version && <div className="my-1 text-danger">{errors.version}</div>}
              </FormGroup>
              <FormGroup className="mb-2">
                <FormLabel>Scale to Print: *</FormLabel>
                <InputGroup>
                  <Field
                    name="scale_to_print"
                    type="number"
                    render={props => (
                      <FormControl {...props.input} required />
                    )}
                  />
                  <InputGroup.Text>%</InputGroup.Text>
                </InputGroup>
              </FormGroup>
            </Col>
            <Col xs={6}>
              <FormGroup className="mb-2">
                <FormLabel>Medical Record Number: *</FormLabel>
                <Field
                  name="medical_record_number"
                  type="text"
                  render={props => (
                    <FormControl {...props.input} required />
                  )}
                />
              </FormGroup>
              <FormGroup className="mb-2">
                <FormLabel>Case ID: *</FormLabel>
                <Field
                  name="case_id"
                  type="text"
                  render={props => (
                    <FormControl {...props.input} required />
                  )}
                />
                {errors.case_id && <div className="my-1 text-danger">{errors.case_id}</div>}
              </FormGroup>
              <FormGroup className="mb-2">
                <FormLabel>
                  <span style={{ marginRight: '1rem' }}>
                    Date Sent: *
                  </span>
                  <Tooltip trigger={<Fa name="info-circle" />} id="date-time-info">
                    Date time in your local timezone
                  </Tooltip>
                </FormLabel>
                <Field
                  name="date_sent"
                  render={props => {
                    const field = { ...props.input, ...props.meta };
                    return (
                      <FormControl
                        type="datetime-local"
                        {...field}
                        required
                      />
                    );
                  }}
                />
              </FormGroup>
              <FormGroup className="mb-2">
                <FormLabel>Accession Number: *</FormLabel>
                <Field
                  name="accession_number"
                  type="text"
                  render={props => (
                    <FormControl {...props.input} required />
                  )}
                />
              </FormGroup>
            </Col>
          </Row>
        </form>
      )}
    />
    {anatomicalModel && (
      <Row>
        <Col xs={12} className="mt15">
          <AnatomicalModelAssembliesContainer
            anatomicalModelUri={anatomicalModel.uri}
            invalidParts={invalidParts}
            setInvalidParts={setInvalidParts}
          />
        </Col>
      </Row>
    )}
  </Container>
);

AnatomicalModel.propTypes = {
  initialFormValues: PropTypes.shape({
    uuid: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    version: PropTypes.number,
    scale_to_print: PropTypes.number,
    medical_record_number: PropTypes.string,
    case_id: PropTypes.string,
    accession_number: PropTypes.string,
    date_sent: PropTypes.string,
  }).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  input: finalFormInputTypes.isRequired,
  meta: PropTypes.shape({}).isRequired,
  anatomicalModel: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    uri: PropTypes.string.isRequired,
    build_file: PropTypes.string,
    build_file_status: PropTypes.string.isRequired,
  }),
  recreateBuildFile: PropTypes.func.isRequired,
  invalidParts: PropTypes.arrayOf(PropTypes.string).isRequired,
  setInvalidParts: PropTypes.func.isRequired,
};

AnatomicalModel.defaultProps = {
  anatomicalModel: null,
};

export default AnatomicalModel;
