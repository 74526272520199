import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Col, Form as BSForm, Card, Button, ButtonToolbar } from 'react-bootstrap';
import Alert from 'rapidfab/utils/alert';
import Actions from 'rapidfab/actions';

import EditRestrictedOrderFormContainer from 'rapidfab/containers/records/order/restricted/EditRestrictedOrderFormContainer';
import CommentsContainer from 'rapidfab/containers/CommentsContainer';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import { getCustomOrderFieldReferences, getUUIDResource } from 'rapidfab/selectors';
import {
  API_RESOURCES,
  COMMENT_RELATED_TABLE_NAMES,
  ORDER_DELETABLE_STATUSES,
  ORDER_STATUS,
  ROUTES,
} from 'rapidfab/constants';
import { orderResourceType } from 'rapidfab/types';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import SaveButton from 'rapidfab/components/SaveButton';

import { connect } from 'react-redux';
import { Form } from 'react-final-form';

import { createOrReplaceArray } from 'rapidfab/utils/arrayUtils';
import getInitialCustomFieldValues from 'rapidfab/utils/getInitialCustomFieldValues';
import { ORDER_SUMMARY_RESTRICTED } from 'rapidfab/constants/forms';
import COMMENT_RESOURCE_TYPES from 'rapidfab/constants/CommentResourceType';
import DeleteButton from './DeleteButton';

const PanelHeader = () => (
  <FormattedMessage id="record.order.summary" defaultMessage="Order Summary" />
);

class OrderSummary extends Component {
  constructor(props) {
    super(props);

    this.onCancel = this.onCancel.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.goToEditLineItem = this.goToEditLineItem.bind(this);
    this.handleGetOrderOwner = this.handleGetOrderOwner.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { loading, orderPut } = this.props;

    if (loading !== prevProps.loading && loading === false) {
      const isSuccessResponse = orderPut.errors.length === 0;
      if (isSuccessResponse) {
        Alert.success(<FormattedMessage
          id="toaster.order.successfullyUpdated"
          defaultMessage="Order successfully updated."
        />);
        this.goToEditLineItem();
      }
    }
  }

  handleGetOrderOwner() {
    const { order } = this.props;
    if (!order?.order_owner) {
      return null;
    }
    return this.props.dispatch(Actions.Api.nautilus[API_RESOURCES.USERS].get(extractUuid(order.order_owner)));
  }

  onCancel() {
    const payload = { status: ORDER_STATUS.CANCELLED };
    this.props.dispatch(
      Actions.Api.nautilus[API_RESOURCES.ORDER].put(this.props.uuid, payload),
    );
  }

  onDelete() {
    this.props
      .dispatch(Actions.Api.nautilus[API_RESOURCES.ORDER].delete(this.props.uuid))
      .then(() => {
        window.location.hash = getRouteURI(ROUTES.ORDERS);
      })
      .catch(error => {
        console.error(error.message);
      });
  }

  onSubmit(formValues) {
    const payload = formValues;
    Object.keys(payload).forEach(key => {
      if (payload[key] === 'none') {
        payload[key] = null;
      }
    });

    if (payload.due_date) {
      const date = new Date(payload.due_date);
      payload.due_date = date.toISOString();
    }

    this.props.dispatch(Actions.Api.nautilus[API_RESOURCES.ORDER].put(this.props.uuid, payload))
      .then(() => this.props.dispatch(Actions.Api.nautilus[API_RESOURCES.ORDER].get(this.props.uuid)))
      .catch(error => {
        console.error(error.message);
      });
  }

  onCustomFieldChange([field, customField], state, { changeValue }) {
    const customFieldValues = state.formState.values.custom_field_values;

    const customFieldValuesReplaced = createOrReplaceArray(
      customFieldValues,
      { custom_field: customField.customFieldReferenceUri },
      { value: customField.value },
    );

    changeValue(state, field, () => (customFieldValuesReplaced));
  }

  initCustomFieldValues([field], state, { changeValue }) {
    const customFieldValues = state.formState.values.custom_field_values;

    const updatedCustomOrderFieldValues = getInitialCustomFieldValues(
      this.props?.customOrderFieldReferences, customFieldValues,
    );
    changeValue(state, field, () => (updatedCustomOrderFieldValues));
  }

  goToEditLineItem() {
    window.location.hash = getRouteURI(ROUTES.EDIT_RESTRICTED_LINE_ITEM_ORDER, {
      uuid: this.props.uuid,
    });
  }

  render() {
    const { goToEditLineItem, onSubmit, onCancel, onDelete } = this;
    const { disableOrderSave, order, initialValues, robozeDigitalAndCastorFeaturesEnabled } = this.props;

    const isDeletable = ORDER_DELETABLE_STATUSES.includes(order.status);
    return (
      <div>
        <Form
          onSubmit={onSubmit}
          initialValues={initialValues}
          mutators={{
            initCustomFieldValues: this.initCustomFieldValues,
            onCustomFieldChange: this.onCustomFieldChange,
          }}
          render={({ handleSubmit, form, values }) => (
            <BSForm {...this.props} horizontal onSubmit={handleSubmit}>
              <Card bg="dark">
                <Card.Header className="pd-exp inverse">
                  <PanelHeader />
                </Card.Header>
                <Card.Body>
                  <Col xs={12} md={12}>
                    {/* eslint-disable react/no-string-refs */}
                    <EditRestrictedOrderFormContainer
                      initCustomFieldValues={form.mutators.initCustomFieldValues}
                      onCustomFieldChange={form.mutators.onCustomFieldChange}
                      formValues={values}
                      handleGetOrderOwner={this.handleGetOrderOwner}
                    />
                    {/* eslint-enable react/no-string-refs */}
                    <CommentsContainer
                      resourceTableName={COMMENT_RELATED_TABLE_NAMES.ORDER}
                      resourceUUID={order.uuid}
                      resourceType={COMMENT_RESOURCE_TYPES.ORDER}
                    />
                  </Col>
                </Card.Body>
              </Card>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row-reverse',
                }}
                className="m-b"
              >
                <ButtonToolbar className="clearfix d-flex align-items-start">
                  {isDeletable && (
                    <div className="pull-left" style={{ 'margin-right': '8px' }}>
                      <DeleteButton
                        onCancel={onCancel}
                        onDelete={onDelete}
                        resourceName={!robozeDigitalAndCastorFeaturesEnabled ? 'Order' : ''}
                        disabled={this.props.loading}
                      />
                    </div>
                  )}
                  <div className="pull-right">
                    {disableOrderSave ? (
                      <Button className="mt-3" variant="primary" onClick={goToEditLineItem}>
                        Next step: Edit Line Items
                      </Button>
                    ) : (
                      <SaveButton
                        isSaving={this.props.loading}
                        resourceName={!robozeDigitalAndCastorFeaturesEnabled ? 'Order' : ''}
                      />
                    )}
                  </div>
                </ButtonToolbar>
              </div>
            </BSForm>
          )}
        />
      </div>
    );
  }
}

OrderSummary.propTypes = {
  dispatch: PropTypes.func.isRequired,
  uuid: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  initialValues: PropTypes.shape({}).isRequired,
  orderPut: PropTypes.shape({
    errors: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  order: orderResourceType.isRequired,
  lineItem: PropTypes.shape({
    errors: PropTypes.arrayOf(PropTypes.shape({})),
    fetching: PropTypes.bool.isRequired,
  }).isRequired,
  disableOrderSave: PropTypes.bool.isRequired,
  // Used in mapStateToProps
  // eslint-disable-next-line react/no-unused-prop-types
  orderUri: PropTypes.string.isRequired,
  customOrderFieldReferences: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  robozeDigitalAndCastorFeaturesEnabled: PropTypes.bool.isRequired,
};

const mapStateToProps = (state, { orderUri }) => {
  const order = getUUIDResource(state, extractUuid(orderUri));
  const customOrderFieldReferences = getCustomOrderFieldReferences(state);
  const initialFormValues = {};
  Object
    .keys(order)
    .filter(key => ORDER_SUMMARY_RESTRICTED.FIELDS.includes(key))
    .forEach(key => {
      initialFormValues[key] = order[key];
    });
  return {
    uuid: extractUuid(orderUri),
    loading: state.ui.nautilus[API_RESOURCES.ORDER].put.fetching,
    orderPut: state.ui.nautilus[API_RESOURCES.ORDER].put,
    order,
    lineItem: state.ui.nautilus[API_RESOURCES.LINE_ITEM].put,
    // TODO: Historically only statuses from `deletable` list were used here.
    //  See `inputsDisabled` in EditRestrictedOrderFormContainer for more details
    disableOrderSave: order && !ORDER_DELETABLE_STATUSES.includes(order.status),
    customOrderFieldReferences,
    initialValues: initialFormValues,
  };
};

export default connect(mapStateToProps)(OrderSummary);
