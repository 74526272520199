import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SmoothCollapse from 'react-smooth-collapse';

import { Button, Modal, Table } from 'react-bootstrap';
import { FormattedMessage, FormattedVolume } from 'rapidfab/i18n';
import Loading from 'rapidfab/components/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormattedLocalizedCost from 'rapidfab/components/FormattedLocalizedCost';
import { lineItemResourceType } from 'rapidfab/types';
import { MATERIAL_UNITS, FEATURES } from 'rapidfab/constants';
import { isFeatureEnabled } from 'rapidfab/selectors';
import { useSelector } from 'react-redux';
import { WORKFLOW_TYPES_ESTIMATES_KEY_MAP } from 'rapidfab/mappings';
import Tooltip from 'rapidfab/components/Tooltip';
import { faAngleDown, faAngleUp, faInfoCircle } from '@fortawesome/free-solid-svg-icons';

const EXPANDED = {
  PRINTING: 'printing',
  MATERIAL: 'material',
  COST: 'cost',
};

const LineItemEstimationViewModal = ({
  show,
  loading,
  onHide,
  lineItem,
}) => {
  const [expanded, setExpanded] = useState([EXPANDED.PRINTING, EXPANDED.COST, EXPANDED.MATERIAL]);
  const isStanleyXUser = useSelector(state => isFeatureEnabled(state, FEATURES.STANLEY_X_DEPLOYMENT));
  const titleColor = isStanleyXUser ? 'text-white' : 'text-black';

  const onToggleExpanded = value => () => {
    if (expanded.includes(value)) {
      setExpanded(prevState => prevState.filter(stateValue => stateValue !== value));
    } else {
      setExpanded(prevState => ([...prevState, value]));
    }
  };

  const { estimates, quantity } = lineItem;
  const workflowTypeEstimatesKey = WORKFLOW_TYPES_ESTIMATES_KEY_MAP[lineItem?.workflow_type];

  const notAvailableMessage = <FormattedMessage id="notAvailable" defaultMessage="N/A" />;
  const printingTime = estimates?.printing_time !== null ?
    Math.round(lineItem[workflowTypeEstimatesKey].printing_time / 60) :
    notAvailableMessage;
  const printingCostPerMinute =
    (lineItem[workflowTypeEstimatesKey].printing_cost !== null && estimates?.printing_time !== null) ? (
      <FormattedLocalizedCost
        value={lineItem[workflowTypeEstimatesKey].printing_cost /
        ((lineItem[workflowTypeEstimatesKey].printing_time / 60) || 1)}
      />
    ) :
      notAvailableMessage;
  const printCostScaleFactor = lineItem[workflowTypeEstimatesKey].print_cost_scale_factor !== null ?
    lineItem[workflowTypeEstimatesKey].print_cost_scale_factor : <FormattedMessage id="notAvailable" defaultMessage="N/A" />;
  const printingSubTotal = lineItem[workflowTypeEstimatesKey].printing_cost !== null ?
    <FormattedLocalizedCost value={lineItem[workflowTypeEstimatesKey].printing_cost} /> :
    notAvailableMessage;
  const materialsBaseVolume = lineItem[workflowTypeEstimatesKey].materials.base !== null ? (
    <FormattedVolume
      value={lineItem[workflowTypeEstimatesKey].materials.base}
      valueUnits={MATERIAL_UNITS.CM3}
    />
  ) : notAvailableMessage;
  const materialsSupportVolume = lineItem[workflowTypeEstimatesKey].materials.support !== null ? (
    <FormattedVolume
      value={lineItem[workflowTypeEstimatesKey].materials.support}
      valueUnits={MATERIAL_UNITS.CM3}
    />
  ) : notAvailableMessage;
  const materialCostBase = lineItem[workflowTypeEstimatesKey].material_cost !== null ?
    <FormattedLocalizedCost value={lineItem[workflowTypeEstimatesKey].material_cost} />
    : notAvailableMessage;
  const materialCostSupport = lineItem[workflowTypeEstimatesKey].material_cost !== null
  && lineItem[workflowTypeEstimatesKey].materials.support !== null ?
    <FormattedLocalizedCost value={lineItem[workflowTypeEstimatesKey].material_cost} />
    : notAvailableMessage;
  const materialCostScaleFactor = lineItem[workflowTypeEstimatesKey].material_cost_scale_factor !== null ?
    lineItem[workflowTypeEstimatesKey].material_cost_scale_factor
    : notAvailableMessage;
  const totalMaterialCost = lineItem[workflowTypeEstimatesKey].total_material_cost !== null ?
    <FormattedLocalizedCost value={lineItem[workflowTypeEstimatesKey].total_material_cost} />
    : notAvailableMessage;
  const postProcessingCost = lineItem[workflowTypeEstimatesKey].post_processing_cost !== null ?
    <FormattedLocalizedCost value={lineItem[workflowTypeEstimatesKey].post_processing_cost} />
    : notAvailableMessage;
  const totalCostPerPiece = estimates.total_cost !== null ?
    <FormattedLocalizedCost value={estimates.total_cost / quantity} />
    : notAvailableMessage;
  const totalCost = estimates.total_cost !== null ?
    <FormattedLocalizedCost value={estimates.total_cost} />
    : notAvailableMessage;
  return (
    <Modal show={show} onHide={onHide} backdrop="static">
      <Modal.Header closeButton>
        <FormattedMessage
          id="CostEstimatesDetails"
          defaultMessage="Cost Estimates Details"
        />
        <Tooltip
          id="costEstimatesDetails.tooltip"
          placement="right"
          trigger={<FontAwesomeIcon className="spacer-left" icon={faInfoCircle} />}
        >
          Please note: Some rounding has been done for ease of review
        </Tooltip>
      </Modal.Header>
      <Modal.Body>
        {loading ? <Loading /> : (
          <>
            <div className="mb-2">
              <div className={`d-flex align-items-center ps-2 pe-1 lh-xl border-gray ${titleColor}`}>
                <FontAwesomeIcon
                  className="flex-initial"
                  icon={expanded.includes(EXPANDED.PRINTING) ? faAngleUp : faAngleDown}
                  size="lg"
                  role="button"
                  onClick={onToggleExpanded(EXPANDED.PRINTING)}
                />
                <span
                  role="button"
                  className="px-2 flex-1 fs-3"
                  aria-hidden="true"
                  onClick={onToggleExpanded(EXPANDED.PRINTING)}
                >
                  <b>Printing</b>
                </span>
              </div>
              <SmoothCollapse expanded={expanded.includes(EXPANDED.PRINTING)}>
                <Table className="text-black" variant="gray" bordered>
                  <tbody>
                    <tr>
                      <td><b>Print Time Estimate (minutes)</b></td>
                      <td>{printingTime} minutes</td>
                    </tr>
                    <tr>
                      <td><b>Printer Running Costs (per minute)</b></td>
                      <td>{printingCostPerMinute}</td>
                    </tr>
                    <tr>
                      <td><b>Print cost Scaling Factor</b></td>
                      <td>{printCostScaleFactor}</td>
                    </tr>
                    <tr>
                      <td><b>Print Sub-total</b></td>
                      <td>{printingSubTotal}</td>
                    </tr>
                  </tbody>
                </Table>
              </SmoothCollapse>
            </div>

            <div className="my-2">
              <div className={`d-flex align-items-center ps-2 pe-1 lh-xl border-gray ${titleColor}`}>
                <FontAwesomeIcon
                  className="flex-initial"
                  icon={expanded.includes(EXPANDED.MATERIAL) ? faAngleUp : faAngleDown}
                  size="lg"
                  role="button"
                  onClick={onToggleExpanded(EXPANDED.MATERIAL)}
                />
                <span
                  role="button"
                  className="px-2 flex-1 fs-3"
                  aria-hidden="true"
                  onClick={onToggleExpanded(EXPANDED.MATERIAL)}
                >
                  <b>Material</b>
                </span>
              </div>
              <SmoothCollapse expanded={expanded.includes(EXPANDED.MATERIAL)}>
                <Table className="text-black" variant="gray" bordered>
                  <tbody>
                    <tr>
                      <td rowSpan={2}><b>Model Volume</b></td>
                      <td><b>Base</b></td>
                      <td>{materialsBaseVolume}</td>
                    </tr>
                    <tr>
                      <td><b>Support</b></td>
                      <td>{materialsSupportVolume}</td>
                    </tr>
                    <tr>
                      <td rowSpan={2}><b>Material Cost (per cm<sup>3</sup>)</b></td>
                      <td><b>Base</b></td>
                      <td>{materialCostBase}</td>
                    </tr>
                    <tr>
                      <td><b>Support</b></td>
                      <td>{materialCostSupport}</td>
                    </tr>
                    <tr>
                      <td><b>Printer Material Scaling Factor</b></td>
                      <td colSpan={2}>{materialCostScaleFactor}</td>
                    </tr>
                  </tbody>
                </Table>
              </SmoothCollapse>
            </div>

            <div className="mt-2">
              <div className={`d-flex align-items-center ps-2 pe-1 lh-xl border-gray ${titleColor}`}>
                <FontAwesomeIcon
                  className="flex-initial"
                  icon={expanded.includes(EXPANDED.COST) ? faAngleUp : faAngleDown}
                  size="lg"
                  role="button"
                  onClick={onToggleExpanded(EXPANDED.COST)}
                />
                <span
                  role="button"
                  className="px-2 flex-1 fs-3"
                  aria-hidden="true"
                  onClick={onToggleExpanded(EXPANDED.COST)}
                >
                  <b>Cost</b>
                </span>
              </div>
              <SmoothCollapse expanded={expanded.includes(EXPANDED.COST)}>
                <Table className="text-black" variant="gray" bordered>
                  <tbody>
                    <tr>
                      <td><b>Material Sub-total</b></td>
                      <td>{totalMaterialCost}</td>
                    </tr>
                    <tr>
                      <td><b>Post-Processing Costs</b></td>
                      <td>{postProcessingCost}</td>
                    </tr>
                    <tr>
                      <td><b>Total Cost per Piece (estimate)</b></td>
                      <td>{totalCostPerPiece}</td>
                    </tr>
                    <tr>
                      <td><b>Pieces</b></td>
                      <td>{quantity}</td>
                    </tr>
                    <tr>
                      <td><b>Total Cost (estimate)</b></td>
                      <td>{totalCost}</td>
                    </tr>
                  </tbody>
                </Table>
              </SmoothCollapse>
            </div>
          </>
        )}

      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide} variant="danger">
          <FormattedMessage id="button.close" defaultMessage="Close" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

LineItemEstimationViewModal.defaultProps = {
  loading: false,
  show: false,
  onHide: () => true,
};

LineItemEstimationViewModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  loading: PropTypes.bool,
  lineItem: lineItemResourceType.isRequired,
};

export default LineItemEstimationViewModal;
