import React from 'react';
import { designFileResourceType } from 'rapidfab/types';
import ResourceReadOnlyViewRow, { RESOURCE_READ_ONLY_VIEW_FIELD_TYPES } from 'rapidfab/components/ResourceReadOnlyView/ResourceReadOnlyViewRow';
import ResourceReadOnlyView from 'rapidfab/components/ResourceReadOnlyView/ResourceReadOnlyView';
import { DESIGN_FILE_STATUS_MAP } from 'rapidfab/mappings';
import { DESIGN_FILE_QUARANTINE_STATUSES } from 'rapidfab/constants';
import Fa from 'react-fontawesome';

const DesignFileInfo = ({ designFile }) => (
  <ResourceReadOnlyView
    withPanelWrapper
    title="Design File Info"
    entity={designFile}
  >
    <ResourceReadOnlyViewRow
      name="name"
      className="wrap-text"
      type={RESOURCE_READ_ONLY_VIEW_FIELD_TYPES.CUSTOM}
      customValue={(
        <>
          {designFile.name}
          <a
            className="btn btn-info-outline btn-xs spacer-left"
            href={designFile.content}
          >
            Download
          </a>
        </>
      )}
    />
    <ResourceReadOnlyViewRow
      name="context.version"
      label="Version"
      type={RESOURCE_READ_ONLY_VIEW_FIELD_TYPES.NUMBER}
    />
    <ResourceReadOnlyViewRow
      name="context.scale_to_print"
      label="Scale to Print"
      type={RESOURCE_READ_ONLY_VIEW_FIELD_TYPES.NUMBER}
    />
    <ResourceReadOnlyViewRow name="format" />
    <ResourceReadOnlyViewRow
      name="virus_scan"
      type={RESOURCE_READ_ONLY_VIEW_FIELD_TYPES.CUSTOM}
      customValue={(
        <>
          {designFile.virus_scan === DESIGN_FILE_QUARANTINE_STATUSES.UNSCANNED && (<Fa name="spinner" />)}
          {designFile.virus_scan === DESIGN_FILE_QUARANTINE_STATUSES.PASSED && (<Fa name="check" />)}
          {designFile.virus_scan === DESIGN_FILE_QUARANTINE_STATUSES.QUARANTINED && (<Fa name="exclamation-triangle" />)}
        </>
      )}
    />
    <ResourceReadOnlyViewRow
      name="created"
      type={RESOURCE_READ_ONLY_VIEW_FIELD_TYPES.DATETIME}
    />
    <ResourceReadOnlyViewRow
      name="status"
      type={RESOURCE_READ_ONLY_VIEW_FIELD_TYPES.MAPPED}
      map={DESIGN_FILE_STATUS_MAP}
    />
    <ResourceReadOnlyViewRow name="notes" />
  </ResourceReadOnlyView>
);

DesignFileInfo.propTypes = {
  designFile: designFileResourceType.isRequired,
};

export default DesignFileInfo;
