import _isEmpty from 'lodash/isEmpty';
import BureauBarcodeIcon from 'rapidfab/components/BureauBarcodeIcon';
import BuildPlateModeToggle from 'rapidfab/components/records/run/BuildPlateModeToggle';
import TableWithSearching from 'rapidfab/components/Tables/TableWithSearching';
import { rcTooltipInnerStyleList } from 'rapidfab/constants/styles';
import { PIECE_GROUPS_MAP } from 'rapidfab/mappings';
import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { pieceResourceType } from 'rapidfab/types';
import { FormattedMessage, FormattedMessageMappingOption } from 'rapidfab/i18n';
import { Button, FormControl, Form, FormLabel, OverlayTrigger, Tooltip } from 'react-bootstrap';
import RCTooltip from 'rc-tooltip';
import { getShortUUID, extractUuid } from 'rapidfab/utils/uuidUtils';
import Loading from 'rapidfab/components/Loading';
import _find from 'lodash/find';
import _values from 'lodash/values';

import {
  PRINT_TYPES,
  ROUTES,
  RUN_STATUSES,
  WORKFLOW_TYPES,
  PRODUCTION_CHANGE_TYPES_VALUES,
  PRODUCTION_CHANGE_OPTIONS,
} from 'rapidfab/constants';

import { Link } from 'react-router-dom';
import ChangeInformationText from 'rapidfab/components/records/run/ChangeInformationText';
import Icon, { ICON_NAMES } from 'rapidfab/icons';
import {
  faExclamationTriangle,
  faInfoCircle,
  faQuestionCircle,
  faWrench,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ExportControlWarningIcon } from 'rapidfab/components/ExportControl';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import ProductionChangeColumn from './ProductionChangeColumn';
import Config from '../../../config';

// eslint-disable-next-line no-shadow
const Layout = ({ Table }) => <Table />;
Layout.propTypes = { Table: PropTypes.func.isRequired };

const RunPrints = ({
  run,
  runTransformations,
  gridData,
  orders,
  selectedPrints,
  piecesByUri,
  isQRPrintTravelerFeatureEnabled,
  nextPrintsWithProcessStep,
  printingRuns,
  showPrintingRunColumn,
  runRemanufacture,
  setSelectedPrints,
  isBuildPlateLoading,
  workflowsByUuid,
  groupedPrintUrisByWorkChecklistLinkingUris,
  labelRelationships,
  pagination,
  listStore,
  filters,
  setSelectedPagesForPrints,
  selectedPagesWithPrints,
  onBuildPlateModeToggle,
  isLineItemsModeEnabled,
  isWorkInstructionsModalShown,
  isScrapModalShown,
  onNonConformanceModalOpen,
  onScrapModalOpen,
  onChangeProductionWorkflow,
  isWorkflowFetching,
  setShouldRefetchWorkflows,
}) => {
  const [productionChange, setProductionChange] = useState(PRODUCTION_CHANGE_TYPES_VALUES.NONE);
  const [selectAllChecked, setselectAllChecked] = useState(false);
  const [isCheckboxDisabled, setisCheckboxDisabled] = useState(false);
  const [isChangeInformationExpanded, setIsChangeInformationExpanded] = useState(false);

  const isScrapChange = productionChange === PRODUCTION_CHANGE_TYPES_VALUES.SCRAP;

  const reworkedPieces = listStore?.resources ?
    listStore.resources
      .filter(piece => piece.reworked)
      .map(pieceData => pieceData.piece) : [];

  const data = useMemo(() => {
    const gridTableData = gridData.map(print => {
      const exportControlLabelRelationship = _find(labelRelationships, { target_uri: print.piece }) ?? null;

      return {
        ...print,
        selected: selectedPrints.some(({ uuid }) => print.uuid === uuid),
        is_qr_print_traveler_feature_enabled: isQRPrintTravelerFeatureEnabled,
        nextPrintWithProcessStep: nextPrintsWithProcessStep[print.uri],
        printingRun: printingRuns[print.uri],
        pieceName: piecesByUri[print.piece]?.name,
        exportControlLabel: exportControlLabelRelationship?.label,
      };
    });
    const scrapTransformations = runTransformations.filter(({ type }) => type === PRODUCTION_CHANGE_TYPES_VALUES.SCRAP);
    if (scrapTransformations.length) {
      const scrapTransformationPrints = new Set(
        scrapTransformations.flatMap(transformation => transformation.prints),
      );
      // removes prints that has been scrapped from data set
      return gridTableData.filter(item => !scrapTransformationPrints.has(item.uri),
      );
    }
    return gridTableData;
  }, [
    runTransformations,
    gridData,
    selectedPrints,
    isQRPrintTravelerFeatureEnabled,
    nextPrintsWithProcessStep,
    printingRuns,
    piecesByUri,
    labelRelationships,
  ]);
  const firstSelectedWorkflow = selectedPrints.length ? selectedPrints[0].workflow : null;

  // If the workflow of the first selected print is different from the rest of the selected prints OR
  // If the data has different workflows, disable the checkbox
  const hasDifferentWorkflows = selectedPrints.length
    ? data.some(print => print.workflow !== firstSelectedWorkflow)
    : data.length && !data.every(print => print.workflow === data[0].workflow);

  const checkboxDisableValues = isDisabled => {
    setisCheckboxDisabled(isDisabled);
  };

  // when production change type changes, reset selected prints, cut Change Information Text
  useEffect(() => {
    setselectAllChecked(false);
    setSelectedPrints([]);
    setIsChangeInformationExpanded(false);
  }, [productionChange]);

  // When the page is changed, reset the selected prints and select all checkbox
  useEffect(() => {
    setselectAllChecked(selectedPagesWithPrints.includes(pagination?.activePage));
  }, [pagination?.activePage, selectedPagesWithPrints]);

  // use the first print of the selected prints array to find the array of print uris
  // that match that uri's work checklist
  const arrayOfGroupedPrintUris = _values(
    groupedPrintUrisByWorkChecklistLinkingUris,
  );
  const printUrisWithSameChecklistLinking =
    selectedPrints[0] &&
    _find(arrayOfGroupedPrintUris, printUris =>
      printUris.includes(selectedPrints[0].uri),
    );

  useEffect(() => {
    if (printUrisWithSameChecklistLinking?.length) {
      const piecesToSelect = gridData.filter(
        ({ uri }) => printUrisWithSameChecklistLinking.includes(uri),
      );

      if (selectAllChecked) {
        if (printUrisWithSameChecklistLinking?.length !== selectedPrints.length) {
          setselectAllChecked(false);
        }

        setSelectedPrints(piecesToSelect);
      }
    }
  }, [printUrisWithSameChecklistLinking?.length]);

  useEffect(() => {
    setShouldRefetchWorkflows(true);
  }, []);

  const handleSelectAllCheckChange = ({ target: { checked } }) => {
    setselectAllChecked(checked);
    // if select all is checked select all the prints
    let dataToSelect = [];

    if (checked) {
      // Filter out pieces that are already in selectedPrints to avoid duplication
      const newSelections = gridData.filter(
        ({ uri }) => !selectedPrints.some(print => print.uri === uri));

      // Combine the already selected prints with the new selections
      dataToSelect = [...selectedPrints, ...newSelections];
    }

    // If we click "unchecked" and there are selected prints, we need to remove the prints that are on the current page
    if (!checked && (selectedPagesWithPrints.includes(pagination.activePage) && selectedPagesWithPrints.length)) {
      const gridDataUris = new Set(gridData.map(print => print.uri));
      dataToSelect = selectedPrints.filter(print => !gridDataUris.has(print.uri));
    }

    setSelectedPrints(dataToSelect);
    setSelectedPagesForPrints(pagination.activePage);
  };

  // since the prints have already been determined
  // to have same workflows and work instructions,
  // we can use any print's(data[0] in this case) workflow.
  const randomPrintWorkflow = data[0]?.workflow;
  const isAssemblyType = (workflowsByUuid[randomPrintWorkflow]?.type === WORKFLOW_TYPES.ASSEMBLY);

  const isNoProductionChangeSelected = !productionChange;
  const isNoPrintsSelected = !selectedPrints.length;

  const onClickProductionChange = () => {
    if (productionChange === PRODUCTION_CHANGE_TYPES_VALUES.REMANUFACTURE) {
      return runRemanufacture();
    }
    if (productionChange === PRODUCTION_CHANGE_TYPES_VALUES.NONCONFORMANCE) {
      return onNonConformanceModalOpen();
    }
    if (productionChange === PRODUCTION_CHANGE_TYPES_VALUES.SCRAP) {
      return onScrapModalOpen();
    }
    return onChangeProductionWorkflow();
  };

  const chooseInformationText = () => {
    switch (productionChange) {
      case PRODUCTION_CHANGE_TYPES_VALUES.NONE:
        return 'record.run.productionChangeInfo.none';
      case PRODUCTION_CHANGE_TYPES_VALUES.WORKFLOW:
        return `record.run.productionChangeInfo.workflow.${isChangeInformationExpanded ? 'expanded' : 'cut'}`;
      case PRODUCTION_CHANGE_TYPES_VALUES.REMANUFACTURE:
        return `record.run.productionChangeInfo.remanufacture.${isChangeInformationExpanded ? 'expanded' : 'cut'}`;
      case PRODUCTION_CHANGE_TYPES_VALUES.NONCONFORMANCE:
        return `record.run.productionChangeInfo.nonconformance.${isChangeInformationExpanded ? 'expanded' : 'cut'}`;
      case PRODUCTION_CHANGE_TYPES_VALUES.SCRAP:
        return `record.run.productionChangeInfo.scrap.${isChangeInformationExpanded ? 'expanded' : 'cut'}`;
      default:
        return 'record.run.productionChangeInfo.none';
    }
  };

  const shouldShowReadMore = !isChangeInformationExpanded &&
    (productionChange === PRODUCTION_CHANGE_TYPES_VALUES.NONCONFORMANCE ||
      productionChange === PRODUCTION_CHANGE_TYPES_VALUES.WORKFLOW ||
      productionChange === PRODUCTION_CHANGE_TYPES_VALUES.SCRAP ||
      productionChange === PRODUCTION_CHANGE_TYPES_VALUES.REMANUFACTURE
    );

  const shouldShowMoreInfo = isChangeInformationExpanded;

  /* You should not be able to change the workflow if the current run status is NOT:
     - 'In Progress'
     - 'Complete' */
  const isNotAllowedChangingWorkflow = productionChange === PRODUCTION_CHANGE_TYPES_VALUES.WORKFLOW
    && Object.values(RUN_STATUSES)
      .filter(status => ![RUN_STATUSES.IN_PROGRESS, RUN_STATUSES.COMPLETE].includes(status))
      .includes(run?.status);

  // When work instruction modal changed to closed, reset selected prints and select all checkbox
  useEffect(() => {
    if (!isWorkInstructionsModalShown) {
      setselectAllChecked(false);
      setSelectedPrints([]);
    }
  }, [isWorkInstructionsModalShown]);

  useEffect(() => {
    if (!isScrapModalShown) {
      setselectAllChecked(false);
      setSelectedPrints([]);
    }
  }, [isScrapModalShown]);

  const columns = [
    {
      type: 'custom',
      uid: 'index',
      accessor: 'piece',
      defaultMessage: '#',
      Cell: ({ row: { index } }) => {
        // Calculate the index of the piece in the table
        const startIndex = pagination.activePage * pagination.pageLimit + 1;
        return (
          <span>{startIndex + index}</span>
        );
      },
    },
    {
      type: 'custom',
      uid: 'piece',
      accessor: 'id',
      defaultMessage: 'Piece',
      Cell: ({ row: { original } }) =>
        (
          <div className="d-inline">
            <span className="spacer-right">
              <Link
                to={getRouteURI(ROUTES.PIECE_EDIT, { uuid: extractUuid(original.piece) }, {}, true)}
              >
                <span className="truncate-data">
                  {original.pieceName}
                </span>
              </Link>
            </span>
            {original.is_qr_print_traveler_feature_enabled && (
              <span className="spacer-left">
                <a
                  href={`${Config.HOST.QR}/traveler/print/${original.uuid}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  type="download"
                >
                  <BureauBarcodeIcon />
                </a>
              </span>
            )}
            {original.exportControlLabel && (
              <ExportControlWarningIcon
                exportControlLabel={original.exportControlLabel}
              />
            )}
            {reworkedPieces.includes(original.piece) && (
              <OverlayTrigger
                className="ml15"
                placement="right"
                overlay={(
                  <Tooltip>
                    A production change has been made to this piece.
                  </Tooltip>
                )}
              >
                <FontAwesomeIcon className="ml3" color="#FFA500" icon={faInfoCircle} />
              </OverlayTrigger>
            )}
          </div>
        ),
    },
    {
      type: 'resource',
      uid: 'field.order',
      accessor: 'order',
      defaultMessage: 'Order',
      resource: 'order',
      resources: orders,
    },
    {
      type: 'custom',
      uid: 'field.partName',
      accessor: 'partName',
      defaultMessage: 'Part',
      Cell: ({ row: { original } }) => (
        <>
          {original.partName}
          {original.type === PRINT_TYPES.SPECIMEN && ' (specimen)'}
        </>
      ),
    },
    !showPrintingRunColumn && (
      {
        type: 'custom',
        uid: 'field.printingRun',
        accessor: 'printingRun',
        defaultMessage: 'Printing Run (Lot)',
        Cell: ({ row: { original: data } }) => {
          const { printingRun } = data;
          if (printingRun) {
            return printingRun.name;
          }
          return <FormattedMessage id="notAvailable" defaultMessage="N/A" />;
        },
      }
    ),
    {
      type: 'date',
      uid: 'field.dueDate',
      accessor: 'dueDate',
      defaultMessage: 'Due Date',
    },
    {
      type: 'custom',
      uid: isAssemblyType ? 'assemblyWorkflow' : 'workflow',
      accessor: 'workflow',
      defaultMessage: isAssemblyType ? 'Sub-Assembly Workflow' : 'Production Workflow',
      Cell: ({ row: { original } }) => {
        const { workflow: workflowUuid } = original;
        const workflow = workflowsByUuid[extractUuid(workflowUuid)];
        if (isWorkflowFetching) return <Loading inline />;
        if (!workflow) return '';
        return (
          <div>
            {workflow.name}
          </div>
        );
      },
    },
    {
      type: 'custom',
      uid: 'field.nextStep',
      accessor: 'nextStep',
      defaultMessage: 'Next Step',
      Cell: ({ row: { original } }) => {
        const print = original.nextPrintWithProcessStep;
        const remanufacturedPrintURI = piecesByUri[original?.piece]?.remanufactured_to;
        const remanufacturedUUID = extractUuid(remanufacturedPrintURI);
        const remanufacturedPrint = piecesByUri[remanufacturedPrintURI];

        if (print) {
          const name = (print.process_step && print.process_step.name);
          return (name ? <Link to={`/records/run/${extractUuid(print.run)}`} onClick={() => window.scrollTo(0, 0)}>{name}</Link> : (
            <FormattedMessage
              id="processStepMissing"
              defaultMessage="[Error - Step Not Found]"
            />
          ));
        }
        return (
          <>
            <FormattedMessage id="notAvailable" defaultMessage="N/A" />
            {remanufacturedUUID && (

              <RCTooltip
                placement="right"
                destroyTooltipOnHide
                overlayInnerStyle={{ padding: '10px', wordBreak: 'break-word' }}
                mouseLeaveDelay={0.4}
                overlay={(
                  <>
                    <p>This piece has been successfully remanufactured. <br />
                      Replacement piece can be found here
                      <Link
                        className="spacer-left"
                        to={getRouteURI(ROUTES.PIECE_EDIT,
                          { uuid: remanufacturedUUID }, {}, true)}
                      >
                        {remanufacturedPrint && (`${remanufacturedPrint?.name} - (${getShortUUID(remanufacturedPrint?.uuid)})`)}
                      </Link>
                    </p>
                  </>
                )}
              >
                <FontAwesomeIcon icon={faExclamationTriangle} className="spacer-left spacer-right text-warning" />
              </RCTooltip>

            )}
          </>

        );
      },
    },
    {
      type: 'custom',
      uid: 'record.run.productionChange',
      accessor: 'productionChange',
      defaultMessage: 'Production Change',
      Cell: ({ row: { original } }) => (
        <ProductionChangeColumn
          rowData={original}
          isNoProductionChangeSelected={isNoProductionChangeSelected}
          isRemanufactureChange={
            productionChange ===
            PRODUCTION_CHANGE_TYPES_VALUES.REMANUFACTURE
          }
          isScrapChange={isScrapChange}
          isWorkflowChange={
            productionChange ===
            PRODUCTION_CHANGE_TYPES_VALUES.WORKFLOW ||
            productionChange === PRODUCTION_CHANGE_TYPES_VALUES.NONCONFORMANCE
          }
          runTransformations={runTransformations}
          selectedPrints={selectedPrints}
          setSelectedPrints={setSelectedPrints}
          printUrisWithSameChecklistLinking={
            printUrisWithSameChecklistLinking
          }
          isRunIncomplete={run.status !== RUN_STATUSES.COMPLETE}
          isAllowedChangingWorkflow={
            run.status === RUN_STATUSES.COMPLETE ||
            run.status === RUN_STATUSES.IN_PROGRESS
          }
          isNotAllowedChangingWorkflow={isNotAllowedChangingWorkflow}
          checkboxDisableValues={checkboxDisableValues}
        />
      ),
    },
  ];

  const {
    activePage,
    setPage,
    goToPage,
    onLimitChange,
    pageLimit,
    offset,
  } = pagination ?? {};

  const {
    setGlobalFilter,
    globalFilter,
    piecesGroupsFilter,
    setPiecesGroupsFilter,
  } = filters ?? {};

  return (
    <>
      {isBuildPlateLoading ? (
        <Loading />
      ) : (
        <>
          <TableWithSearching
            data={data}
            search={globalFilter}
            onSearch={setGlobalFilter}
            columns={columns}
            skipOffset
            onLimitChange={onLimitChange}
            listStore={listStore}
            offset={offset}
            withoutDivider
            limit={pageLimit}
            onSetPage={setPage}
            onPageChange={goToPage}
            isFilteringEnabled
            isUpdatedColumnShown={false}
            withDefaultPagination={false}
            extraFilters={[
              <div key="ordersExtraFilters" className="form-inline">
                <div className="form-group mr15">
                  <FormLabel htmlFor="piecesGroupsFilter">
                    Piece Groups:
                  </FormLabel>
                  <FormControl
                    className="spacer-left"
                    id="piecesGroupsFilter"
                    as="select"
                    name="piecesGroups"
                    value={piecesGroupsFilter}
                    onChange={event => setPiecesGroupsFilter(event.target.value)}
                  >
                    {Object.keys(PIECE_GROUPS_MAP).map(filter => (
                      <FormattedMessageMappingOption
                        key={filter}
                        mapping={PIECE_GROUPS_MAP}
                        value={filter}
                      />
                    ))}
                  </FormControl>
                </div>
              </div>,
            ]}
            extraToggle={(
              <BuildPlateModeToggle
                lineItemsMode={false}
                hasSelectedItems={!_isEmpty(selectedPrints)}
                onBuildPlateModeToggle={onBuildPlateModeToggle}
                isLineItemsModeEnabled={isLineItemsModeEnabled}
              />
            )}
            tableHeadColSpan={12}
          />

          {!!data.length && (
            <div className="d-flex justify-content-end align-items-start">
              {!!productionChange && (!isScrapChange && hasDifferentWorkflows) && (
                <OverlayTrigger
                  className="ml15"
                  placement="left"
                  overlay={(
                    <Tooltip>
                      <FormattedMessage
                        id="record.run.workflowMismatchTooltipSelectAllWarning"
                        defaultMessage="Select All Production Change is only available for pieces with the same workflow."
                      />
                    </Tooltip>
                  )}
                >
                  <FontAwesomeIcon className="mt3" icon={faQuestionCircle} />
                </OverlayTrigger>
              )}
              <Form.Check
                className="spacer-left"
                checked={selectAllChecked}
                disabled={isCheckboxDisabled || (!isScrapChange && hasDifferentWorkflows)}
                onChange={handleSelectAllCheckChange}
                label={(
                  <>
                    <p className="mb0">Select All Production Change</p>
                    <p className="mb0 font-size-10">(For Page {activePage + 1})</p>
                  </>
                )}
              />
            </div>
          )}

          {!!selectedPrints.length && (
            <div className="d-flex justify-content-end align-items-center">
              {selectedPrints.length} pieces selected

              <RCTooltip
                placement="left"
                destroyTooltipOnHide
                overlayInnerStyle={{ ...rcTooltipInnerStyleList, width: 'max-content' }}
                mouseLeaveDelay={0.4}
                overlay={(
                  <>
                    {selectedPrints.map(({ piece }) => (
                      <div key={piece}>
                        <FontAwesomeIcon icon={faWrench} className="spacer-right" />
                        {piecesByUri[piece]?.name}
                      </div>
                    ))}
                  </>
                )}
              >
                <FontAwesomeIcon icon={faInfoCircle} className="spacer-left spacer-right" />
              </RCTooltip>
            </div>
          )}

          {!!data.length && (
            <div className="d-flex justify-content-end align-items-center">
              <div className="d-flex justify-content-end mr30">
                <ChangeInformationText>
                  <div className="d-flex align-items-center">
                    <Icon name={ICON_NAMES.INFO} size="large" />
                    <span>
                      <FormattedMessage
                        id="record.run.productionChangeType"
                        defaultMessage="Production Change Information"
                      />
                    </span>
                  </div>
                </ChangeInformationText>
                <ChangeInformationText>
                  <span>
                    <FormattedMessage
                      id={chooseInformationText()}
                      defaultMessage="Select a type of Production Change to alter the manufacturing process for a Piece"
                    />
                  </span>
                    &nbsp;
                  {shouldShowReadMore && (
                    <span
                      tabIndex={0}
                      role="button"
                      className="change-info-expander"
                      onClick={() => setIsChangeInformationExpanded(true)}
                    >
                      read more...
                    </span>
                  )}
                    &nbsp;
                  {shouldShowMoreInfo && (
                    <a
                      target="_blank"
                      href="https://authentise.zendesk.com/hc/en-us/articles/360055743712"
                      rel="noreferrer"
                      className="change-info-more-link"
                    >
                      More info
                    </a>
                  )}
                </ChangeInformationText>
              </div>
              <FormControl
                className="wauto ml5"
                as="select"
                onChange={event => setProductionChange(event.target.value)}
                value={productionChange}
              >
                {PRODUCTION_CHANGE_OPTIONS.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </FormControl>
              <Button
                onClick={onClickProductionChange}
                bsSize="small"
                bsStyle="success"
                className="spacer-left"
                disabled={isNoProductionChangeSelected || isNoPrintsSelected}
              >
                Submit
              </Button>
            </div>
          )}

        </>
      )}
    </>
  );
};

RunPrints.defaultProps = {
  selectedPrints: [],
  isQRPrintTravelerFeatureEnabled: false,
};

RunPrints.propTypes = {
  gridData: PropTypes.arrayOf(
    PropTypes.shape({
      customerName: PropTypes.string,
      dueDate: PropTypes.string,
      id: PropTypes.string,
      order: PropTypes.string,
      uuid: PropTypes.string,
      uri: PropTypes.string,
      nextStep: PropTypes.string,
    }),
  ).isRequired,
  piecesByUri: PropTypes.objectOf(pieceResourceType).isRequired,
  orders: PropTypes.shape({}).isRequired,
  showPrintingRunColumn: PropTypes.bool.isRequired,
  selectedPrints: PropTypes.arrayOf(PropTypes.shape({
    uri: PropTypes.string.isRequired,
    workflow: PropTypes.string.isRequired,
  })),
  runRemanufacture: PropTypes.func.isRequired,
  isQRPrintTravelerFeatureEnabled: PropTypes.bool,
  run: PropTypes.shape({
    status: PropTypes.string,
    operation: PropTypes.string,
  }).isRequired,
  runTransformations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  nextPrintsWithProcessStep: PropTypes.shape({}).isRequired,
  printingRuns: PropTypes.shape({}).isRequired,
  isBuildPlateLoading: PropTypes.bool.isRequired,
  workflowsByUuid: PropTypes.objectOf(PropTypes.shape({
    uri: PropTypes.string,
    type: PropTypes.string,
    name: PropTypes.string,
  })).isRequired,
  groupedPrintUrisByWorkChecklistLinkingUris: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
  postProcessorsByUri: PropTypes.shape({}).isRequired,
  row: PropTypes.shape({
    original: PropTypes.shape({
      uuid: PropTypes.string.isRequired,
      uri: PropTypes.string.isRequired,
      piece: pieceResourceType,
      reworked: PropTypes.bool,
      printingRun: PropTypes.shape({
        name: PropTypes.string,
      }),
      type: PropTypes.string.isRequired,
      workflow: PropTypes.shape({}),
      pieceName: PropTypes.string.isRequired,
      pieceUri: PropTypes.string.isRequired,
      is_qr_print_traveler_feature_enabled: PropTypes.bool.isRequired,
      partName: PropTypes.string.isRequired,
      length: PropTypes.number,
      nextPrintWithProcessStep: PropTypes.shape({
        process_step: PropTypes.shape({
          name: PropTypes.string.isRequired,
        }),
        run: PropTypes.shape({}),
      }).isRequired,
      exportControlLabel: PropTypes.shape({
        name: PropTypes.string,
        description: PropTypes.string,
        color: PropTypes.string,
      }),
    }),
    index: PropTypes.number,
  }).isRequired,
  labelRelationships: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  pagination: PropTypes.shape({
    activePage: PropTypes.number,
    setPage: PropTypes.func,
    goToPage: PropTypes.func,
    onLimitChange: PropTypes.func,
    pageLimit: PropTypes.number,
    offset: PropTypes.number,
  }).isRequired,
  listStore: PropTypes.shape({
    resources: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  filters: PropTypes.shape({
    setGlobalFilter: PropTypes.func,
  }).isRequired,
  setSelectedPagesForPrints: PropTypes.func.isRequired,
  selectedPagesWithPrints: PropTypes.arrayOf(PropTypes.number).isRequired,
  onBuildPlateModeToggle: PropTypes.func.isRequired,
  isLineItemsModeEnabled: PropTypes.bool.isRequired,
  isWorkInstructionsModalShown: PropTypes.bool.isRequired,
  isScrapModalShown: PropTypes.bool.isRequired,
  onNonConformanceModalOpen: PropTypes.func.isRequired,
  onScrapModalOpen: PropTypes.func.isRequired,
  onChangeProductionWorkflow: PropTypes.func.isRequired,
  isWorkflowFetching: PropTypes.bool.isRequired,
  setShouldRefetchWorkflows: PropTypes.func.isRequired,
  setSelectedPrints: PropTypes.func.isRequired,
};

export default RunPrints;
