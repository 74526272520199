import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, ButtonToolbar, Container } from 'react-bootstrap';

import BreadcrumbNav from 'rapidfab/components/BreadcrumbNav';
import {
  LINE_ITEM_STATUS, ROUTES,
  ORDER_UNLOCKED_STATUSES,
  RESTRICTED_USER_ORDER_STEP_CAD_FILE,
  RESTRICTED_USER_ORDER_STEPS, API_RESOURCES,
} from 'rapidfab/constants';
import Stepper from 'rapidfab/components/stepper/Stepper';
import { extractUuid } from 'rapidfab/reducers/makeApiReducers';
import { getShortUUID } from 'rapidfab/utils/uuidUtils';
import { getRouteURI, prepareStepperURI } from 'rapidfab/utils/uriUtils';
import Loading from 'rapidfab/components/Loading';
import * as Selectors from 'rapidfab/selectors';
import AddProductContainer from 'rapidfab/containers/AddProductContainer';
import { orderResourceType, productResourceType } from 'rapidfab/types';
import OrderProductsContainer from 'rapidfab/containers/OrderProductsContainer';

class EditRestrictedLineItemOrder extends React.Component {
  constructor(props) {
    super(props);

    this.navigateToNextStep = this.navigateToNextStep.bind(this);
  }

  navigateToNextStep() {
    const { order } = this.props;
    const orderUuid = extractUuid(order.uri);
    window.location.hash = getRouteURI(ROUTES.REVIEW_RESTRICTED_ORDER, { uuid: orderUuid });
  }

  render() {
    const { order, uploadModel, fetchingLineItem, fetchingModel, products } = this.props;
    if (!order) {
      return (
        <Loading />
      );
    }

    const id = order && order.id ? `${order.name} (${order.customer_id || getShortUUID(order.uuid)})` : '';
    const breadcrumbs = ['orders', id];
    const orderUuid = extractUuid(order.uri);

    const isDisabledSubmitAction =
      fetchingLineItem || fetchingModel || uploadModel.uploading || !products.length;

    const isOrderUnlocked = ORDER_UNLOCKED_STATUSES.includes(order.status);

    // TODO: to be fixed/adjusted/confirmed in CH31806
    // From the first look the list of statuses is not correct
    // Based on isBeforePreparationStageLineItemPresentForOrder selector
    // (used for user redirects to Line Items, or Review order pages)
    // the correct list of statuses would be ALL EXCEPT new/calculating estimates/pending
    // since we need to allow restricted user to finish filling in the line item details before going further
    const lineItemReadOnlyStatuses = new Set([
      LINE_ITEM_STATUS.NEW,
      LINE_ITEM_STATUS.CALCULATING_ESTIMATES,
      LINE_ITEM_STATUS.PENDING,
      LINE_ITEM_STATUS.CANCELLED,
    ]);

    return (
      <Container fluid>
        <BreadcrumbNav breadcrumbs={breadcrumbs} />
        <Stepper
          steps={prepareStepperURI(RESTRICTED_USER_ORDER_STEPS, {
            uuid: orderUuid,
          })}
          activeStep={RESTRICTED_USER_ORDER_STEP_CAD_FILE}
        />
        <OrderProductsContainer
          orderUri={order.uri}
          readOnlyLineItemsOrCallback={lineItem =>
            lineItemReadOnlyStatuses.has(lineItem.status)}
        />
        {isOrderUnlocked ? (
          <AddProductContainer orderUri={order.uri} lineItemsLength={this.props.lineItemsLength} />
        ) : (
          <div className="jumbotron">
            <p>
              Additional line items cannot be added to a confirmed order. Please
              create a new order to add additional line items.
            </p>
          </div>
        )}
        <ButtonToolbar className="clearfix pull-right mt15" style={{ marginBottom: '3rem' }}>
          <div className="pull-right">
            <Button
              type="outline-primary"
              variant="primary"
              onClick={() => this.navigateToNextStep()}
              disabled={isDisabledSubmitAction}
            >
              Next step: Review
            </Button>
          </div>
        </ButtonToolbar>
      </Container>
    );
  }
}

EditRestrictedLineItemOrder.propTypes = {
  order: orderResourceType.isRequired,
  products: PropTypes.arrayOf(productResourceType).isRequired,
  fetchingLineItem: PropTypes.bool.isRequired,
  lineItemsLength: PropTypes.number.isRequired,
  fetchingModel: PropTypes.bool.isRequired,
  uploadModel: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const mapStateToProps = state => {
  const order = Selectors.getRouteUUIDResource(state);
  return {
    order,
    products: Selectors.getProductsForOrder(state, order),
    fetchingLineItem: (state.ui.nautilus[API_RESOURCES.LINE_ITEM].put.fetching
      || state.ui.nautilus[API_RESOURCES.LINE_ITEM].post.fetching),
    fetchingModel: state.ui.nautilus[API_RESOURCES.MODEL].post.fetching,
    uploadModel: Selectors.getUploadModel(state),
  };
};

export default connect(mapStateToProps)(EditRestrictedLineItemOrder);
