import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Timeline, { DateHeader, TimelineHeaders, TimelineMarkers, TodayMarker } from 'react-calendar-timeline';
import 'react-calendar-timeline/lib/Timeline.css';
import 'rapidfab/styles/componentStyles/gantt-chart.scss';
import { COLORS, PIECE_STATUSES, PRINT_TYPES, ROUTES, RUN_STATUSES, TEXT_COLOR_CONTRAST } from 'rapidfab/constants';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import formatGanttDateHeaderLabel from 'rapidfab/utils/formatGanttDateHeaderLabel';

import dayjs from 'dayjs';
import _map from 'lodash/map';
import _round from 'lodash/round';
import _toNumber from 'lodash/toNumber';
import _sortBy from 'lodash/sortBy';
import { orderResourceType } from 'rapidfab/types';

import 'rc-tooltip/assets/bootstrap_white.css';
import _ceil from 'lodash/ceil';
import { Button, FormLabel, Col, Row } from 'react-bootstrap';
import Tooltip from 'rapidfab/components/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faArrowCircleRight, faCalendar, faCheck, faChevronDown, faChevronRight, faExclamationCircle, faList, faScrewdriverWrench, faWrench } from '@fortawesome/free-solid-svg-icons';

import { capitalize } from 'lodash/string';
import DebugModeDataPanel from 'rapidfab/components/DebugMode/DebugModeDataPanel';
import { FormattedMessage } from 'rapidfab/i18n';
import { ORDER_STATUS_COLOR_CODE_MAPPING, RUN_STATUS_COLOR_MAP } from '../../../mappings';

import Loading from '../../Loading';
import OrderProgressTooltip from './OrderProgressTooltip';
import GanttRunTooltip from './GanttRunTooltip';
import Pagination from '../../RecordList/Pagination';
import Limit from '../../RecordList/Limit';

const MAX_ZOOM = 365.24 * 86400 * 1000; // 1 year in ms
const MIN_ZOOM = 15 * 60 * 1000; // 15 min in ms

const ICON_COLORS = {
  ORANGE: '#E6933B',
  GREEN: '#009F10',
  BLUE: '#2B78E4',
  GREY: '#51586a',
};

const arrowIconStyle = {
  fontSize: '30px',
  marginLeft: '10px',
};

const sphereIconStyle = {
  borderRadius: '50%',
  marginLeft: '10px',
  border: '2px solid',
  width: '30px',
  height: '30px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'center',
};

const getSphereColorStyle = (piece, order) => {
  if (piece === 0) return ICON_COLORS.GREY;
  if (piece > 0 && piece < order) return ICON_COLORS.ORANGE;
  if (piece >= order) return ICON_COLORS.GREEN;
  return ICON_COLORS.GREY;
};

const onItemSelect = uuid => {
  window.location = uuid.includes('_run') ?
    getRouteURI(ROUTES.RUN_EDIT, { uuid: uuid.split('_run')[0] })
    : getRouteURI(ROUTES.ORDER_EDIT, { uuid });
  return null;
};

const ItemRenderer = ({
  item,
  getItemProps,
  buildsByRunUri,
}) => {
  const itemProps = getItemProps(item.itemProps);
  const itemPropsToPass = {
    ...itemProps,
    style: {
      ...itemProps.style,
      backgroundColor: '#FBEAD1',
      border: '0 solid #ff0000',
      boxShadow: '1px 1px 3px #222',
    },
  };

  const runTitleStyle = {
    height: '100%',
    width: '100%',
    color: '#000!important',
    backgroundColor: RUN_STATUS_COLOR_MAP[item.status],
    fontSize: '14px',
  };

  const showText = _round(_toNumber(itemPropsToPass.style.width.slice(0, -2))) > 100;
  const showAdditionalText = _round(_toNumber(itemPropsToPass.style.width.slice(0, -2))) > 330;
  const { completePercent } = item;
  const orderDueDate = item.due_date ? dayjs(item.due_date).format('MM/DD/YYYY hh:mm A') : '-----';
  const labelBgColor = RUN_STATUS_COLOR_MAP[item.status];
  const textColor = TEXT_COLOR_CONTRAST[labelBgColor];

  return item?.isRunLine ? (
    <GanttRunTooltip run={item} isBuildAttached={buildsByRunUri[item.uri]}>
      <div {...itemPropsToPass}>
        <div
          className="rct-item-content"
          data-id={item.originalUUID}
          style={runTitleStyle}
        >
          {
            item.status === RUN_STATUSES.ERROR && (
              <FontAwesomeIcon icon={faExclamationCircle} className="spacer-right" style={{ color: 'red' }} />
            )
          }
          Run: {item.name} {item.workstation_name ? `WS: ${item.workstation_name?.trim()}` : ''}
        </div>
      </div>
    </GanttRunTooltip>
  ) : (
    <div {...itemPropsToPass}>
      <OrderProgressTooltip
        runs={item.runs}
        orderDueDate={item.due_date}
        orderRunsEstimates={item.orderRunsEstimates}
        piecesScheduledCount={item.piecesScheduledCount}
        piecesCount={item.piecesCount}
        trigger={(
          <div
            data-id={item?.id}
            style={{
              height: '100%',
              width: '100%',
            }}
          >
            <div style={{
              width: `${completePercent}%`,
              backgroundColor: ORDER_STATUS_COLOR_CODE_MAPPING[item.status],
              height: '100%',
            }}
            />
            {
              showAdditionalText && (
                <div className="d-flex align-items-center" style={{ position: 'absolute', inset: 0 }}>
                  <div className="orderGanttChartStatuses">
                    <FontAwesomeIcon icon={faList} className="spacer-right" />{item.runsCompletedCount}/{item.runs.length} Runs
                  </div>
                  <div className="orderGanttChartStatuses">
                    <FontAwesomeIcon icon={faWrench} className="spacer-right" />{item.piecesCompletedCount}/{item.piecesCount} Pieces
                  </div>
                  <div className="orderGanttChartStatuses">
                    <FormLabel
                      className="badge badge-sm"
                      style={{ backgroundColor: ORDER_STATUS_COLOR_CODE_MAPPING[item.status], color: textColor }}
                    >{
                        capitalize(item.status)
                      }
                    </FormLabel>
                  </div>
                </div>
              )
            }
            {showText && (
              <div style={{
                inset: 0,
                position: 'absolute',
                textAlign: 'right',
                marginRight: '5px',
                color: '#000',
              }}
              >
                <FontAwesomeIcon icon={faCheck} className="spacer-right spacer-left" />{completePercent}%
                <Tooltip
                  id="order-progress4"
                  placement="bottom"
                  trigger={(
                    <FontAwesomeIcon className="spacer-right spacer-left" icon={faCalendar} />
                  )}
                >
                  <span>Due Date: {orderDueDate}</span>
                </Tooltip>
              </div>
            )}
          </div>
        )}
      />

    </div>
  );
};

ItemRenderer.propTypes = {
  item: orderResourceType.isRequired,
  getItemProps: PropTypes.func.isRequired,
  buildsByRunUri: PropTypes.shape({}).isRequired,
};

const GroupRenderer = ({
  group,
  expandedOrders,
  setExpandedOrders,
  piecesByOrderUri,
  runsByOrderUri,
  printsByUri,
  runEstimatesByRunUri,
  prints,
}) => {
  const {
    title,
    uri,
    root,
    canBeExpanded,
    due_date,
  } = group;

  const orderPieces = (piecesByOrderUri[uri] || []).filter(p => p.type === PRINT_TYPES.PRODUCT);
  const isExpanded = expandedOrders.find(expandedOrderUri => expandedOrderUri === uri);
  const href = root ?
    getRouteURI(ROUTES.ORDER_EDIT, { uuid: extractUuid(uri) }) :
    getRouteURI(ROUTES.PIECE_EDIT, { uuid: extractUuid(uri) });

  const restrictedWidthViewPieces = 45;
  const restrictedTitleGroupStyle = {
    maxWidth: expandedOrders.length ? '150px' : '110px',
  };

  if (root) {
    const runs = runsByOrderUri[uri] || [];
    const runsCount = runs ? runs.length : 0;

    /* For Blue Circle: We need to show: % of pieces in any state (status) except anything but new, confirmed, so
         piecesScheduled Array contains of number of pieces that has runs but scheduledByStatus -> shows the
         number of pieces with the piece status > new, confirmed. */
    const piecesScheduled = orderPieces.filter(
      orderPiece => printsByUri[orderPiece.current_print]?.run ||
        ![PIECE_STATUSES.NEW, PIECE_STATUSES.CONFIRMED].includes(orderPiece.status),
    );
    /* For Orange Circle: We need to show the number of pieces printed.
         This implies that a piece can still have status In Progress but has already finished printing,
         and is now onto later steps in the manufacturing process. Slack Thread: C02AQQC2J/p1649949322605329

         So that means we should get the piece.status and check if it has already finalized "printing" and has
         any other status next by the rest of the steps but not "error", "cancelled", "incomplete".
         This way we are getting all the keys of the PIECE_STATUSES object and getting the index of the statuses
         as they have pretty good alignment from start (positive) statuses till the end (negative) statuses. */
    const piecesOfCompletedRuns = prints.filter(print => {
      const orderPieceUris = orderPieces.map(piece => piece.uri);
      return orderPieceUris.includes(print.piece) && print.status === PIECE_STATUSES.COMPLETE;
    });
    const piecesScheduledCount = piecesScheduled.length;
    const piecesCount = orderPieces.length;

    const complete = runs.filter(run => [RUN_STATUSES.COMPLETE, RUN_STATUSES.CANCELLED].includes(run.status)).length;
    const runsAfterOrderDueDate = runs.filter(run => run.finish && dayjs(run.finish) > dayjs(due_date));

    const percentageOfPiecesScheduled = piecesCount ?
      _ceil((piecesScheduled.length * 100) / piecesCount) : 0;
    const completePercent = piecesCount ?
      _ceil((piecesOfCompletedRuns.length * 100) / piecesCount) : 0;

    const orderRunsEstimates = Object.values(runEstimatesByRunUri)
      .filter(
        estimate => runs.map(run => run.uri)
          .includes(estimate.run),
      );

    const runEstimate = orderRunsEstimates.find(re => dayjs(re.estimates.end) > dayjs(due_date));

    let arrowStatusColor = ICON_COLORS.GREEN;

    if (runsAfterOrderDueDate.length > 0) {
      arrowStatusColor = ICON_COLORS.RED;
    } else if (runEstimate) {
      arrowStatusColor = ICON_COLORS.ORANGE;
    }

    const arrowStateToShow = piecesScheduledCount === piecesCount && complete < runsCount;
    const restrictedWidthView = arrowStateToShow ? 17 : 33;
    const handleExpandOrderData = orderUri => {
      const isAlreadyExpanded = expandedOrders.find(expandedOrderUri => expandedOrderUri === orderUri);
      if (isAlreadyExpanded) {
        setExpandedOrders(expandedOrders.filter(expandedOrderUri => expandedOrderUri !== orderUri));
      } else {
        setExpandedOrders([...expandedOrders, orderUri]);
      }
    };
    return (
      <div className="spacer-left d-flex justify-content-between spacer-right align-items-center w-full">
        <div className="d-flex align-items-center">
          {
            root && (
              <Button
                className="spacer-right"
                onClick={() => handleExpandOrderData(uri)}
                style={{
                  backgroundColor: 'transparent',
                  color: 'white',
                  border: 0,
                }}
                disabled={!canBeExpanded}
              >
                <FontAwesomeIcon icon={isExpanded ? faChevronDown : faChevronRight} />
              </Button>
            )
          }
          {
            (title.length > restrictedWidthView) && !isExpanded ? (
              <Tooltip
                id="order-title-tooltip"
                placement="bottom"
                trigger={(
                  <a className="restrictedTitleWidth" href={href} style={restrictedTitleGroupStyle}>{title}</a>
                )}
              >
                <span>{title}</span>
              </Tooltip>
            ) : (
              <a href={href}>{title}</a>
            )
          }
        </div>

        <div className="d-flex align-items-center">
          <div className="d-flex">
            <Tooltip
              id="order-blue-tooltip"
              placement="bottom"
              trigger={(
                <span style={{ ...sphereIconStyle,
                  background: `linear-gradient(360deg, ${getSphereColorStyle(piecesScheduled.length, orderPieces.length)} ${percentageOfPiecesScheduled}%, transparent 0%)`,
                  borderColor: getSphereColorStyle(piecesScheduled.length, orderPieces.length) }}
                >
                  <FontAwesomeIcon icon={faCalendar} />
                </span>
              )}
            >
              <span>
                {percentageOfPiecesScheduled}% ({piecesScheduled.length}/{orderPieces.length} pieces)
                scheduled into Runs
              </span>
            </Tooltip>

            <Tooltip
              id="order-orange-tooltip"
              placement="bottom"
              trigger={(
                <span style={{ ...sphereIconStyle,
                  background: `linear-gradient(360deg, ${getSphereColorStyle(piecesOfCompletedRuns.length, orderPieces.length)} ${completePercent}%, transparent 0%)`,
                  borderColor: getSphereColorStyle(piecesOfCompletedRuns.length, orderPieces.length) }}
                >
                  <FontAwesomeIcon icon={faScrewdriverWrench} />
                </span>
              )}
            >
              <span>
                {completePercent}% ({piecesOfCompletedRuns.length}/{orderPieces.length} pieces)
                completed their Printing Run
              </span>
            </Tooltip>
          </div>
          {
            arrowStateToShow && (
              <div className="d-flex">
                <Tooltip
                  id="order-arrow-green-tooltip"
                  placement="bottom"
                  trigger={(
                    <FontAwesomeIcon style={{ ...arrowIconStyle, color: arrowStatusColor }} icon={faArrowCircleRight} />
                  )}
                >
                  <span>Manufacturing is Running</span>
                </Tooltip>
              </div>
            )
          }
        </div>
      </div>
    );
  }

  return (
    <div className="spacer-left d-flex  spacer-right align-items-center w-full">
      <FontAwesomeIcon icon={faWrench} className="spacer-right ml15" />
      <div className="d-flex align-items-center">
        {
          (title.length > restrictedWidthViewPieces) && !isExpanded ? (
            <Tooltip
              id="order-title-tooltip-pieces"
              placement="bottom"
              trigger={(
                <a className="restrictedTitleWidthPieces" href={href} style={restrictedTitleGroupStyle}>{title}</a>
              )}
            >
              <span>{title}</span>
            </Tooltip>
          ) : (
            <a className="restrictedTitleWidthPieces" href={href}>{title}</a>
          )
        }

      </div>
    </div>
  );
};

GroupRenderer.propTypes = {
  group: PropTypes.shape({
    title: PropTypes.string.isRequired,
    uri: PropTypes.string.isRequired,
    root: PropTypes.bool.isRequired,
    canBeExpanded: PropTypes.bool.isRequired,
    due_date: PropTypes.string,
  }).isRequired,
  expandedOrders: PropTypes.arrayOf(PropTypes.string).isRequired,
  setExpandedOrders: PropTypes.func.isRequired,
  runsByOrderUri: PropTypes.shape({}).isRequired,
  runEstimatesByRunUri: PropTypes.shape({}).isRequired,
  piecesByOrderUri: PropTypes.shape({}).isRequired,
  printsByUri: PropTypes.shape({}).isRequired,
  prints: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const OrdersGanttChart = ({
  orders,
  isFetching,
  runsByOrderUri,
  runEstimatesByRunUri,
  piecesByOrderUri,
  prints,
  printsByUri,
  runActualsByRunUri,
  workflowByPieceUri,
  allRunsByPieceUri,
  buildsByRunUri,
  lineHeight = 50,
  lineHeightRatio = 0.65,
  handleGanttScroll,
  defaultStartTime,
  defaultEndTime,
  limit,
  listStore,
  offset,
  onPageChange,
  onLimitChange,
  isDebugModeEnabled,
  scheduledRuns,
}) => {
  const [expandedOrders, setExpandedOrders] = useState([]);
  const [groupData, setGroupData] = useState([]);
  const [itemsData, setItemsData] = useState([]);

  const handleOnTimeChange = (start, end, updateScrollCanvas) => {
    updateScrollCanvas(start, end);
    const startTimeHasReachedEndOfQueryTimelinePast = dayjs(start) < dayjs(defaultStartTime).subtract(3, 'month');
    const startTimeHasReachedEndOfQueryTimelineFuture = dayjs(start) > dayjs(defaultEndTime).add(3, 'month');
    if (startTimeHasReachedEndOfQueryTimelinePast || startTimeHasReachedEndOfQueryTimelineFuture) {
      handleGanttScroll(start, end);
    }
  };

  const handleRenderDataGroups = () => {
    const data = [];

    orders.forEach(order => {
      const orderPieces = piecesByOrderUri[order.uri].filter(p => p.type === PRINT_TYPES.PRODUCT);
      const expanded = expandedOrders.includes(order.uri);

      data.push({ ...order, root: true, canBeExpanded: !!orderPieces.length });

      if (expanded) {
        const mappedOrderPieces = orderPieces.map(orderPiece => {
          const allWorkflowSteps = workflowByPieceUri[orderPiece.uri];

          return {
            ...orderPiece,
            name: `${orderPiece.name} - in Run ${orderPiece.current_step_position} of ${allWorkflowSteps?.process_steps?.length || 1}`,
          };
        });

        const result = {
          id: order.uri,
          data: mappedOrderPieces,
        };

        if (Object.values(result).length) {
          data.push(...result.data);
        }
      }
    });

    setGroupData(data);
  };

  const handleRenderItems = () => {
    const itemData = [...orders];

    orders.forEach(order => {
      const expanded = expandedOrders.includes(order.uri);

      if (expanded) {
        const orderPieces = piecesByOrderUri[order.uri];

        const allPrintingRuns = [];
        orderPieces.forEach(piece => {
          if (allRunsByPieceUri[piece.uri]) {
            allPrintingRuns.push({ groupPieceId: piece.uri, runs: [...allRunsByPieceUri[piece.uri]] || [] });
          }
        });

        let items = {};
        if (allPrintingRuns?.length) {
          const runs = allPrintingRuns.map(printingRuns => printingRuns.runs.map(printingRun => {
            items = {
              ...printingRun,
              isRunLine: true,
            };

            items.originalUUID = items.uuid;
            items.uuid = extractUuid(printingRuns.groupPieceId);
            return items;
          }));

          const result = {
            id: order.uri,
            data: runs.flat(),
          };

          if (Object.values(result).length) {
            itemData.push(...result.data);
          }
        }
      }
    });
    setItemsData(itemData);
  };

  useEffect(() => {
    if (!isFetching) {
      handleRenderDataGroups();
      handleRenderItems();
    }
  }, [expandedOrders, isFetching]);

  if (isFetching) {
    return (
      <Row className="d-flex align-items-center">
        <p className="text-center">
          <FormattedMessage
            id="fetchingRecordsPleaseWait"
            defaultMessage="Fetching records, please wait..."
          />
        </p>
        <Loading />
      </Row>
    );
  }

  const items = _map(itemsData, ({
    uuid,
    isRunLine,
    name,
    ...rest
  }) => {
    if (isRunLine) {
      const { uri, start, finish, originalUUID } = rest;
      const startTimes = new Date(runActualsByRunUri[uri]?.start_in_progress_time
        || start
        || runEstimatesByRunUri[uri]?.estimates.start).getTime();
      const endTimes = new Date(runActualsByRunUri[uri]?.end_in_progress_time
        || finish
        || runEstimatesByRunUri[uri]?.estimates.end).getTime();

      if (startTimes.length === 0 || endTimes.length === 0) {
        return {};
      }

      const startTime = dayjs(startTimes);
      const endTime = dayjs(endTimes);

      return {
        ...rest,
        name,
        id: `${originalUUID}_run${Math.random() * 10}`,
        start_time: startTime,
        end_time: endTime,
        group: uuid,
        isRunLine: true,
      };
    }

    const runs = runsByOrderUri[rest.uri];
    const orderPieces = piecesByOrderUri[rest.uri];
    const startTimes = _sortBy(
      runs?.map(run =>
        runActualsByRunUri[run.uri]?.start_in_progress_time
        || run.start
        || runEstimatesByRunUri[run.uri]?.estimates.start,
      )
        .filter(Boolean),
      datetime => new Date(datetime).getTime(),
    );
    const endTimes = _sortBy(
      runs?.map(run =>
        runActualsByRunUri[run.uri]?.end_in_progress_time
        || run.finish
        || runEstimatesByRunUri[run.uri]?.estimates.end,
      )
        .filter(Boolean),
      datetime => new Date(datetime).getTime(),
    );
    if (startTimes.length === 0 || endTimes.length === 0) {
      return {};
    }

    const piecesScheduled = orderPieces?.filter(
      orderPiece => printsByUri[orderPiece.current_print]?.run ||
        ![PIECE_STATUSES.NEW, PIECE_STATUSES.CONFIRMED].includes(orderPiece.status),
    );
    const piecesCompleted = orderPieces.filter(
      orderPiece => Object.values(PIECE_STATUSES).slice(4, 9).includes(orderPiece.status),
    );
    const piecesCompletedCount = piecesCompleted.length > 999 ? '999+' : piecesCompleted.length;
    const runsCompleted = runs.filter(run => [RUN_STATUSES.COMPLETE, RUN_STATUSES.CANCELLED].includes(run.status));
    const runsCompletedCount = runsCompleted.length ? runsCompleted.length : 0;
    const complete = runs?.filter(run => run.status === RUN_STATUSES.COMPLETE).length;
    const total = runs ? runs.length : 0;
    const completePercent = piecesScheduled.length === orderPieces.length ?
      _ceil((complete * 100) / (total || 1)) : 0;
    const startTime = dayjs(startTimes[0]);
    const endTime = dayjs(endTimes[endTimes.length - 1]);
    const orderRunsEstimates = Object.values(runEstimatesByRunUri)
      .filter(
        estimate => runs.map(run => run.uri)
          .includes(estimate.run),
      );
    return {
      ...rest,
      name,
      id: uuid,
      start_time: startTime,
      end_time: endTime,
      group: uuid,
      completePercent,
      runs,
      piecesCount: orderPieces.length,
      piecesScheduledCount: piecesScheduled.length,
      piecesCompletedCount,
      runsCompletedCount,
      orderRunsEstimates,
    };
  });
  const groups = _map(groupData, ({
    name,
    uuid,
    ...rest
  }) => ({
    ...rest,
    id: uuid,
    title: name,
  }));

  const DebugDataValues = {
    name: 'Scheduled Runs For Orders',
    current_Starting_Date: dayjs(defaultStartTime).subtract(3, 'month').format('YYYY-MM-DD HH:mm:ss'),
    current_Ending_Date: dayjs(defaultEndTime).add(3, 'month').format('YYYY-MM-DD HH:mm:ss'),
    available_scheduled_runs: scheduledRuns,
  };

  return (
    <>
      {isDebugModeEnabled && (
        <DebugModeDataPanel style={{ marginBottom: 10 }} className="spacer-bottom" data={DebugDataValues} />
      )}
      <Timeline
        onTimeChange={handleOnTimeChange}
        lineHeight={lineHeight}
        itemHeightRatio={lineHeightRatio}
        groups={groups}
        items={items}
        defaultTimeStart={defaultStartTime}
        defaultTimeEnd={defaultEndTime}
        maxZoom={MAX_ZOOM}
        minZoom={MIN_ZOOM}
        canMove={false}
        canResize={false}
        className="ganttTimeline"
        canChangeGroup={false}
        sidebarWidth={expandedOrders.length ? 330 : 290}
        timeSteps={{
          second: 30,
          minute: 15,
          hour: 1,
          day: 1,
          month: 1,
          year: 1,
        }}
        onItemSelect={uri => onItemSelect(uri)}
        itemRenderer={
          ({ item, getItemProps }) =>
            <ItemRenderer item={item} getItemProps={getItemProps} buildsByRunUri={buildsByRunUri} />
        }
        groupRenderer={
          ({ group }) => (
            <GroupRenderer
              group={group}
              expandedOrders={expandedOrders}
              setExpandedOrders={setExpandedOrders}
              piecesByOrderUri={piecesByOrderUri}
              prints={prints}
              printsByUri={printsByUri}
              runsByOrderUri={runsByOrderUri}
              runEstimatesByRunUri={runEstimatesByRunUri}
            />
          )
        }
        stackItems
      >
        <TimelineHeaders>
          <DateHeader unit="primaryHeader" />
          <DateHeader
            height={40}
            labelFormat={formatGanttDateHeaderLabel}
          />
        </TimelineHeaders>
        <TimelineMarkers>
          <TodayMarker>
            {({ styles }) => (
              <div style={{
                ...styles,
                backgroundColor: COLORS.PURPLE,
              }}
              />
            )}
          </TodayMarker>
        </TimelineMarkers>
      </Timeline>
      <Row className="mt15">
        <Col>
          <Pagination
            limit={limit}
            listStore={listStore}
            offset={offset}
            onPageChange={onPageChange}
          />

        </Col>
        <Col lg={2}>
          <Limit
            limit={limit}
            onLimitChange={onLimitChange}
            availableLimits={[10, 20]}
          />
        </Col>
      </Row>
    </>
  );
};

OrdersGanttChart.defaultProps = {
  lineHeight: 50,
  lineHeightRatio: 0.65,
};

OrdersGanttChart.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  orders: PropTypes.arrayOf(orderResourceType).isRequired,
  runsByOrderUri: PropTypes.shape({}).isRequired,
  runEstimatesByRunUri: PropTypes.shape({}).isRequired,
  piecesByOrderUri: PropTypes.shape({}).isRequired,
  prints: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  printsByUri: PropTypes.shape({}).isRequired,
  runActualsByRunUri: PropTypes.shape({}).isRequired,
  allRunsByPieceUri: PropTypes.shape({}).isRequired,
  workflowByPieceUri: PropTypes.shape({}).isRequired,
  buildsByRunUri: PropTypes.shape({}).isRequired,
  lineHeight: PropTypes.number,
  lineHeightRatio: PropTypes.number,
  handleGanttScroll: PropTypes.func.isRequired,
  defaultStartTime: PropTypes.shape({
    format: PropTypes.func,
  }).isRequired,
  defaultEndTime: PropTypes.shape({
    format: PropTypes.func,
  }).isRequired,
  limit: PropTypes.number.isRequired,
  onLimitChange: PropTypes.func.isRequired,
  offset: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  isDebugModeEnabled: PropTypes.bool.isRequired,
  listStore: PropTypes.shape({}).isRequired,
  scheduledRuns: PropTypes.shape([]).isRequired,
};

export default memo(OrdersGanttChart);
