import { useImpersonationContext } from 'rapidfab/context/ImpersonationContext';
import React, { useEffect, useState } from 'react';
import { ButtonToolbar, Col, Row, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import { API_RESOURCES, ROUTES } from 'rapidfab/constants';
import { useDispatch, useSelector } from 'react-redux';
import Alert from 'rapidfab/utils/alert';
import { getIsDebugModeEnabled } from 'rapidfab/selectors';
import Actions from 'rapidfab/actions';
import { FormattedMessage } from 'react-intl';
import { FormattedDateTime } from 'rapidfab/i18n';
import Config from 'rapidfab/config';

const About = () => {
  const isDebugModeEnabled = useSelector(getIsDebugModeEnabled);
  const [nautilusVerison, setNautilusVersion] = useState({});
  const dispatch = useDispatch();

  const {
    setIsImpersonationModalActive,
  } = useImpersonationContext();

  const fetchNautilusVersion = async () => {
    const { json: nautilusVersonData } = await dispatch(Actions.Api.nautilus[API_RESOURCES.ABOUT].list());
    setNautilusVersion(nautilusVersonData);
  };

  useEffect(() => {
    fetchNautilusVersion();
  }, []);

  const handleChangeDebugMode = () => {
    const debugModeStatus = !isDebugModeEnabled ? 'enabled' : 'disabled';
    dispatch(Actions.Admin.setIsDebugModeEnabled(!isDebugModeEnabled));
    Alert.warning(
      <FormattedMessage
        id="toaster.warning.debugModeToggled"
        defaultMessage="Debug Mode is {debugModeStatus}!"
        values={{ debugModeStatus }}
      />,
    );
  };

  return (
    <Row>
      <Col md={{
        span: 10,
        offset: 1,
      }}
      >
        <dl>
          <dt>Commit hash:</dt>
          <dd>{process.env.COMMIT_HASH}</dd>
        </dl>
        <dl>
          <dt>Git describe:</dt>
          <dd>{process.env.GITDESCRIBE}</dd>
        </dl>
        <dl>
          <dt>Environment:</dt>
          <dd>{Config.ENVIRONMENT}</dd>
        </dl>
        {
          nautilusVerison && (
            <>
              <dl>
                <dt>Nautilus Version:</dt>
                <dd>{nautilusVerison.version}</dd>
              </dl>
              <dl>
                <dt>Nautilus Deployment Date:</dt>
                <dd><FormattedDateTime value={nautilusVerison.deployment_date} /></dd>
              </dl>
            </>
          )
        }
        <dl>
          <dt className="mb15">Debug Mode Switch:</dt>
          <dd>
            <ButtonToolbar>
              <ToggleButtonGroup
                type="radio"
                name="DebugModeSwitch"
                value={isDebugModeEnabled.toString()}
              >
                <ToggleButton
                  name="Disabled"
                  variant="outline-success"
                  value="false"
                  onClick={handleChangeDebugMode}
                >
                  Disabled
                </ToggleButton>
                <ToggleButton
                  name="Enabled"
                  variant="outline-success"
                  value="true"
                  onClick={handleChangeDebugMode}
                >
                  Enabled
                </ToggleButton>
              </ToggleButtonGroup>
            </ButtonToolbar>
          </dd>
        </dl>
        <hr />
        <dl>
          <dt>Prints:</dt>
          <dd>
            <a href={getRouteURI(ROUTES.PRINTS)}>{getRouteURI(ROUTES.PRINTS)}</a>
          </dd>
        </dl>
        <dl>
          <dt>Create Runs:</dt>
          <dd>
            <a href={getRouteURI(ROUTES.CREATE_RUNS)}>
              {getRouteURI(ROUTES.CREATE_RUNS)}
            </a>
          </dd>
        </dl>
        <dl>
          <dt>Prep Tasks:</dt>
          <dd>
            <a href={getRouteURI(ROUTES.PREP_TASKS)}>
              {getRouteURI(ROUTES.PREP_TASKS)}
            </a>
          </dd>
        </dl>
        <dl>
          <dt>Captures:</dt>
          <dd>
            <a href={getRouteURI(ROUTES.CAPTURES)}>
              {getRouteURI(ROUTES.CAPTURES)}
            </a>
          </dd>
        </dl>
        <dl>
          <dt>Impersonate:</dt>
          <dd>
            <span
              role="button"
              tabIndex={0}
              onClick={() => setIsImpersonationModalActive(true)}
              className="impersonationModalText"
            >
              <FormattedMessage
                id="openImpersonationModal"
                defaultMessage="Open Impersonation Modal"
              />
            </span>
          </dd>
        </dl>
        <dl>
          <dt>Sentry Test:</dt>
          <dd>
            <a href={getRouteURI(ROUTES.SENTRY_TEST)}>
              {getRouteURI(ROUTES.SENTRY_TEST)}
            </a>
          </dd>
        </dl>
      </Col>
    </Row>
  );
};

export default About;
