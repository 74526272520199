import { selectInputStyles } from 'rapidfab/constants/styles';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Col,
  FormLabel,
  FormControl,
  FormGroup,
  Container,
  Dropdown,
  Card,
  Row,
  SplitButton,
  Tooltip, OverlayTrigger, Badge,
  InputGroup,
  Form as ReactBootStrapForm,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';

import BreadcrumbNav from 'rapidfab/components/BreadcrumbNav';
import CostTooltip from 'rapidfab/components/CostTooltip';
import SaveButtonTitle from 'rapidfab/components/SaveButtonTitle';
import { getShortUUID } from 'rapidfab/utils/uuidUtils';
import BatchType from 'rapidfab/components/records/BatchType';
import WorkChecklistContainer from 'rapidfab/containers/records/WorkChecklistContainer';
import { postProcessorTypeResourceType, finalFormInputTypes } from 'rapidfab/types';
import LaborDuration from 'rapidfab/components/LaborDuration';
import { FormControlCost, FormControlSelect } from 'rapidfab/components/formTools';
import { ACCESS_INFO_ACTION_TYPES, FEATURES, REACT_SELECT_ACTIONS } from 'rapidfab/constants';
import DisabledByAccessInfoCheck from 'rapidfab/components/DisabledByAccessInfoCheck';

import { Form, Field } from 'react-final-form';
import { REVIEW_TYPES } from 'rapidfab/constants/reviewTypes';
import Loading from 'rapidfab/components/Loading';
import { isInHoursMinutesFormat } from 'rapidfab/utils/timeUtils';
import LaborAvailableSchedule from 'rapidfab/components/records/LaborAvailableSchedule';
import Feature from 'rapidfab/components/Feature';
import usePrevious from 'rapidfab/hooks';
import { faArchive, faBan, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import CreatableSelect from 'react-select/creatable';
import IntegratedWorkstationCheck from '../IntegratedWorkstationCheck';

const PostProcessorType = ({
  onDelete,
  onArchive,
  onUnarchive,
  onSubmit,
  manufacturers,
  customGroups,
  materials,
  defaultCurrency,
  postProcessorType,
  isGroupQualificationsFeatureEnabled,
  initialFormValues,
  isEditing,
  onSelectAllMaterials,
  onDeselectAllMaterials,
  submitting,
  laborWorkstationEnable,
  isLaborWorkflowEnabled,
  handleChangeLaborTime,
  handleSetLaborNonStopTime,
  laborScheduleTime,
  laborNonStopEnabled,
  workScheduleEnabled,
  handleTriggerWorkSchedule,
  handleUTC_TimezoneSetting,
  isWorkScheduleFeatureEnabled,
  handleSetWorkday,
  workDays,
  UTC_TimeZone,
  handleSubmitManufacturer,
  isManufacturerFetching,
}) => {
  const [isAllMaterialsButtonSelected, setIsAllMaterialsButtonSelected] =
    useState(initialFormValues?.materials?.length === materials?.length);
  const [isDurationInvalid, setIsDurationInvalid] = useState(false);
  const [laborDurationSelected, setLaborDurationSelected] = useState(initialFormValues.laborType === 'labor_to_run');
  const [, setLaborToStart] = useState(initialFormValues.laborType === 'labor_to_start');
  const [currentDurationValue, setCurrentDurationValue] = useState(initialFormValues.duration);
  const [currentLaborDurationValue, setCurrentLaborDurationValue] = useState(initialFormValues.workstation_duration);
  const [isLaborDurationInvalid, setIsLaborDurationInvalid] = useState(false);
  const [isLaborDurationDisabled, setIsLaborDurationDisabled] = useState(false);

  const formattedManufacturers = manufacturers.map(manufacturer => ({
    label: manufacturer.name,
    value: manufacturer.uri,
  })).filter(Boolean);

  const previousDuration = usePrevious(initialFormValues.workstation_duration);

  /* There are cases when initialFormValues.workstation_duration is undefined or 00:00
     as the value has not refreshed on time, and we need to track the changes in the value
     in order to update the input field and show the new data. */

  useEffect(() => {
    if (initialFormValues.workstation_duration !== previousDuration) {
      setCurrentLaborDurationValue(initialFormValues.workstation_duration);
    }
  }, [initialFormValues.workstation_duration, previousDuration]);

  const handleManufacturersChange = async (selectedOption, actionMeta, onChange) => {
    // The user would like to create the new manufacturer "on the fly"
    if (actionMeta.action === REACT_SELECT_ACTIONS.CREATE_OPTION) {
      // Send API request to create the new manufacturer with the "name" field
      const manufacturerUri = await handleSubmitManufacturer(selectedOption.label);
      if (manufacturerUri) {
        // Set the new manufacturer URI as the value of the select input
        return onChange(manufacturerUri);
      }
    }
    const newValue = selectedOption ? selectedOption.value : '';
    return onChange(newValue);
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialFormValues.in_progress_max ? initialFormValues :
        { ...initialFormValues, in_progress_max: 1 }}
      mutators={{
        onSelectAllMaterials,
        onDeselectAllMaterials,
        onChangeLaborType: ([field, value], state, { changeValue }) => {
          changeValue(state, field, () => value);
        },
        toggleIntegratedWorkStation: (_, state, { changeValue }) => {
          const { integrated: initialValue } = state.formState.values;
          changeValue(
            state,
            'integrated',
            () => !initialValue,
          );
        },
      }}
      render={({ handleSubmit, form, values }) => (
        <form onSubmit={handleSubmit}>
          <Container fluid className="p-b">
            <BreadcrumbNav
              breadcrumbs={['assets', 'postProcessorTypes', initialFormValues?.id ? `${initialFormValues?.name} (${getShortUUID(initialFormValues?.uuid)})` : 'New']}
            />
            <div className="d-flex align-items-center justify-content-between w-100">
              <div>
                {postProcessorType?.archived && (
                  <Badge bg="default">
                    <FormattedMessage id="field.archived" defaultMessage="Archived" />
                  </Badge>
                )}
              </div>
              <div className="pull-right">
                <DisabledByAccessInfoCheck
                  resourceUri={postProcessorType?.uri}
                  actionType={ACCESS_INFO_ACTION_TYPES.EDIT}
                  renderDisabledPrefix
                  tooltipPlacement="bottom"
                >
                  {({ disabled }) => (
                    <SplitButton
                      id="uxSaveDropdown"
                      type="submit"
                      variant="success"
                      size="sm"
                      title={submitting ? <Loading /> : <SaveButtonTitle />}
                      disabled={disabled || submitting || isLaborDurationInvalid}
                    >
                      {postProcessorType?.archived ? (
                        <>
                          <Dropdown.Item
                            eventKey={1}
                            onClick={() => onUnarchive(values)}
                            disabled={!initialFormValues?.id}
                          >
                            <FontAwesomeIcon icon={faArchive} />{' '}
                            <FormattedMessage id="button.unarchive" defaultMessage="Unarchive" />
                          </Dropdown.Item>
                          <Dropdown.Item
                            eventKey={2}
                            onClick={() => onDelete(initialFormValues?.uuid)}
                            disabled={!initialFormValues?.id}
                          >
                            <FontAwesomeIcon icon={faBan} />{' '}
                            <FormattedMessage id="button.delete" defaultMessage="Delete" />
                          </Dropdown.Item>
                        </>
                      ) : (
                        <Dropdown.Item
                          eventKey={1}
                          onClick={() => onArchive(initialFormValues?.uuid)}
                          disabled={!initialFormValues?.id}
                        >
                          <FontAwesomeIcon icon={faArchive} />{' '}
                          <FormattedMessage id="button.archive" defaultMessage="Archive" />
                        </Dropdown.Item>
                      )}
                    </SplitButton>
                  )}
                </DisabledByAccessInfoCheck>
              </div>
            </div>

            <hr />
            <hr />

            <Row>
              <Col xs={12} sm={6}>
                <FormGroup className="form-group" controlId="uxName">
                  <FormLabel>
                    <FormattedMessage id="field.name" defaultMessage="Name" />: *
                  </FormLabel>
                  <Field
                    name="name"
                    type="text"
                    render={props => (
                      <FormControl
                        {...props.input}
                        required
                      />
                    )}
                  />
                </FormGroup>
                <FormGroup className="form-group" controlId="uxDescription">
                  <FormLabel>
                    <FormattedMessage
                      id="field.description"
                      defaultMessage="Description"
                    />: *
                  </FormLabel>
                  <Field
                    name="description"
                    type="text"
                    render={props => (
                      <FormControl
                        {...props.input}
                        required
                      />
                    )}
                  />
                </FormGroup>
                <FormGroup className="form-group" controlId="uxCapacity">
                  <FormLabel>
                    <FormattedMessage
                      id="field.Capacity"
                      defaultMessage="Max Capacity"
                    />: *
                    {' '}
                    <OverlayTrigger
                      placement="top"
                      overlay={(
                        <Tooltip>
                          <FormattedMessage
                            id="maxCapacityTooltip"
                            defaultMessage="Maximum parallel runs allowed on this workstation type."
                          />
                        </Tooltip>
                      )}
                    >
                      <FontAwesomeIcon className="spacer-left spacer-right" icon={faQuestionCircle} />
                    </OverlayTrigger>
                  </FormLabel>
                  <Field
                    name="in_progress_max"
                    type="number"
                    render={props => (
                      <FormControl
                        {...props.input}
                        required
                        min="1"
                        max="32767"
                        inputmode="numeric"
                        data-cy="form-max-capacity-input-field"
                      />
                    )}
                  />
                </FormGroup>
                <FormGroup className="form-group" controlId="uxCost">
                  <FormLabel>
                    <FormattedMessage
                      id="field.costPerMinute"
                      defaultMessage="Cost Per Minute"
                    />: *
                    {' '}
                    <CostTooltip />
                  </FormLabel>
                  <FormControlCost
                    name="cost"
                    initialValue={initialFormValues?.cost}
                    type="number"
                    required
                    currency={defaultCurrency}
                  />
                </FormGroup>
                <FormGroup className="form-group" controlId="uxDuration">
                  <FormLabel>Workstation Default Duration: *

                    <OverlayTrigger
                      placement="top"
                      overlay={(
                        <Tooltip>
                          <FormattedMessage
                            id="WorkstationDurationTooltip"
                            defaultMessage="This is how long the Workstation runs for"
                          />
                        </Tooltip>
                      )}
                    >
                      <FontAwesomeIcon icon={faQuestionCircle} className="spacer-left" />
                    </OverlayTrigger>
                  </FormLabel>

                  <InputGroup>
                    <Field
                      name="duration"
                      type="text"
                      validate={value => !isInHoursMinutesFormat(value)}
                      initialValue={initialFormValues.duration}
                      render={fieldProps => {
                        setIsDurationInvalid(fieldProps.meta.touched && fieldProps.meta.invalid);
                        return (
                          <>
                            <FormControl
                              {...fieldProps.input}
                              onChange={event => {
                                setCurrentDurationValue(event.target.value);
                                fieldProps.input.onChange(event.target.value);
                                if (laborDurationSelected) {
                                  setCurrentLaborDurationValue(event.target.value);
                                }
                              }}
                              required
                              isInvalid={(fieldProps.meta.touched && fieldProps.meta.invalid && fieldProps.input.value !== '')}
                            />
                          </>
                        );
                      }}
                    />

                    <InputGroup.Text>
                      <FormattedMessage
                        id="field.workstation-duration"
                        defaultMessage="HH:MM"
                      />
                    </InputGroup.Text>

                  </InputGroup>
                  {isDurationInvalid && (
                    <span className="text-start text-danger">Please use HH:MM format above</span>)}
                </FormGroup>
                {isWorkScheduleFeatureEnabled && (
                  <LaborDuration
                    toggleLaborWorkWorkField={laborWorkstationEnable}
                    isLaborWorkstationEnabled={isLaborWorkflowEnabled}
                  >
                    <FormGroup className="form-group" controlId="uxlabor">

                      <FormLabel>
                        <FormattedMessage
                          id="field.labor"
                          defaultMessage="Type of Labor Needed for post processor type"
                        />
                      </FormLabel>
                      <Field
                        name="laborType"
                        type="radio"
                        value="labor_to_run"
                        checked={initialFormValues.laborType === 'labor_to_run' && laborDurationSelected}
                      >
                        {props => (

                          <ReactBootStrapForm.Check
                            name={props.input.name}
                            value={props.input.value}
                            checked={(initialFormValues.laborType === 'labor_to_run' && laborDurationSelected) ||
                                values.laborType === 'labor_to_run'}
                            onChange={() => form.mutators.onChangeLaborType('laborType', 'labor_to_run')}
                            onClick={() => {
                              setLaborDurationSelected(true);
                              setLaborToStart(false);
                              setIsLaborDurationDisabled(true);
                              setCurrentLaborDurationValue(currentDurationValue);
                            }}
                            type="radio"
                            label="Continuous Labor (person is working full workstation duration)"
                            disabled={!isLaborWorkflowEnabled}
                          />

                        )}
                      </Field>

                      <Field
                        name="laborType"
                        type="radio"
                        value="labor_to_start"
                        disabled={!isLaborWorkflowEnabled}
                        checked={initialFormValues.laborType === 'labor_to_start' && !laborDurationSelected}
                      >
                        {props => (
                          <ReactBootStrapForm.Check
                            checked={(initialFormValues.laborType === 'labor_to_start' && !laborDurationSelected) ||
                                values.laborType === 'labor_to_start'}
                            name={props.input.name}
                            value={props.input.value}
                            onChange={() => form.mutators.onChangeLaborType('laborType', 'labor_to_start')}
                            onClick={() => {
                              setLaborDurationSelected(false);
                              setIsLaborDurationDisabled(false);
                              setLaborToStart(true);
                            }}
                            type="radio"
                            label="Labor to Start (person is working less time than workstation duration, at start of work)"
                            disabled={!isLaborWorkflowEnabled}
                          />

                        )}
                      </Field>
                    </FormGroup>

                    {!laborDurationSelected && (
                      <FormGroup className="form-group" controlId="uxLaborDuration">
                        <Row>
                          <Col>
                            <FormLabel>Default Labor Duration:</FormLabel>
                            <InputGroup>
                              <Field
                                name="workstation_duration"
                                type="text"
                                initialValue={initialFormValues.workstation_duration || '00:00'}
                                validate={value => (!isInHoursMinutesFormat(value))}

                                render={fieldProps => {
                                  setIsLaborDurationInvalid(
                                    laborDurationSelected && currentLaborDurationValue !== currentDurationValue);
                                  return (
                                    <>
                                      <FormControl
                                        {...fieldProps.input}
                                        value={currentLaborDurationValue}
                                        required
                                        onChange={event => {
                                          setCurrentLaborDurationValue(event.target.value);
                                          fieldProps.input.onChange(event.target.value);
                                        }}
                                        isInvalid={(fieldProps.meta.touched
                                            && fieldProps.meta.invalid) || isLaborDurationInvalid}
                                        disabled={!isLaborWorkflowEnabled || isLaborDurationDisabled}
                                      />

                                    </>
                                  );
                                }}
                              />

                              <InputGroup.Text>
                                <FormattedMessage
                                  id="field.workstation-duration"
                                  defaultMessage="HH:MM"
                                />

                              </InputGroup.Text>

                            </InputGroup>
                          </Col>

                        </Row>
                        {(laborDurationSelected && isLaborDurationInvalid) && (
                          <div className="text-start text-danger">Continuous labor is selected, please select labor to
                            start or make sure default labor duration time matches default workstation duration
                            time
                          </div>
                        )}
                      </FormGroup>
                    )}
                  </LaborDuration>
                )}

                {// working here
                }

                <FormGroup className="form-group" controlId="uxMaterials">
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <FormLabel>
                      <FormattedMessage
                        id="materials"
                        defaultMessage="Materials"
                      />: *
                    </FormLabel>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
                      <OverlayTrigger
                        placement="top"
                        overlay={(
                          <Tooltip>
                            <p>
                              This selection only applies to all the current materials <b>on this list</b>,
                              any new materials added in the future will need to be continuously selected manually.
                            </p>
                          </Tooltip>
                        )}
                      >
                        <FontAwesomeIcon className="spacer-right" icon={faQuestionCircle} />
                      </OverlayTrigger>
                      <Button
                        className="spacer-left btn-xs"
                        data-cy="select-all-materials-button"
                        variant={isAllMaterialsButtonSelected ? 'link' : 'info'}
                        onClick={() => {
                          setIsAllMaterialsButtonSelected(!isAllMaterialsButtonSelected);
                          if (isAllMaterialsButtonSelected) form.mutators.onDeselectAllMaterials();
                          else form.mutators.onSelectAllMaterials();
                        }}
                      >
                        {isAllMaterialsButtonSelected ? 'Deselect all materials' : 'Select all materials'}
                      </Button>
                    </div>
                  </div>
                  <Field
                    name="materials"
                    type="select"
                    multiple
                    render={props => (
                      <FormControlSelect
                        {...props.input}
                        disabled={isAllMaterialsButtonSelected}
                        required
                      >
                        {materials.map(material => (
                          <option key={material.uri} value={material.uri}>
                            {material.name}
                          </option>
                        ))}
                      </FormControlSelect>
                    )}
                  />
                </FormGroup>
                <FormGroup className="form-group" controlId="uxManufacturer">
                  <FormLabel>
                    <FormattedMessage
                      id="field.manufacturer"
                      defaultMessage="Manufacturer"
                    />: *
                  </FormLabel>
                  <Field
                    name="manufacturer"
                    render={props => {
                      const selectedOption = formattedManufacturers.find(option =>
                        option.value === props.input.value) || null;
                      return (
                        <CreatableSelect
                          {...props.input}
                          styles={selectInputStyles}
                          isLoading={isManufacturerFetching}
                          placeholder={(
                            <FormattedMessage
                              id="field.selectManufacturer"
                              defaultMessage="Select Manufacturer"
                            />
                          )}
                          isDisabled={isManufacturerFetching}
                          components={{ LoadingIndicator: () => (<Loading inline className="spacer-right" />) }}
                          value={selectedOption}
                          options={formattedManufacturers}
                          required
                          onChange={(value, actionMeta) =>
                            handleManufacturersChange(value, actionMeta, props.input.onChange)}
                          isClearable
                        />
                      );
                    }}
                  />
                </FormGroup>
                <BatchType fields={initialFormValues} />

                <Card bg="dark" className="mt15">
                  <Card.Header className="pd-exp inverse">
                    Custom Bureau Cost
                  </Card.Header>
                  <div className="card-body-wrapper">
                    <Card.Body>
                      <FormGroup className="form-group">
                        <FormLabel>
                          <FormattedMessage
                            id="overheadCostPerPiece"
                            defaultMessage="Overhead Cost Per Piece"
                          />:
                        </FormLabel>
                        <Field
                          name="overhead_cost_per_piece_in_run"
                          type="number"
                          render={props => (
                            <FormControlCost
                              {...props.input}
                              name="overhead_cost_per_piece_in_run"
                              initialValue={initialFormValues?.overhead_cost_per_piece_in_run || 0}
                              type="number"
                              currency={defaultCurrency}
                            />
                          )}
                        />
                      </FormGroup>
                      <FormGroup>
                        <FormLabel>
                          <FormattedMessage
                            id="overheadCostPerRun"
                            defaultMessage="Overhead Cost Per Run"
                          />:
                        </FormLabel>
                        <Field
                          name="overhead_cost_per_run"
                          type="text"
                          render={props => (
                            <FormControlCost
                              {...props.input}
                              name="overhead_cost_per_run"
                              initialValue={initialFormValues?.overhead_cost_per_run || 0}
                              type="number"
                              currency={defaultCurrency}
                            />
                          )}
                        />
                      </FormGroup>
                    </Card.Body>
                  </div>
                </Card>

              </Col>
              <Col sm={6}>
                {
                  postProcessorType && (
                    <WorkChecklistContainer
                      workstep={postProcessorType.uri}
                      wrapPanel
                    />
                  )
                }
                <Card bg="dark" className="mb15">
                  <Card.Header className="pd-exp inverse">User Groups</Card.Header>
                  <div className="card-body-wrapper">
                    <Card.Body>
                      <FormGroup controlId="uxCustomGroup">
                        <Field
                          name="edit_group"
                          type="select"
                          render={props => (
                            <FormControlSelect
                              {...props.input}
                              className="mb15"
                              disabled={!isGroupQualificationsFeatureEnabled}
                            >
                              <FormattedMessage id="all" defaultMessage="All">{text =>
                                <option value="">{text}</option>}
                              </FormattedMessage>
                              {customGroups.map(customGroup => (
                                <option
                                  value={customGroup.uri}
                                  key={customGroup.uuid}
                                >
                                  {customGroup.name}
                                </option>
                              ))}
                            </FormControlSelect>
                          )}
                        />
                      </FormGroup>
                      <p>
                        <FormattedMessage
                          id="postProcessorTypeEditGroupHelperText"
                          defaultMessage="Assigning a Post Processor Type to a User Group will result in the associated Post Processors, Runs and Workflows will only be editable by users within the User Group"
                        />
                      </p>
                    </Card.Body>
                  </div>
                </Card>
                <Feature featureName={FEATURES.WORK_SCHEDULE}>
                  <LaborAvailableSchedule
                    handleChange={handleChangeLaborTime}
                    handleSetNonStopTime={handleSetLaborNonStopTime}
                    scheduleTime={laborScheduleTime}
                    nonStopEnabled={laborNonStopEnabled}
                    workSchedule={workScheduleEnabled}
                    setWorkSchedule={handleTriggerWorkSchedule}
                    setUTC_Timezone={handleUTC_TimezoneSetting}
                    handleSetWorkday={handleSetWorkday}
                    workDays={workDays}
                    UTC_TimeZone={UTC_TimeZone}
                  />
                </Feature>
                <Card bg="dark" className="mb15">
                  <Card.Header className="pd-exp inverse">Advanced Settings</Card.Header>
                  <div className="card-body-wrapper">
                    <Card.Body>
                      <Feature featureName={FEATURES.JENI_CLUSTER}>
                        <IntegratedWorkstationCheck
                          toggleIntegratedWorkStation={form.mutators.toggleIntegratedWorkStation}
                          isIntegratedWorkstationEnabled={values.integrated}
                          machineType="Post-Processor-Type"
                        />
                      </Feature>
                      <FormGroup controlId="uxCustomGroup">
                        <FormLabel>Review Type: *</FormLabel>
                        <Field
                          name="special_type"
                          type="select"
                          render={props => (
                            <FormControlSelect
                              {...props.input}
                              disabled={isEditing}
                              className="mb15"
                            >
                              <FormattedMessage id="postProcessorTypeOption.None" defaultMessage="None">{text =>
                                <option value={null}>{text}</option>}
                              </FormattedMessage>
                              <FormattedMessage
                                id="postProcessorTypeOption.NonConformanceReview"
                                defaultMessage="Non-Conformance Review"
                              >
                                {text => <option value={REVIEW_TYPES.NON_CONFORMANCE_REVIEW}>{text}</option>}
                              </FormattedMessage>
                            </FormControlSelect>
                          )}
                        />
                      </FormGroup>
                      <p>
                        <FormattedMessage
                          id="postProcessorTypeEditAdvancedSettingsHelperText"
                          defaultMessage="Indicates if this Post Processor is for special Reviews. Select the type of review which is applies."
                        />
                        <a href="/to-be-continued"> More Information</a>
                      </p>
                    </Card.Body>
                  </div>
                </Card>
              </Col>
            </Row>
          </Container>
        </form>
      )}
    />
  );
};

PostProcessorType.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onArchive: PropTypes.func.isRequired,
  onUnarchive: PropTypes.func.isRequired,
  manufacturers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  customGroups: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  materials: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  defaultCurrency: PropTypes.string.isRequired,
  postProcessorType: postProcessorTypeResourceType,
  input: finalFormInputTypes.isRequired,
  initialFormValues: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    manufacturer: PropTypes.string,
    materials: PropTypes.arrayOf(PropTypes.string),
    edit_group: PropTypes.string,
    special_type: PropTypes.string,
    uuid: PropTypes.string,
    id: PropTypes.string,
    duration: PropTypes.string,
    cost: PropTypes.string,
    in_progress_max: PropTypes.number,
    workstation_duration: PropTypes.string,
    laborType: PropTypes.string,
    overhead_cost_per_run: PropTypes.number,
    overhead_cost_per_piece_in_run: PropTypes.number,
  }).isRequired,
  isGroupQualificationsFeatureEnabled: PropTypes.bool.isRequired,
  isEditing: PropTypes.bool.isRequired,
  onSelectAllMaterials: PropTypes.func.isRequired,
  onDeselectAllMaterials: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  laborWorkstationEnable: PropTypes.func.isRequired,
  isLaborWorkflowEnabled: PropTypes.bool.isRequired,
  handleChangeLaborTime: PropTypes.func.isRequired,
  handleSetLaborNonStopTime: PropTypes.func.isRequired,
  laborScheduleTime: PropTypes.shape({}).isRequired,
  laborNonStopEnabled: PropTypes.bool.isRequired,
  workScheduleEnabled: PropTypes.bool.isRequired,
  handleTriggerWorkSchedule: PropTypes.func.isRequired,
  handleUTC_TimezoneSetting: PropTypes.func.isRequired,
  isWorkScheduleFeatureEnabled: PropTypes.bool.isRequired,
  handleSetWorkday: PropTypes.func.isRequired,
  workDays: PropTypes.arrayOf(PropTypes.string).isRequired,
  UTC_TimeZone: PropTypes.number.isRequired,
  handleSubmitManufacturer: PropTypes.func.isRequired,
  isManufacturerFetching: PropTypes.bool.isRequired,
};

PostProcessorType.defaultProps = {
  postProcessorType: null,
};

export default PostProcessorType;
