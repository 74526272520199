import FeedstocksTabs from 'rapidfab/components/inventory/FeedstocksTabs';
import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import Loading from 'rapidfab/components/Loading';
import { ROUTES } from 'rapidfab/constants';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import TableWithSearching from 'rapidfab/components/Tables/TableWithSearching';
import { faClipboard, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Stocks = props => {
  const { stocks,
    materials,
    locations,
    fetching,
    isMaterialManagementFeatureEnabled,
    onSort } = props;

  const NavbarLinks = (
    <div className="pull-right d-flex">
      {!isMaterialManagementFeatureEnabled && (
        <Button
          variant="primary"
          size="sm"
          href={getRouteURI(ROUTES.STOCK_NEW)}
          className="pull-right spacer-right"
        >
          <FontAwesomeIcon icon={faPlus} />{' '}
          <FormattedMessage
            id="record.materialStock.add"
            defaultMessage="Add Material Stock"
          />
        </Button>
      )}
      {
        isMaterialManagementFeatureEnabled && (
          <>
            <Button
              variant="info"
              size="sm"
              href={getRouteURI(
                ROUTES.MATERIAL_LOT_NEW, null, { isOrderMaterialLot: true },
              )}
              className="pull-right spacer-right"
            >
              <FontAwesomeIcon icon={faClipboard} />{' '}
              Order Material Lot
            </Button>
            <Button
              variant="primary"
              size="sm"
              href={getRouteURI(ROUTES.MATERIAL_LOT_NEW)}
              className="pull-right spacer-right"
            >
              <FontAwesomeIcon icon={faPlus} />{' '}
              <FormattedMessage id="materialLot.add" defaultMessage="Add Material Lot" />
            </Button>
          </>
        )
      }
    </div>
  );

  const breadcrumbs = ['inventory', 'materialStocks'];

  const columns = [
    {
      type: 'uuid',
      uid: 'field.id',
      accessor: 'uuid',
      defaultMessage: 'ID',
      resource: 'stock',
    },
    {
      type: 'resource',
      uid: 'field.material',
      accessor: 'material',
      defaultMessage: 'Material',
      resource: 'material',
      resources: materials,
      isSortable: true,
    },
    {
      type: 'location',
      uid: 'field.location',
      accessor: 'location',
      defaultMessage: 'Location',
      resource: 'location',
      resources: locations,
      isSortable: true,
    },
    {
      type: 'caps',
      uid: 'field.status',
      accessor: 'status',
      defaultMessage: 'Status',
      isSortable: true,
    },
    {
      type: 'custom',
      uid: 'field.quantity',
      accessor: 'quantity',
      defaultMessage: 'Quantity',
      isSortable: true,
      Cell: ({ row: { original } }) => {
        if (original.quantity === 0) return null;
        if (!isMaterialManagementFeatureEnabled && !original.track_quantity) return null;
        return original.quantity;
      },
    },
  ];

  return (
    <>
      {fetching ? (
        <Loading />
      ) : (
        <TableWithSearching
          {...props}
          isFetching={fetching}
          withBreadcrumbs
          showLocationsFilter
          subheader={<FeedstocksTabs activeKey="material-stocks" />}
          locations={Object.values(locations)}
          breadcrumbs={breadcrumbs}
          navbar={NavbarLinks}
          data={stocks}
          columns={columns}
          isFilteringEnabled={false}
          withDefaultPagination={false}
          isManualSoringEnabled
          manualSortingFunc={onSort}
          isUpdatedColumnShown={false}
          initialSortedDesc
        />
      )}
    </>
  );
};

Stocks.propTypes = {
  fetching: PropTypes.bool.isRequired,
  locations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  materials: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  stocks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isMaterialManagementFeatureEnabled: PropTypes.bool.isRequired,
  onSort: PropTypes.func.isRequired,
  row: PropTypes.shape({
    original: PropTypes.shape({
      quantity: PropTypes.number,
      track_quantity: PropTypes.bool,
    }),
  }).isRequired,
};

export default Stocks;
