import { createSelector } from 'reselect';
import { getPredicate } from 'rapidfab/selectors/helpers/base';
import _filter from 'lodash/filter';
import _keyBy from 'lodash/keyBy';
import _reduce from 'lodash/reduce';
import _find from 'lodash/find';
import _sortBy from 'lodash/sortBy';
import _map from 'lodash/map';
import _mapValues from 'lodash/mapValues';
import _isEmpty from 'lodash/isEmpty';
import _compact from 'lodash/compact';
import _uniq from 'lodash/uniq';
import _isArray from 'lodash/isArray';
import { getPieces } from 'rapidfab/selectors/piece';
import { getModels, getModelsByUri } from 'rapidfab/selectors/model';
import { getServiceProviderJobs } from 'rapidfab/selectors/serviceProviderJob';
import { getPrepWorkflowRecords } from 'rapidfab/selectors/prepWorkflowRecord';
import { getPrepWorkflows } from 'rapidfab/selectors/prepWorkflow';
import { getPrints } from 'rapidfab/selectors/print';
import { getPrinterTypes } from 'rapidfab/selectors/printerType';
import { getProcessSteps } from 'rapidfab/selectors/processStep';
import { getWorkflows } from 'rapidfab/selectors/workflow';
import { getMaterialsByUri } from 'rapidfab/selectors/material';
import { getLineItemActualsByLineItemUri } from 'rapidfab/selectors/lineItemActuals';
import { getPrintersByUri } from 'rapidfab/selectors/printer';
import { getPrintersGroupedByPrinterType } from 'rapidfab/selectors/legacy-selectors';
import { getShippingsByUri } from 'rapidfab/selectors/shipping';
import { getAssemblyMeta } from 'rapidfab/selectors/assemblyMeta';
import { getAssemblyPartMeta } from 'rapidfab/selectors/assemblyPartMeta';
import dayjs from 'dayjs';
import { getLineItemWorkflowTypeObjectKey } from 'rapidfab/utils/lineItemUtils';

export const getPiecesForLineItem = createSelector(
  [getPredicate, getPieces],
  (lineItem, pieces) => {
    if (!lineItem) {
      return [];
    }
    return _filter(pieces, { line_item: lineItem.uri });
  },
);
export const getPiecesForOrder = createSelector(
  [getPredicate, getPieces],
  (order, pieces) => {
    if (!order) {
      return [];
    }
    if (_isArray(order)) {
      const orderUris = _map(order, 'uri');
      const result = _filter(pieces, piece => orderUris.includes(piece.order));
      return result;
    }
    return _filter(pieces, { order: order.uri });
  },
);

export const getJobsByServiceProviderUriForLineItem = createSelector(
  [getPredicate, getServiceProviderJobs],
  (lineItemURI, serviceProviderJobs) => _keyBy(
    _filter(serviceProviderJobs, {
      line_item: lineItemURI,
      process_step: null,
    }),
    'service_provider',
  ),
);

export const getOutsourceJobsByServiceProviderUriForLineItem = createSelector(
  [getPredicate, getServiceProviderJobs],
  (lineItemURI, serviceProviderJobs) => _reduce(
    serviceProviderJobs,
    (result, serviceProviderJob) => {
      if (!serviceProviderJob.process_step || serviceProviderJob.line_item !== lineItemURI) {
        return result;
      }

      const newResult = result;

      if (!newResult[serviceProviderJob.service_provider]) {
        newResult[serviceProviderJob.service_provider] = {};
      }
      newResult[serviceProviderJob.service_provider][serviceProviderJob.process_step] =
        serviceProviderJob;

      return newResult;
    },
    {},
  ),
);

export const getModelForLineItem = createSelector(
  [getPredicate, getModels],
  (lineItem, models) => {
    const workflowTypeKey = getLineItemWorkflowTypeObjectKey(lineItem);

    if (!lineItem) {
      return null;
    }
    return _find(models, { uri: lineItem[workflowTypeKey]?.model });
  },
);

export const getPrepWorkflowForLineItem = createSelector(
  [getPredicate, getPrepWorkflows],
  (lineItem, prepWorkflows) => {
    if (!lineItem) {
      return null;
    }
    return _find(prepWorkflows, { uri: lineItem.prep_workflow });
  },
);

export const getProductionWorkflowForLineItem = createSelector(
  [getPredicate, getWorkflows],
  (lineItem, productionWorkflows) => {
    if (!lineItem) {
      return null;
    }
    return _find(productionWorkflows, { uri: lineItem.workflow });
  },
);

export const getPrepWorkflowRecordForLineItem = createSelector(
  [getPredicate, getPrepWorkflowRecords],
  (lineItem, prepWorkflowRecords) => {
    if (!lineItem) {
      return null;
    }
    return _find(prepWorkflowRecords, prepWorkflowRecord => (
      prepWorkflowRecord.line_item === lineItem.uri
      || (prepWorkflowRecord.order === lineItem.order && !prepWorkflowRecord.line_item)
    ));
  },
);

export const getAvailablePrepWorkflowsForLineItem = createSelector(
  [getPredicate, getPrepWorkflows],
  (lineItem, prepWorkflows) => {
    if (!lineItem) {
      return [];
    }
    return _filter(prepWorkflows, prepWorkflow => (
      // TODO add usage_state
      // Ignore custom line item prep workflow and archived ones
      (!prepWorkflow.line_item && !prepWorkflow.order)
      // unless current line item uses it
      || prepWorkflow.uri === lineItem.prep_workflow
    ));
  },
);

export const getLineItemModelHistory = createSelector(
  [getModelForLineItem, getModelsByUri],
  (model, modelsByUri) => {
    if (!model || _isEmpty(modelsByUri)) return [];

    const { replaced_models: replacedModels } = model;
    // append the model to the beginning of the list of replaced models
    // so full history is returned; compacted so that undefined values aren't
    // included in array before the modelsByUri loads
    const modelHistory = _compact([
      model,
      ..._map(replacedModels, replacedModel => modelsByUri[replacedModel]),
    ]);
    // reverse order so that oldest will be listed first
    const orderedModelHistory = _sortBy(modelHistory, 'updated');
    return orderedModelHistory;
  },
);

// !!!ATTENTION!!! This selector returns ALL prints, including Remanufactured ones
export const getPrintsForLineItem = createSelector(
  [getPredicate, getPrints],
  (lineItem, prints) => {
    if (!lineItem) {
      return [];
    }
    return _filter(prints, { line_item: lineItem.uri });
  },
);

export const getWorkflowUrisByMaterialUri = createSelector(
  [getPrinterTypes, getProcessSteps, getWorkflows, getMaterialsByUri],
  (printerTypes, processSteps, workflows, materialsByUri) => _mapValues(
    materialsByUri, (material, materialUri) => _map(_filter(
      workflows,
      workflowItem => workflowItem?.materials?.includes(materialUri),
    ), 'uri'),
  ),
);

export const getWorkflowUrisByShippingUri = createSelector(
  [getShippingsByUri, getProcessSteps],
  (shippingsByUri, processSteps) => _mapValues(shippingsByUri, (shipping, shippingUri) => {
    const processStepsWithCompatibleShipping = _filter(processSteps, { workstation_type_uri: shippingUri });
    const workflowUrisWithMatchingShipping = _map(processStepsWithCompatibleShipping, 'workflows').flat();

    return _uniq(workflowUrisWithMatchingShipping);
  }),
);

// Even though it is called `actuals` (with `s`) the return value is 1 object (or none)
export const getLineItemActualsForLineItem = createSelector(
  [getPredicate, getLineItemActualsByLineItemUri],
  (lineItem, lineItemActualsByLineItemUri) => lineItem && lineItemActualsByLineItemUri[lineItem.uri],
);

export const getProcessStepsForLineItem = createSelector(
  [getPredicate, getProcessSteps, getProductionWorkflowForLineItem],
  (lineItem, processSteps, productionWorkflow) => {
    if (!productionWorkflow) {
      return [];
    }
    return processSteps.filter(ps => ps?.workflows?.includes(productionWorkflow.uri));
  },
);

export const getPrintingProcessStepForLineItem = createSelector(
  [getProcessStepsForLineItem],
  lineItemProcessSteps => _find(lineItemProcessSteps, ps => ps.step_positions.includes(1)),
);

export const getEarliestAvailablePrinterForLineItem = createSelector(
  [getPrintingProcessStepForLineItem, getPrintersByUri, getPrintersGroupedByPrinterType],
  (printingProcessStep, printersByUri, printersGroupedByPrinterType) => {
    if (!printingProcessStep) {
      return null;
    }

    if (printingProcessStep.workstation) {
      // In case specific workstation is specified
      return printersByUri[printingProcessStep.workstation];
    }

    const availablePrinters = printersGroupedByPrinterType[printingProcessStep.workstation_type_uri];
    if (!availablePrinters) {
      return null;
    }

    let firstAvailablePrinter;
    availablePrinters.forEach(availablePrinter => {
      if (
        // No printer selected yet
        !firstAvailablePrinter
        // Or previous printer has no availability date (Is it possible?)
        || !firstAvailablePrinter.available_at
        || (
          availablePrinter.available_at
          // Or when current printer available_at date is earlier
          && dayjs(firstAvailablePrinter.available_at) > dayjs(availablePrinter.available_at)
        )
      ) {
        firstAvailablePrinter = availablePrinter;
      }
    });
    return firstAvailablePrinter;
  },
);

export const getAssemblyMetaForLineItem = createSelector(
  [getAssemblyMeta, getPredicate],
  (assemblyMeta, lineItem) => {
    if (!lineItem) {
      return null;
    }
    return _find(assemblyMeta, { root_line_item: lineItem.uri });
  },
);

export const getAssemblyPartMetaForLineItem = createSelector(
  [getAssemblyPartMeta, getPredicate],
  (assemblyPartMeta, lineItem) => {
    if (!lineItem) {
      return null;
    }
    return _find(assemblyPartMeta, { part_line_item: lineItem.uri });
  },
);

export const getBaseAndSupportMaterialsForLineItem = createSelector(
  [getPredicate, getMaterialsByUri],
  (lineItem, materialsByUri) => {
    if (!lineItem) {
      return {};
    }

    const base = materialsByUri[lineItem.additive.materials?.base];
    const support = materialsByUri[lineItem.additive.materials?.support];
    return { base, support };
  },
);
