import dayjs from 'dayjs';
import _find from 'lodash/find';
import _orderBy from 'lodash/orderBy';
import { createSelector } from 'reselect';
import { getPredicate, getStateResources } from 'rapidfab/selectors/helpers/base';
import { getComments } from 'rapidfab/selectors/comment';
import { getRuns } from 'rapidfab/selectors/run';
import _filter from 'lodash/filter';
import _findLast from 'lodash/findLast';
import _sortBy from 'lodash/sortBy';
import _sumBy from 'lodash/sumBy';
import * as baseStateSelectors from 'rapidfab/selectors/baseStateSelectors';
import { getLineItems } from 'rapidfab/selectors/lineItem';
import { getOrderQuotes } from 'rapidfab/selectors/orderQuote';
import { getModelsByUri } from 'rapidfab/selectors/model';
import { getNonRemanufacturedPrints } from 'rapidfab/selectors/print';
import { getProducts } from 'rapidfab/selectors/product';
import { getZipFiles } from 'rapidfab/selectors/zipFile';
import _isEmpty from 'lodash/isEmpty';
import _isArray from 'lodash/isArray';
import _map from 'lodash/map';
import {
  LINE_ITEM_BEFORE_PREPARATION_STAGE_STATUSES,
  LINE_ITEM_ERROR_STATUSES,
} from 'rapidfab/constants';
import { getBaseAndSupportMaterials, getBaseMaterialsByLocationUri } from 'rapidfab/selectors/helpers/material';
import { getLineItemWorkflowTypeObjectKey } from 'rapidfab/utils/lineItemUtils';

export const getRunsForOrder = createSelector(
  [getPredicate, getRuns],
  (order, runs) => {
    if (!order) {
      return [];
    }
    if (_isArray(order)) {
      const orderUris = _map(order, 'uri');
      const result = _filter(runs, run => run?.orders?.some(uri => orderUris.includes(uri)));
      return result;
    }
    return _filter(runs, run => run?.orders?.includes(order.uri));
  },
);

export const getCommentsForOrder = createSelector(
  [getPredicate, getComments],
  (order, comments) => _filter(comments, { related_uuid: order.uuid }),
);

export const getOrderDocuments = createSelector(
  [baseStateSelectors.getStateDocuments, getStateResources],
  (uuids, resources) => _filter(uuids.map(uuid => resources[uuid]), ['related_table_name', 'order']),
);

export const getLineItemsForOrder = createSelector(
  [getPredicate, getLineItems],
  (order, lineItems) => {
    if (!order) {
      return [];
    }
    return _filter(lineItems, ({ order: orderUri }) => orderUri === order.uri);
  },
);

export const getSortedLineItemsForOrder = createSelector(
  [getLineItemsForOrder],
  filteredLineItems =>
    _sortBy(filteredLineItems, 'created'),
);

export const getTotalModelsVolumeMmForOrder = createSelector(
  [getLineItemsForOrder, getModelsByUri],
  (lineItems, modelsByUri) =>
    _sumBy(
      lineItems,
      lineItem => {
        const model = modelsByUri[lineItem[getLineItemWorkflowTypeObjectKey(lineItem)]?.model];
        const modelVolume = model ? model.volume_mm : 0;
        return modelVolume * lineItem.quantity;
      },
    ),
);

export const getLatestOrderQuoteDocumentForOrder = createSelector(
  [getPredicate, getOrderQuotes],
  (order, orderQuotes) => {
    if (order) {
      // First, sort the quotes by the 'created' date in descending order
      const sortedQuotes = _orderBy(orderQuotes, ['created'], ['desc']);

      // Now, find the last quote that matches the order URI
      // Since the array is sorted in descending order, the first match is the most recent one
      return _find(sortedQuotes, { order: order.uri });
    }
    return null;
  },
);

export const getOrderQuoteDocumentsForOrder = createSelector(
  [getPredicate, getOrderQuotes],
  (order, orderQuotes) => _filter(orderQuotes, { order: order?.uri }),
);

export const getOrderPackingSlips = createSelector(
  [baseStateSelectors.getStateOrderPackingSlips, getStateResources],
  (uuids, resources) => _sortBy(
    uuids.map(uuid => resources[uuid]), packingSlip => dayjs(packingSlip.created).local(),
  ),
);

export const getOrderPackingSlipForOrder = createSelector(
  [getPredicate, getOrderPackingSlips],
  (orderUri, orderPackingSlips) => {
    if (orderUri) {
      return _findLast(orderPackingSlips, { order: orderUri });
    }
    return null;
  },
);

export const getNonRemanufacturedPrintsForOrder = createSelector(
  [getPredicate, getNonRemanufacturedPrints],
  (order, nonRemanufacturedPrints) => {
    if (!order) {
      return [];
    }
    return _filter(nonRemanufacturedPrints, { order: order.uri });
  },
);

/**
 * !!!ATTENTION!!! This is a legacy selector,
 * which is used to determine if order needs line items to be added/updated before going further
 * Naming might be controversial. Use with caution.
 */
export const isBeforePreparationStageLineItemPresentForOrder = createSelector(
  [getLineItemsForOrder],
  lineItems => {
    const lineItemsWithoutErrorAndCancelled =
      _filter(lineItems, ({ status }) => !LINE_ITEM_ERROR_STATUSES.includes(status));
    if (_isEmpty(lineItemsWithoutErrorAndCancelled)) {
      return true;
    }

    return (
      lineItemsWithoutErrorAndCancelled
        .some(({ status }) => LINE_ITEM_BEFORE_PREPARATION_STAGE_STATUSES.includes(status))
    );
  },
);

export const getProductsForOrder = createSelector(
  [getPredicate, getProducts],
  (order, products) => {
    if (!order) {
      return [];
    }
    return _filter(products, { order: order.uri });
  },
);

export const getProductsForOrderSortedByCreatedDesc = createSelector(
  [getProductsForOrder],
  products => _sortBy(products, ({ created }) => dayjs(created).local()),
);

export const getLineItemsForOrderSortedByCreatedDesc = createSelector(
  [getPredicate, getLineItems],
  (order, lineItems) => {
    if (!order) {
      return [];
    }

    return _sortBy(lineItems, ({ created }) => dayjs(created).local());
  },
);

export const getAvailableBaseMaterialsForOrder = createSelector(
  [getPredicate, getBaseMaterialsByLocationUri, getBaseAndSupportMaterials],
  (order, baseMaterialsByLocationUri, { base: baseMaterials }) => {
    if (!order) {
      return [];
    }
    return order.location
      ? (
        // Use materials from location if there are any
        baseMaterialsByLocationUri[order.location]
        // or no materials as a fallback
        || []
      )
      : baseMaterials;
  },
);

export const getZipFilesForOrder = createSelector(
  [getPredicate, getZipFiles],
  (order, zipFiles) => {
    if (order) {
      return _filter(zipFiles, { related_uuid: order.uuid });
    }
    return [];
  },
);
