import React from 'react';
import { FormattedMessage } from 'rapidfab/i18n';
import {
  COLORS,
  COMMENT_RELATED_TABLE_NAMES,
  SERVICE_PROVIDER_JOB_STATUS,
  ORDER_QUOTE_DISCOUNT_TYPES,
  ORDER_STATUS,
  LINE_ITEM_STATUS,
  BOEING_CUSTOM_ORDER_STATUS,
  BOEING_FINANCE_REVIEW_STATUS,
  MATERIAL_BATCH_STATUSES,
  MATERIAL_BATCH_CUSTOM_STATUSES,
  MATERIAL_CONTAINER_STATUSES,
  BUILD_PACKER_TYPES,
  MATERIAL_BATCH_ACTION_TYPES,
  TOOLING_STOCK_ACTION_TYPES,
  TOOLING_STOCK_STATUSES,
  MATERIAL_FAMILY,
  MATERIAL_LOT_STATUSES,
  RUN_OPERATIONS,
  RUN_TRANSFORM_TYPES,
  PIECE_STATUSES,
  RUN_STATUSES,
  PRINT_STATUSES,
  PRODUCT_STATUSES,
  RUN_TRANSFORMATION_STATUSES,
  PRIORITIES,
  SHIPMENT_STATUSES,
  PROCESS_PROVIDER_STATUSES,
  MATERIAL_UNITS,
  MODEL_UNITS,
  PREP_TASK_RECORD_STATUSES,
  CREATE_RUNS_STATUSES,
  COMMENT_ACTION_STATUSES,
  WORK_INSTRUCTION_UNITS,
  WORK_INSTRUCTION_THRESHOLD_TYPES,
  ENTITY_TYPES,
  NON_ENTITY_API_RESOURCES,
  MODELER_STATUSES,
  CAPTURE_STATUSES,
  MATERIAL_REASON_CODE_OPTIONS,
  STOCK_STATUSES,
  STATUS_COLOR_CODE_TYPES,
  LOCATION_NOTIFICATION_SETTING_BOOLS,
  WORKFLOW_TYPES,
  BATCHING_STRATEGIES,
  DESIGN_FILE_STATUSES,
  ASSEMBLY_META_ASSEMBLING_TYPES,
  SHIPMENT_ADDRESS_TYPES,
  WORK_INSTRUCTION_REPORT_TYPES,
  LINE_ITEM_COMPOSITION_TYPES,
  PRODUCT_MODEL_FILE_SOURCES,
  CASTOR_COSTING_STATUSES,
  CASTOR_COSTING_CONFIG_MANUFACTURABILITY_OPTIONS,
  MODEL_STATUSES,
  ZVERSE_CONVERSION_STATUSES,
  MODEL_CONVERSION_STATUSES,
  MSNAV_IMPORT_FILE_STATUSES,
  PIECE_COLORS,
  PERMANENT_CONTAINER_STATUSES,
  MATERIAL_BATCH_POWDER_QUALITY_STATUSES,
  PERMANENT_CONTAINER_ACTION_TYPES,
  SECURE_FILE_CHECKOUT_STATUSES,
  CREATE_RUN_PROGRESS_STATUSES,
  EXPERT_CONVERSION_STATUSES,
  DOWNTIME_STATUS, PIECE_GROUPS_FILTER,
} from 'rapidfab/constants';
import { defineMessages } from 'react-intl';

export const PIECE_STATUS_MAP = {
  [PIECE_STATUSES.NEW]: {
    id: 'status.new',
    defaultMessage: 'New',
  },
  [PIECE_STATUSES.CONFIRMED]: {
    id: 'status.confirmed',
    defaultMessage: 'Confirmed',
  },
  [PIECE_STATUSES.QUEUED_FOR_PRINTING]: {
    id: 'queuedForPrinting',
    defaultMessage: 'Queued for Printing',
  },
  [PIECE_STATUSES.PRINTING]: {
    id: 'status.printing',
    defaultMessage: 'Printing',
  },
  [PIECE_STATUSES.QUEUED_FOR_POST_PROCESSING]: {
    id: 'queuedForPostProcessing',
    defaultMessage: 'Queued for Post-Processing',
  },
  [PIECE_STATUSES.POST_PROCESSING]: {
    id: 'postProcessing',
    defaultMessage: 'Post Processing',
  },
  [PIECE_STATUSES.QUEUED_FOR_SHIPPING]: {
    id: 'queuedForShipping',
    defaultMessage: 'Queued for Shipping',
  },
  [PIECE_STATUSES.SHIPPING]: {
    id: 'shipping',
    defaultMessage: 'Shipping',
  },
  [PIECE_STATUSES.COMPLETE]: {
    id: 'status.complete',
    defaultMessage: 'Complete',
  },
  [PIECE_STATUSES.INCOMPLETE]: {
    id: 'incomplete',
    defaultMessage: 'Incomplete',
  },
  [PIECE_STATUSES.CANCELLED]: {
    id: 'status.cancelled',
    defaultMessage: 'Cancelled',
  },
  [PIECE_STATUSES.ERROR]: {
    id: 'status.error',
    defaultMessage: 'Error',
  },
};

export const LINE_ITEM_STATUS_MAP = {
  [LINE_ITEM_STATUS.CALCULATING_ESTIMATES]: {
    id: 'status.calculatingEstimates',
    defaultMessage: 'Calculating Estimates',
  },
  [LINE_ITEM_STATUS.CANCELLED]: {
    id: 'status.cancelled',
    defaultMessage: 'Cancelled',
  },
  [LINE_ITEM_STATUS.CONFIRMED]: {
    id: 'status.confirmed',
    defaultMessage: 'Confirmed',
  },
  [LINE_ITEM_STATUS.COMPLETE]: {
    id: 'status.complete',
    defaultMessage: 'Complete',
  },
  [LINE_ITEM_STATUS.ERROR]: {
    id: 'status.error',
    defaultMessage: 'Error',
  },
  [LINE_ITEM_STATUS.IN_PREPARATION]: {
    id: 'status.inPreparation',
    defaultMessage: 'In Preparation',
  },
  [LINE_ITEM_STATUS.NEW]: {
    id: 'status.new',
    defaultMessage: 'New',
  },
  [LINE_ITEM_STATUS.PENDING]: {
    id: 'status.pending',
    defaultMessage: 'Pending',
  },
  [LINE_ITEM_STATUS.POST_PROCESSING]: {
    id: 'postProcessing',
    defaultMessage: 'Post Processing',
  },
  [LINE_ITEM_STATUS.PRINTING]: {
    id: 'status.printing',
    defaultMessage: 'Printing',
  },
  [LINE_ITEM_STATUS.SHIPPING]: {
    id: 'shipping',
    defaultMessage: 'Shipping',
  },
};

export const LINE_ITEM_COMPOSITION_TYPE_MAP = {
  [LINE_ITEM_COMPOSITION_TYPES.ASSEMBLY]: {
    id: 'assembly',
    defaultMessage: 'Sub-Assembly',
  },
  [LINE_ITEM_COMPOSITION_TYPES.ASSEMBLY_PART]: {
    id: 'compositionType.assemblyPart',
    defaultMessage: 'Sub-Assembly Part',
  },
  [LINE_ITEM_COMPOSITION_TYPES.SINGLE_MESH_PRODUCT]: {
    id: 'compositionType.singleMeshProduct',
    defaultMessage: 'Single Mesh Product',
  },
  [LINE_ITEM_COMPOSITION_TYPES.CO_PRINT]: {
    id: 'coPrint',
    defaultMessage: 'Co-Print',
  },
  [LINE_ITEM_COMPOSITION_TYPES.CO_PRINT_PART]: {
    id: 'compositionType.coPrintPart',
    defaultMessage: 'Co-Print part',
  },
};
export const LINE_ITEM_STATUS_COLOR_CODE_MAPPING = {
  [LINE_ITEM_STATUS.NEW]: COLORS.PURPLE,
  [LINE_ITEM_STATUS.PENDING]: COLORS.YELLOW,
  [LINE_ITEM_STATUS.CONFIRMED]: COLORS.BLUE,
  [LINE_ITEM_STATUS.SHIPPING]: COLORS.GRAY,
  [LINE_ITEM_STATUS.COMPLETE]: COLORS.GREEN,
  [LINE_ITEM_STATUS.ERROR]: COLORS.RED,
  [LINE_ITEM_STATUS.CALCULATING_ESTIMATES]: COLORS.YELLOW,
  [LINE_ITEM_STATUS.CANCELLED]: COLORS.GRAY,
  [LINE_ITEM_STATUS.IN_PREPARATION]: COLORS.ORANGE,
  [LINE_ITEM_STATUS.POST_PROCESSING]: COLORS.ORANGE,
  [LINE_ITEM_STATUS.PRINTING]: COLORS.ORANGE,
};

export const PIECE_STATUS_COLOR_CODE_MAPPING = {
  [PIECE_STATUSES.NEW]: COLORS.PURPLE,
  [PIECE_STATUSES.CONFIRMED]: COLORS.BLUE,
  [PIECE_STATUSES.QUEUED_FOR_PRINTING]: PIECE_COLORS.DARK_BLUE,
  [PIECE_STATUSES.PRINTING]: COLORS.ORANGE,
  [PIECE_STATUSES.QUEUED_FOR_SHIPPING]: PIECE_COLORS.LIGHT_GRAY,
  [PIECE_STATUSES.SHIPPING]: COLORS.GRAY,
  [PIECE_STATUSES.QUEUED_FOR_POST_PROCESSING]: COLORS.YELLOW,
  [PIECE_STATUSES.POST_PROCESSING]: COLORS.ORANGE,
  [PIECE_STATUSES.COMPLETE]: COLORS.GREEN,
  [PIECE_STATUSES.CANCELLED]: COLORS.GRAY,
  [PIECE_STATUSES.INCOMPLETE]: PIECE_COLORS.DARK_RED,
  [PIECE_STATUSES.ERROR]: COLORS.RED,
};

export const ORDER_STATUS_MAP = {
  [ORDER_STATUS.CALCULATING_ESTIMATES]: {
    id: 'status.calculatingEstimates',
    defaultMessage: 'Calculating Estimates',
  },
  [ORDER_STATUS.CANCELLED]: {
    id: 'status.cancelled',
    defaultMessage: 'Cancelled',
  },
  [ORDER_STATUS.CLONING]: {
    id: 'status.cloning',
    defaultMessage: 'Cloning',
  },
  [ORDER_STATUS.CONFIRMED]: {
    id: 'status.confirmed',
    defaultMessage: 'Confirmed',
  },
  [ORDER_STATUS.COMPLETE]: {
    id: 'status.complete',
    defaultMessage: 'Complete',
  },
  [ORDER_STATUS.ERROR]: {
    id: 'status.error',
    defaultMessage: 'Error',
  },
  [ORDER_STATUS.IN_PREPARATION]: {
    id: 'status.inPreparation',
    defaultMessage: 'In Preparation',
  },
  [ORDER_STATUS.NEW]: {
    id: 'status.new',
    defaultMessage: 'New',
  },
  [ORDER_STATUS.PENDING]: {
    id: 'status.pending',
    defaultMessage: 'Pending',
  },
  [ORDER_STATUS.POST_PROCESSING]: {
    id: 'postProcessing',
    defaultMessage: 'Post Processing',
  },
  [ORDER_STATUS.PRINTING]: {
    id: 'status.printing',
    defaultMessage: 'Printing',
  },
  [ORDER_STATUS.PROCESSING]: {
    id: 'status.processing',
    defaultMessage: 'Processing',
  },
  [ORDER_STATUS.SHIPPING]: {
    id: 'shipping',
    defaultMessage: 'Shipping',
  },
};

export const ORDER_STATUS_COLOR_CODE_MAPPING = {
  [ORDER_STATUS.NEW]: COLORS.PURPLE,
  [ORDER_STATUS.PENDING]: COLORS.YELLOW,
  [ORDER_STATUS.CONFIRMED]: COLORS.BLUE,
  [ORDER_STATUS.PROCESSING]: COLORS.ORANGE,
  [ORDER_STATUS.SHIPPING]: COLORS.GRAY,
  [ORDER_STATUS.COMPLETE]: COLORS.GREEN,
  [ORDER_STATUS.ERROR]: COLORS.RED,

  // Next order statuses are defined
  // to match all order statuses
  [ORDER_STATUS.CALCULATING_ESTIMATES]: COLORS.YELLOW,
  [ORDER_STATUS.CANCELLED]: COLORS.GRAY,
  [ORDER_STATUS.CLONING]: COLORS.YELLOW,
  [ORDER_STATUS.IN_PREPARATION]: COLORS.ORANGE,
  [ORDER_STATUS.POST_PROCESSING]: COLORS.ORANGE,
  [ORDER_STATUS.PRINTING]: COLORS.ORANGE,
};

export const PREP_TASK_RECORD_STATUS_COLOR_MAP = {
  [PREP_TASK_RECORD_STATUSES.NEW]: COLORS.PURPLE,
  [PREP_TASK_RECORD_STATUSES.QUEUED]: COLORS.BLUE,
  [PREP_TASK_RECORD_STATUSES.IN_PROGRESS]: COLORS.ORANGE,
  [PREP_TASK_RECORD_STATUSES.ON_HOLD]: COLORS.YELLOW,
  [PREP_TASK_RECORD_STATUSES.COMPLETE]: COLORS.GREEN,
  [PREP_TASK_RECORD_STATUSES.SKIPPED]: COLORS.GRAY,
  [PREP_TASK_RECORD_STATUSES.CANCELLED]: COLORS.GRAY,
};

export const COMMENT_ACTION_STATUS_COLOR_MAP = {
  [COMMENT_ACTION_STATUSES.NEW]: COLORS.PURPLE,
  [COMMENT_ACTION_STATUSES.IN_PROGRESS]: COLORS.ORANGE,
  [PREP_TASK_RECORD_STATUSES.COMPLETE]: COLORS.GREEN,
};

export const COMMENT_ACTION_STATUSES_MAP = {
  [COMMENT_ACTION_STATUSES.NEW]: {
    id: 'status.unstarted',
    defaultMessage: 'Unstarted',
  },
  [COMMENT_ACTION_STATUSES.IN_PROGRESS]: {
    id: 'status.inProgress',
    defaultMessage: 'In Progress',
  },
  [COMMENT_ACTION_STATUSES.COMPLETE]: {
    id: 'status.completed',
    defaultMessage: 'Completed',
  },
};

export const RUN_STATUS_MAP = {
  [RUN_STATUSES.CALCULATING]: {
    id: 'status.calculating',
    defaultMessage: 'Calculating',
  },
  [RUN_STATUSES.CALCULATED]: {
    id: 'status.calculated',
    defaultMessage: 'Calculated',
  },
  [RUN_STATUSES.COMPLETE]: {
    id: 'status.complete',
    defaultMessage: 'Complete',
  },
  [RUN_STATUSES.ERROR]: {
    id: 'status.error',
    defaultMessage: 'Error',
  },
  [RUN_STATUSES.IN_PROGRESS]: {
    id: 'status.inProgress',
    defaultMessage: 'In Progress',
  },
  [RUN_STATUSES.PAUSED]: {
    id: 'status.paused',
    defaultMessage: 'Paused',
  },
  [RUN_STATUSES.QUEUED]: {
    id: 'status.queued',
    defaultMessage: 'Queued',
  },
  [RUN_STATUSES.QUEUED_READY]: {
    id: 'status.queuedWorkReady',
    defaultMessage: 'Queued Work Ready',
  },
  [RUN_STATUSES.CANCELLED]: {
    id: 'status.cancelled',
    defaultMessage: 'Cancelled',
  },
};

// In backend implementation it's like the same statuses, as in run,
// but also with `created` and `incomplete` statuses
export const PRINT_STATUS_MAP = {
  ...RUN_STATUS_MAP,
  [PRINT_STATUSES.CREATED]: {
    id: 'created',
    defaultMessage: 'Created',
  },
  [PRINT_STATUSES.INCOMPLETE]: {
    id: 'incomplete',
    defaultMessage: 'Incomplete',
  },
};

export const PRODUCT_STATUS_MAP = {
  [PRODUCT_STATUSES.CALCULATING_ESTIMATES]: {
    id: 'status.calculatingEstimates',
    defaultMessage: 'Calculating Estimates',
  },
  [PRODUCT_STATUSES.CANCELLED]: {
    id: 'status.cancelled',
    defaultMessage: 'Cancelled',
  },
  [PRODUCT_STATUSES.CONFIRMED]: {
    id: 'status.confirmed',
    defaultMessage: 'Confirmed',
  },
  [PRODUCT_STATUSES.COMPLETE]: {
    id: 'status.complete',
    defaultMessage: 'Complete',
  },
  [PRODUCT_STATUSES.ERROR]: {
    id: 'status.error',
    defaultMessage: 'Error',
  },
  [PRODUCT_STATUSES.IN_PREPARATION]: {
    id: 'status.inPreparation',
    defaultMessage: 'In Preparation',
  },
  [PRODUCT_STATUSES.NEW]: {
    id: 'status.new',
    defaultMessage: 'New',
  },
  [PRODUCT_STATUSES.PENDING]: {
    id: 'status.pending',
    defaultMessage: 'Pending',
  },
  [PRODUCT_STATUSES.PROCESSING]: {
    id: 'status.processing',
    defaultMessage: 'Processing',
  },
  [PRODUCT_STATUSES.SHIPPING]: {
    id: 'shipping',
    defaultMessage: 'Shipping',
  },
};

export const PRODUCT_STATUS_COLOR_CODE_MAP = {
  [PRODUCT_STATUSES.NEW]: COLORS.PURPLE,
  [PRODUCT_STATUSES.PENDING]: COLORS.YELLOW,
  [PRODUCT_STATUSES.CONFIRMED]: COLORS.BLUE,
  [PRODUCT_STATUSES.PROCESSING]: COLORS.ORANGE,
  [PRODUCT_STATUSES.SHIPPING]: COLORS.GRAY,
  [PRODUCT_STATUSES.COMPLETE]: COLORS.GREEN,
  [PRODUCT_STATUSES.ERROR]: COLORS.RED,
  [PRODUCT_STATUSES.CALCULATING_ESTIMATES]: COLORS.YELLOW,
  [PRODUCT_STATUSES.CANCELLED]: COLORS.GRAY,
  [PRODUCT_STATUSES.IN_PREPARATION]: COLORS.ORANGE,
};

export const RUN_STATUS_COLOR_MAP = {
  [RUN_STATUSES.CALCULATING]: COLORS.YELLOW,
  [RUN_STATUSES.CALCULATED]: COLORS.PURPLE,
  [RUN_STATUSES.QUEUED]: COLORS.BLUE,
  [RUN_STATUSES.QUEUED_READY]: COLORS.BLUE,
  [RUN_STATUSES.IN_PROGRESS]: COLORS.ORANGE,
  [RUN_STATUSES.PAUSED]: COLORS.GRAY,
  [RUN_STATUSES.COMPLETE]: COLORS.GREEN,
  [RUN_STATUSES.ERROR]: COLORS.RED,
  [RUN_STATUSES.CANCELLED]: COLORS.GRAY,
};

export const RUN_OPERATION_MAP = {
  [RUN_OPERATIONS.POST_PROCESSING]: {
    id: 'postProcessing',
    defaultMessage: 'Post Processing',
  },
  [RUN_OPERATIONS.PRINTING]: {
    id: 'status.printing',
    defaultMessage: 'Printing',
  },
  [RUN_OPERATIONS.SHIPPING]: {
    id: 'shipping',
    defaultMessage: 'Shipping',
  },
};

/** Get API field/key from determined variable run workstation type. */
export const RUN_OPERATION_TO_TYPE_KEY_MAP = {
  [RUN_OPERATIONS.POST_PROCESSING]: 'post_processor_type',
  [RUN_OPERATIONS.PRINTING]: 'printer_type',
  [RUN_OPERATIONS.SHIPPING]: 'shipping',
};

export const MATERIAL_BATCH_STATUS_MAP = {
  [MATERIAL_BATCH_STATUSES.NEW]: {
    id: 'status.new',
    defaultMessage: 'New',
  },
  [MATERIAL_BATCH_STATUSES.IN_USE]: {
    id: 'status.inUse',
    defaultMessage: 'In Use',
  },
  [MATERIAL_BATCH_STATUSES.IDLE]: {
    id: 'status.idle',
    defaultMessage: 'Idle',
  },
  [MATERIAL_BATCH_STATUSES.DONE]: {
    id: 'status.done',
    defaultMessage: 'Done',
  },
  [MATERIAL_BATCH_STATUSES.CONSUMED]: {
    id: 'status.consumed',
    defaultMessage: 'Consumed',
  },
};

export const MATERIAL_BATCH_POWDER_QUALITY_STATUS_MAP = {
  [MATERIAL_BATCH_POWDER_QUALITY_STATUSES.NEW]: {
    id: 'status.new',
    defaultMessage: 'New',
  },
  [MATERIAL_BATCH_POWDER_QUALITY_STATUSES.SIEVED]: {
    id: 'status.sieved',
    defaultMessage: 'Sieved',
  },
  [MATERIAL_BATCH_POWDER_QUALITY_STATUSES.DONE]: {
    id: 'status.done',
    defaultMessage: 'Done',
  },
  [MATERIAL_BATCH_POWDER_QUALITY_STATUSES.UNKNOWN]: {
    id: 'status.unknown',
    defaultMessage: 'Unknown',
  },
  [MATERIAL_BATCH_POWDER_QUALITY_STATUSES.UNSIEVED]: {
    id: 'status.unsieved',
    defaultMessage: 'Unsieved',
  },
};

export const PERMANENT_CONTAINER_STATUS_MAP = {
  [PERMANENT_CONTAINER_STATUSES.NEW]: {
    id: 'status.new',
    defaultMessage: 'New',
  },
  [PERMANENT_CONTAINER_STATUSES.EMPTY]: {
    id: 'status.empty',
    defaultMessage: 'Empty',
  },
  [PERMANENT_CONTAINER_STATUSES.IN_USE]: {
    id: 'status.inUse',
    defaultMessage: 'In Use',
  },
};

export const MATERIAL_BATCH_CUSTOM_STATUSES_MAP = {
  [MATERIAL_BATCH_CUSTOM_STATUSES.IN_TESTING]: {
    id: 'status.inTesting',
    defaultMessage: 'In Testing',
  },
  [MATERIAL_BATCH_CUSTOM_STATUSES.TESTING_COMPLETE]: {
    id: 'status.testingComplete',
    defaultMessage: 'Testing Complete',
  },
};

export const MATERIAL_CONTAINER_STATUS_MAP = {
  [MATERIAL_CONTAINER_STATUSES.NEW]: {
    id: 'status.new',
    defaultMessage: 'New',
  },
  [MATERIAL_CONTAINER_STATUSES.IN_USE]: {
    id: 'status.inUse',
    defaultMessage: 'In Use',
  },
  [MATERIAL_CONTAINER_STATUSES.EMPTY]: {
    id: 'status.empty',
    defaultMessage: 'Empty',
  },
};

export const MATERIAL_LOT_STATUS_MAP = {
  [MATERIAL_LOT_STATUSES.RECEIVED]: {
    id: 'status.received',
    defaultMessage: 'Received',
  },
  [MATERIAL_LOT_STATUSES.IN_TESTING]: {
    id: 'status.inTesting',
    defaultMessage: 'In Testing',
  },
  [MATERIAL_LOT_STATUSES.AVAILABLE]: {
    id: 'status.available',
    defaultMessage: 'Available',
  },
  [MATERIAL_LOT_STATUSES.IN_USE]: {
    id: 'status.inUse',
    defaultMessage: 'In Use',
  },
  [MATERIAL_LOT_STATUSES.DONE]: {
    id: 'status.done',
    defaultMessage: 'Done',
  },
  [MATERIAL_LOT_STATUSES.SCRAPPED]: {
    id: 'status.scrapped',
    defaultMessage: 'Scrapped',
  },
  [MATERIAL_LOT_STATUSES.RETURN_TO_SUPPLIER]: {
    id: 'status.returnToSupplier',
    defaultMessage: 'Return to Supplier',
  },
  [MATERIAL_LOT_STATUSES.ON_ORDER]: {
    id: 'status.onOrder',
    defaultMessage: 'On Order',
  },
  [MATERIAL_LOT_STATUSES.IN_STORAGE]: {
    id: 'status.storage',
    defaultMessage: 'Storage',
  },
};

export const MATERIAL_BATCH_ACTION_TYPES_MAP = {
  [MATERIAL_BATCH_ACTION_TYPES.MACHINE_LOAD]: {
    id: 'batchAction.machineLoad',
    defaultMessage: 'Machine Load',
  },
  [MATERIAL_BATCH_ACTION_TYPES.BLEND_BATCHES]: {
    id: 'batchAction.blendBatches',
    defaultMessage: 'Blend Batches',
  },
  [MATERIAL_BATCH_ACTION_TYPES.MACHINE_UNLOAD_CHAMBER]: {
    id: 'batchAction.completePrintJob',
    defaultMessage: 'Complete Print Job',
  },
  [MATERIAL_BATCH_ACTION_TYPES.SPLIT_BATCH]: {
    id: 'batchAction.splitBatch',
    defaultMessage: 'Split Batch',
  },
  [MATERIAL_BATCH_ACTION_TYPES.ADD_CONTAINER]: {
    id: 'batchAction.addContainer',
    defaultMessage: 'Add Container',
  },
  [MATERIAL_BATCH_ACTION_TYPES.MACHINE_UNLOAD_HOPPER]: {
    id: 'batchAction.machineUnloadHopper',
    defaultMessage: 'Machine Unload Hopper',
  },
  [MATERIAL_BATCH_ACTION_TYPES.SCRAP_BATCH]: {
    id: 'batchAction.scrapBatch',
    defaultMessage: 'Scrap Batch',
  },
  [MATERIAL_BATCH_ACTION_TYPES.SIEVE]: {
    id: 'batchAction.sieve',
    defaultMessage: 'Sieve',
  },
  [MATERIAL_BATCH_ACTION_TYPES.TEST]: {
    id: 'batchAction.test',
    defaultMessage: 'Test',
  },
  [MATERIAL_BATCH_ACTION_TYPES.CONTAINERIZE_BATCH]: {
    id: 'batchAction.containerize',
    defaultMessage: 'Containerize',
  },
  [MATERIAL_BATCH_ACTION_TYPES.MACHINE_TOP_OFF]: {
    id: 'batchAction.topOff',
    defaultMessage: 'Top Off',
  },
  [MATERIAL_BATCH_ACTION_TYPES.CREATE_INITIAL_BATCH]: {
    id: 'batchAction.createInitialBatch',
    defaultMessage: 'Initial Batch Process',
  },
  [MATERIAL_BATCH_ACTION_TYPES.EDIT_BATCH_QUANTITY]: {
    id: 'batchAction.editBatchQuantity',
    defaultMessage: 'Edit Batch Quantity',
  },
  [MATERIAL_BATCH_ACTION_TYPES.RELOCATE]: {
    id: 'batchAction.relocate',
    defaultMessage: 'Relocate',
  },
};

export const TOOLING_STOCK_ACTION_TYPES_MAP = {
  [TOOLING_STOCK_ACTION_TYPES.RELOCATE]: {
    id: 'toolingStock.relocate',
    defaultMessage: 'Relocate',
  },
  [TOOLING_STOCK_ACTION_TYPES.RECORD_TESTS]: {
    id: 'toolingStock.recordTests',
    defaultMessage: 'Record Tests',
  },
  [TOOLING_STOCK_ACTION_TYPES.MAKE_READY_FOR_USE]: {
    id: 'toolingStock.makeReadyForUse',
    defaultMessage: 'Make Ready For Use',
  },
  [TOOLING_STOCK_ACTION_TYPES.PUT_INTO_USE]: {
    id: 'toolingStock.putIntoUse',
    defaultMessage: 'Put Into Use',
  },
  [TOOLING_STOCK_ACTION_TYPES.COMPLETE_USE]: {
    id: 'toolingStock.completeUse',
    defaultMessage: 'Complete Use',
  },
  [TOOLING_STOCK_ACTION_TYPES.NEEDS_REFURBISH]: {
    id: 'toolingStock.needsRefurbish',
    defaultMessage: 'Needs Refurbish',
  },
  [TOOLING_STOCK_ACTION_TYPES.SENT_TO_REFURBISH]: {
    id: 'toolingStock.sentToRefurbish',
    defaultMessage: 'Sent To Refurbish',
  },
  [TOOLING_STOCK_ACTION_TYPES.COMPLETE_REFURBISH]: {
    id: 'toolingStock.completeRefurbish',
    defaultMessage: 'Complete Refurbish',
  },
  [TOOLING_STOCK_ACTION_TYPES.DISCARD]: {
    id: 'toolingStock.discard',
    defaultMessage: 'Discard',
  },
};

export const TOOLING_STOCK_STATUSES_MAP = {
  [TOOLING_STOCK_STATUSES.NEW]: {
    id: 'toolingStock.new',
    defaultMessage: 'New',
  },
  [TOOLING_STOCK_STATUSES.IDLE]: {
    id: 'toolingStock.idle',
    defaultMessage: 'Ready For Use',
  },
  [TOOLING_STOCK_STATUSES.IN_USE]: {
    id: 'toolingStock.in_use',
    defaultMessage: 'In Use',
  },
  [TOOLING_STOCK_STATUSES.NEEDS_REFURBISHMENT]: {
    id: 'toolingStock.needs_refurbishment',
    defaultMessage: 'Needs Refurbishment',
  },
  [TOOLING_STOCK_STATUSES.DOING_REFURBISHMENT]: {
    id: 'toolingStock.doing_refurbishment',
    defaultMessage: 'Doing Refurbishment',
  },
  [TOOLING_STOCK_STATUSES.REFURBISHED]: {
    id: 'toolingStock.refurbished',
    defaultMessage: 'Refurbished',
  },
  [TOOLING_STOCK_STATUSES.ARCHIVED]: {
    id: 'toolingStock.archived',
    defaultMessage: 'Archived',
  },
};

export const PERMANENT_CONTAINER_ACTION_TYPES_MAP = {
  [PERMANENT_CONTAINER_ACTION_TYPES.LOAD_MATERIAL]: {
    id: 'permanentContainerAction.loadMaterial',
    defaultMessage: 'Load Material',
  },
  [PERMANENT_CONTAINER_ACTION_TYPES.TOP_OFF]: {
    id: 'permanentContainerAction.topOff',
    defaultMessage: 'Top Off',
  },
  [PERMANENT_CONTAINER_ACTION_TYPES.TRANSFER_MATERIAL]: {
    id: 'permanentContainerAction.transferMaterial',
    defaultMessage: 'Transfer Material',
  },
  [PERMANENT_CONTAINER_ACTION_TYPES.TRANSFER_MATERIAL]: {
    id: 'permanentContainerAction.transferMaterial',
    defaultMessage: 'Transfer Material',
  },
  [PERMANENT_CONTAINER_ACTION_TYPES.RELOCATE]: {
    id: 'permanentContainerAction.relocate',
    defaultMessage: 'Relocate',
  },
  [PERMANENT_CONTAINER_ACTION_TYPES.UNLOAD_MATERIAL]: {
    id: 'permanentContainerAction.unloadMaterial',
    defaultMessage: 'Unload Material',
  },
};

export const MODELER_STATUS_MAP = {
  [MODELER_STATUSES.IDLE]: {
    id: 'status.idle',
    defaultMessage: 'Idle',
  },
  [MODELER_STATUSES.OFFLINE]: {
    id: 'status.offline',
    defaultMessage: 'Offline',
  },
  [MODELER_STATUSES.ERROR]: {
    id: 'status.error',
    defaultMessage: 'Error',
  },
  [MODELER_STATUSES.PRINTING]: {
    id: 'status.printing',
    defaultMessage: 'Printing',
  },
  [MODELER_STATUSES.MAINTENANCE]: {
    id: 'maintenance',
    defaultMessage: 'Maintenance',
  },
  [MODELER_STATUSES.PRINT_FINISHED]: {
    id: 'status.printFinished',
    defaultMessage: 'Print Finished',
  },
  [MODELER_STATUSES.READY_TO_PRINT]: {
    id: 'status.readyToPrint',
    defaultMessage: 'Ready to Print',
  },
  [MODELER_STATUSES.NOT_CONNECTED]: {
    id: 'status.notConnected',
    defaultMessage: 'Not Connected',
  },
  [MODELER_STATUSES.UNKNOWN]: {
    id: 'field.unknown',
    message: 'Unknown',
  },
};

export const MODELER_STATUS_TOOLTIP_MAP = {
  [MODELER_STATUSES.IDLE]: {
    status: 'info',
    message: 'The modeler is idle',
  },
  [MODELER_STATUSES.OFFLINE]: {
    status: 'warning',
    message: 'The modeler is offline',
  },
  [MODELER_STATUSES.ERROR]: {
    status: 'danger',
    message: 'The modeler is in error',
  },
  [MODELER_STATUSES.PRINTING]: {
    status: 'success',
    message: 'The modeler is printing',
  },
  [MODELER_STATUSES.MAINTENANCE]: {
    status: 'warning',
    message: 'The modeler could not be found',
  },
  [MODELER_STATUSES.PRINT_FINISHED]: {
    status: 'success',
    message: 'Print Finished',
  },
  [MODELER_STATUSES.READY_TO_PRINT]: {
    status: 'info',
    message: 'Ready to Print',
  },
  [MODELER_STATUSES.NOT_CONNECTED]: {
    status: 'danger',
    message: 'Not Connected',
  },
  [MODELER_STATUSES.UNKNOWN]: {
    status: 'unknown',
    message: 'Receiving data but could not parse status',
  },
};

export const ORDER_REGION_MAP = {
  central: {
    id: 'order.central',
    defaultMessage: 'Central',
  },
  east: {
    id: 'order.east',
    defaultMessage: 'East',
  },
  mid_atlantic: {
    id: 'order.midAtlantic',
    defaultMessage: 'Mid-Atlantic',
  },
  south: {
    id: 'order.south',
    defaultMessage: 'South',
  },
  west: {
    id: 'order.west',
    defaultMessage: 'West',
  },
};

export const ORDER_TYPE_EOS_MAP = {
  benchmark: {
    id: 'order.benchmark',
    defaultMessage: 'Benchmark',
  },
  timestudy: {
    id: 'order.timestudy',
    defaultMessage: 'Timestudy',
  },
};

export const ORDER_TYPE_FR_MAP = {
  commercial: {
    id: 'commercial',
    defaultMessage: 'Commercial',
  },
  'sample-free': {
    id: 'sample',
    defaultMessage: 'Sample (Free)',
  },
  'r-d': {
    id: 'r-d',
    defaultMessage: 'R&D',
  },
};

export const IP_SENSITIVITY_MAP = {
  low: {
    id: 'ip_sensitivity_low',
    defaultMessage: 'Low (Can Be Shown To External)',
  },
  medium: {
    id: 'ip_sensitivity_medium',
    defaultMessage: 'Medium (Internal Only)',
  },
  high: {
    id: 'ip_sensitivity_high',
    defaultMessage: 'High (Business Unit Only)',
  },
};

export const SERVICE_PROVIDER_JOB_STATUS_COLOR_MAPPING = {
  [SERVICE_PROVIDER_JOB_STATUS.BID_REQUESTED]: COLORS.PURPLE,
  [SERVICE_PROVIDER_JOB_STATUS.BID_UPDATED]: COLORS.YELLOW,
  [SERVICE_PROVIDER_JOB_STATUS.BID_NEGOTIATED]: COLORS.BLUE,
  [SERVICE_PROVIDER_JOB_STATUS.SHIPPING_TO]: COLORS.GRAY,
  [SERVICE_PROVIDER_JOB_STATUS.IN_PROGRESS]: COLORS.ORANGE,
  [SERVICE_PROVIDER_JOB_STATUS.SHIPPING_FROM]: COLORS.GRAY,
  [SERVICE_PROVIDER_JOB_STATUS.COMPLETED]: COLORS.GREEN,
  [SERVICE_PROVIDER_JOB_STATUS.DECLINED]: COLORS.RED,
  [SERVICE_PROVIDER_JOB_STATUS.CANCELLED]: COLORS.GRAY,
};

export const SERVICE_PROVIDER_JOB_MAP = {
  [SERVICE_PROVIDER_JOB_STATUS.BID_REQUESTED]: {
    id: 'status.bidRequested',
    defaultMessage: 'Bid Requested',
  },
  [SERVICE_PROVIDER_JOB_STATUS.BID_UPDATED]: {
    id: 'status.bidUpdated',
    defaultMessage: 'Bid Updated',
  },
  [SERVICE_PROVIDER_JOB_STATUS.BID_NEGOTIATED]: {
    id: 'status.bidNegotiated',
    defaultMessage: 'Bid Negotiated',
  },
  [SERVICE_PROVIDER_JOB_STATUS.SHIPPING_TO]: {
    id: 'shippingTo',
    defaultMessage: 'Shipping To',
  },
  [SERVICE_PROVIDER_JOB_STATUS.IN_PROGRESS]: {
    id: 'status.inProgress',
    defaultMessage: 'In Progress',
  },
  [SERVICE_PROVIDER_JOB_STATUS.SHIPPING_FROM]: {
    id: 'shippingFrom',
    defaultMessage: 'Shipping From',
  },
  [SERVICE_PROVIDER_JOB_STATUS.COMPLETED]: {
    id: 'status.completed',
    defaultMessage: 'Completed',
  },
  [SERVICE_PROVIDER_JOB_STATUS.DECLINED]: {
    id: 'status.declined',
    defaultMessage: 'Declined',
  },
  [SERVICE_PROVIDER_JOB_STATUS.CANCELLED]: {
    id: 'status.cancelled',
    defaultMessage: 'Cancelled',
  },
};

export const JOB_RATING_MAP = {
  1: {
    id: 'rating.1',
    defaultMessage: '1 - Very Poor',
  },
  2: {
    id: 'rating.2',
    defaultMessage: '2 - Poor',
  },
  3: {
    id: 'rating.3',
    defaultMessage: '3 - OK',
  },
  4: {
    id: 'rating.4',
    defaultMessage: '4 - Good',
  },
  5: {
    id: 'rating.5',
    defaultMessage: '5 - Very Good',
  },
};

export const EVENT_KEY_MAP = {
  base_material: (
    <FormattedMessage id="field.baseMaterial" defaultMessage="Base Material" />
  ),
  bureau: <FormattedMessage id="field.bureau" defaultMessage="Bureau" />,
  currency: <FormattedMessage id="field.currency" defaultMessage="Currency" />,
  customer_email: (
    <FormattedMessage
      id="field.customer_email"
      defaultMessage="Customer Email"
    />
  ),
  due_date: <FormattedMessage id="field.dueDate" defaultMessage="Due Date" />,
  itar: <FormattedMessage id="field.itar" defaultMessage="Itar" />,
  model: <FormattedMessage id="field.model" defaultMessage="Model" />,
  model_permission: (
    <FormattedMessage
      id="event.modelPermission"
      defaultMessage="Model Permission"
    />
  ),
  name: <FormattedMessage id="field.name" defaultMessage="Name" />,
  notes: <FormattedMessage id="field.notes" defaultMessage="Notes" />,
  order_owner: <FormattedMessage id="field.owner" defaultMessage="Owner" />,
  order_type: <FormattedMessage id="field.type" defaultMessage="Type" />,
  // there is no such field as `print_time` anymore (it is replaced with `printing_time`)
  // anyway for events `print_time` key is still used in old and new items.
  print_time: <FormattedMessage id="printTime" defaultMessage="Print Time" />,
  quantity: <FormattedMessage id="field.quantity" defaultMessage="Quantity" />,
  region: <FormattedMessage id="field.region" defaultMessage="Region" />,
  shipping_address: (
    <FormattedMessage
      id="field.shippingAddress"
      defaultMessage="Shipping Address"
    />
  ),
  shipping_name: (
    <FormattedMessage id="field.shippingName" defaultMessage="Shipping Name" />
  ),
  shipping_uuid: (
    <FormattedMessage id="shippingPartner" defaultMessage="Shipping Partner" />
  ),
  status: <FormattedMessage id="field.status" defaultMessage="Status" />,
  support_material: (
    <FormattedMessage
      id="field.supportMaterial"
      defaultMessage="Support Material"
    />
  ),
  workflow: (
    <FormattedMessage id="workflow" defaultMessage="Production Workflow" />
  ),
  prep_workflow: (
    <FormattedMessage id="prepWorkflow" defaultMessage="Prep Workflow" />
  ),
  service_provider: (
    <FormattedMessage
      id="record.serviceProvider"
      defaultMessage="Service Provider"
    />
  ),
  user: <FormattedMessage id="field.user" defaultMessage="User" />,
};

export const MODEL_LIBRARY_TYPE_MAP = {
  product: {
    id: 'modelLibrary.type.product',
    defaultMessage: 'Product',
  },
  specimen: {
    id: 'modelLibrary.type.specimen',
    defaultMessage: 'Specimen',
  },
};

export const MATERIAL_FAMILY_MAP = {
  [MATERIAL_FAMILY.PRIORITY]: {
    id: 'materialFamily.priority',
    defaultMessage: 'Priority',
  },
  [MATERIAL_FAMILY.POLYMER]: {
    id: 'materialFamily.polymer',
    defaultMessage: 'Polymer',
  },
  [MATERIAL_FAMILY.COMPOSITE]: {
    id: 'materialFamily.composite',
    defaultMessage: 'Composite',
  },
  [MATERIAL_FAMILY.METAL]: {
    id: 'materialFamily.metal',
    defaultMessage: 'Metal',
  },
  [MATERIAL_FAMILY.CERAMIC]: {
    id: 'materialFamily.ceramic',
    defaultMessage: 'Ceramic',
  },
  [MATERIAL_FAMILY.WAX]: {
    id: 'materialFamily.wax',
    defaultMessage: 'Wax',
  },
  [MATERIAL_FAMILY.SAND]: {
    id: 'materialFamily.sand',
    defaultMessage: 'Sand',
  },
};

export const ORDER_QUOTE_DISCOUNT_TYPE_MAP = {
  amount: {
    id: ORDER_QUOTE_DISCOUNT_TYPES.AMOUNT,
    defaultMessage: 'Amount',
  },
  percentage: {
    id: ORDER_QUOTE_DISCOUNT_TYPES.PERCENTAGE,
    defaultMessage: 'Percentage',
  },
};

export const RUN_TRANSFORM_TYPES_FOR_REWORK_MODAL_MAP = {
  [RUN_TRANSFORM_TYPES.REMANUFACTURE]: {
    id: 'failPieces',
    defaultMessage: 'Fail Pieces',
  },
  [RUN_TRANSFORM_TYPES.REDO]: {
    id: 'redoThisRunOnly',
    defaultMessage: 'Redo this run only',
  },
};

// Boeing specific fields maps
export const BOEING_CUSTOM_ORDER_STATUS_COLOR_MAPPING = {
  [BOEING_CUSTOM_ORDER_STATUS.PENDING]: COLORS.RED,
  [BOEING_CUSTOM_ORDER_STATUS.IN_PROGRESS]: COLORS.YELLOW,
  [BOEING_CUSTOM_ORDER_STATUS.COMPLETE]: COLORS.GREEN,
};
export const BOEING_FINANCE_REVIEW_STATUS_COLOR_MAPPING = {
  [BOEING_FINANCE_REVIEW_STATUS.PENDING]: COLORS.RED,
  [BOEING_FINANCE_REVIEW_STATUS.IN_PROGRESS]: COLORS.YELLOW,
  [BOEING_FINANCE_REVIEW_STATUS.COMPLETE]: COLORS.GREEN,
};

export const ORDER_CUSTOMER_GO_COLOR_MAP = {
  No: COLORS.RED,
  Yes: COLORS.GREEN,
};

export const ORDER_CUSTOMER_GO_MAP = {
  No: {
    id: 'no',
    defaultMessage: 'No',
    backgroundColor: ORDER_CUSTOMER_GO_COLOR_MAP.No,
  },
  Yes: {
    id: 'yes',
    defaultMessage: 'Yes',
    backgroundColor: ORDER_CUSTOMER_GO_COLOR_MAP.Yes,
  },
};
export const ORDER_PLANNING_STATUS_MAP = {
  Pending: {
    id: 'status.pending',
    defaultMessage: 'Pending',
  },
  'In Progress': {
    id: 'status.inProgress',
    defaultMessage: 'In Progress',
  },
  Complete: {
    id: 'status.completed',
    defaultMessage: 'Completed',
  },
};
export const ORDER_BUILD_FILE_STATUS_MAP = {
  Pending: {
    id: 'status.pending',
    defaultMessage: 'Pending',
  },
  'In Progress': {
    id: 'status.inProgress',
    defaultMessage: 'In Progress',
  },
  Complete: {
    id: 'status.completed',
    defaultMessage: 'Completed',
  },
};

export const BUILD_PACKER_TYPES_MAP = {
  [BUILD_PACKER_TYPES.PACK2D]: {
    id: 'buildPacking.2d',
    defaultMessage: '2D Build Packing',
  },
  [BUILD_PACKER_TYPES.PACK3D]: {
    id: 'buildPacking.3d',
    defaultMessage: '3D Build Packing',
  },
  [BUILD_PACKER_TYPES.PACK3D_PREMIUM]: {
    id: 'buildPacking.3d_premium',
    defaultMessage: '3D Build Packing (Premium)',
  },
  [BUILD_PACKER_TYPES.USER_MANAGED]: {
    id: 'buildPacking.user_managed',
    defaultMessage: 'User Managed',
  },
};

export const RUN_TRANSFORMATION_STATUSES_MAP = {
  [RUN_TRANSFORMATION_STATUSES.PROCESSING]: {
    id: 'status.processing',
    defaultMessage: 'Processing',
  },
  [RUN_TRANSFORMATION_STATUSES.PENDING]: {
    id: 'status.pending',
    defaultMessage: 'Pending',
  },
  [RUN_TRANSFORMATION_STATUSES.COMPLETE]: {
    id: 'status.completed',
    defaultMessage: 'Completed',
  },
  [RUN_TRANSFORMATION_STATUSES.ERROR]: {
    id: 'status.error',
    defaultMessage: 'Error',
  },
};

export const PRIORITIES_MAP = {
  [PRIORITIES.CRITICAL]: {
    id: 'field.critical',
    defaultMessage: 'Critical',
  },
  [PRIORITIES.HIGH]: {
    id: 'field.high',
    defaultMessage: 'High',
  },
  [PRIORITIES.NORMAL]: {
    id: 'field.normal',
    defaultMessage: 'Normal',
  },
  [PRIORITIES.LOW]: {
    id: 'field.low',
    defaultMessage: 'Low',
  },
};

export const COMMENT_RELATED_TABLE_NAMES_MAP = {
  [COMMENT_RELATED_TABLE_NAMES.ORDER]: {
    id: 'field.order',
    defaultMessage: 'Order',
  },
  [COMMENT_RELATED_TABLE_NAMES.PREP_TASK_RECORD]: {
    // From User perspective, Prep Task Record is actually a Task
    id: 'record.task',
    defaultMessage: 'Task',
  },
  [COMMENT_RELATED_TABLE_NAMES.MODEL_LIBRARY]: {
    id: 'organize.modelLibrary',
    defaultMessage: 'Model Library',
  },
  [COMMENT_RELATED_TABLE_NAMES.RUN]: {
    id: 'field.run',
    defaultMessage: 'Run',
  },
};

export const SHIPMENT_STATUS_MAP = {
  [SHIPMENT_STATUSES.NEW]: {
    id: 'status.new',
    defaultMessage: 'New',
  },
  [SHIPMENT_STATUSES.QUEUED]: {
    id: 'status.queued',
    defaultMessage: 'Queued',
  },
  [SHIPMENT_STATUSES.IN_TRANSIT]: {
    id: 'status.inTransit',
    defaultMessage: 'In Transit',
  },
  [SHIPMENT_STATUSES.RECEIVED]: {
    id: 'status.received',
    defaultMessage: 'Received',
  },
  [SHIPMENT_STATUSES.ERROR]: {
    id: 'status.error',
    defaultMessage: 'Error',
  },
  [SHIPMENT_STATUSES.CANCELLED]: {
    id: 'status.cancelled',
    defaultMessage: 'Cancelled',
  },
};

export const SHIPMENT_STATUS_COLOR_MAP = {
  [SHIPMENT_STATUSES.NEW]: COLORS.PURPLE,
  [SHIPMENT_STATUSES.QUEUED]: COLORS.BLUE,
  [SHIPMENT_STATUSES.IN_TRANSIT]: COLORS.ORANGE,
  [SHIPMENT_STATUSES.RECEIVED]: COLORS.GREEN,
  [SHIPMENT_STATUSES.ERROR]: COLORS.RED,
  [SHIPMENT_STATUSES.CANCELLED]: COLORS.GRAY,
};

export const PROCESS_PROVIDER_STATUSES_MAP = {
  [PROCESS_PROVIDER_STATUSES.PENDING]: {
    id: 'status.pending',
    defaultMessage: 'Pending',
  },
  [PROCESS_PROVIDER_STATUSES.ACTIVE]: {
    id: 'status.active',
    defaultMessage: 'Active',
  },
  [PROCESS_PROVIDER_STATUSES.INACTIVE]: {
    id: 'status.inactive',
    defaultMessage: 'Inactive',
  },
};

export const MATERIAL_UNITS_MAP = {
  [MATERIAL_UNITS.GRAM]: {
    id: 'field.units.gram',
    defaultMessage: 'g',
  },
  [MATERIAL_UNITS.KG]: {
    id: 'field.units.kg',
    defaultMessage: 'kg',
  },
  [MATERIAL_UNITS.LB]: {
    id: 'field.units.lb',
    defaultMessage: 'lb',
  },
  [MATERIAL_UNITS.MM3]: {
    id: 'field.units.mm3',
    defaultMessage: 'mm³',
  },
  [MATERIAL_UNITS.CM3]: {
    id: 'field.units.cm3',
    defaultMessage: 'cm³',
  },
  [MATERIAL_UNITS.L]: {
    id: 'field.units.l',
    defaultMessage: 'l',
  },
  [MATERIAL_UNITS.ML]: {
    id: 'field.units.ml',
    defaultMessage: 'ml',
  },
  [MATERIAL_UNITS.INCH3]: {
    id: 'field.units.inch3',
    defaultMessage: 'in³',
  },
};

export const MODEL_UNITS_MAP_METRIC = {
  [MODEL_UNITS.MM]: {
    id: 'millimeters',
    defaultMessage: 'Millimeters',
  },
  [MODEL_UNITS.CM]: {
    id: 'centimeters',
    defaultMessage: 'Centimeters',
  },
  [MODEL_UNITS.M]: {
    id: 'meters',
    defaultMessage: 'Meters',
  },
};

export const MODEL_UNITS_MAP_IMPERIAL = {
  [MODEL_UNITS.INCHES]: {
    id: 'inches',
    defaultMessage: 'Inches',
  },
  [MODEL_UNITS.FEET]: {
    id: 'feet',
    defaultMessage: 'Feet',
  },
};

export const MODEL_UNITS_MAP_VIEW_MODEL_IN = {
  [MODEL_UNITS.AUTOMATIC]: {
    id: 'automatic',
    defaultMessage: 'Automatic',
  },
  [MODEL_UNITS.MM]: {
    id: 'millimeters',
    defaultMessage: 'Millimeters',
  },
  [MODEL_UNITS.INCHES]: {
    id: 'inches',
    defaultMessage: 'Inches',
  },
};

export const MODEL_UNITS_MAP = {
  ...MODEL_UNITS_MAP_METRIC,
  ...MODEL_UNITS_MAP_IMPERIAL,
  [MODEL_UNITS.AUTOMATIC]: {
    id: 'automatic',
    defaultMessage: 'Automatic',
  },
};

export const PREP_TASK_RECORD_STATUSES_MAP = {
  [PREP_TASK_RECORD_STATUSES.NEW]: {
    id: 'status.new',
    defaultMessage: 'New',
  },
  [PREP_TASK_RECORD_STATUSES.QUEUED]: {
    id: 'status.queued',
    defaultMessage: 'Queued',
  },
  [PREP_TASK_RECORD_STATUSES.IN_PROGRESS]: {
    id: 'status.inProgress',
    defaultMessage: 'In Progress',
  },
  [PREP_TASK_RECORD_STATUSES.ON_HOLD]: {
    id: 'status.onHold',
    defaultMessage: 'On Hold',
  },
  [PREP_TASK_RECORD_STATUSES.COMPLETE]: {
    id: 'status.complete',
    defaultMessage: 'Complete',
  },
  [PREP_TASK_RECORD_STATUSES.SKIPPED]: {
    id: 'status.skipped',
    defaultMessage: 'Skipped',
  },
  [PREP_TASK_RECORD_STATUSES.CANCELLED]: {
    id: 'status.cancelled',
    defaultMessage: 'Cancelled',
  },
};

export const CREATE_RUNS_STATUSES_MAP = {
  [CREATE_RUNS_STATUSES.PENDING]: {
    id: 'status.pending',
    defaultMessage: 'Pending',
  },
  [CREATE_RUNS_STATUSES.PROCESSING]: {
    id: 'status.processing',
    defaultMessage: 'Processing',
  },
  [CREATE_RUNS_STATUSES.COMPLETE]: {
    id: 'status.complete',
    defaultMessage: 'Complete',
  },
  [CREATE_RUNS_STATUSES.ERROR]: {
    id: 'status.error',
    defaultMessage: 'Error',
  },
};

export const WORK_INSTRUCTION_UNITS_MAP = {
  [WORK_INSTRUCTION_UNITS.MM]: {
    id: 'field.units.mm',
    defaultMessage: 'mm',
  },
  [WORK_INSTRUCTION_UNITS.CM]: {
    id: 'field.units.cm',
    defaultMessage: 'cm',
  },
  [WORK_INSTRUCTION_UNITS.INCH]: {
    id: 'field.units.inch',
    defaultMessage: 'in',
  },
  [WORK_INSTRUCTION_UNITS.GRAM]: {
    id: 'field.units.gram',
    defaultMessage: 'g',
  },
  [WORK_INSTRUCTION_UNITS.KG]: {
    id: 'field.units.kg',
    defaultMessage: 'kg',
  },
  [WORK_INSTRUCTION_UNITS.LB]: {
    id: 'field.units.lb',
    defaultMessage: 'lb',
  },
};

export const WORK_INSTRUCTION_THRESHOLD_TYPES_MAP = {
  [WORK_INSTRUCTION_THRESHOLD_TYPES.BETWEEN]: {
    id: 'thresholdTypes.between',
    defaultMessage: 'Between',
  },
  [WORK_INSTRUCTION_THRESHOLD_TYPES.NOT_BETWEEN]: {
    id: 'thresholdTypes.notBetween',
    defaultMessage: 'Not Between',
  },
  [WORK_INSTRUCTION_THRESHOLD_TYPES.TOLERANCE]: {
    id: 'thresholdTypes.tolerance',
    defaultMessage: 'Tolerance',
  },
  [WORK_INSTRUCTION_THRESHOLD_TYPES.LESS_THAN]: {
    id: 'thresholdTypes.lessThen',
    defaultMessage: 'Less Than',
  },
  [WORK_INSTRUCTION_THRESHOLD_TYPES.LESS_THAN_OR_EQUALS_TO]: {
    id: 'thresholdTypes.lessThanOrEqualsTo',
    defaultMessage: 'Less Than or Equals to',
  },
  [WORK_INSTRUCTION_THRESHOLD_TYPES.GREATER_THAN]: {
    id: 'thresholdTypes.greaterThan',
    defaultMessage: 'Greater Than',
  },
  [WORK_INSTRUCTION_THRESHOLD_TYPES.GREATER_THAN_OR_EQUALS_TO]: {
    id: 'thresholdTypes.greaterThanOrEqualsTo',
    defaultMessage: 'Greater Than or Equals to',
  },
  [WORK_INSTRUCTION_THRESHOLD_TYPES.EQUALS_TO]: {
    id: 'thresholdTypes.equalTo',
    defaultMessage: 'Equal To',
  },
  [WORK_INSTRUCTION_THRESHOLD_TYPES.NOT_EQUALS_TO]: {
    id: 'thresholdTypes.notEqualTo',
    defaultMessage: 'Not Equal to',
  },
  [WORK_INSTRUCTION_THRESHOLD_TYPES.CONTAINS]: {
    id: 'thresholdTypes.contains',
    defaultMessage: 'Contains',
  },
  [WORK_INSTRUCTION_THRESHOLD_TYPES.NOT_CONTAINS]: {
    id: 'thresholdTypes.doesNotContain',
    defaultMessage: 'Does Not Contain',
  },
  [WORK_INSTRUCTION_THRESHOLD_TYPES.NONE]: {
    id: 'thresholdTypes.none',
    defaultMessage: 'None',
  },
};

export const ENTITY_TYPE_TO_NON_ENTITY_API_RESOURCE_MAP = {
  [ENTITY_TYPES.LINE_ITEM]: [
    NON_ENTITY_API_RESOURCES.LINE_ITEM_WITH_AVAILABLE_FOR_RUN_PRINTS,
    NON_ENTITY_API_RESOURCES.SERVICE_PROVIDER_V1_LINE_ITEM,
  ],
  [ENTITY_TYPES.ORDER]: [NON_ENTITY_API_RESOURCES.SERVICE_PROVIDER_V1_ORDER],

  // All non-real resources included here
  [ENTITY_TYPES.NONE]: [
    NON_ENTITY_API_RESOURCES.MATERIAL_GENEALOGY,
    NON_ENTITY_API_RESOURCES.OAUTH_TOKEN_AUTODESK_FORGE,
    NON_ENTITY_API_RESOURCES.WORK_CHECKLISTS_FOR,
    NON_ENTITY_API_RESOURCES.WORK_CHECKLISTS_FOR_RESOURCE,
    NON_ENTITY_API_RESOURCES.ACCESS_INFO_FOR_RESOURCE,
    NON_ENTITY_API_RESOURCES.CSRF_TOKEN,
    NON_ENTITY_API_RESOURCES.BULK_DELETE_PRODUCT,
  ],
};

export const CAPTURE_STATUS_MAP = {
  [CAPTURE_STATUSES.COMPLETE]: {
    id: 'status.complete',
    defaultMessage: 'Complete',
  },
  [CAPTURE_STATUSES.FAILED]: {
    id: 'status.failed',
    defaultMessage: 'Failed',
  },
  [CAPTURE_STATUSES.PROCESSING]: {
    id: 'status.processing',
    defaultMessage: 'Processing',
  },
  [CAPTURE_STATUSES.BATCH_PROCESSING]: {
    id: 'status.batch_processing',
    defaultMessage: 'Batch Processing',
  },
  [CAPTURE_STATUSES.NEW]: {
    id: 'status.new',
    defaultMessage: 'New',
  },
};

export const MATERIAL_REASON_CODE_MAP = {
  [MATERIAL_REASON_CODE_OPTIONS.INVALID_RECEIPTS]: {
    id: 'reasonCode.invalidReceipts',
    defaultMessage: 'Invalid Receipts',
  },
  [MATERIAL_REASON_CODE_OPTIONS.ITEM_SUBSTITUTION]: {
    id: 'reasonCode.itemSubstitution',
    defaultMessage: 'Item Substitution',
  },
  [MATERIAL_REASON_CODE_OPTIONS.UOM_DISCREPANCY]: {
    id: 'reasonCode.uomDiscrepancy',
    defaultMessage: 'UOM Discrepancy',
  },
  [MATERIAL_REASON_CODE_OPTIONS.CYCLE_COUNT]: {
    id: 'reasonCode.cycleCount',
    defaultMessage: 'Cycle Count',
  },
  [MATERIAL_REASON_CODE_OPTIONS.CANNOT_BE_DETERMINED]: {
    id: 'reasonCode.cannotBeDetermined',
    defaultMessage: 'Cannot be Determined',
  },
  [MATERIAL_REASON_CODE_OPTIONS.OVERSHIPMENT_ADJUSTMENT]: {
    id: 'reasonCode.overshipmentAdjustment',
    defaultMessage: 'Overshipment Adjustment',
  },
  [MATERIAL_REASON_CODE_OPTIONS.DAMAGED_TRANSIT]: {
    id: 'reasonCode.damagedTransit',
    defaultMessage: 'Damaged — Transit',
  },
  [MATERIAL_REASON_CODE_OPTIONS.DAMAGED_WAREHOUSE]: {
    id: 'reasonCode.damagedWarehouse',
    defaultMessage: 'Damaged — Warehouse',
  },
  [MATERIAL_REASON_CODE_OPTIONS.PHYSICAL_COUNT]: {
    id: 'reasonCode.physicalCount',
    defaultMessage: 'Physical Count',
  },
  [MATERIAL_REASON_CODE_OPTIONS.DATA_ENTRY_ERROR]: {
    id: 'reasonCode.dataEntryError',
    defaultMessage: 'Data Entry Error',
  },
  [MATERIAL_REASON_CODE_OPTIONS.SCRAP]: {
    id: 'reasonCode.scrap',
    defaultMessage: 'Scrap',
  },
};

export const DOWNTIME_STATUS_MAP = {
  [DOWNTIME_STATUS.COMPLETE]: {
    id: 'status.complete',
    defaultMessage: 'Complete',
  },
  [DOWNTIME_STATUS.SCHEDULED]: {
    id: 'status.scheduled',
    defaultMessage: 'Scheduled',
  },
};

export const STOCK_STATUS_MAP = {
  [STOCK_STATUSES.IN_USE]: {
    id: 'status.inUse',
    defaultMessage: 'In Use',
  },
  [STOCK_STATUSES.AVAILABLE]: {
    id: 'status.available',
    defaultMessage: 'Available',
  },
  [STOCK_STATUSES.EXHAUSTED]: {
    id: 'status.exhausted',
    defaultMessage: 'Exhausted',
  },
};

export const STOCK_STATUS_COLOR_MAP = {
  [STOCK_STATUSES.EXHAUSTED]: COLORS.PURPLE,
  [STOCK_STATUSES.AVAILABLE]: COLORS.BLUE,
  [STOCK_STATUSES.IN_USE]: COLORS.ORANGE,
};

export const BATCH_STATUS_COLOR_MAP = {
  [MATERIAL_BATCH_STATUSES.NEW]: COLORS.PURPLE,
  [MATERIAL_BATCH_STATUSES.IN_USE]: COLORS.ORANGE,
  [MATERIAL_BATCH_STATUSES.DONE]: COLORS.GREEN,
  [MATERIAL_BATCH_STATUSES.CONSUMED]: COLORS.GRAY,
};

export const DOWNTIME_STATUS_COLOR_MAP = {
  [DOWNTIME_STATUS.COMPLETE]: COLORS.GREEN,
  [DOWNTIME_STATUS.SCHEDULED]: COLORS.BLUE,
};

// TODO: It might make more sense to use combination of entity and field name
//  instead of extra `_TYPES_` constant in future
export const STATUS_COLOR_CODE_TYPE_TO_COLOR_MAP = {
  [STATUS_COLOR_CODE_TYPES.ORDER_STATUS]: ORDER_STATUS_COLOR_CODE_MAPPING,
  [STATUS_COLOR_CODE_TYPES.RUN_STATUS]: RUN_STATUS_COLOR_MAP,
  [STATUS_COLOR_CODE_TYPES.SERVICE_PROVIDER_JOB]: SERVICE_PROVIDER_JOB_STATUS_COLOR_MAPPING,
  [STATUS_COLOR_CODE_TYPES.BOEING_FIELD_STATUS]: BOEING_CUSTOM_ORDER_STATUS_COLOR_MAPPING,
  [STATUS_COLOR_CODE_TYPES.BOEING_FINANCE_REVIEW_STATUS]: BOEING_FINANCE_REVIEW_STATUS_COLOR_MAPPING,
  [STATUS_COLOR_CODE_TYPES.YES_NO]: ORDER_CUSTOMER_GO_COLOR_MAP,
  [STATUS_COLOR_CODE_TYPES.SHIPMENT]: SHIPMENT_STATUS_COLOR_MAP,
  [STATUS_COLOR_CODE_TYPES.PREP_TASK_RECORD_STATUS]: PREP_TASK_RECORD_STATUS_COLOR_MAP,
  [STATUS_COLOR_CODE_TYPES.COMMENT_ACTION_STATUS]: COMMENT_ACTION_STATUS_COLOR_MAP,
  [STATUS_COLOR_CODE_TYPES.STOCK_STATUS]: STOCK_STATUS_COLOR_MAP,
  [STATUS_COLOR_CODE_TYPES.PRODUCT]: PRODUCT_STATUS_COLOR_CODE_MAP,
  [STATUS_COLOR_CODE_TYPES.STOCK_STATUS]: STOCK_STATUS_COLOR_MAP,
  [STATUS_COLOR_CODE_TYPES.BATCH_STATUS]: BATCH_STATUS_COLOR_MAP,
  [STATUS_COLOR_CODE_TYPES.DOWNTIME_STATUS]: DOWNTIME_STATUS_COLOR_MAP,
  [STATUS_COLOR_CODE_TYPES.LINE_ITEM_STATUS]: LINE_ITEM_STATUS_COLOR_CODE_MAPPING,
};

export const LOCATION_NOTIFICATION_SETTINGS_BOOLS_MAP = {
  [LOCATION_NOTIFICATION_SETTING_BOOLS.LINE_ITEMS_CONFIRMED]: {
    id: 'field.locationSetting.lineItemsConfirmed',
    defaultMessage: 'All order line items have been confirmed',
  },
  [LOCATION_NOTIFICATION_SETTING_BOOLS.ORDER_COMPLETED]: {
    id: 'field.locationSetting.orderCompleted',
    defaultMessage: 'An order has finished shipping',
  },
  [LOCATION_NOTIFICATION_SETTING_BOOLS.ORDER_CREATED]: {
    id: 'field.locationSetting.orderCreated',
    defaultMessage: 'New order created',
  },
  [LOCATION_NOTIFICATION_SETTING_BOOLS.ORDER_STARTED_PRINTING]: {
    id: 'field.locationSetting.startedPrinting',
    defaultMessage: 'First object in an order has started printing',
  },
  [LOCATION_NOTIFICATION_SETTING_BOOLS.ORDER_SCHEDULED]: {
    id: 'field.locationSetting.orderScheduled',
    defaultMessage: 'All order objects have been scheduled',
  },
  [LOCATION_NOTIFICATION_SETTING_BOOLS.PRINT_ERROR]: {
    id: 'field.locationSetting.printError',
    defaultMessage: 'Any print has errored',
  },
};

export const WORKFLOW_TYPES_MAP = {
  [WORKFLOW_TYPES.ADDITIVE_MANUFACTURING]: {
    id: 'workflowType.additive_manufacturing',
    defaultMessage: 'Additive Manufacturing',
  },
  [WORKFLOW_TYPES.SPECIMEN]: {
    id: 'workflowType.specimen',
    defaultMessage: 'Specimen',
  },
  [WORKFLOW_TYPES.ASSEMBLY]: {
    id: 'assembly',
    defaultMessage: 'Sub-Assembly',
  },
  [WORKFLOW_TYPES.POWDER_MANUFACTURING]: {
    id: 'workflow.type.powder_manufacturing',
    defaultMessage: 'Powder Manufacturing',
  },
  // Only Used for UI wording for non-additive GeneralMFGLanguage users
  // Wallpaper fix should keep the same value as additive manufacturing.
  // Only purpose is to display differently in UI
  [WORKFLOW_TYPES.MANUFACTURING]: {
    id: 'workflow.type.manufacturing',
    defaultMessage: 'Manufacturing',
  },
};

export const BATCHING_STRATEGIES_MAP = {
  [BATCHING_STRATEGIES.BY_SIZE_XY]: {
    id: 'batchingStrategy.bySizeXY',
    defaultMessage: 'Batch By Size (XY)',
  },
  [BATCHING_STRATEGIES.BY_SIZE_XYZ]: {
    id: 'batchingStrategy.bySizeXYZ',
    defaultMessage: 'Batch By Size (XYZ)',
  },
  [BATCHING_STRATEGIES.BY_NUMBER_OF_PARTS]: {
    id: 'batchingStrategy.byNumberOfParts',
    defaultMessage: 'Batch By Number of Parts',
  },
  [BATCHING_STRATEGIES.BY_PREVIOUS_RUN]: {
    id: 'batchingStrategy.byPreviousRun',
    defaultMessage: 'Include All Parts of Previous Run',
  },
  [BATCHING_STRATEGIES.SINGLE]: {
    id: 'batchingStrategy.single',
    defaultMessage: 'Single',
  },
};

export const SHIPMENT_ADDRESS_TYPES_MAPPING = {
  [SHIPMENT_ADDRESS_TYPES.RECIPIENT_ADDRESS]: {
    id: 'shippingTo',
    defaultMessage: 'Shipping To',
  },
  [SHIPMENT_ADDRESS_TYPES.BILLING_ADDRESS]: {
    id: 'billingAddress',
    defaultMessage: 'Billing Address',
  },
  [SHIPMENT_ADDRESS_TYPES.ORIGIN_ADDRESS]: {
    id: 'shippingFrom',
    defaultMessage: 'Shipping From',
  },
};

export const WORK_INSTRUCTION_REPORT_TYPE_MAP = {
  [WORK_INSTRUCTION_REPORT_TYPES.NO_ENTRY]: {
    id: 'field.noEntry',
    defaultMessage: 'No Entry',
  },
  [WORK_INSTRUCTION_REPORT_TYPES.CHECKBOX]: {
    id: 'field.checkbox',
    defaultMessage: 'Checkbox',
  },
  [WORK_INSTRUCTION_REPORT_TYPES.DOCUMENT]: {
    id: 'field.document',
    defaultMessage: 'Document',
  },
  [WORK_INSTRUCTION_REPORT_TYPES.PICTURE]: {
    id: 'field.picture',
    defaultMessage: 'Picture',
  },
  [WORK_INSTRUCTION_REPORT_TYPES.NUMBER]: {
    id: 'field.number',
    defaultMessage: 'Number',
  },
  [WORK_INSTRUCTION_REPORT_TYPES.RANGE]: {
    id: 'field.range',
    defaultMessage: 'Range',
  },
  [WORK_INSTRUCTION_REPORT_TYPES.TEXT]: {
    id: 'field.text',
    defaultMessage: 'Text',
  },
  [WORK_INSTRUCTION_REPORT_TYPES.SINGLE_SELECT_DROPDOWN]: {
    id: 'field.dropdown',
    defaultMessage: 'Dropdown',
  },
  [WORK_INSTRUCTION_REPORT_TYPES.OUTPUT_AT_LOCATION]: {
    id: 'field.storageOutput',
    defaultMessage: 'Output',
  },
};

export const WORK_INSTRUCTION_REPORT_TYPE_ANSWERS_MAP = {
  [WORK_INSTRUCTION_REPORT_TYPES.NO_ENTRY]: {
    id: 'field.noEntry',
    defaultMessage: 'No Entry',
  },
  [WORK_INSTRUCTION_REPORT_TYPES.CHECKBOX]: {
    id: 'field.checked',
    defaultMessage: 'Checked',
  },
  [WORK_INSTRUCTION_REPORT_TYPES.DOCUMENT]: {
    id: 'field.uploaded',
    defaultMessage: 'Uploaded',
  },
  [WORK_INSTRUCTION_REPORT_TYPES.PICTURE]: {
    id: 'field.uploaded',
    defaultMessage: 'Uploaded',
  },
};

export const DESIGN_FILE_STATUS_MAP = {
  [DESIGN_FILE_STATUSES.NEW]: {
    id: 'status.new',
    defaultMessage: 'New',
  },
  [DESIGN_FILE_STATUSES.PROCESSING]: {
    id: 'status.processing',
    defaultMessage: 'Processing',
  },
  [DESIGN_FILE_STATUSES.PROCESSED]: {
    id: 'status.processed',
    defaultMessage: 'Processed',
  },
  [DESIGN_FILE_STATUSES.ERROR]: {
    id: 'status.error',
    defaultMessage: 'Error',
  },
};

export const ASSEMBLY_META_ASSEMBLING_TYPE_MAP = {
  [ASSEMBLY_META_ASSEMBLING_TYPES.NONE]: {
    id: 'coprinted',
    defaultMessage: 'Co-Printed (None)',
  },
  [ASSEMBLY_META_ASSEMBLING_TYPES.GLUED]: {
    id: 'glued',
    defaultMessage: 'Glued',
  },
  [ASSEMBLY_META_ASSEMBLING_TYPES.MAGNET]: {
    id: 'magnet',
    defaultMessage: 'Magnet',
  },
};

export const PRODUCT_MODEL_FILE_SOURCE_MAP = {
  [PRODUCT_MODEL_FILE_SOURCES.RICOH_3D_CMP]: {
    id: 'ricoh3dCMP',
    defaultMessage: 'Ricoh 3D CMP',
  },
  [PRODUCT_MODEL_FILE_SOURCES.WEB_PRINT]: {
    id: 'webPrint',
    defaultMessage: 'Web Print',
  },
  [PRODUCT_MODEL_FILE_SOURCES.OTHER]: {
    id: 'other',
    defaultMessage: 'Other',
  },
};

export const CASTOR_COSTING_MAPPING = {
  [CASTOR_COSTING_STATUSES.NEW]: {
    id: 'status.new',
    defaultMessage: 'New',
  },
  [CASTOR_COSTING_STATUSES.PENDING]: {
    id: 'status.pending',
    defaultMessage: 'Pending',
  },
  [CASTOR_COSTING_STATUSES.PROJECT_UP]: {
    id: 'status.castor.projectUp',
    defaultMessage: 'Project Created, Files Pending',
  },
  [CASTOR_COSTING_STATUSES.PROJECT_UP_FAILED]: {
    id: 'status.castor.projectUpFailed',
    defaultMessage: 'Creating Project Failed',
  },
  [CASTOR_COSTING_STATUSES.FILES_UP]: {
    id: 'status.castor.filesUp',
    defaultMessage: 'Uploading Files for Analysis',
  },
  [CASTOR_COSTING_STATUSES.FILES_UP_FAILED]: {
    id: 'status.castor.filesUpFailed',
    defaultMessage: 'Project Created, Files Upload Failed',
  },
  [CASTOR_COSTING_STATUSES.PROCESSING]: {
    id: 'status.castor.processing',
    defaultMessage: 'Processing files for analysis',
  },
  [CASTOR_COSTING_STATUSES.UP_TO_DATE]: {
    id: 'status.castor.upToDate',
    defaultMessage: 'Analysis Complete, Up to Date',
  },
  [CASTOR_COSTING_STATUSES.REFETCH_NEEDED]: {
    id: 'status.castor.refetchNeeded',
    defaultMessage: 'Refetch Needed',
  },
  [CASTOR_COSTING_STATUSES.FAILED]: {
    id: 'status.failed',
    defaultMessage: 'Failed',
  },
};

export const MODEL_STATUS_MAP = {
  [MODEL_STATUSES.NOT_UPLOADED]: {
    id: 'status.notUploaded',
    defaultMessage: 'Not Uploaded',
  },
  [MODEL_STATUSES.CONVERTING]: {
    id: 'converting',
    defaultMessage: 'Converting',
  },
  [MODEL_STATUSES.PROCESSING]: {
    id: 'status.processing',
    defaultMessage: 'Processing',
  },
  [MODEL_STATUSES.PROCESSED]: {
    id: 'status.processed',
    defaultMessage: 'Processed',
  },
  [MODEL_STATUSES.EXTERNAL_PROCESS]: {
    id: 'status.externalProcess',
    defaultMessage: 'External Process',
  },
  [MODEL_STATUSES.ERROR]: {
    id: 'status.error',
    defaultMessage: 'Error',
  },
  [MODEL_STATUSES.REPAIRED]: {
    id: 'status.repaired',
    defaultMessage: 'Repaired',
  },
  [MODEL_STATUSES.IRREPARABLE]: {
    id: 'status.irreparable',
    defaultMessage: 'Irreparable',
  },
  [MODEL_STATUSES.INOPERABLE]: {
    id: 'status.inoperable',
    defaultMessage: 'Inoperable',
  },
  [MODEL_STATUSES.FILE_TOO_LARGE]: {
    id: 'status.fileToLarge',
    defaultMessage: 'File Too Large',
  },
};

export const CASTOR_COSTING_CONFIG_MANUFACTURABILITY_OPTIONS_MAP = {
  [CASTOR_COSTING_CONFIG_MANUFACTURABILITY_OPTIONS.PRINTABLE]: {
    id: 'field.printable',
    defaultMessage: 'Printable',
  },
  [CASTOR_COSTING_CONFIG_MANUFACTURABILITY_OPTIONS.NOT_PRINTABLE]: {
    id: 'field.reviewRequired',
    defaultMessage: 'Review required',
  },
  [CASTOR_COSTING_CONFIG_MANUFACTURABILITY_OPTIONS.PRINTABLE_WITH_SUPPORT]: {
    id: 'printableWithSupport',
    defaultMessage: 'Printable with supports',
  },
};

export const ZVERSE_CONVERSION_STATUSES_MAP = {
  [ZVERSE_CONVERSION_STATUSES.NEW]: defineMessages({
    id: 'status.new',
    defaultMessage: 'New',
  }),
  [ZVERSE_CONVERSION_STATUSES.PENDING]: defineMessages({
    id: 'status.pending',
    defaultMessage: 'Pending',
  }),
  // Submitting to 3rd party is done simultaneously with file upload on the backend
  // Means conversion request is sent and awaiting review. Showing it as Processing to our users
  [ZVERSE_CONVERSION_STATUSES.SUBMITTED]: defineMessages({
    id: 'status.processing',
    defaultMessage: 'Processing',
  }),
  // Means file is uploaded to 3rd party API and awaiting review. Showing it as Processing to our users
  [ZVERSE_CONVERSION_STATUSES.FILE_UP]: defineMessages({
    id: 'status.processing',
    defaultMessage: 'Processing',
  }),
  [ZVERSE_CONVERSION_STATUSES.PROCESSING]: defineMessages({
    id: 'status.processing',
    defaultMessage: 'Processing',
  }),
  [ZVERSE_CONVERSION_STATUSES.ESTIMATE_COMPLETED]: defineMessages({
    id: 'estimateCompleted',
    defaultMessage: 'Estimate Completed',
  }),
  // Showing it as `processing` for user since user just needs to wait for the result of approval
  [ZVERSE_CONVERSION_STATUSES.APPROVAL_REQUESTED]: defineMessages({
    id: 'status.processing',
    defaultMessage: 'Processing',
  }),
  // Failed Approval will be shown as `error` to user
  [ZVERSE_CONVERSION_STATUSES.APPROVAL_FAILED]: defineMessages({
    id: 'status.error',
    defaultMessage: 'Error',
  }),
  // Showing it as `processing` for user since user just needs to wait for the result of approval
  [ZVERSE_CONVERSION_STATUSES.CONVERSION_APPROVED]: defineMessages({
    id: 'status.processing',
    defaultMessage: 'Processing',
  }),
  [ZVERSE_CONVERSION_STATUSES.CONVERSION_COMPLETED]: defineMessages({
    id: 'conversionCompleted',
    defaultMessage: 'Conversion Completed',
  }),
  [ZVERSE_CONVERSION_STATUSES.COMPLETE]: defineMessages({
    id: 'status.complete',
    defaultMessage: 'Complete',
  }),
  [ZVERSE_CONVERSION_STATUSES.ERROR]: defineMessages({
    id: 'status.error',
    defaultMessage: 'Error',
  }),
};

export const EXPERT_CONVERSION_STATUSES_MAP = {
  [EXPERT_CONVERSION_STATUSES.NEW]: defineMessages({
    id: 'status.new',
    defaultMessage: 'New',
  }),
  [EXPERT_CONVERSION_STATUSES.PENDING]: defineMessages({
    id: 'status.pending',
    defaultMessage: 'Pending',
  }),
  [EXPERT_CONVERSION_STATUSES.SUBMITTED]: defineMessages({
    id: 'status.processing',
    defaultMessage: 'Processing',
  }),

  [EXPERT_CONVERSION_STATUSES.COMPLETE]: defineMessages({
    id: 'status.complete',
    defaultMessage: 'Complete',
  }),
  [EXPERT_CONVERSION_STATUSES.ERROR]: defineMessages({
    id: 'status.error',
    defaultMessage: 'Error',
  }),
};

export const MODEL_CONVERSION_STATUSES_MAP = {
  [MODEL_CONVERSION_STATUSES.NEW]: defineMessages({
    id: 'status.new',
    defaultMessage: 'New',
  }),
  [MODEL_CONVERSION_STATUSES.PENDING]: defineMessages({
    id: 'status.pending',
    defaultMessage: 'Pending',
  }),
  // Downloading means Conversion Server received request and is in the process of loading native file
  // Showing as Processing to users
  [MODEL_CONVERSION_STATUSES.DOWNLOADING]: defineMessages({
    id: 'status.processing',
    defaultMessage: 'Processing',
  }),
  // Repairing is not supported for now. Anyway, it will still be Processing from user perspective
  [MODEL_CONVERSION_STATUSES.REPAIRING]: defineMessages({
    id: 'status.processing',
    defaultMessage: 'Processing',
  }),
  [MODEL_CONVERSION_STATUSES.PROCESSING]: defineMessages({
    id: 'status.processing',
    defaultMessage: 'Processing',
  }),
  // Uploading means Nautilus is loading file to S3 - so it's still Processing for the user
  [MODEL_CONVERSION_STATUSES.UPLOADING]: defineMessages({
    id: 'status.processing',
    defaultMessage: 'Processing',
  }),
  [MODEL_CONVERSION_STATUSES.COMPLETE]: defineMessages({
    id: 'status.complete',
    defaultMessage: 'Complete',
  }),
  // Not used for now. Anyway, it still means Complete to the user
  [MODEL_CONVERSION_STATUSES.COMPLETE_WITHOUT_UPLOAD]: defineMessages({
    id: 'status.complete',
    defaultMessage: 'Complete',
  }),
  [MODEL_CONVERSION_STATUSES.FAILED]: defineMessages({
    id: 'status.failed',
    defaultMessage: 'Failed',
  }),
};

export const WORKFLOW_TYPES_OBJECT_KEY_MAP = {
  [WORKFLOW_TYPES.ADDITIVE_MANUFACTURING]: 'additive',
  [WORKFLOW_TYPES.POWDER_MANUFACTURING]: 'powder',
};

export const WORKFLOW_TYPES_ESTIMATES_KEY_MAP = {
  [WORKFLOW_TYPES.ADDITIVE_MANUFACTURING]: 'estimates_additive',
  [WORKFLOW_TYPES.POWDER_MANUFACTURING]: 'estimates_powder',
};

export const MSNAV_IMPORT_FILE_STATUSES_MAP = {
  [MSNAV_IMPORT_FILE_STATUSES.UPLOADED]: 'Uploaded',
  [MSNAV_IMPORT_FILE_STATUSES.NOT_UPLOADED]: 'Not Uploaded',
  [MSNAV_IMPORT_FILE_STATUSES.ERROR]: 'Error',
  [MSNAV_IMPORT_FILE_STATUSES.PROCESSING]: 'Processing',
  [MSNAV_IMPORT_FILE_STATUSES.PROCESSED]: 'Processed',
};

export const WORK_INSTRUCTION_BADGE_REPORT_COLOR_MAP = {
  general: 'rgb(28, 168, 221)',
  danger: 'rgb(230, 71, 89)',
  success: 'rgb(27, 201, 142)',
};

export const PIECE_CARDS_TOOLTIP_THRESHOLDS = {
  pieceName: { threshold: 13 },
  workstationName: { threshold: 17 },
  materialName: { threshold: 28 },
  orderName: { threshold: 17 },
};

export const SECURE_FILE_CHECKOUT_STATUSES_MAP = {
  [SECURE_FILE_CHECKOUT_STATUSES.COMPLETED]: 'Completed',
  [SECURE_FILE_CHECKOUT_STATUSES.ERROR]: 'Error',
  [SECURE_FILE_CHECKOUT_STATUSES.FAILED]: 'Failed',
  [SECURE_FILE_CHECKOUT_STATUSES.CHECKING_OUT]: 'Checking Out',
  [SECURE_FILE_CHECKOUT_STATUSES.CHECKED_OUT]: 'Checked Out',
  [SECURE_FILE_CHECKOUT_STATUSES.PROCESSING]: 'Processing',
  [SECURE_FILE_CHECKOUT_STATUSES.PROCESSED]: 'Processed',
  [SECURE_FILE_CHECKOUT_STATUSES.CHECKING_IN]: 'Checking In',
  [SECURE_FILE_CHECKOUT_STATUSES.CORRECTED]: 'Corrected',
};

export const SECURE_FILE_CHECKOUT_STATUS_COLOR_CODE_MAPPING = {
  [SECURE_FILE_CHECKOUT_STATUSES.COMPLETED]: COLORS.GREEN,
  [SECURE_FILE_CHECKOUT_STATUSES.ERROR]: COLORS.RED,
  [SECURE_FILE_CHECKOUT_STATUSES.FAILED]: COLORS.RED,
  [SECURE_FILE_CHECKOUT_STATUSES.CHECKING_OUT]: COLORS.YELLOW,
  [SECURE_FILE_CHECKOUT_STATUSES.CHECKED_OUT]: COLORS.YELLOW,
  [SECURE_FILE_CHECKOUT_STATUSES.PROCESSING]: COLORS.YELLOW,
  [SECURE_FILE_CHECKOUT_STATUSES.PROCESSED]: COLORS.YELLOW,
  [SECURE_FILE_CHECKOUT_STATUSES.CHECKING_IN]: COLORS.YELLOW,
  [SECURE_FILE_CHECKOUT_STATUSES.CORRECTED]: COLORS.YELLOW,
};

export const PRINTER_TYPE_SECTION_FIELDS_LIST = {
  general: ['name', 'description', 'manufacturer', 'materials'],
  buildPacking: [
    'build_packer_type',
    'build_volume',
    'min_packing_distance',
    'time_before_print',
    'time_after_print',
  ],
  costPricing: ['running_cost_per_hour'],
};

export const MATERIAL_TYPE_SECTION_FIELDS_LIST = {
  general: ['name', 'description', 'manufacturer'],
  typesCost: ['type', 'cost', 'density', 'units'],
};

export const POST_PROCESSOR_TYPE_SECTION_FIELDS_LIST = {
  general: [
    'name',
    'description',
    'manufacturer',
    'materials',
    'build_volume',
    'in_progress_max',
  ],
  costDuration: ['cost'],
};

export const CREATE_RUN_STEPPER_PROGRESS_STATUS_MAPPING = {
  [CREATE_RUN_PROGRESS_STATUSES.PENDING]: 'Preparation',
  [CREATE_RUN_PROGRESS_STATUSES.PROCESSING]: 'Packing',
  [CREATE_RUN_PROGRESS_STATUSES.FINISHING]: 'Completed',
};

export const PIECE_GROUPS_MAP = {
  [PIECE_GROUPS_FILTER.ALL]: {
    id: 'pieceGroups.all',
    defaultMessage: 'All Pieces',
  },
  [PIECE_GROUPS_FILTER.ORIGINAL_PIECES_ONLY]: {
    id: 'pieceGroups.originalPiecesOnly',
    defaultMessage: 'Original Pieces Only',
  },
  [PIECE_GROUPS_FILTER.PRODUCTION_CHANGED_PIECES]: {
    id: 'pieceGroups.productionChangedPieces',
    defaultMessage: 'Production Changed Pieces',
  },
};
