import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import * as Selectors from 'rapidfab/selectors';
import CONFIG from 'rapidfab/config';
import Alert from 'rapidfab/utils/alert';
import { FormattedMessage } from 'react-intl';
import OrderReport from '../components/OrderReport';

const OrderReportContainer = props => {
  const isUserRestricted = useSelector(Selectors.isCurrentUserRestricted);

  const [start, setStart] = useState('');
  const [end, setEnd] = useState('');
  const [show, setShow] = useState(false);

  const handleChange = event => {
    const { name, value } = event.target;
    switch (name) {
      case 'start':
        setStart(value);
        break;
      case 'end':
        setEnd(value);
        break;
      default:
        break;
    }
  };

  const handleHide = () => {
    setStart('');
    setEnd('');
    setShow(false);
  };

  const handleShow = () => setShow(true);

  const downloadData = () => {
    const startDate = dayjs(start).hour(0).minute(0).second(0)
      .format('YYYY-MM-DDTHH:mm:ss');
    const endDate = dayjs(end).hour(23).minute(59).second(59)
      .format('YYYY-MM-DDTHH:mm:ss');

    if (dayjs(start).isAfter(dayjs(end))) {
      Alert.error(
        <FormattedMessage
          id="toaster.error.date.enterValidDate"
          defaultMessage="Please enter a valid date"
        />);
      return;
    }

    window.location.href = `${CONFIG.HOST.NAUTILUS}/order-report/?start=${startDate}&end=${endDate}`;
  };

  return (
    <OrderReport
      {...props}
      isUserRestricted={isUserRestricted}
      end={end}
      handleChange={handleChange}
      handleHide={handleHide}
      handleShow={handleShow}
      downloadData={downloadData}
      show={show}
      start={start}
    />
  );
};

export default OrderReportContainer;
