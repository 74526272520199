import React from 'react';
import PropTypes from 'prop-types';

import { Nav, NavItem, NavLink } from 'react-bootstrap';
import { FEATURES, ROUTES } from 'rapidfab/constants';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import Feature from 'rapidfab/components/Feature';
import { connect } from 'react-redux';
import { isFeatureEnabled } from 'rapidfab/selectors';

export const WORKFLOW_TABS = {
  PRODUCTION_WORKFLOWS: 'production-workflows',
  PREP_WORKFLOWS: 'prep-workflows',
  ASSEMBLY_WORKFLOWS: 'assembly-workflows',
};

const WorkflowTabs = ({ activeKey, isPrepWorkflowFeatureEnabled, isAnatomicalModelToProductFeatureEnabled }) => (
  <Feature featureNames={[FEATURES.PREP_WORKFLOW, FEATURES.ANATOMICAL_MODEL_TO_PRODUCT]}>
    <Nav
      variant="tabs"
      activeKey={activeKey}
      // `undefined` is needed to allow `href` in NavItem-s
      onSelect={undefined}
      id="commentsTabs"
      className="gap-1"
    >
      <NavItem>
        <NavLink eventKey={WORKFLOW_TABS.PRODUCTION_WORKFLOWS} href={getRouteURI(ROUTES.PRODUCTION_WORKFLOW_LIST)}>
          Production Workflows
        </NavLink>
      </NavItem>
      {isPrepWorkflowFeatureEnabled && (
        <NavItem>
          <NavLink eventKey={WORKFLOW_TABS.PREP_WORKFLOWS} href={getRouteURI(ROUTES.PREP_WORKFLOW_LIST)}>
            Prep Workflows
          </NavLink>
        </NavItem>
      )}
      {
        // <Feature ...> can NOT be used here, since `Nav` expects `NavItems` as children
        // `Feature` component has no props like `eventKey` etc.
        isAnatomicalModelToProductFeatureEnabled
        && (
          <NavItem>
            <NavLink eventKey={WORKFLOW_TABS.ASSEMBLY_WORKFLOWS} href={getRouteURI(ROUTES.ASSEMBLY_WORKFLOW_LIST)}>
              Sub-Assembly Workflows
            </NavLink>
          </NavItem>
        )
      }
    </Nav>
  </Feature>
);

WorkflowTabs.propTypes = {
  activeKey: PropTypes.oneOf(Object.values(WORKFLOW_TABS)).isRequired,
  isPrepWorkflowFeatureEnabled: PropTypes.bool.isRequired,
  isAnatomicalModelToProductFeatureEnabled: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  isPrepWorkflowFeatureEnabled: isFeatureEnabled(state, FEATURES.PREP_WORKFLOW),
  isAnatomicalModelToProductFeatureEnabled: isFeatureEnabled(state, FEATURES.ANATOMICAL_MODEL_TO_PRODUCT),
});

export default connect(mapStateToProps)(WorkflowTabs);
