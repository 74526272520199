export const FILE_EXTENSIONS = {
  ABP: 'abp',
  AMF: 'amf',
  ADM: 'adm',
  BREP: 'brep',
  CARBON: 'carbon',
  CATPART: 'catpart',
  CMB: 'cmb',
  CLO: 'clo',
  CLS: 'cls',
  CPS: 'cps',
  CRS: 'crs',
  CSV: 'csv',
  CXPROJ: 'CXProj',
  DAE: 'dae',
  DOC: 'doc',
  DOCX: 'docx',
  DWG: 'dwg',
  DXF: 'dxf',
  EOSJOB: 'eosjob',
  FORM: 'form',
  F3D: 'f3d',
  GCODE: 'gcode',
  GIF: 'gif',
  IAM: 'iam',
  IGES: 'iges',
  IGS: 'igs',
  IPT: 'ipt',
  JPEG: 'jpeg',
  JPG: 'jpg',
  JT: 'jt',
  J_T: 'j_t',
  MAGICS: 'magics',
  MFP: 'mfp',
  MP3: 'mp3',
  MPF: 'mpf',
  MTT: 'mtt',
  NC: 'nc',
  OBJ: 'obj',
  OPENJZ: 'openjz',
  PDF: 'pdf',
  PLY: 'ply',
  PNG: 'png',
  PPT: 'ppt',
  PPTX: 'pptx',
  PRINT: 'print',
  PRT: 'prt',
  SAT: 'sat',
  SLDPRT: 'sldprt',
  SLI: 'sli',
  SLM: 'slm',
  STEP: 'step',
  STL: 'stl',
  STP: 'stp',
  SVG: 'svg',
  THREE_DM: '3dm',
  THREE_MF: '3mf',
  TIF: 'tif',
  TIFF: 'tiff',
  TXT: 'txt',
  UFP: 'ufp',
  WRL: 'wrl',
  XML: 'xml',
  XLS: 'xls',
  XLSM: 'xlsm',
  XLSX: 'xlsx',
  X_T: 'x_t',
  XT: 'xt',
  ZAG: 'zag',
  ZIP: 'zip',
  MSG: 'msg',
};
export const RUN_MODEL_FILE_EXTENSIONS = [FILE_EXTENSIONS.STL, FILE_EXTENSIONS.THREE_MF];

export const ALLOWED_DOCUMENT_EXTENSIONS = [
  FILE_EXTENSIONS.ADM,
  FILE_EXTENSIONS.DOC,
  FILE_EXTENSIONS.DOCX,
  FILE_EXTENSIONS.PDF,
  FILE_EXTENSIONS.STL,
  FILE_EXTENSIONS.MP3,
  FILE_EXTENSIONS.DXF,
  FILE_EXTENSIONS.CSV,
  FILE_EXTENSIONS.XLS,
  FILE_EXTENSIONS.XLSX,
  FILE_EXTENSIONS.JPEG,
  FILE_EXTENSIONS.PNG,
  FILE_EXTENSIONS.OBJ,
  FILE_EXTENSIONS.STP,
  FILE_EXTENSIONS.STEP,
  FILE_EXTENSIONS.THREE_MF,
  FILE_EXTENSIONS.DAE,
  FILE_EXTENSIONS.PLY,
  FILE_EXTENSIONS.IGS,
  FILE_EXTENSIONS.BREP,
  FILE_EXTENSIONS.CATPART,
  FILE_EXTENSIONS.CMB,
  FILE_EXTENSIONS.CARBON,
  FILE_EXTENSIONS.FORM,
  FILE_EXTENSIONS.GCODE,
  FILE_EXTENSIONS.MFP,
  FILE_EXTENSIONS.PRINT,
  FILE_EXTENSIONS.PPT,
  FILE_EXTENSIONS.PPTX,
  FILE_EXTENSIONS.PRT,
  FILE_EXTENSIONS.SLDPRT,
  FILE_EXTENSIONS.MPF,
  FILE_EXTENSIONS.JPG,
  FILE_EXTENSIONS.MAGICS,
  FILE_EXTENSIONS.MTT,
  FILE_EXTENSIONS.EOSJOB,
  FILE_EXTENSIONS.SLI,
  FILE_EXTENSIONS.GIF,
  FILE_EXTENSIONS.DWG,
  FILE_EXTENSIONS.NC,
  FILE_EXTENSIONS.CPS,
  FILE_EXTENSIONS.F3D,
  FILE_EXTENSIONS.SVG,
  FILE_EXTENSIONS.AMF,
  FILE_EXTENSIONS.CRS,
  FILE_EXTENSIONS.WRL,
  FILE_EXTENSIONS.OPENJZ,
  FILE_EXTENSIONS.SLM,
  FILE_EXTENSIONS.ABP,
  FILE_EXTENSIONS.XML,
  FILE_EXTENSIONS.CXPROJ,
  FILE_EXTENSIONS.ZAG,
  FILE_EXTENSIONS.XLSM,
  FILE_EXTENSIONS.CLO,
  FILE_EXTENSIONS.CLS,
  FILE_EXTENSIONS.UFP,
  FILE_EXTENSIONS.MSG,
];

export const ALLOWED_MODEL_EXTENSIONS = [FILE_EXTENSIONS.STL];

export const NATIVE_CAD_CONVERSION_EXTENSIONS = [
  FILE_EXTENSIONS.X_T,
  FILE_EXTENSIONS.XT,
  FILE_EXTENSIONS.SAT,
  FILE_EXTENSIONS.STEP,
  FILE_EXTENSIONS.STP,
  FILE_EXTENSIONS.IGS,
  FILE_EXTENSIONS.IGES,
  FILE_EXTENSIONS.SLDPRT,
  FILE_EXTENSIONS.IPT,
  FILE_EXTENSIONS.PRT,
  FILE_EXTENSIONS.CATPART,
  FILE_EXTENSIONS.JT,
  FILE_EXTENSIONS.J_T,
  FILE_EXTENSIONS.THREE_DM,
  FILE_EXTENSIONS.OBJ,
  FILE_EXTENSIONS.IAM,
  FILE_EXTENSIONS.DAE,
];
export const ZVERSE_CONVERSION_EXTENSIONS = [
  FILE_EXTENSIONS.TIF,
  FILE_EXTENSIONS.TIFF,
  FILE_EXTENSIONS.PDF,
  FILE_EXTENSIONS.PNG,
];
export const BASIC_CONVERSION_EXTENSIONS = [
  FILE_EXTENSIONS.STP,
  FILE_EXTENSIONS.STEP,
];
export const EXPERT_CONVERSION_EXTENSIONS = [
  FILE_EXTENSIONS.TIF,
  FILE_EXTENSIONS.TIFF,
  FILE_EXTENSIONS.PDF,
  FILE_EXTENSIONS.PNG,
];

export const CAROUSEL_ALLOWED_EXTENSIONS = [
  FILE_EXTENSIONS.JPEG,
  FILE_EXTENSIONS.JPG,
  FILE_EXTENSIONS.PNG,
  FILE_EXTENSIONS.TIF,
  FILE_EXTENSIONS.TIFF,
  FILE_EXTENSIONS.PDF,
];

export const MIME_TYPES = {
  OCTET_STREAM: 'application/octet-stream',
  JSON: 'application/json',
  STL: 'application/sla',
};
