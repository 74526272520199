import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { productResourceType } from 'rapidfab/types';
import { Button, Card } from 'react-bootstrap';
import CancelOrDeleteModal from 'rapidfab/components/CancelOrDeleteModal';
import Alert from 'react-s-alert';
import { getShortUUID } from 'rapidfab/utils/uuidUtils';
import ConfirmationModal from 'rapidfab/components/ConfirmationModal';
import SmoothCollapse from 'react-smooth-collapse';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp, faClone, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FormattedMessage } from 'rapidfab/i18n';

const ProductWrapper = ({
  product,
  deleteProduct,
  children,
  refreshLineItems,
  setLineItems,
  onDuplicate,
}) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [isConfirmDeleteModalVisible, setIsConfirmDeleteModalVisible] = useState(false);
  const [confirmDuplicate, setConfirmDuplicate] = useState(false);

  const onProductDelete = async () => {
    await deleteProduct()
      .then(() => {
        Alert.success(
          <FormattedMessage
            id="toaster.product.deleted"
            defaultMessage="Product {productItemUuid} was deleted successfully."
            values={{ productItemUuid: product.uuid }}
          />,
        );
      }).then(() => {
        refreshLineItems().then(items => {
          if (!items.json?.line_items) {
            setLineItems([]);
          } else {
            setLineItems(items.json.line_items);
          }
        });
      });
  };

  const handleDuplicateProduct = () => {
    setConfirmDuplicate(false);
    onDuplicate();
  };

  return (
    <>
      <Card bg="dark" className="m-b">
        <Card.Header
          className="pd-exp inverse d-flex align-items-center justify-content-between position-sticky z-index-1000 w-100"
          style={{ top: 56 }}
        >
          {/* eslint-disable-next-line jsx-a11y/interactive-supports-focus */}
          <div
            role="button"
            id="card-header-clickable-container"
            onClick={() => setIsExpanded(!isExpanded)}
            className="d-flex align-items-center w-100"
          >
            <FontAwesomeIcon
              icon={isExpanded ? faAngleUp : faAngleDown}
              size="2x"
              style={{ lineHeight: '20px' }}
            />
            <span className="mx-2">
              Product ({getShortUUID(product.uuid)})
            </span>
          </div>
          <div
            tabIndex={0}
            role="button"
            onClick={event => { event.stopPropagation(); }}
            className="d-flex align-items-center"
          >
            <Button
              className="me-1"
              variant="primary"
              size="xs"
              title="Duplicate"
              onClick={() => setConfirmDuplicate(true)}
            >
              <FontAwesomeIcon icon={faClone} />
            </Button>
            <Button
              variant="danger"
              size="xs"
              title="Delete"
              onClick={() => setIsConfirmDeleteModalVisible(true)}
            >
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          </div>
        </Card.Header>
        <SmoothCollapse expanded={isExpanded}>
          <div className="card-body-wrapper">
            <Card.Body>
              {children}
            </Card.Body>
          </div>
        </SmoothCollapse>
      </Card>
      {isConfirmDeleteModalVisible && (
        <CancelOrDeleteModal
          modalType="delete"
          handleConfirm={onProductDelete}
          handleOpen={() => {
            // handleOpen is used only to close modal `handleOpen('')`
            setIsConfirmDeleteModalVisible(false);
          }}
        />
      )}
      {
        confirmDuplicate && (
          <ConfirmationModal
            handleCancel={() => setConfirmDuplicate(false)}
            handleConfirm={() => handleDuplicateProduct()}
            confirmButtonText="Duplicate"
            message="Are you sure you want to duplicate?"
          />
        )
      }
    </>
  );
};

ProductWrapper.propTypes = {
  product: productResourceType.isRequired,
  deleteProduct: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  refreshLineItems: PropTypes.func.isRequired,
  setLineItems: PropTypes.func.isRequired,
  onDuplicate: PropTypes.func.isRequired,
};

export default ProductWrapper;
