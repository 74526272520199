import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  getDesignFilesForProduct,
  getLineItemsForProduct, getModelsForDesignFile,
  getUUIDResource,
} from 'rapidfab/selectors';
import Product from 'rapidfab/components/Product';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import { API_RESOURCES } from 'rapidfab/constants';
import Loading from 'rapidfab/components/Loading';
import Actions from 'rapidfab/actions';
import { designFileResourceType, modelResourceType } from 'rapidfab/types';
import { RenderIfVisible } from 'rapidfab/utils/intersectionAPI/intersectionHOC';
import usePrevious from 'rapidfab/hooks';

const ProductContainer = ({ fetching, anyProductDetailsFetching, ...props }) => {
  /* We need to have "Initially Fetched" state in order to avoid showing the "Spinner"
     when the user is duplicating/adding/removing the Product/Line Item, but we also need to
     avoid showing the Spinner if we are cloning the Order or initially fetching it,
     so we will have 2 different states of "fetching" to handle this.
   */
  const { setInitialFetched } = props.initialFetchedState;
  const previousFetching = usePrevious(anyProductDetailsFetching);
  useEffect(() => {
    if (previousFetching !== undefined && previousFetching && !anyProductDetailsFetching) {
      setInitialFetched(true);
    }
  }, [anyProductDetailsFetching, previousFetching]);

  if (fetching) {
    return <Loading className="m-b" />;
  }
  return (
    <RenderIfVisible initialVisible keepState>
      <Product {...props} />
    </RenderIfVisible>
  );
};

ProductContainer.propTypes = {
  fetching: PropTypes.bool.isRequired,
  productUri: PropTypes.string.isRequired,
  readOnlyLineItemsOrCallback: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  designFile: designFileResourceType,
  modelsForDesignFile: PropTypes.arrayOf(modelResourceType).isRequired,
  initialFetchedState: PropTypes.shape({
    initialFetched: PropTypes.bool.isRequired,
    setInitialFetched: PropTypes.func.isRequired,
  }).isRequired,
  anyProductDetailsFetching: PropTypes.bool.isRequired,
  productIndex: PropTypes.number.isRequired,

};

ProductContainer.defaultProps = {
  readOnlyLineItemsOrCallback: false,
  designFile: null,
};

const mapStateToProps = (state, { productUri, initialFetchedState }) => {
  const { initialFetched } = initialFetchedState;
  const product = getUUIDResource(state, extractUuid(productUri));
  const designFiles = getDesignFilesForProduct(state, product);
  // In the current implementation, product will have only 1 design file
  const designFile = designFiles[0];
  const modelsForDesignFile = getModelsForDesignFile(state, designFile);

  // TODO: We might need to use `modelLibrary` later (if it was selected on Product creation)
  //  in order to pre-fill Line Item creation form from Model Library data
  const modelLibrary = product.model_library && getUUIDResource(state, extractUuid(product.model_library));
  const modelLibraryModel =
    modelLibrary && modelLibrary.additive?.model && getUUIDResource(state, extractUuid(modelLibrary.additive.model));

  const lineItems = getLineItemsForProduct(state, product);

  /* When any product related data API request is in progress ->
   * Taking into account in order to load the resources for Line Item,
   * we also need to fetch the rest of the resources related to it,
   * it is better to show the "Spinner" until everything is loaded for the
   * particular Line Item or Product. */

  const anyProductDetailsFetching = state.ui.nautilus[API_RESOURCES.DESIGN_FILE].list.fetching
    || state.ui.nautilus[API_RESOURCES.LINE_ITEM].list.fetching
    || state.ui.nautilus[API_RESOURCES.PIECE].list.fetching
    || state.ui.nautilus[API_RESOURCES.PRINT].list.fetching
    || state.ui.nautilus[API_RESOURCES.MODEL].list.fetching
    || state.ui.nautilus[API_RESOURCES.RUN].list.fetching
    || state.ui.nautilus[API_RESOURCES.PRODUCT].list.fetching
    || state.ui.nautilus[API_RESOURCES.SHIPMENT].list.fetching
    || state.ui.nautilus[API_RESOURCES.PRINTER].list.fetching
    || state.ui.nautilus[API_RESOURCES.PROCESS_STEP].list.fetching
    || state.ui.nautilus[API_RESOURCES.MODEL].get.fetching
    || state.ui.nautilus[API_RESOURCES.MODEL_LIBRARY].list.fetching;

  const fetching =
    (anyProductDetailsFetching && (
    // and current product has no line items loaded
      !lineItems.length
        // and no design file (or no models for design file)
        && (!designFile || !modelsForDesignFile.length)
        // and no model library loaded (or no model for model library)
        && (!modelLibraryModel || !modelLibraryModel))
    );

  const initialFetching = (anyProductDetailsFetching && !lineItems.length);

  return {
    fetching: !initialFetched ? initialFetching : fetching,
    product,
    lineItems,
    designFile,
    modelsForDesignFile,
    modelLibraryModel,
    anyProductDetailsFetching,
  };
};

const mapDispatchToProps = (dispatch, { productUri }) => ({
  deleteProduct: () => dispatch(Actions.Api.nautilus[API_RESOURCES.PRODUCT].delete(extractUuid(productUri))),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductContainer);
