import { fileNameHasPotentialNullByte } from 'rapidfab/utils/fileUtils';
import React, { useCallback, useEffect, useMemo, useState, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Button, Form as BsForm } from 'react-bootstrap';
import { extractUuid } from 'rapidfab/reducers/makeApiReducers';
import Feature from 'rapidfab/components/Feature';
import ModalThreeScene, { SNAPSHOT_STATE } from 'rapidfab/components/ModalThreeScene';
import NetfabbImportExport from 'rapidfab/components/NetfabbImportExport';
import ForgeAuth from 'rapidfab/components/ForgeAuth';
import LineItemEditFormContainer from 'rapidfab/containers/records/order/LineItemEditFormContainer';
import VisibleFor from 'rapidfab/components/VisibleFor';
import * as Selectors from 'rapidfab/selectors';
import { getStateForgeToken, getStateModelRotation } from 'rapidfab/selectors/baseStateSelectors';
import Config from 'rapidfab/config';
import Documents from 'rapidfab/components/records/Documents';
import {
  API_RESOURCES,
  DOCUMENT_RELATED_TABLE_NAMES,
  FEATURES,
  LINE_ITEM_COMPOSITION_TYPES,
  MODEL_UNITS,
  TEXT_COLOR_CONTRAST,
  USER_HIDE_INFO_TYPES,
  UPLOAD_LINE_ITEMS_MAX_LIMIT, LINE_ITEM_STATUS, PAGINATION_IGNORE_DEFAULT_LIMIT,
} from 'rapidfab/constants';
import _keyBy from 'lodash/keyBy';

import { getPiecesForLineItem } from 'rapidfab/selectors';
import ServiceProviderJobListContainer from 'rapidfab/containers/records/order/ServiceProviderJobListContainer';
import LineItemPiecesContainer from 'rapidfab/containers/records/order/LineItemPiecesContainer';
import LineItemQuoteContainer from 'rapidfab/containers/records/order/LineItemQuoteContainer';
import LineItemPrepWorkflowPanelContainer from 'rapidfab/containers/records/order/LineItemPrepWorkflowPanelContainer';
import EstimatesAndActuals from 'rapidfab/components/records/order/edit/EstimatesAndActuals';
import LineItemCustomPricing from 'rapidfab/components/records/order/edit/restricted/LineItemCustomPricing';
import SingleMeshLayout from 'rapidfab/components/records/order/edit/LineItem/SingleMeshLayout';
import AssemblyLayout from 'rapidfab/components/records/order/edit/LineItem/AssemblyLayout';
import CoPrintLayout from 'rapidfab/components/records/order/edit/LineItem/CoPrintLayout';
import AssemblyPartLayout from 'rapidfab/components/records/order/edit/LineItem/AssemblyPartLayout';
import CoPrintPartsContainer from 'rapidfab/containers/records/order/CoPrintPartsContainer';
import LineItemFileDetails from 'rapidfab/components/records/order/edit/LineItemFileDetails';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { capitalize } from 'lodash/string';
import Alert from 'react-s-alert';
import {
  LINE_ITEM_STATUS_COLOR_CODE_MAPPING,
  WORKFLOW_TYPES_ESTIMATES_KEY_MAP,
} from 'rapidfab/mappings';
import Actions from 'rapidfab/actions';
import CancelOrDeleteModal from 'rapidfab/components/CancelOrDeleteModal';
import useOnScreen from 'rapidfab/hooks/useComponentOnScreen';
import DebugModeDataPanel from 'rapidfab/components/DebugMode/DebugModeDataPanel';
import { FormattedMessage } from 'rapidfab/i18n';
import { getLineItemWorkflowTypeObjectKey, getSnapshotFromLineItem } from 'rapidfab/utils/lineItemUtils';
import { faAngleDown, faAngleUp, faClone, faTrash, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import RCTooltip from 'rc-tooltip';
import { useLocation } from 'react-router-dom';
import useScrollToComponent from 'rapidfab/hooks/useScrollToComponent';
import { designFileResourceType } from 'rapidfab/types';
import { BulkLineItemActionContext } from 'rapidfab/context/BulkLineItemActionContext';
import { getShortUUID } from 'rapidfab/utils/uuidUtils';
import AssignedUserBadge from 'rapidfab/components/AssignedUserBadge';
import BREAKPOINTS from 'rapidfab/constants/deviceSizes';

const getModelContentFromLineItem = (lineItem, model) => {
  if (!lineItem || !model) {
    return 'LOADING';
  }

  if (lineItem[getLineItemWorkflowTypeObjectKey(lineItem)].no_model_upload) {
    return 'NO_MODEL_UPLOAD';
  }

  const modelContent = model?.content;
  // the default must return 'loading' due to imperfect information from the
  // event stream. E.g: The UI can receive a model that is 'processed' but
  // without a snapshot

  if (modelContent) {
    return modelContent;
  }

  if (lineItem.status === 'error') {
    return 'ERROR';
  }

  return 'LOADING';
};

const LineItem = props => {
  const {
    readOnly,
    expandMode,
    setExpandedItems,
    setExpandMode,
    expandedItems,
    productIndex,
    designFile,
  } = props;
  const dispatch = useDispatch();
  const displayWarningStyling = productIndex > UPLOAD_LINE_ITEMS_MAX_LIMIT;
  const invalidDesignFile = designFile?.status === 'error';
  const [isWorkflowChanged, setIsWorkflowChanged] = useState(false);
  const [modelUpload, setModelUpload] = useState(null);
  const [fileDetailsEditMode, setFileDetailsEditMode] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [modalType, setModalType] = useState('');
  const [noWorkflowLineItem, setNoWorkflowLineItem] = useState(null);
  const currency = useSelector(Selectors.getBureauDefaultCurrency);
  const lineItem = useSelector(state => Selectors.getUUIDResource(state, extractUuid(props.uri)));
  const workflowTypeKey = getLineItemWorkflowTypeObjectKey(lineItem);
  const workflowTypeEstimatesKey = WORKFLOW_TYPES_ESTIMATES_KEY_MAP[lineItem?.workflow_type];
  const pieces = useSelector(state => getPiecesForLineItem(state, lineItem));
  const piecesWithModifiedWorkflowByWorkflowURI = _keyBy(pieces?.filter(piece => piece?.workflow !== lineItem?.workflow), 'workflow');
  const { handleMarkLineItemForBulkAction,
    isBulkActionLineItemMarked } = useContext(BulkLineItemActionContext) || {};

  const order = useSelector(
    state =>
      (lineItem?.order ? Selectors.getUUIDResource(state, extractUuid(lineItem?.order)) : null),
  );
  const models = useSelector(Selectors.getModels);
  const uploadData = useSelector(Selectors.getUploadModel);
  const isRestrictedUser = useSelector(Selectors.isCurrentUserRestricted);
  const role = useSelector(Selectors.getCurrentUserRoleMax);
  const forgeToken = useSelector(state => getStateForgeToken(state)[0]);
  const lineItems = useSelector(state => Selectors.getLineItemsForOrderSortedByCreatedDesc(state, order));
  const customLineItemFieldReferences = useSelector(Selectors.getCustomLineItemFieldReferences);
  const rotation = useSelector(
    state => getStateModelRotation(state)[extractUuid(props.uri)] ?? state.modelRotation.rotation,
  );
  const isDebugModeEnabled = useSelector(Selectors.getIsDebugModeEnabled);

  const model = useMemo(
    () => (lineItem ? models.find(m => m.uri === lineItem[workflowTypeKey]?.model) : null),
    [lineItem, models],
  );
  const snapshot = useMemo(() => getSnapshotFromLineItem(lineItem, model), [lineItem, model]);
  const modelContent = useMemo(() => getModelContentFromLineItem(lineItem, model), [lineItem, model]);
  const unit = useMemo(() => (model?.user_unit) || MODEL_UNITS.MM, [model]);

  const redirect = encodeURIComponent(window.location.href);
  const forgeRedirectUrl = `${Config.HOST.NAUTILUS}/oauth/authorize/autodesk-forge/?redirect=${redirect}`;

  const index = useMemo(
    () => lineItems.findIndex(l => l.uri === lineItem?.uri),
    [lineItems, lineItem?.uri],
  );

  const assemblyPartMetaByAssemblyMetaUri = useSelector(Selectors.getAssemblyPartMetaByAssemblyMetaUri);
  const assemblyMeta = useSelector(state => Selectors.getAssemblyMetaForLineItem(state, lineItem));

  const location = useLocation();
  const [scrollRef, setScrollToIncomplete] = useScrollToComponent();

  const [lineItemViewUri, setLineItemViewUri] = useState(lineItem?.uri);

  const hideFinancial = role?.hide_info === USER_HIDE_INFO_TYPES.FINANCIAL;

  useEffect(() => {
    setIsWorkflowChanged(false);
  }, [lineItem?.workflow]);

  useEffect(() => {
    if (!isRestrictedUser && !expandedItems?.length && expandMode === 'expanded') {
      setExpandMode('collapsed');
    }
  }, [isRestrictedUser, expandMode, expandedItems?.length]);

  useEffect(() => {
    if (expandMode === 'expanded') {
      return setExpanded(true);
    }
    if (expandMode === 'collapsed') {
      return setExpanded(false);
    }

    if (
      !isRestrictedUser && expandedItems
      && !expandedItems?.length
      && !expandMode && (index < 1 || isRestrictedUser)) {
      setExpandedItems(expandedItems => [...expandedItems, index]);
    }

    return setExpanded(index < 1 || isRestrictedUser);
  }, [expandMode, index, isRestrictedUser]);

  useEffect(() => {
    if (location.state?.highlightNoWorkflowLineItemUri) {
      setNoWorkflowLineItem(location.state.highlightNoWorkflowLineItemUri);
      const targetIndex = lineItems.findIndex(lineItem =>
        lineItem.uri === location.state.highlightNoWorkflowLineItemUri);
      setExpanded(index === targetIndex);
      setScrollToIncomplete(true);
    }
  }, [JSON.stringify(location.state)]);

  useEffect(() => {
    setFileDetailsEditMode(!!modelUpload);
  }, [modelUpload]);

  const lineItemFileDetailsProps = {
    currency,
    lineItem,
    model,
    uploadData,
    snapshot,
    unit,
    modelContent,
    forgeToken,
    forgeRedirectUrl,
    customLineItemFieldReferences,
    assemblyPartMetaByAssemblyMetaUri,
    assemblyMeta,
    hideFinancial,
    rotation,
    uuid: extractUuid(props.uri),
    index,
    workflowTypeKey,
  };

  const lazyLoadRef = useRef();
  const lazyLoadComponentDidAppearOnScreen = useOnScreen(lazyLoadRef);

  const lineItemFileDetailsPropsMemo = useMemo(
    () => lineItemFileDetailsProps,
    [JSON.stringify(lineItemFileDetailsProps)],
  );

  const handleFileChange = useCallback(event => {
    const modelUpload = event.target.files[0];

    if (fileNameHasPotentialNullByte(modelUpload.name)) {
      Alert.error('The filename does not meet the requirements. Please rename the file and try again.');
      return;
    }

    if (modelUpload) {
      setModelUpload(modelUpload);
    }
  }, []);

  const handleFileRemove = useCallback(() => {
    setModelUpload(null);
  }, []);

  const onSubmitComplete = useCallback(() => {
    setModelUpload(null);
    setFileDetailsEditMode(false);
  }, []);

  const onWorkflowChange = useCallback(selectedWorkflow => {
    const isWorkflowChanged = lineItem.workflow !== selectedWorkflow;
    setIsWorkflowChanged(isWorkflowChanged);
    dispatch(
      Actions.Api.nautilus[API_RESOURCES.PROCESS_STEP].list(
        { workflows: selectedWorkflow }, {}, {}, {}, true,
      ));
  }, [lineItem?.workflow]);

  const onDuplicate = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await dispatch(
        Actions.Api.nautilus[API_RESOURCES.LINE_ITEM].clone(lineItem.uuid, { quantity: lineItem.quantity }),
      );
      const { location } = response.headers;
      dispatch(Actions.Api.nautilus[API_RESOURCES.ORDER].get(extractUuid(lineItem.order)));
      dispatch(Actions.Api.nautilus[API_RESOURCES.PRODUCT].list(
        { order: lineItem.order }, { limit: PAGINATION_IGNORE_DEFAULT_LIMIT }, {}, {}, true),
      );
      dispatch(Actions.Api.nautilus[API_RESOURCES.LINE_ITEM].get(extractUuid(location)));

      Alert.success(
        <FormattedMessage
          id="toaster.lineItem.duplicated"
          defaultMessage="Line item {lineItemUuid} was duplicated successfully."
          values={{ lineItemUuid: lineItem.uuid }}
        />,
      );
    } finally {
      setIsLoading(false);
    }
  }, [dispatch, lineItem?.uuid, lineItem?.quantity]);

  const onDelete = useCallback(async () => {
    setIsLoading(true);
    try {
      await dispatch(Actions.Api.nautilus[API_RESOURCES.PRODUCT].delete(extractUuid(lineItem.product)))
        .then(() => dispatch(Actions.Api.nautilus[API_RESOURCES.LINE_ITEM].remove(extractUuid(lineItem.uri))));
    } catch (error) {
      Alert.error(error.message);
    }
    setIsLoading(false);
    Alert.success(
      <FormattedMessage
        id="toaster.lineItem.deleted"
        defaultMessage="Line item {lineItemUuid} was deleted successfully."
        values={{ lineItemUuid: lineItem.uuid }}
      />,
    );
  }, [dispatch, lineItem?.product, lineItem?.uri]);

  const onHandleConfirmModal = useCallback(async () => {
    switch (modalType) {
      case 'duplicate': {
        await onDuplicate();
        break;
      }
      case 'delete': {
        await onDelete();
        break;
      }
      default:
    }
  }, [modalType, onDuplicate, onDelete]);

  if (!lineItem) {
    return null;
  }

  // Don't show the line item while the model is uploading
  if (model?.uuid === modelUpload?.modelUuid && modelUpload?.uploading) {
    return null;
  }

  const {
    composition_type: compositionType,
    uri,
  } = lineItem;
  const {
    no_model_upload: noModelUpload,
  } = lineItem[workflowTypeKey] || {};

  const labelBgColor = LINE_ITEM_STATUS_COLOR_CODE_MAPPING[lineItem?.status];
  const labelTextColor = TEXT_COLOR_CONTRAST[labelBgColor];

  const isReadOnly = typeof readOnly === 'function' ? readOnly(lineItem) : readOnly;

  const getPrepWorkflowPanel = () => (
    <VisibleFor unrestricted>
      <Feature featureName={FEATURES.PREP_WORKFLOW}>
        <Col md={12} className="p-b-md">
          <LineItemPrepWorkflowPanelContainer lineItemUri={uri} />
        </Col>
      </Feature>
    </VisibleFor>
  );

  const getModalThreeScene = () => !noModelUpload && model && (
    <ModalThreeScene
      snapshot={invalidDesignFile ? SNAPSHOT_STATE.NO_SNAPSHOT : snapshot}
      model={modelContent}
      noModelUpload={noModelUpload}
      unit={unit}
      fileUnit={model?.file_unit}
      size={model?.size}
      rotation={rotation}
      originalRotation={model?.manufacturing_orientation}
      volume={model?.volume_mm}
      uuid={model?.uuid}
    />
  );

  const getNetfabbPanel = () => !noModelUpload && (
    <VisibleFor unrestricted>
      <Feature featureName={FEATURES.NETFABB}>
        {forgeToken ? (
          <NetfabbImportExport lineItemUri={uri} />
        ) : (
          <ForgeAuth redirectUrl={forgeRedirectUrl} />
        )}
      </Feature>
    </VisibleFor>
  );

  const getEstimatesAndActuals = () => !noModelUpload && (
    <VisibleFor unrestricted>
      <EstimatesAndActuals lineItemUri={uri} noEstimatesAvailable={invalidDesignFile} />
    </VisibleFor>
  );

  const getWorkstepQuoteDetailsPanel = () => (
    <VisibleFor unrestricted>
      <Feature
        featureNamesEnforceAll
        featureNames={[
          FEATURES.LINE_ITEM_WORK_STEP_QUOTE_DETAILS,
          FEATURES.ORDER_QUOTE,
        ]}
      >
        <LineItemQuoteContainer
          lineItemUri={lineItemViewUri}
          lineItems={lineItems}
          isWorkflowChanged={isWorkflowChanged}
          setLineItemViewUri={setLineItemViewUri}
        />
      </Feature>
    </VisibleFor>
  );

  const getServiceProvidersPanel = () => !noModelUpload && (
    <VisibleFor unrestricted>
      <Feature featureName={FEATURES.SERVICE_PROVIDERS}>
        <ServiceProviderJobListContainer
          lineItem={uri}
        />
      </Feature>
    </VisibleFor>
  );

  const getCustomPricingPanel = () => (
    <VisibleFor restricted>
      <Feature featureName={FEATURES.THREE_M_AQUA_ORDER_FIELDS}>
        <LineItemCustomPricing
          customFieldReferences={customLineItemFieldReferences}
          customFieldValues={lineItem.custom_field_values}
        />
      </Feature>
    </VisibleFor>
  );

  const getLineItemDetails = () => !noModelUpload && (
    <LineItemFileDetails
      {...props}
      {...lineItemFileDetailsPropsMemo}
      model={model}
      unit={unit}
      modelUpload={modelUpload}
      handleFileRemove={handleFileRemove}
      handleFileChange={handleFileChange}
      editMode={fileDetailsEditMode}
      setEditMode={setFileDetailsEditMode}
      readOnly={readOnly}
      onSubmitComplete={onSubmitComplete}
      designFile={designFile}
      setModelUpload={setModelUpload}
    />
  );

  const getLineItemEditForm = () => (
    <LineItemEditFormContainer
      currency={currency}
      lineItem={lineItem}
      formKey={lineItem.uri}
      readOnly={readOnly}
      onWorkflowChange={onWorkflowChange}
      modelUpload={modelUpload}
      onSubmitComplete={onSubmitComplete}
      handleFileChange={handleFileChange}
      handleFileRemove={handleFileRemove}
      componentDidAppearOnScreen={lazyLoadComponentDidAppearOnScreen}
      workflowTypeKey={workflowTypeKey}
      workflowTypeEstimatesKey={workflowTypeEstimatesKey}
      scrollRef={scrollRef}
      noWorkflowLineItem={noWorkflowLineItem}
    />
  );

  const getDocumentsPanel = () => (
    <Documents
      relatedTable={DOCUMENT_RELATED_TABLE_NAMES.LINE_ITEM}
      relatedUUID={extractUuid(lineItem.uri)}
      skipInitialize
    />
  );

  const getPiecesPanel = () => (
    <VisibleFor unrestricted>
      <LineItemPiecesContainer
        lineItem={lineItem}
        piecesModifiedWorkFlowList={piecesWithModifiedWorkflowByWorkflowURI}
      />
    </VisibleFor>
  );

  const getCoPrintParts = () => (
    <CoPrintPartsContainer coPrintUri={lineItem.uri} isReadOnly={isReadOnly} />
  );

  const getDebugPanel = () => (
    isDebugModeEnabled && (
      <DebugModeDataPanel style={{ marginBottom: 10 }} className="spacer-bottom" data={lineItem} />
    )
  );

  const getAssemblyParts = () => {
    const assemblyMetaUri = assemblyMeta && assemblyMeta.uri;
    const assemblyPartsMeta = assemblyPartMetaByAssemblyMetaUri[assemblyMetaUri] || [];
    return assemblyPartsMeta.map(({ part_line_item: partLineItemUri }) => (
      <LineItem
        key={partLineItemUri}
        formKey={partLineItemUri}
        uri={partLineItemUri}
        readOnly={readOnly}
        expandMode={expandMode}
        setExpandedItems={setExpandedItems}
        expandedItems={expandedItems}
        setExpandMode={setExpandMode}
        productIndex={productIndex}
      />
    ));
  };

  const getHeaderPanel = heading => (
    <>
      <div id="card-header-main-content" className="d-flex align-items-center w-100">
        {isBulkActionLineItemMarked && (
          <BsForm.Check
            id={`bulk-action-checkbox-${props.formKey}`}
            className="mr15"
            name={`bulk-action-checkbox-${props.formKey}`}
            checked={isBulkActionLineItemMarked(uri)}
            onChange={() => handleMarkLineItemForBulkAction(lineItem)}
            type="checkbox"
          />
        )}

        {/* eslint-disable-next-line jsx-a11y/interactive-supports-focus */}
        <div
          role="button"
          id="card-header-clickable-container"
          onClick={() => {
            setExpanded(previous => !previous);

            setExpandedItems(expanded => {
              if (!expanded.includes(index)) {
                return [...expanded, index];
              }
              return expanded.filter(item => item !== index);
            });
          }}
          className="d-flex align-items-center w-100"
        >
          <FontAwesomeIcon
            icon={expanded ? faAngleUp : faAngleDown}
            size="2x"
            style={{ lineHeight: '20px' }}
          />
          <span className="mx-2">
            {heading}
          </span>
          <span
            className="badge badge-sm"
            style={{
              backgroundColor: labelBgColor,
              color: labelTextColor,
              marginRight: '10px',
            }}
          >
            {capitalize(lineItem.status)}
          </span>
          <AssignedUserBadge assignedUser={lineItem.assigned_user} assignedTo="Line Item" breakpoint={BREAKPOINTS.DESKTOP} />
          {displayWarningStyling && (
            <>
              <RCTooltip
                placement="right"
                destroyTooltipOnHide
                overlayInnerStyle={{ padding: '10px', wordBreak: 'break-word' }}
                mouseLeaveDelay={0.4}
                overlay={(

                  <p>
                    This item is outside the max allowed number of line-items for an order. <br />
                    Please delete and create a new order for this item.
                  </p>

                )}
              >

                <FontAwesomeIcon icon={faTriangleExclamation} className="spacer-left" style={{ color: '#ffd500' }} />
              </RCTooltip>
            </>
          )}
        </div>
      </div>

      <div
        tabIndex={0}
        role="button"
        onClick={event => { event.stopPropagation(); }}
        className="d-flex align-items-center"
      >
        <Button
          className="me-1"
          variant="primary"
          size="xs"
          title="Duplicate"
          disabled={isLoading}
          onClick={() => setModalType('duplicate')}
        >
          <FontAwesomeIcon icon={faClone} />
        </Button>
        <Button
          variant="danger"
          size="xs"
          title="Delete"
          disabled={isLoading}
          onClick={() => {
            if (isRestrictedUser && ![LINE_ITEM_STATUS.NEW, LINE_ITEM_STATUS.PENDING].includes(lineItem.status)) {
              Alert.error(
                'One or more Line Items have been Confirmed. ' +
                'Please contact your Manufacturing Operations Team for assistance. ' +
                'You can add a comment to the Order',
              );
              return;
            }
            setModalType('delete');
          }}
        >
          <FontAwesomeIcon icon={faTrash} />
        </Button>
        {modalType && (
          <CancelOrDeleteModal
            modalType={modalType}
            handleConfirm={onHandleConfirmModal}
            handleOpen={setModalType}
          />
        )}
      </div>
    </>
  );

  switch (compositionType) {
    case LINE_ITEM_COMPOSITION_TYPES.SINGLE_MESH_PRODUCT:
      return (
        <SingleMeshLayout
          uri={uri}
          prepWorkflowPanel={getPrepWorkflowPanel()}
          modalThreeScene={getModalThreeScene()}
          netfabbPanel={getNetfabbPanel()}
          estimatesAndActuals={getEstimatesAndActuals()}
          workstepQuoteDetailsPanel={getWorkstepQuoteDetailsPanel()}
          serviceProvidersPanel={getServiceProvidersPanel()}
          customPricingPanel={getCustomPricingPanel()}
          lineItemDetails={getLineItemDetails()}
          lineItemEditForm={getLineItemEditForm()}
          documentsPanel={getDocumentsPanel()}
          piecesPanel={getPiecesPanel()}
          assemblyPartsPanel={getAssemblyParts()}
          headerPanel={getHeaderPanel(
            `Line Item of ${lineItem.name} (${props.productIndex}) - (${lineItem.id})`,
          )}
          expanded={expanded}
          displayWarningStyling={displayWarningStyling}
          hideFinancial={hideFinancial}
          lazyLoadRef={lazyLoadRef}
          debugPanel={getDebugPanel()}
        />
      );
    case LINE_ITEM_COMPOSITION_TYPES.ASSEMBLY:
      return (
        <AssemblyLayout
          uri={uri}
          prepWorkflowPanel={getPrepWorkflowPanel()}
          estimatesAndActuals={getEstimatesAndActuals()}
          lineItemEditForm={getLineItemEditForm()}
          documentsPanel={getDocumentsPanel()}
          piecesPanel={getPiecesPanel()}
          assemblyPartsPanel={getAssemblyParts()}
        />
      );
    case LINE_ITEM_COMPOSITION_TYPES.CO_PRINT:
      return (
        <CoPrintLayout
          uri={uri}
          prepWorkflowPanel={getPrepWorkflowPanel()}
          estimatesAndActuals={getEstimatesAndActuals()}
          lineItemEditForm={getLineItemEditForm()}
          documentsPanel={getDocumentsPanel()}
          piecesPanel={getPiecesPanel()}
          coPrintPartsPanel={getCoPrintParts()}
        />
      );
    case LINE_ITEM_COMPOSITION_TYPES.ASSEMBLY_PART:
      return (
        <AssemblyPartLayout
          uri={uri}
          modalThreeScene={getModalThreeScene()}
          estimatesAndActuals={getEstimatesAndActuals()}
          lineItemDetails={getLineItemDetails()}
          lineItemEditForm={getLineItemEditForm()}
          piecesPanel={getPiecesPanel()}
          headerPanel={getHeaderPanel(
            <>
              <FormattedMessage id="record.lineItem" defaultMessage="Line Item" /> {getShortUUID(uri)}
            </>,
          )}
          expanded={expanded}
        />
      );
    case LINE_ITEM_COMPOSITION_TYPES.CO_PRINT_PART:
      // Co-Print part should not be provided here,
      // anyway, as an edge-case returning null to make it obvious
      return null;
    default:
      return null;
  }
};

LineItem.defaultProps = {
  readOnly: false,
};

LineItem.propTypes = {
  formKey: PropTypes.string.isRequired,
  uri: PropTypes.string.isRequired,
  readOnly: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  expandMode: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.string]).isRequired,
  setExpandedItems: PropTypes.func.isRequired,
  setExpandMode: PropTypes.func.isRequired,
  expandedItems: PropTypes.arrayOf(PropTypes.number).isRequired,
  productIndex: PropTypes.number.isRequired,
  designFile: designFileResourceType.isRequired,
};

export default React.memo(LineItem);
