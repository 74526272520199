import React from 'react';
import PropTypes from 'prop-types';
import Fa from 'react-fontawesome';
import { productResourceType } from 'rapidfab/types';
import { PRODUCT_PRIOR_CONFIRM_STAGE_STATUSES, PRODUCT_STATUSES } from 'rapidfab/constants';
import { FormattedMessage } from 'rapidfab/i18n';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Loading from 'rapidfab/components/Loading';

const ProductConfirmButton = ({ product, onConfirm, isConfirming, disabled, isFormDirty }) => {
  // Actually there are much more validations on backend (based on line-items workflow, types, etc)
  // so user will see toaster on error
  const canBeConfirmed = product.status === PRODUCT_STATUSES.PENDING;
  const isPriorProductionStage = PRODUCT_PRIOR_CONFIRM_STAGE_STATUSES.includes(product.status);

  const isDisabled = isConfirming || !canBeConfirmed || disabled;

  const buttonText = (canBeConfirmed && isFormDirty)
    ? (
      <FormattedMessage
        id="confirmToManufactureAndSave"
        defaultMessage="Confirm To Manufacture and Save"
      />
    )
    : (
      <FormattedMessage
        id="confirmToManufacture"
        defaultMessage="Confirm To Manufacture"
      />
    );

  return (
    <span className="m-l">
      {
        !canBeConfirmed && isPriorProductionStage && (
          <OverlayTrigger
            placement="top"
            overlay={(
              <Tooltip>
                Product can&apos;t be confirmed yet due to {product.status} status
              </Tooltip>
            )}
          >
            <Fa name="exclamation-circle" />
          </OverlayTrigger>
        )
      }
      {' '}
      <Button bsStyle="primary" bsSize="xsmall" onClick={onConfirm} disabled={isDisabled}>
        { isConfirming && (<Loading inline className="spacer-right" />) }
        {buttonText}
      </Button>
    </span>
  );
};
ProductConfirmButton.propTypes = {
  product: productResourceType.isRequired,
  onConfirm: PropTypes.func.isRequired,
  isConfirming: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  isFormDirty: PropTypes.bool.isRequired,
};

ProductConfirmButton.defaultProps = {
  disabled: false,
};

export default ProductConfirmButton;
