import React from 'react';
import { useSelector } from 'react-redux';
import withRecordsListHandling from 'rapidfab/containers/hocs/withRecordsListHandling';
import { getNonCustomAssemblyWorkflows } from 'rapidfab/selectors';
import { FEATURES, WORKFLOW_TYPES, WORKFLOW_USAGE_STATES } from 'rapidfab/constants';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import _difference from 'lodash/difference';
import ProductionWorkflows from 'rapidfab/components/plan/ProductionWorkflows';
import * as Selectors from 'rapidfab/selectors';

const {
  ARCHIVED,
  ...NON_ARCHIVED_WORKFLOW_USAGE_STATES
} = WORKFLOW_USAGE_STATES;
const nonArchivedWorkflowUsageStatesList = Object.values(NON_ARCHIVED_WORKFLOW_USAGE_STATES);

const AssemblyWorkflowsContainer = props => {
  const specimenWorkflowFeatureEnabled = useSelector(state =>
    Selectors.isFeatureEnabled(state, FEATURES.SPECIMEN_LIBRARY));

  const onCheckboxChange = event => {
    const { checked } = event.target;
    const {
      onFilterUpdate,
      filters,
    } = props;
    const {
      usage_state,
      ...changedFilters
    } = filters;

    if (checked) {
      changedFilters.usage_state = nonArchivedWorkflowUsageStatesList;
    }
    onFilterUpdate(changedFilters);
  };

  const { usage_state: usageState } = props.filters;

  return (
    <ProductionWorkflows
      {...props}
      isAssembly
      specimenWorkflowFeatureEnabled={specimenWorkflowFeatureEnabled}
      extraFilters={[
        <>
          <Form.Check
            name="hideArchived"
            checked={!_difference(nonArchivedWorkflowUsageStatesList, usageState).length}
            onChange={onCheckboxChange}
            type="checkbox"
            label="Hide Archived"
          />
        </>,
      ]}
    />
  );
};

AssemblyWorkflowsContainer.propTypes = {
  onFilterUpdate: PropTypes.func.isRequired,
  filters: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default withRecordsListHandling(
  AssemblyWorkflowsContainer,
  getNonCustomAssemblyWorkflows,
  ['workflow'],
  {
    staticQueryParams: { type: WORKFLOW_TYPES.ASSEMBLY },
  },
);
