import _compact from 'lodash/compact';
import _map from 'lodash/map';
import { API_RESOURCES, WORKFLOW_TYPES } from 'rapidfab/constants';

export const getLineItemWorkflowTypeObjectKey = lineItem => {
  switch (lineItem?.workflow_type) {
    case WORKFLOW_TYPES.POWDER_MANUFACTURING:
      return 'powder';
    default:
      return 'additive';
  }
};

export const getLineItemEstimatesTypeObjectKey = lineItem => {
  switch (lineItem?.workflow_type) {
    case WORKFLOW_TYPES.POWDER_MANUFACTURING:
      return 'estimates_powder';
    default:
      return 'estimates_additive';
  }
};

export const getSnapshotFromLineItem = (lineItem, model) => {
  const workflowTypeKey = getLineItemWorkflowTypeObjectKey(lineItem);

  if (!lineItem || !model) {
    return 'LOADING';
  }

  if (lineItem[workflowTypeKey].no_model_upload) {
    return 'NO_MODEL_UPLOAD';
  }

  const snapshotContent = model.snapshot_content;

  // the default must return 'loading' due to imperfect information from the
  // event stream. E.g: The UI can receive a model that is 'processed' but
  // without a snapshot

  if (snapshotContent) {
    return snapshotContent;
  }

  if (lineItem.status === 'error') {
    return 'ERROR';
  }

  return 'LOADING';
};

/**
 * Transforms an array of line items available for run prints into their corresponding URIs.
 *
 * This function takes an array of line items that are available for run prints, extracts their URIs,
 * and replaces part of the URI path with the appropriate line item resource. If no URIs are available,
 * it returns an empty array.
 *
 * @param {Array<Object>} lineItemsAvailableForRunPrints - Array of line items with available run prints.
 * Each line item object should contain a `uri` property.
 * @returns {Array<string>} - Array of transformed URIs for the line items, where the resource path
 * is adjusted to reflect the correct line item endpoint.
 */
export const transformLineItemsAvailableForRunPrints = lineItemsAvailableForRunPrints => {
  const lineItemsAvailableForRunPrintsUris = _compact(_map(lineItemsAvailableForRunPrints, 'uri'));
  if (!lineItemsAvailableForRunPrintsUris) return [];
  const lineItemUris = lineItemsAvailableForRunPrintsUris.map(url =>
    url.replace(API_RESOURCES.LINE_ITEM_WITH_AVAILABLE_FOR_RUN_PRINTS, API_RESOURCES.LINE_ITEM),
  );

  return lineItemUris;
};

/**
 * Transforms a single line item URI for run prints into the corresponding URI for the line item.
 *
 * This function replaces part of the provided URI path with the appropriate line item resource.
 *
 * @param {string} lineItemsAvailableForRunPrintUri - The URI of a line item available for run prints.
 * @returns {string} - The transformed URI where the resource path is adjusted to reflect
 * the correct line item endpoint.
 */
export const transformLineItemsAvailableForRunPrintUri =
    lineItemsAvailableForRunPrintUri =>
      lineItemsAvailableForRunPrintUri.replace(
        API_RESOURCES.LINE_ITEM_WITH_AVAILABLE_FOR_RUN_PRINTS,
        API_RESOURCES.LINE_ITEM,
      );

/**
 * Get required custom fields with default values if they are missing in the current values.
 *
 * @param {Array} customFieldReferences - Array of custom field references.
 * @param {Array} customFieldValues - Array of current custom field values.
 * @returns {Array} - Array of required custom fields with default values if not already provided.
 */
export const getRequiredDefaultCustomFields = (customFieldReferences, customFieldValues) => {
  if (!customFieldReferences) return [];

  // Get required custom fields
  const requiredCustomFields = customFieldReferences.filter(field => field.required);

  // If we do not have any required fields, skip the rest of the logic
  if (!requiredCustomFields.length) return [];

  // Create a set of URIs of custom fields that already have values
  const existingCustomFieldUris = new Set(customFieldValues.map(field => field.custom_field));

  // Map through required custom fields to add default values if not already present
  return requiredCustomFields
    .map(customField => {
      if (!existingCustomFieldUris.has(customField.uri)) {
        return {
          custom_field: customField.uri,
          value: customField.default_value,
        };
      }
      return null;
    })
    .filter(Boolean); // Filter out null values
};

export default getLineItemWorkflowTypeObjectKey;
