import _filter from 'lodash/filter';
import PropTypes from 'prop-types';
import Actions from 'rapidfab/actions';
import Feature from 'rapidfab/components/Feature';
import FormRow from 'rapidfab/components/FormRow';
import CustomFieldList from 'rapidfab/components/forms/CustomFieldList';
import SelectSingle from 'rapidfab/components/forms/SelectSingle';
import SelectSingleLazy from 'rapidfab/components/forms/SelectSingleLazy';
import VisibleFor from 'rapidfab/components/VisibleFor';
import {
  API_RESOURCES,
  DESIGN_REVIEW_MATERIAL_NAME,
  FEATURES, LINE_ITEM_COMPOSITION_TYPES,
  WORKFLOW_TYPES,
  WORKFLOW_USAGE_STATES,
} from 'rapidfab/constants';
import { FormattedMessage, FormattedMessageMappingOption } from 'rapidfab/i18n';
import {
  MODEL_UNITS_MAP,
  MODEL_UNITS_MAP_IMPERIAL,
  MODEL_UNITS_MAP_METRIC,
  MODEL_UNITS_MAP_VIEW_MODEL_IN,
} from 'rapidfab/mappings';
import * as Selectors from 'rapidfab/selectors';
import { createOrReplaceArray } from 'rapidfab/utils/arrayUtils';
import getInitialCustomFieldValues from 'rapidfab/utils/getInitialCustomFieldValues';
import getVisibleCustomFieldReferencesWithOptions from 'rapidfab/utils/getVisibleCustomFieldReferencesWithOptions';
import getlineItemCustomFieldReferencesOverrides from 'rapidfab/utils/lineItemCustomFieldReferencesOverrides';
import getLineItemFormOptions from 'rapidfab/utils/lineItemFormOptions';
import React, { useMemo, useState } from 'react';
import { FormControl } from 'react-bootstrap';
import { Field, Form, FormSpy } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';

const LineItemDetailsForm = ({ updateFormData, order, isFormsSubmitted, handleFormValidation }) => {
  const dispatch = useDispatch();
  const [workflowsFetchMoreState, setWorkflowsFetchMoreState] = useState({ offset: 0, count: 1 });
  const [customFieldValues, setCustomFieldValues] = useState([]);

  const isUserRestricted = useSelector(Selectors.isCurrentUserRestricted);
  const customLineItemFieldReferences = useSelector(Selectors.getCustomLineItemFieldReferences);
  const customOrderFieldReferences = useSelector(Selectors.getCustomOrderFieldReferences);
  const isBoeingOrderFieldsFeatureEnabled = useSelector(
    state => Selectors.isFeatureEnabled(state, FEATURES.BOEING_ORDER_FIELDS),
  );
  const is3MOrderFieldsFeatureEnabled = useSelector(
    state => Selectors.isFeatureEnabled(state, FEATURES.THREE_M_MAIN_BUREAU_ORDER_FIELDS),
  );
  const isOrderBusinessSegmentFeatureEnabled = useSelector(state => Selectors.isFeatureEnabled(
    state,
    FEATURES.ORDER_BUSINESS_SEGMENT,
  ));
  const features = useSelector(Selectors.getFeatures);

  const formOptions = getLineItemFormOptions({
    features,
    isNormalUser: !isUserRestricted,
    isDanfossUser: isOrderBusinessSegmentFeatureEnabled,
    isBoeingUser: isBoeingOrderFieldsFeatureEnabled,
    is3MUser: is3MOrderFieldsFeatureEnabled,
    compositionType: LINE_ITEM_COMPOSITION_TYPES.SINGLE_MESH_PRODUCT,
  });

  const validate = values => {
    const invalidValues = {};
    if (!isUserRestricted && !values.workflow) {
      invalidValues.workflow = true;
    }
    if (!values.baseMaterial) {
      invalidValues.baseMaterial = true;
    }
    return invalidValues;
  };

  const workflows = useSelector(state => Selectors.getAvailableWorkflowsOfType(
    state,
    WORKFLOW_TYPES.ADDITIVE_MANUFACTURING,
  ));

  const getBaseMaterialWorkflows = baseMaterial => {
    if (!baseMaterial) {
      return [];
    }
    return _filter(workflows, workflowItem => workflowItem.materials.includes(baseMaterial));
  };

  const baseMaterialsByLocationUri = useSelector(Selectors.getBaseMaterialsByLocationUri);

  const {
    base: baseMaterials,
    support: supportMaterials,
  } = useSelector(Selectors.getBaseAndSupportMaterials);

  const filterMaterials = () => {
    if (!order?.location) {
      return _filter(baseMaterials, ({ name }) => name !== DESIGN_REVIEW_MATERIAL_NAME);
    }

    return _filter(baseMaterialsByLocationUri[order?.location], ({ name }) => name !== DESIGN_REVIEW_MATERIAL_NAME);
  };
  const onFetchMoreWorkflows = async () => {
    const limit = 100;
    const response = await dispatch(Actions.Api.nautilus[API_RESOURCES.WORKFLOW].list(
      {
        include_custom_workflows: true,
        type: WORKFLOW_TYPES.ADDITIVE_MANUFACTURING,
        usage_state: [
          WORKFLOW_USAGE_STATES.ACTIVE,
          WORKFLOW_USAGE_STATES.CAN_BE_SAFELY_ARCHIVED,
          WORKFLOW_USAGE_STATES.INACTIVE,
        ],
      },
      { limit, offset: workflowsFetchMoreState.offset }, {}, { sort: 'name' }, true),
    );
    setWorkflowsFetchMoreState(previous => (
      { offset: previous.offset + limit, count: response?.json.meta?.count || 0 }
    ));
  };

  const customFieldValuesReplaced = getInitialCustomFieldValues(customLineItemFieldReferences, customFieldValues);
  const fieldOverrides = getlineItemCustomFieldReferencesOverrides({ isNormalUser: !isUserRestricted });

  const visiblelineItemCustomFieldReferencesWithOptions = getVisibleCustomFieldReferencesWithOptions({
    fieldReferences: customLineItemFieldReferences,
    fieldValues: customFieldValuesReplaced,
    fieldOverrides,
    parentFieldReferences: customOrderFieldReferences,
    parentFieldValues: order.custom_field_values,
  });

  const onCustomFieldChange = (onChangeField, value) => {
    const customFieldValuesReplaced = createOrReplaceArray(
      customFieldValues,
      { custom_field: value.customFieldReferenceUri },
      { value: value.value },
    );

    setCustomFieldValues(customFieldValuesReplaced);
    onChangeField('customFields', customFieldValuesReplaced);
  };
  const filteredMaterials = filterMaterials();
  const initialFormValues = useMemo(() => ({
    autorun: false,
    baseMaterial: !formOptions.baseMaterial.show ? filteredMaterials[0]?.uri : null,
    supportMaterial: null,
    notes: null,
    quantity: 1,
    workflow: null,
    customFields: [],
    partName: null,
    customerId: null,
  }), [JSON.stringify(order), formOptions.baseMaterial.show]);

  return (
    <div>
      <Form
        onSubmit={validate}
        validate={validate}
        initialValues={initialFormValues}
        validateOnBlur={false}
        validateFields={[
          'baseMaterial',
          'workflow',
        ]}
      >
        {formProps => (
          <form onSubmit={formProps.handleSubmit}>

            <Feature featureName={FEATURES.LINE_ITEM_EXTENDED_DETAILS}>
              <>
                <FormRow id="field.partName" defaultMessage="Part Name">
                  <Field name="partName">
                    {
                      ({ input }) => (
                        <FormControl
                          name="partName"
                          type="text"
                          onChange={input.onChange}
                          value={input?.value || ''}
                        />
                      )
                    }
                  </Field>
                </FormRow>
                <FormRow
                  id={formOptions.customerID.id}
                  defaultMessage={formOptions.customerID.defaultMessage}
                >
                  <Field name="customerId">
                    {
                      ({ input }) => (
                        <FormControl
                          name="customerId"
                          type="text"
                          onChange={input.onChange}
                          value={input?.value || ''}
                        />
                      )
                    }
                  </Field>
                </FormRow>
              </>
            </Feature>

            {formOptions.baseMaterial.show && (
              <FormRow id="field.material" defaultMessage="Material" isRequired>
                <Field
                  name="baseMaterial"
                >
                  {
                    ({ meta, input }) => (
                      <div
                        style={{ borderRadius: '4px' }}
                        className={`${meta.invalid && (isFormsSubmitted || meta.touched) ? 'error-outline' : ''}`}
                      >
                        <SelectSingle
                          name="baseMaterial"
                          data={filteredMaterials}
                          handleOnChange={input.onChange}
                          placeholder={
                            filteredMaterials.length > 0 ?
                              'Select material' : 'No materials available for this location'
                          }
                          required
                          imitateOnChangeEvent
                          {...input}
                        />
                      </div>
                    )
                  }
                </Field>
              </FormRow>
            )}

            <FormRow id="modelFileUnits" defaultMessage="Model File Units" isRequired>
              <Field
                name="fileUnits"
              >
                {
                  fieldProps => (
                    <FormControl
                      name="modelFileUnits"
                      value={fieldProps.input.value}
                      as="select"
                      onChange={fieldProps.input.onChange}
                      required
                    >
                      <FormattedMessageMappingOption
                        mapping={MODEL_UNITS_MAP}
                        value="Automatic"
                        key="automatic"
                      />
                      <optgroup label="Metric">
                        {Object.keys(MODEL_UNITS_MAP_METRIC).map(modelUnit => (
                          <FormattedMessageMappingOption
                            mapping={MODEL_UNITS_MAP_METRIC}
                            value={modelUnit}
                            key={modelUnit}
                          />
                        ))}
                      </optgroup>
                      <optgroup label="Imperial">
                        {Object.keys(MODEL_UNITS_MAP_IMPERIAL).map(modelUnit => (
                          <FormattedMessageMappingOption
                            mapping={MODEL_UNITS_MAP_IMPERIAL}
                            value={modelUnit}
                            key={modelUnit}
                          />
                        ))}
                      </optgroup>
                    </FormControl>
                  )
                }
              </Field>
            </FormRow>

            <FormRow
              id="field.viewModelIn"
              defaultMessage="View Model In"
              isRequired
            >
              <Field name="userUnits">
                {
                  fieldProps => (
                    <FormControl
                      name="modelUserUnits"
                      value={fieldProps.input.value}
                      as="select"
                      onChange={fieldProps.input.onChange}
                      required
                    >
                      {Object.keys(MODEL_UNITS_MAP_VIEW_MODEL_IN).map(modelUnit => (
                        <FormattedMessageMappingOption
                          mapping={MODEL_UNITS_MAP_VIEW_MODEL_IN}
                          value={modelUnit}
                          key={modelUnit}
                        />
                      ))}
                    </FormControl>
                  )
                }
              </Field>
            </FormRow>

            {formOptions.supportMaterial.show && (
              <FormRow id="field.supportMaterial" defaultMessage="Support Material">
                <Field name="supportMaterial">
                  {
                    fieldProps => (
                      <SelectSingle
                        name="supportMaterial"
                        data={supportMaterials}
                        value={fieldProps.input.value}
                        handleOnChange={fieldProps.input.onChange}
                        imitateOnChangeEvent
                      />
                    )
                  }
                </Field>
              </FormRow>
            )}

            <FormRow id="field.quantity" defaultMessage="Quantity" isRequired>
              <Field name="quantity">
                {
                  ({ input }) => (
                    <FormControl
                      name="quantity"
                      type="number"
                      min="1"
                      required
                      onChange={input.onChange}
                      value={input?.value || ''}
                    />
                  )
                }
              </Field>
            </FormRow>

            {
              formOptions.workflow.show && (
                <FormRow
                  id="workflow"
                  defaultMessage="Production Workflow"
                  isRequired
                >
                  <Field name="workflow">
                    {
                      ({ meta, input }) => (
                        <>
                          <div
                            style={{ borderRadius: '4px' }}
                            className={`${meta.invalid && (isFormsSubmitted || meta.touched) ? 'error-outline' : ''}`}
                          >
                            <SelectSingleLazy
                              name="workflow"
                              dataTestId="lineItemBaseMaterialDetailsForm"
                              data={getBaseMaterialWorkflows(formProps.values.baseMaterial)}
                              disabled={!formProps.values.baseMaterial}
                              placeholder={!formProps.values.baseMaterial ? 'Please select a base material' : ''}
                              value={input.value}
                              handleOnChange={input.onChange}
                              imitateOnChangeEvent
                              isOptionDisabledCallback={item => item.usage_state === WORKFLOW_USAGE_STATES.ARCHIVED}
                              onFetchMore={
                                workflowsFetchMoreState.offset < workflowsFetchMoreState.count ?
                                  onFetchMoreWorkflows :
                                  null
                              }
                            />
                          </div>
                          {formProps.values.baseMaterial &&
                            !getBaseMaterialWorkflows(formProps.values.baseMaterial).length && (
                            <span className="spacer-top text-danger">
                              <FormattedMessage
                                id="noMatchingWorkflowForMaterial"
                                defaultMessage="No workflow matches selected material at your current location."
                              />
                            </span>
                          )}
                        </>
                      )
                    }
                  </Field>
                </FormRow>
              )
            }

            <FormRow id="field.notes" defaultMessage="Notes">
              <Field name="notes">
                {
                  fieldProps => (
                    <FormControl
                      name="notes"
                      value={fieldProps.input.value}
                      as="textarea"
                      onChange={fieldProps.input.onChange}
                    />
                  )
                }
              </Field>
            </FormRow>

            <CustomFieldList
              customFieldReferences={
                visiblelineItemCustomFieldReferencesWithOptions
              }
              customFieldValues={customFieldValues}
              onChange={(_, value) => onCustomFieldChange(formProps.form.change, value)}
            />

            <VisibleFor unrestricted>
              <Feature featureName={FEATURES.AUTORUN}>
                <FormRow id="field.autorun" defaultMessage="Auto-create Run">
                  <Field
                    name="autorun"
                    component="input"
                    type="checkbox"
                  />{' '}
                </FormRow>
              </Feature>
            </VisibleFor>
            <FormSpy
              subscription={{ values: true, valid: true }}
              onChange={({ values, valid }) => {
                updateFormData(values);
                handleFormValidation(valid);
              }}
            />
          </form>
        )}
      </Form>
    </div>
  );
};

export default React.memo(LineItemDetailsForm);

LineItemDetailsForm.propTypes = {
  updateFormData: PropTypes.func.isRequired,
  handleFormValidation: PropTypes.func.isRequired,
  isFormsSubmitted: PropTypes.bool.isRequired,
  order: PropTypes.shape({
    location: PropTypes.string,
    custom_field_values: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};
