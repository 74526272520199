import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';

import WorkflowTabs, { WORKFLOW_TABS } from 'rapidfab/components/plan/WorkflowTabs';
import PrepWorkflowModalContainer from 'rapidfab/containers/plan/PrepWorkflowModalContainer';

import TableWithSearching from 'rapidfab/components/Tables/TableWithSearching';
import { faPencil, faPlus } from '@fortawesome/free-solid-svg-icons';

const PrepWorkflows = ({ initialPrepWorkflowUUID, data, fetching, onSort, isDebugModeEnabled, ...componentProps }) => {
  const [isModalShown, setIsModalShown] = useState(false);
  const [currentPrepWorkflowUUID, setCurrentPrepWorkflowUUID] = useState(null);

  const closeModal = () => {
    setIsModalShown(false);
    setCurrentPrepWorkflowUUID(null);
  };

  const showModal = prepWorkflowUUID => {
    setIsModalShown(true);
    setCurrentPrepWorkflowUUID(prepWorkflowUUID);
  };

  useEffect(() => {
    if (initialPrepWorkflowUUID) {
      showModal(initialPrepWorkflowUUID);
    }
  }, [initialPrepWorkflowUUID]);

  const columns = [
    isDebugModeEnabled && {
      type: 'text',
      uid: 'field.id',
      accessor: 'uuid',
      defaultMessage: 'ID',
      short: true,
      debugMode: true,
    },
    {
      type: 'custom',
      uid: 'field.name',
      accessor: 'name',
      defaultMessage: 'Name',
      isSortable: true,
      Cell: ({ row: { original } }) => (
        <>
          {original.name}
          {' '}
          <Button
            className="pull-right"
            variant="primary"
            size="xs"
            tabIndex={0}
            onClick={() => showModal(original.uuid)}
          >
            <FontAwesomeIcon icon={faPencil} />
          </Button>
        </>
      ),
    },
    {
      type: 'text',
      uid: 'field.description',
      accessor: 'description',
      defaultMessage: 'Description',
      isSortable: true,
    },
  ];

  const NavbarLinks = (
    <Button
      variant="primary"
      size="sm"
      onClick={() => showModal(null)}
      className="pull-right"
    >
      <FontAwesomeIcon icon={faPlus} />{' '}
      <FormattedMessage
        id="record.workflow.add"
        defaultMessage="Add Workflow"
      />
    </Button>
  );

  return (
    <>
      <TableWithSearching
        {...componentProps}
        breadcrumbs={['plan', 'workflows']}
        navbar={NavbarLinks}
        subheader={<WorkflowTabs activeKey={WORKFLOW_TABS.PREP_WORKFLOWS} />}
        data={data}
        columns={columns}
        isFetching={fetching}
        isFilteringEnabled={false}
        withDefaultPagination={false}
        isManualSoringEnabled
        manualSortingFunc={onSort}
        initialSortedDesc
        initialSortedColumn="updated"
        isDebugModeEnabled={isDebugModeEnabled}
      />
      {isModalShown && (
        <PrepWorkflowModalContainer
          prepWorkflowUUID={currentPrepWorkflowUUID}
          onClose={closeModal}
          {...componentProps}
        />
      )}
    </>
  );
};

PrepWorkflows.defaultProps = {
  sort: '',
  search: '',
  initialPrepWorkflowUUID: null,
};

PrepWorkflows.propTypes = {
  initialPrepWorkflowUUID: PropTypes.string,
  onPageChange: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onFilterUpdate: PropTypes.func.isRequired,
  onSort: PropTypes.func.isRequired,
  sort: PropTypes.string,
  search: PropTypes.string,
  recordListItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  filters: PropTypes.shape({}).isRequired,
  recordListStore: PropTypes.shape({}).isRequired,
  offset: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  fetching: PropTypes.bool.isRequired,
  isDebugModeEnabled: PropTypes.bool.isRequired,
  row: PropTypes.shape({
    original: PropTypes.shape({
      uuid: PropTypes.string.isRequired,
      uri: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default PrepWorkflows;
