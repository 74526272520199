import React from 'react';
import PropTypes from 'prop-types';
import { SHIPMENT_DATE_TYPES, STATUS_COLOR_CODE_TYPES } from 'rapidfab/constants';
import { SHIPMENT_STATUS_MAP } from 'rapidfab/mappings';
import { orderResourceType, runType } from 'rapidfab/types';
import TableWithSearching from 'rapidfab/components/Tables/TableWithSearching';

const ShipmentsComponent = ({
  data,
  onSort,
  fetching,
  isShipmentForOrderFeatureEnabled,
  ordersByUri,
  runsByUri,
  ...componentProps
}) => {
  const columns = [
    {
      type: 'uuid',
      uid: 'field.id',
      accessor: 'uuid',
      defaultMessage: 'ID',
      resource: 'shipment',
    },
    {
      type: 'text',
      uid: 'field.name',
      accessor: 'name',
      defaultMessage: 'Name',
      isSortable: true,
    },
    {
      type: 'resource',
      uid: 'field.run',
      accessor: 'run',
      defaultMessage: 'Run',
      resource: 'run',
      resources: runsByUri,
      isSortable: true,
    },
    isShipmentForOrderFeatureEnabled && (
      {
        type: 'resource',
        uid: 'field.order',
        accessor: 'order',
        defaultMessage: 'Order',
        resource: 'order',
        resources: ordersByUri,
        isSortable: true,
      }
    ),
    {
      type: 'shipment',
      uid: 'field.estimatedActualSend',
      accessor: 'actual_shipment_date',
      defaultMessage: 'Estimated / Actual Sent',
      shipment: SHIPMENT_DATE_TYPES.SHIPMENT,
      isSortable: true,
    },
    {
      type: 'shipment',
      uid: 'field.estimatedActualReceived',
      accessor: 'actual_delivery_date',
      defaultMessage: 'Estimated / Actual Received',
      shipment: SHIPMENT_DATE_TYPES.DELIVERY,
      isSortable: true,
    },
    {
      type: 'translatable',
      uid: 'field.status',
      accessor: 'status',
      defaultMessage: 'Status',
      mapping: SHIPMENT_STATUS_MAP,
      coloured: true,
      colorScheme: STATUS_COLOR_CODE_TYPES.SHIPMENT,
      isSortable: true,
    },
  ];

  return (
    <>
      <TableWithSearching
        {...componentProps}
        data={data}
        columns={columns}
        isFetching={fetching}
        isManualSoringEnabled
        manualSortingFunc={onSort}
        initialSortedDesc
        initialSortedColumn="name"
        breadcrumbs={['plan', 'shipments']}
        withBreadcrumbs
      />
    </>
  );
};

ShipmentsComponent.propTypes = {
  isShipmentForOrderFeatureEnabled: PropTypes.bool.isRequired,
  ordersByUri: PropTypes.objectOf(orderResourceType).isRequired,
  runsByUri: PropTypes.objectOf(runType).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  fetching: PropTypes.bool.isRequired,
  onSort: PropTypes.func.isRequired,
  row: PropTypes.shape({
    original: PropTypes.shape({
      uuid: PropTypes.string.isRequired,
      uri: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default ShipmentsComponent;
