import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'rapidfab/i18n';
import { API_RESOURCES } from 'rapidfab/constants';
import DownloadDocumentButton from 'rapidfab/components/records/DownloadDocumentButton';

const AnatomicalModelSummaryReportButton = ({
  pieceUri,
}) => (
  <DownloadDocumentButton
    buttonContent={(
      <FormattedMessage
        id="button.download"
        defaultMessage="Download"
      />
    )}
    resourceUri={pieceUri}
    documentEndpointName={API_RESOURCES.ANATOMICAL_MODEL_SUMMARY_REPORT}
  />
);

AnatomicalModelSummaryReportButton.propTypes = {
  pieceUri: PropTypes.string.isRequired,
};

export default AnatomicalModelSummaryReportButton;
