import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { assemblyPartMetaResourceType, lineItemResourceType, modelResourceType } from 'rapidfab/types';
import { Table, Image } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import ResourceLink from 'rapidfab/components/ResourceLink';
import { LINE_ITEM_COMPOSITION_TYPES } from 'rapidfab/constants';
import { LINE_ITEM_STATUS_MAP } from 'rapidfab/mappings';
import { useSelector } from 'react-redux';
import * as Selectors from 'rapidfab/selectors';
import { getLineItemWorkflowTypeObjectKey, getSnapshotFromLineItem } from 'rapidfab/utils/lineItemUtils';

const AssemblyParts = ({ assemblyPartMeta, lineItemsByUri }) => (
  <Table bordered>
    <thead>
      <tr>
        <th>Name (Line Item)</th>
        <th>Thumbnail</th>
        <th>Sub-Assembly</th>
        <th>Workflow</th>
        <th>Status</th>
      </tr>
    </thead>
    <tbody>
      {assemblyPartMeta.map(assemblyPartMetaItem => {
        const lineItem = lineItemsByUri[assemblyPartMetaItem.part_line_item];
        if (!lineItem) {
          // Line Item is expected to exist here
          // anyway, to prevent black-wall in case of any edge-case
          return null;
        }

        const workflowTypeKey = getLineItemWorkflowTypeObjectKey(lineItem);
        const models = useSelector(Selectors.getModels);
        const model = useMemo(
          () => (lineItem ? models.find(m => m.uri === lineItem[workflowTypeKey]?.model) : null),
          [lineItem, models],
        );
        const snapshot = useMemo(() => getSnapshotFromLineItem(lineItem, model), [lineItem, model]);
        const assemblyLineItem = lineItemsByUri[assemblyPartMetaItem.root_line_item] || {};
        return (
          <tr key={`${assemblyPartMetaItem.uuid}`}>
            <td>{lineItem.name || <FormattedMessage id="notAvailable" defaultMessage="N/A" />} </td>
            <td>
              {
                (model && snapshot)
                  ? <Image className="part_image" src={snapshot} />
                  : <FormattedMessage id="notAvailable" defaultMessage="N/A" />
              }
            </td>
            <td>
              {
                (assemblyLineItem && assemblyLineItem.name)
                  ? (
                    <>
                      {assemblyLineItem.name}
                      {lineItem.composition_type === LINE_ITEM_COMPOSITION_TYPES.ASSEMBLY_PART && ' sub-assembly part'}
                      {lineItem.composition_type === LINE_ITEM_COMPOSITION_TYPES.CO_PRINT_PART && ' co-printed part'}
                    </>
                  ) : <FormattedMessage id="notAvailable" defaultMessage="N/A" />
              }
            </td>
            <td>
              {
                lineItem.workflow
                  ? <ResourceLink uri={lineItem.workflow} />
                  : <FormattedMessage id="notAvailable" defaultMessage="N/A" />
              }
            </td>
            <td>
              {
                lineItem.status
                  ? <FormattedMessage {...LINE_ITEM_STATUS_MAP[lineItem.status]} />
                  : <FormattedMessage id="notAvailable" defaultMessage="N/A" />
              }
            </td>
          </tr>
        );
      })}
    </tbody>
  </Table>
);

AssemblyParts.propTypes = {
  lineItemsByUri: PropTypes.objectOf(lineItemResourceType).isRequired,
  modelsByUri: PropTypes.objectOf(modelResourceType).isRequired,
  assemblyPartMeta: PropTypes.arrayOf(assemblyPartMetaResourceType).isRequired,
};

export default AssemblyParts;
