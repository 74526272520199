import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _map from 'lodash/map';
import _uniq from 'lodash/uniq';
import _compact from 'lodash/compact';
import _isEqual from 'lodash/isEqual';
import { pieceResourceType } from 'rapidfab/types';
import _isEmpty from 'lodash/isEmpty';
import _omit from 'lodash/omit';
import PropTypes from 'prop-types';
import Actions from 'rapidfab/actions';
import * as Selectors from 'rapidfab/selectors';
import { API_RESOURCES, LOCATION_FILTER_DEFAULT_VALUES, VIEW_MODE_OPTIONS } from 'rapidfab/constants';

import Pieces from 'rapidfab/components/plan/pieces';
import _mapValues from 'lodash/mapValues';
import { LINE_ITEM_STATUS_MAP, PIECE_STATUS_MAP } from 'rapidfab/mappings';
import {
  Button,
  ButtonToolbar,
  Col,
  Container,
  FormControl,
  FormLabel,
  Row,
} from 'react-bootstrap';
import SelectMultiple from 'rapidfab/components/forms/SelectMultiple';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PiecesCardView from 'rapidfab/components/plan/PiecesCardView';
import usePrevious from 'rapidfab/hooks';
import Locations from 'rapidfab/components/locations';
import BreadcrumbNav from 'rapidfab/components/BreadcrumbNav';
import useDeepCompareEffect from 'rapidfab/hooks/useDeepCompareEffect';
import { getLocationFilter } from 'rapidfab/selectors';
import Pagination from 'rapidfab/components/RecordList/Pagination';
import Limit from 'rapidfab/components/RecordList/Limit';
import { faList, faThLarge } from '@fortawesome/free-solid-svg-icons';

const PiecesContainer = props => {
  const [viewMode, setViewMode] = useState(VIEW_MODE_OPTIONS.CARDS);
  const [modelsFetching, setModelsFetching] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [filters, setFilters] = useState({});
  const [sortValue, setSortValue] = useState('-updated');
  const [globalSearch, setGlobalSearch] = useState('');
  const [piecesListStore, setPiecesListStore] = useState({});

  const [paginationState, setPaginationState] = useState({
    pageLimit: 25,
    offset: 0,
    activePage: 0,
    totalPieces: 0,
  });

  const isCardsView = viewMode === VIEW_MODE_OPTIONS.CARDS;
  const isDebugModeEnabled = useSelector(Selectors.getIsDebugModeEnabled);
  const ordersByUri = useSelector(Selectors.getOrdersByUri);
  const runs = useSelector(Selectors.getRuns);
  const piecesFetching = useSelector(state => state.ui.nautilus[API_RESOURCES.PIECE].list.fetching);
  const ordersFetching = useSelector(state => state.ui.nautilus[API_RESOURCES.ORDER].list.fetching);
  const printRelatedDataFetching = useSelector(state => state.ui.nautilus[API_RESOURCES.PRINT].list.fetching ||
    state.ui.nautilus[API_RESOURCES.RUN].list.fetching);
  const isFetching = piecesFetching || ordersFetching || printRelatedDataFetching;
  const locations = useSelector(Selectors.getLocationsByUri);
  const locationFilter = useSelector(getLocationFilter);
  const groupedPieces = useSelector(Selectors.getPiecesGroupedByLocation);
  const [lastPiecesInCardsView, setLastPiecesInCardsView] = useState([]);
  const [piecesCleanup, setPiecesCleanup] = useState(false);
  const modelsByUri = useSelector(Selectors.getModelsByUri);
  const pieces = useSelector(Selectors.getPieces);

  const orderUris = _compact(_uniq(_map(pieces, 'order')));
  const printUris = _compact(_uniq(_map(pieces, 'current_print')));
  const totalPaginatedPages = Math.ceil(paginationState.totalPieces / paginationState.pageLimit);
  const breadcrumbs = ['plan', 'orders', 'pieces'];

  const dispatch = useDispatch();

  // To make sure no old pieces data is cached to prevent multiple API requests.
  const handleCleanupPieces = () => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.PIECE].clear('list'));
    return true;
  };
  const onFetchOrders = ordersUris => dispatch(Actions.Api.nautilus[API_RESOURCES.ORDER].list(
    { uri: ordersUris },
    { limit: ordersUris.length },
    {},
    {},
    true,
  ));

  const loadPieces = () => {
    const search = {};
    let globalFilters = {};

    if (globalSearch) {
      search.multicolumn_search = globalSearch;
    }

    if (sortValue) {
      search.sort = sortValue;
    }

    if (!_isEmpty(filters)) {
      globalFilters = filters;
    }

    if (typeof locationFilter !== 'undefined'
      && locationFilter !== LOCATION_FILTER_DEFAULT_VALUES.ALL) {
      globalFilters.location = locationFilter;
    }

    dispatch(Actions.Api.nautilus[API_RESOURCES.LOCATION].list());

    dispatch(Actions.Api.nautilus[API_RESOURCES.PIECE].list({ ...globalFilters },
      { limit: paginationState.pageLimit,
        offset: paginationState.offset > 0 ? paginationState.offset : 0 }, {}, { ...search }, true))
      .then(piecesResponse => {
        setPaginationState(previous => ({
          ...previous,
          totalPieces: piecesResponse?.json?.meta?.count,
          activePage: paginationState.activePage >= totalPaginatedPages && paginationState.activePage !== 0 ?
            totalPaginatedPages - 1 :
            paginationState.activePage,
        }));

        setPiecesListStore(piecesResponse?.json || {});
      });
  };

  const loadRunsByPrintUris = async printUris => {
    const printsResponse = await dispatch(Actions.Api.nautilus[API_RESOURCES.PRINT].list(
      { uri: printUris },
      { limit: printUris.length },
      {},
      {},
      true,
    ));
    const runUris = _compact(_uniq(_map(printsResponse?.json?.resources, 'run')));
    if (runUris.length) {
      await dispatch(Actions.Api.nautilus[API_RESOURCES.RUN].list(
        { uri: runUris },
        { limit: runUris.length },
        {},
        {},
        true,
      ));
    }
  };

  const loadRelatedModels = modelUris => {
    if (modelUris.length) {
      setModelsFetching(true);
      dispatch(Actions.Api.nautilus[API_RESOURCES.MODEL].list({ uri: modelUris }, {}, {}, {}, true))
        .then(() => setModelsFetching(false));
    }
  };

  const onSortPieces = sort => {
    let sorts = sort.id;
    if (!sort.sortAscending) {
      sorts = `-${sorts}`;
    }

    setSortValue(sorts);
  };

  const goToPage = offset => {
    setPaginationState({
      ...paginationState,
      offset,
    });
  };

  const onLimitChange = limit => {
    setPaginationState({
      ...paginationState,
      pageLimit: limit,
      offset: 0,
    });
  };

  const previousLimit = usePrevious(paginationState.pageLimit);
  const previousOffset = usePrevious(paginationState.offset);
  const previousGlobalSearch = usePrevious(globalSearch);
  const previousSort = usePrevious(sortValue);
  const previousFilters = usePrevious(filters);
  const previousLocation = usePrevious(locationFilter);
  const previousOrderUris = usePrevious(orderUris);

  useEffect(() => {
    if (!piecesCleanup) {
      handleCleanupPieces();
      setPiecesCleanup(true);
    }
  }, []);

  useDeepCompareEffect(() => {
    // Clear pieces cache if we are going through the pagination
    handleCleanupPieces();

    if (globalSearch !== previousGlobalSearch ||
      paginationState.offset !== previousOffset ||
    paginationState.pageLimit !== previousLimit ||
    sortValue !== previousSort ||
    filters !== previousFilters ||
    locationFilter !== previousLocation) {
      loadPieces();
    }
  }, [
    paginationState.pageLimit,
    paginationState.offset,
    paginationState.activePage,
    globalSearch,
    sortValue,
    filters,
    locationFilter,
  ]);

  useDeepCompareEffect(() => {
    if ((orderUris.length && !_isEqual(previousOrderUris, orderUris)) && piecesCleanup) {
      onFetchOrders(orderUris);
    }
  }, [orderUris, isFetching, pieces, piecesCleanup]);

  useDeepCompareEffect(() => {
    if (printUris.length && piecesCleanup) {
      loadRunsByPrintUris(printUris);
    }
  }, [printUris, piecesCleanup]);

  useDeepCompareEffect(() => {
    if ((isCardsView && (pieces.length && !_isEqual(lastPiecesInCardsView, pieces))) && piecesCleanup) {
      loadRelatedModels(_compact(_uniq(_map(pieces, 'model'))));
      setLastPiecesInCardsView(pieces);
    }
  }, [isCardsView, pieces, piecesCleanup]);

  const availableStatuses = _mapValues(PIECE_STATUS_MAP, (statusOption, status) => ({
    label: statusOption.defaultMessage,
    status,
  }));

  const availableLineItemStatuses = _mapValues(LINE_ITEM_STATUS_MAP, (lineItemStatusOption, status) => ({
    label: lineItemStatusOption.defaultMessage,
    status,
  }));

  const { status: statuses = [] } = filters;
  const { line_item_status: lineItemStatuses = [] } = filters;

  const selectedStatuses = statuses.map(status => availableStatuses[status]);
  const selectedLineItemStatuses = lineItemStatuses.map(lineItemStatus => availableLineItemStatuses[lineItemStatus]);

  const onStatusChange = selectedStatuses => {
    if (_isEmpty(selectedStatuses)) {
      setFilters(_omit(filters, 'status'));
      return;
    }

    const changedFilters = { ...filters };
    changedFilters.status = selectedStatuses.map(statusItem => statusItem.status);
    setFilters(changedFilters);
  };

  const onLineItemStatusChange = lineItemStatuses => {
    if (_isEmpty(lineItemStatuses)) {
      setFilters(_omit(filters, 'line_item_status'));
      return;
    }

    const changedFilters = { ...filters };

    if (lineItemStatuses.length) {
      changedFilters.line_item_status = lineItemStatuses.map(lineItemStatus => lineItemStatus.status);
    }
    setFilters(changedFilters);
  };

  const renderHeaderView = () => {
    const isListView = !isCardsView;
    return (
      <Row>
        <Col xs={isListView && { span: 12 }} lg={isListView && { span: 10, offset: 1 }} className="mb8 d-flex justify-content-between">
          <Col xs={4}>
            <div className="d-flex align-items-center">
              <FormControl
                type="text"
                value={searchValue}
                placeholder="Search"
                onChange={({ target }) => {
                  setSearchValue(target.value);
                }}
              />
              <Button
                className="pull-right spacer-left"
                variant="primary"
                type="submit"
                onClick={() => setGlobalSearch(searchValue)}
                disabled={!globalSearch && !searchValue}
              >
                <FormattedMessage id="button.submit" defaultMessage="Submit" />
              </Button>
            </div>
          </Col>
          <ButtonToolbar className="pull-right toolbar spacer-left">
            <Button
              variant="default"
              text="dark"
              className={viewMode === VIEW_MODE_OPTIONS.CARDS ? 'btn-active' : ''}
              onClick={() => setViewMode(VIEW_MODE_OPTIONS.CARDS)}
              data-cy="piece-card-view-btn"
            >
              <FontAwesomeIcon icon={faThLarge} size="lg" />
            </Button>
            <Button
              text="dark"
              variant="default"
              className={viewMode === VIEW_MODE_OPTIONS.LIST ? 'btn-active' : ''}
              active={viewMode === VIEW_MODE_OPTIONS.LIST}
              onClick={() => setViewMode(VIEW_MODE_OPTIONS.LIST)}
              data-cy="piece-table-view-btn"
            >
              <FontAwesomeIcon icon={faList} size="lg" />
            </Button>
          </ButtonToolbar>
        </Col>

        <Row className="mb8">
          <Col xs={isListView && { span: 12 }} lg={isListView && { span: 10, offset: 1 }}>
            <div className="filters-label">Filters:</div>
            <div key="ordersExtraFilters" className="form-inline" style={{ lineHeight: '40px' }}>
              <div className="form-group mr15 ">
                <FormLabel htmlFor="statusFilter">
                  Status:
                </FormLabel>
                <div className="spacer-left form-control inline-picky-wrapper" data-cy="status-select">
                  <SelectMultiple
                    title="Status"
                    data={Object.values(availableStatuses)}
                    labelKey="label"
                    valueKey="status"
                    selectedData={selectedStatuses}
                    handleOnClose={onStatusChange}

                  />
                </div>
              </div>
              <div className="form-group mr15 ">
                <FormLabel htmlFor="lineItemStatusFilter">
                  Line Item Status:
                </FormLabel>
                <div className="spacer-left form-control inline-picky-wrapper" data-cy="lineitem-status-select">
                  <SelectMultiple
                    title="Status"
                    data={Object.values(availableLineItemStatuses)}
                    labelKey="label"
                    valueKey="status"
                    selectedData={selectedLineItemStatuses}
                    handleOnClose={onLineItemStatusChange}
                    data-cy="lineitem-status-select"
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Row>
    );
  };

  const PaginationComponent = () => (
    <Row>
      <Col>
        <Pagination
          limit={paginationState.pageLimit}
          listStore={piecesListStore}
          offset={paginationState.offset}
          onPageChange={goToPage}
        />
      </Col>
      <Col lg={2}>
        <Limit
          limit={paginationState.pageLimit}
          onLimitChange={onLimitChange}
        />
      </Col>
    </Row>
  );

  return (
    <Container fluid>
      <BreadcrumbNav breadcrumbs={breadcrumbs} />
      <Row>
        <Col xs={8}>
          <Locations />
        </Col>
      </Row>

      <hr />

      { isCardsView ? (
        <PiecesCardView
          renderHeaderView={renderHeaderView}
          locations={locations}
          groupedPieces={groupedPieces}
          fetchingState={{
            piecesFetching,
            ordersFetching,
            printRelatedDataFetching,
            modelsFetching,
          }}
          ordersFet
          modelsByUri={modelsByUri}
          ordersByUri={ordersByUri}
          runs={runs}
          paginationComponent={PaginationComponent}
        />
      ) : (
        <Pieces
          isDebugModeEnabled={isDebugModeEnabled}
          ordersByUri={ordersByUri}
          runs={runs}
          renderHeaderView={renderHeaderView}
          pieces={pieces}
          isFetching={isFetching}
          onSort={onSortPieces}
          paginationComponent={PaginationComponent}
          {...props}
        />
      )}
    </Container>
  );
};

PiecesContainer.propTypes = {
  data: PropTypes.arrayOf(pieceResourceType).isRequired,
  filters: PropTypes.shape({
    status: PropTypes.arrayOf(PropTypes.string),
    line_item_status: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  onFilterUpdate: PropTypes.func.isRequired,
};

export default PiecesContainer;
