import React from 'react';
import PropTypes from 'prop-types';
import { FormLabel, FormGroup } from 'react-bootstrap';
import FormControlField from 'rapidfab/components/forms/FormControlField';
import _upperFirst from 'lodash/upperFirst';

const FormGroupField = ({
  name,
  label,
  required,
  controlId,
  validationMessage,
  shouldShowValidationMessage,
  ...otherProps
}) => {
  const labelText = label || _upperFirst(name);
  const id = controlId || `ux-${name}`;

  return (
    <FormGroup className={`form-group ${shouldShowValidationMessage && 'position-relative mb30'}`} controlId={id}>
      <FormLabel>{labelText}:{required && ' *'}</FormLabel>
      <FormControlField name={name} required={required} {...otherProps} />
      {shouldShowValidationMessage && (
        <div className="form-validation-message">
          {validationMessage}
        </div>
      )}
    </FormGroup>
  );
};

FormGroupField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.node,
  required: PropTypes.bool,
  controlId: PropTypes.string,
  validationMessage: PropTypes.node,
  shouldShowValidationMessage: PropTypes.bool,
};

FormGroupField.defaultProps = {
  required: false,
  label: null,
  controlId: null,
  validationMessage: null,
  shouldShowValidationMessage: false,
};

export default FormGroupField;
