import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Alert from 'rapidfab/utils/alert';
import _map from 'lodash/map';
import _find from 'lodash/find';

import * as Selectors from 'rapidfab/selectors';
import {
  LINE_ITEM_STATUS,
  LINE_ITEM_PRODUCTION_STAGE_STATUSES,
  FEATURES,
  PAGINATION_IGNORE_DEFAULT_LIMIT,
  API_RESOURCES,
  GUIDELINE_API_RESOURCES,
  ORDER_SHIPPING_GROUPING_OPTIONS,
} from 'rapidfab/constants';
import Actions from 'rapidfab/actions';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import getInitialCustomFieldValues from 'rapidfab/utils/getInitialCustomFieldValues';
import { createOrReplaceArray } from 'rapidfab/utils/arrayUtils';
import {
  lineItemResourceType,
  lineItemDefaultValues,
  orderResourceType,
  shippingResourceType,
} from 'rapidfab/types';
import { useIntl } from 'react-intl';
import LineItemPowderWorkflowEditForm from 'rapidfab/components/records/order/edit/LineItemPowderWorkflowEditForm';
import _keyBy from 'lodash/keyBy';

// Temporary solution to hide the table field until it is not removed
const oldFieldsToRemove = new Set([
  'sieved-fine-stored-at',
]);

const carpenterCustomFields = new Set([
  'atomized-lump-revert-stored-at',
  'atomized-secondary-hopper-stored-at',
  'atomized-coarse-revert-stored-at',
  'atomized-primary-hopper-stored-at',
  'atomized-scrap-posting-stored-at',

  'sieved-oversized-stored-at',
  'sieved-undersized-stored-at',
  'sieved-fine-stored-at',
  'sieved-coarse-revert-stored-at',

  'classifier-fine-revert-stored-at',
  'classifier-undersized-stored-at',
]);

const carpenterAtomizedTableKeys = {
  'atomized-primary-hopper-stored-at': 'Primary Hopper',
  'atomized-secondary-hopper-stored-at': 'Secondary Hopper',
  'atomized-coarse-revert-stored-at': 'Coarse Revert',
  'atomized-lump-revert-stored-at': 'Lump Revert',
  'atomized-scrap-posting-stored-at': 'Scrap Posting',
};

const carpenterSievedTableKeys = {
  'sieved-undersized-stored-at': 'Undersize',
  'sieved-oversized-stored-at': 'Oversize',
  'sieved-coarse-revert-stored-at': 'Coarse Revert',
};

const carpenterClassifierTableKeys = {
  'classifier-fine-revert-stored-at': 'Fine Revert',
  'classifier-undersized-stored-at': 'Undersize',
};

const atomizedTableDataOptions = {
  type: 'atomized-',
  tableKeys: carpenterAtomizedTableKeys,
};

const sievedTableDataOptions = {
  type: 'sieved-',
  tableKeys: carpenterSievedTableKeys,
};

const classifierTableDataOptions = {
  type: 'classifier-',
  tableKeys: carpenterClassifierTableKeys,
};

const LineItemPowderWorkflowEditFormContainer = props => {
  const intl = useIntl();
  const {
    lineItem,
    // workflowTypeKey,
  } = props;
  const {
    base: baseMaterials,
  } = useSelector(Selectors.getBaseAndSupportMaterials);
  const baseMaterialsByLocationUri = useSelector(Selectors.getBaseMaterialsByLocationUri);
  const compatibleWorkflowUrisByMaterialUris = useSelector(Selectors.getWorkflowUrisByMaterialUri);
  const compatibleWorkflowUrisByShippingUris = useSelector(Selectors.getWorkflowUrisByShippingUri);
  const workflows = useSelector(state => Selectors.getAvailableWorkflowsForLineItem(state, props.lineItem));
  const orderUuid = useSelector(Selectors.getRouteUUID);
  const order = useSelector(state => Selectors.getUUIDResource(state, orderUuid));
  const processSteps = useSelector(Selectors.getProcessSteps);
  const printerTypes = useSelector(Selectors.getPrinterTypes);
  const isCurrentUserRestricted = useSelector(Selectors.isCurrentUserRestricted);
  const customLineItemFieldReferences = useSelector(Selectors.getCustomLineItemFieldReferences);
  const customOrderFieldReferences = useSelector(Selectors.getCustomOrderFieldReferences);
  const shippingsByUri = useSelector(Selectors.getShippingsByUri);
  const pieces = useSelector(state => Selectors.getPiecesForLineItem(state, props.lineItem));
  const anyPiecesHaveModifiedWorkflow = pieces?.some(piece => piece?.workflow !== lineItem?.workflow);

  const isDebugModeEnabled = useSelector(Selectors.getIsDebugModeEnabled);
  const isShipmentForOrderFeatureEnabled = useSelector(state =>
    Selectors.isFeatureEnabled(state, FEATURES.SHIPMENT_FOR_ORDER));
  const isAdditiveWorkflowFeatureEnabled = useSelector(state => Selectors.isFeatureEnabled(
    state,
    FEATURES.ADDITIVE_WORKFLOW,
  ));
  const isPowderWorkflowFeatureEnabled = useSelector(state => Selectors.isFeatureEnabled(
    state,
    FEATURES.POWDER_WORKFLOW,
  ));

  const workChecklistLinkings = useSelector(state =>
    Selectors.getRelatedWorkChecklistLinking(state, props.lineItem.uri));

  const isGuidelineEngineFeatureEnabled = useSelector(state => Selectors.isFeatureEnabled(
    state,
    FEATURES.GUIDELINES_ENGINE,
  ));

  const isUserRestricted = useSelector(Selectors.isCurrentUserRestricted);

  const fetchingGuidelineSuggestionsForLineItem = useSelector(state =>
    state.ui.nautilus[GUIDELINE_API_RESOURCES.FOR_LINE_ITEM].get.fetching,
  );

  const fetchingProcessSteps = useSelector(state => state.ui.nautilus[API_RESOURCES.PROCESS_STEP].list.fetching);
  const fetchingPieces = useSelector(state => state.ui.nautilus[API_RESOURCES.PIECE].list.fetching);
  const fetchingWorkflow = useSelector(state => state.ui.nautilus[API_RESOURCES.WORKFLOW].list.fetching);
  const isPiecesOrWorkflowfetching = fetchingPieces || fetchingWorkflow;

  const piecesWithModifiedWorkflowByWorkflowURI = _keyBy(pieces?.filter(piece => piece?.workflow !== lineItem?.workflow), 'workflow');

  // Hide Carpenter custom fields as "General Inputs" as we will display them in a table.
  const hiddenCarpenterCustomFields = Object.values(customLineItemFieldReferences).filter(customField =>
    !carpenterCustomFields.has(customField.field_id));

  const selected = {
    baseMaterials,
    baseMaterialsByLocationUri,
    customLineItemFieldReferences: hiddenCarpenterCustomFields,
    customOrderFieldReferences,
    compatibleWorkflowUrisByMaterialUris,
    compatibleWorkflowUrisByShippingUris,
    orderUuid,
    order,
    workflows,
    workChecklistLinkings,
    processSteps,
    printerTypes,
    pieces,
    isDebugModeEnabled,
    isCurrentUserRestricted,
    isUserRestricted,
    isShipmentForOrderFeatureEnabled,
    shippingsByUri,
    isGuidelineEngineFeatureEnabled,
    fetchingGuidelineSuggestionsForLineItem,
    fetchingProcessSteps,
    isAdditiveWorkflowFeatureEnabled,
    isPowderWorkflowFeatureEnabled,
    piecesWithModifiedWorkflowByWorkflowURI,
  };

  const lineItemWorkflow = lineItem?.workflow;
  // const [baseMaterial, setBaseMaterial] = useState(lineItem[workflowTypeKey]?.materials.base);
  const [customFieldValues, setCustomFieldValues] = useState(lineItem.custom_field_values);
  const [notes, setNotes] = useState(lineItem.notes);
  const [quantity, setQuantity] = useState(lineItem.quantity.toString());
  const [status, setStatus] = useState(lineItem.status);
  const [workflow, setWorkflow] = useState(lineItemWorkflow);
  const [itemName, setItemName] = useState(lineItem.name);
  const [showCompleteWarningModal, setShowCompleteWarningModal] = useState(false);
  const [isLineItemSubmitting, setIsLineItemSubmitting] = useState(false);
  const [isLineItemDuplicating, setIsLineItemDuplicating] = useState(false);
  const [modalTextPending, setModalTextPending] = useState(false);
  const [designHours, setDesignHours] = useState(lineItem.design_time / 60 / 60);
  const [workflowsFetchMoreState, setWorkflowsFetchMoreState] = useState({ offset: 0, count: 1 });
  const [guidelineSuggestionsForLineItem, setGuidelineSuggestionsForLineItem] = useState([]);

  // Temporary solution until the BE is not implemented for this.
  const transformCarpenterTableCustomFields = typeOptions => {
    const transformedFieldsWithValues = [];
    /* 1. Find all custom fields.
       2. Get only Carpenter-related fields.
       3. Filter by typeOptions.type which is passed in as a prop. */

    // Skip the old data fields to hide them in the UI.
    const filteredCarpenterCustomFields = new Set([...carpenterCustomFields]
      .filter(value => !oldFieldsToRemove.has(value)));

    const filteredFieldsByType = Object.values(customLineItemFieldReferences).filter(customField => {
      if (filteredCarpenterCustomFields.has(customField.field_id)) {
        return customField.field_id.includes(typeOptions.type);
      }
      return false;
    });

    /* 1. Go through all filtered Carpenter Custom Fields
       2. Get Custom Field value at Line Item level (if it exists) or set it as empty string
       3. Return transformed Table data */

    filteredFieldsByType.forEach(customField => {
      const customFieldValue = customFieldValues.find(customFieldValue => customField.uri === customFieldValue.custom_field)?.value || '';
      transformedFieldsWithValues.push({
        tableKey: typeOptions.tableKeys[customField.field_id],
        key: customField.field_id,
        value: customFieldValue,
      });
    });

    return transformedFieldsWithValues.every(field => !field.value)
      ? []
      : transformedFieldsWithValues;
  };

  const carpenterAtomizedTableData = transformCarpenterTableCustomFields(atomizedTableDataOptions);
  const carpenterSievedTableData = transformCarpenterTableCustomFields(sievedTableDataOptions);
  const carpenterClassifierTableData = transformCarpenterTableCustomFields(classifierTableDataOptions);

  const state = {
    isLineItemSubmitting,
    isLineItemDuplicating,
    modalTextPending,
    // baseMaterial,
    customFieldValues,
    notes,
    itemName,
    quantity,
    designHours,
    status,
    workflow,
    showCompleteWarningModal,
    isPiecesOrWorkflowfetching,
    carpenterTableData: {
      carpenterAtomizedTableData,
      carpenterSievedTableData,
      carpenterClassifierTableData,
    },
  };

  const initCustomFieldValues = () => {
    const updatedCustomLineItemFieldValues = getInitialCustomFieldValues(
      customLineItemFieldReferences,
      customFieldValues,
    );
    setCustomFieldValues(updatedCustomLineItemFieldValues);
  };

  const dispatch = useDispatch();

  const onReloadPrints = queryParams => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.PIECE].clear('list'));
    dispatch(Actions.Api.nautilus[API_RESOURCES.PIECE].list(
      queryParams,
      { limit: PAGINATION_IGNORE_DEFAULT_LIMIT },
    )).then(piecesResponse => {
      const currentPieces = piecesResponse.json.resources;
      const pieceUris = _map(currentPieces, 'uri');
      dispatch(
        Actions.Api.nautilus[API_RESOURCES.PRINT].list(
          { piece: pieceUris },
          { limit: PAGINATION_IGNORE_DEFAULT_LIMIT },
          {},
          {},
          true,
        ),
      );
    });
  };

  useEffect(() => {
    if (customLineItemFieldReferences) {
      initCustomFieldValues();
    }
  }, [customLineItemFieldReferences]);

  useEffect(() => {
    setStatus(lineItem.status);
  }, [lineItem.status]);

  useEffect(() => {
    if (lineItemWorkflow) setWorkflow(lineItemWorkflow);
  }, [JSON.stringify(lineItem)]);

  useEffect(() => {
    initCustomFieldValues();
  }, [customLineItemFieldReferences.length]);

  const handleCloseCompleteWarningModal = () => {
    setShowCompleteWarningModal(false);
  };

  const handleInputChange = event => {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    if (status === LINE_ITEM_STATUS.PENDING &&
      value === LINE_ITEM_STATUS.COMPLETE) {
      setShowCompleteWarningModal(true);
      setModalTextPending(true);
      return;
    }

    if ((status === LINE_ITEM_STATUS.PRINTING ||
      status === LINE_ITEM_STATUS.POST_PROCESSING ||
      status === LINE_ITEM_STATUS.SHIPPING) &&
      value === LINE_ITEM_STATUS.COMPLETE) {
      showCompleteWarningModal(true);
      return;
    }

    if (name === 'workflow') {
      props.onWorkflowChange(value);
    }

    switch (name) {
      case 'itemName':
        setItemName(value);
        break;
      case 'status':
        setStatus(value);
        break;
      case 'quantity':
        setQuantity(value);
        break;
      case 'notes':
        setNotes(value);
        break;
      case 'designHours':
        setDesignHours(value);
        break;
      // case 'baseMaterial':
      //   setBaseMaterial(value);
      //   break;
      case 'workflow':
        setWorkflow(value);
        break;
      default:
        break;
    }
  };

  const onDuplicate = useCallback(currentQuantity => {
    setIsLineItemDuplicating(true);

    return dispatch(Actions.Api.nautilus[API_RESOURCES.LINE_ITEM].clone(lineItem.uuid, { quantity: currentQuantity }))
      .finally(() => {
        setIsLineItemDuplicating(false);
      });
  }, [dispatch, lineItem.uuid]);

  const onSubmit = async () => {
    const selectedWorkflow = _find(workflows, { uri: workflow });

    if (!selectedWorkflow) {
      Alert.warning('Workflow is required. Please select a workflow.');
      return;
    }

    /* Parent API calls to process-step caps-off at 5,000 entries, which possibly excludes the entries
    related to this workflow. The following API call ensures the process-steps are fetched the belong to
    the current workfow. */
    const processStepResponse = await dispatch(Actions.Api.nautilus[API_RESOURCES.PROCESS_STEP]
      .list({ uri: selectedWorkflow.process_steps }, {}, {}, {}, true));
    const processStepsForCurrentWorkflow = processStepResponse.json?.resources;

    // if (!isCurrentUserRestricted) {
    //   const selectedMaterial = _find(baseMaterials, { uri: baseMaterial });
    //
    //   // get workflow compatible with the base material
    //   const compatibleWorkflowUris = compatibleWorkflowUrisByMaterialUris[baseMaterial];
    //
    //   // note: only restricted users don't have the option to select a workflow
    //   if ((!compatibleWorkflowUris?.length || !compatibleWorkflowUris.includes(workflow))
    //     && selectedWorkflow) {
    //     Alert.error(
    //       `Material/Workflow Conflict: Cannot update line item with requested
    //       Material and Workflow combination because ${selectedWorkflow.name} does
    //       not support ${selectedMaterial.name}. Please review the Workstation Type
    //       page, for the Workflow's first step, to confirm the requested material is
    //       included in its materials list.`,
    //     );
    //     return;
    //   }
    // }

    if (processStepsForCurrentWorkflow?.length
      && isShipmentForOrderFeatureEnabled
      && order?.shipping_grouping === ORDER_SHIPPING_GROUPING_OPTIONS.BY_ORDER
      && LINE_ITEM_PRODUCTION_STAGE_STATUSES.includes(status)
      && order.shipping?.uri) {
      const compatibleWorkflowUris = compatibleWorkflowUrisByShippingUris[order.shipping.uri];
      const selectedOrderShipping = shippingsByUri[order.shipping.uri];

      if (!compatibleWorkflowUris.includes(workflow) && selectedWorkflow && selectedOrderShipping) {
        Alert.error(
          `Shipping/Workflow Conflict: Cannot confirm line item with the requested
          Workflow and Shipping combination because ${selectedWorkflow.name} does
          not match ${selectedOrderShipping.name}.`,
        );
        return;
      }
    }

    const payload = {
      autorun: true,
      bureau: lineItem.bureau,
      // additive: {
      //   materials: {
      //     base: baseMaterial,
      //   },
      // },
      notes,
      name: itemName,
      quantity: Number.parseInt(quantity, 10),
      workflow,
      custom_field_values: customFieldValues,
    };

    // // Remove base material from the payload when it has not been updated.
    // if (lineItem[workflowTypeKey]?.materials.base === baseMaterial) {
    //   delete payload.additive?.materials.base;
    // }

    // TODO: Remove support material from the payload when it has not been updated.
    // if (lineItem[workflowTypeKey]?.materials.support === supportMaterial) {
    //   delete payload.additive?.materials.support;
    // }

    if (status !== lineItem.status) {
      payload.status = status;
    }

    if (designHours) {
      // Convert hours to seconds
      payload.design_time = Number.parseFloat(designHours) * 60 * 60;
    }

    setIsLineItemSubmitting(true);

    dispatch(Actions.Api.nautilus[API_RESOURCES.LINE_ITEM].put(lineItem.uuid, payload)).then(() => {
      setIsLineItemSubmitting(false);
      onReloadPrints({ line_item: lineItem.uri });
      Alert.success(intl.formatMessage(
        { id: 'toaster.lineItem.successfullyUpdated', defaultMessage: 'Line Item successfully updated.' }));
    }).catch(() => {
      setIsLineItemSubmitting(false);
      // Alert with error also will be handled by main app component
    });
  };

  const onDelete = () =>
    // TODO: This needs to be adjusted, once there are more than 1 line item for product
    dispatch(Actions.Api.nautilus[API_RESOURCES.PRODUCT].delete(extractUuid(lineItem.product))).then(() => {
      dispatch(Actions.Api.nautilus[API_RESOURCES.LINE_ITEM].remove(extractUuid(lineItem.uri)));
    });

  const onReloadChecklistLinkings = () =>
    dispatch(Actions.Api.nautilus[API_RESOURCES.WORK_CHECKLIST_LINKING].list({ related_uri: lineItem.uri }));

  const onReloadPrintsForOrder = orderUri => {
    onReloadPrints({ order: orderUri });
  };

  const onCustomFieldChange = (_, value) => {
    const customFieldValuesReplaced = createOrReplaceArray(
      customFieldValues,
      { custom_field: value.customFieldReferenceUri },
      { value: value.value },
    );

    setCustomFieldValues(customFieldValuesReplaced);
  };

  const submitConfirmModal = () => {
    setShowCompleteWarningModal(false);
  };

  const onFetchMoreWorkflows = async () => {
    const limit = 100;
    const response = await dispatch(Actions.Api.nautilus[API_RESOURCES.WORKFLOW].list(
      { include_custom_workflows: true },
      { limit, offset: workflowsFetchMoreState.offset }, {}, { sort: 'name' }, true),
    );
    setWorkflowsFetchMoreState(previous => (
      { offset: previous.offset + limit, count: response?.json.meta?.count || 0 }
    ));
  };

  const getGuidelineSuggestionsForLineItem = async () => {
    const forLineItemResponse = await dispatch(Actions.Api.nautilus[GUIDELINE_API_RESOURCES.FOR_LINE_ITEM]
      .get(lineItem.uuid, true));
    if (forLineItemResponse) {
      setGuidelineSuggestionsForLineItem(forLineItemResponse.json?.resources);
    }
  };

  useEffect(() => {
    if (isGuidelineEngineFeatureEnabled && lineItem.workflow) {
      getGuidelineSuggestionsForLineItem();
    }
  }, [isGuidelineEngineFeatureEnabled, lineItem.workflow, lineItem.uuid]);

  const {
    readOnly,
    isRestrictedUploadModelLibraryFeatureEnabled,
  } = props;

  // const baseMaterialURI = baseMaterials.find(
  //   material => material.uri === baseMaterial,
  // );

  // const baseMaterialFormUrl = baseMaterialURI ?
  //   `/#/records/material/${extractUuid(baseMaterialURI.uri)}` : '';

  // const baseMaterialColor = baseMaterialURI ? baseMaterialURI.color : null;
  // const baseMaterialUrl = baseMaterialURI && baseMaterialURI.external_datasheet_url ?
  //   baseMaterialURI.external_datasheet_url :
  //   baseMaterialFormUrl;

  let allowFileChange = true;

  if (isRestrictedUploadModelLibraryFeatureEnabled) {
    allowFileChange = !isCurrentUserRestricted;
  }

  return (
    <LineItemPowderWorkflowEditForm
      {...props}
      {...state}
      {...selected}
      // baseMaterial={baseMaterial}
      // baseMaterialColor={baseMaterialColor}
      // baseMaterialUrl={baseMaterialUrl}
      handleInputChange={handleInputChange}
      handleCloseCompleteWarningModal={handleCloseCompleteWarningModal}
      onDelete={onDelete}
      onSubmit={onSubmit}
      onDuplicate={onDuplicate}
      onCustomFieldChange={onCustomFieldChange}
      onReloadPrints={onReloadPrintsForOrder}
      onReloadChecklistLinkings={onReloadChecklistLinkings}
      itemName={itemName}
      submitConfirmModal={submitConfirmModal}
      readOnly={readOnly}
      allowFileChange={allowFileChange}
      onFetchMoreWorkflows={
        workflowsFetchMoreState.offset < workflowsFetchMoreState.count ? onFetchMoreWorkflows : null
      }
      guidelineSuggestionsForLineItem={guidelineSuggestionsForLineItem}
      anyPiecesHaveModifiedWorkflow={anyPiecesHaveModifiedWorkflow}
    />
  );
};

LineItemPowderWorkflowEditFormContainer.defaultProps = {
  readOnly: false,
  lineItem: lineItemDefaultValues,
  // Model can be null, when `no_model_upload` is true
};

LineItemPowderWorkflowEditFormContainer.propTypes = {
  orderUuid: PropTypes.string.isRequired,
  baseMaterials: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  customLineItemFieldReferences: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  dispatch: PropTypes.func.isRequired,
  lineItem: lineItemResourceType,
  workflows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  processSteps: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  printerTypes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  readOnly: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  isCurrentUserRestricted: PropTypes.bool.isRequired,
  workChecklistLinkings: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isRestrictedUploadModelLibraryFeatureEnabled: PropTypes.bool.isRequired,
  onWorkflowChange: PropTypes.func.isRequired,
  compatibleWorkflowUrisByMaterialUris: PropTypes.objectOf(PropTypes.string).isRequired,
  compatibleWorkflowUrisByShippingUris: PropTypes.objectOf(PropTypes.string).isRequired,
  pieces: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isShipmentForOrderFeatureEnabled: PropTypes.bool.isRequired,
  order: orderResourceType.isRequired,
  shippingsByUri: PropTypes.objectOf(shippingResourceType).isRequired,
  workflowTypeKey: PropTypes.string.isRequired,
};

export default LineItemPowderWorkflowEditFormContainer;
