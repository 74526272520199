import { TOOLING_STOCK_ACTION_TYPES } from 'rapidfab/constants/resources';

export const ORDER_SUMMARY = {
  FIELDS: [
    'application_type',
    'due_date',
    'customer_email',
    'customer_name',
    'customer_po',
    'ip_sensitivity',
    'location',
    'name',
    'order_owner',
    'order_type',
    'priority',
    'region',
    'shipping',
    'status',
    'uuid',
    'customer_go',
    'planning_status',
    'build_file_status',
    'custom_field_values',
    'shipping_grouping',
  ],
  NULL_FIELDS: [
    'location',
    'order_owner',
  ],
};

export const ORDER_SUMMARY_RESTRICTED = {
  FIELDS: [
    'application_type',
    'custom_field_values',
    'due_date',
    'customer_email',
    'customer_name',
    'customer_po',
    'ip_sensitivity',
    'location',
    'name',
    'order_owner',
    'order_type',
    'region',
    'shipping',
    'shipping.name',
    'shipping.address',
    'shipping.tracking',
    'shipping.uri',
    'status',
    'uuid',
  ],
};

export const NEW_ORDER_FORM_CONTAINER = {
  FIELDS: [
    'application_type',
    'due_date',
    'customer_email',
    'customer_name',
    'customer_po',
    'ip_sensitivity',
    'initial_comment', // not order resource field, but on order form. Is used to submit as separate comment resource
    'location',
    'name',
    'order_owner',
    'order_type',
    'priority',
    'region',
    'shipping',
    'custom_field_values',
    'shipping_grouping',
  ],
  UNIQUE_FIELDS: [
    'due_date',
    'name',
  ],
};

export const CUSTOM_FIELDS_MODAL = {
  FIELDS: [
    'field_name',
    'field_id',
    'related_table_name',
    'type',
    'default_value',
    'required',
    'meta',
    'meta.tooltip',
    'meta.placeholder',
    'enabled',
    'is_visible_for_restricted',
    'options',
    'position',
    'description',
  ],
  FLOAT_FIELDS: [
    'position',
  ],
};

export const NEW_RESTRICTED_ORDER_FORM_CONTAINER = {
  FIELDS: [
    'application_type',
    'custom_field_values',
    'due_date',
    'customer_email',
    'customer_name',
    'customer_po',
    'initial_comment', // not order resource field, but on order form. Is used to submit as separate comment resource
    'ip_sensitivity',
    'location',
    'name',
    'order_owner',
    'order_type',
    'region',
    'shipping.name',
    'shipping.address',
    'shipping.tracking',
    'shipping.uri',
  ],
};

export const MODELER_MODAL_CONTAINER = {
  FIELDS: [
    'uuid',
    'uri',
    'name',
    'ip_address',
    'serial_number',
    'username',
    'password',
    'type',
    'history_collection_frequency',
  ],
  STRING_FIELDS: [
    'serial_number',
  ],
};

export const MATERIAL_TYPE_STOCK_MODAL = {
  FIELDS: [
    'location',
    'status',
    'quantity',
    'track_quantity',
  ],
};

export const MATERIAL_LOT_CREATE_PO_MODAL = {
  FIELDS: [
    'external_id',
    'planned_full_quantity',
    'material',
    'units',
  ],
};

export const MATERIAL_LOT_RECEIVE_MODAL = {
  FIELDS: [
    'isAsExpected',
    'notes',
  ],
};

export const RUN_ACTUALS_MODAL = {
  FIELDS: [
    'startDate',
    'startTime',
    'endDate',
    'endTime',
    'materialWeight',
  ],
};

export const MODEL_LIBRARY_DETAILS_PANEL = {
  FIELDS: [
    'notes',
    'labels',
    'uuid',
  ],
};

export const PRODUCT_SPECIMEN_DETAIL_PANEL = {
  FIELDS: [
    'layer_thickness',
    'base_material',
    'support_material',
    'workflow',
    'uuid',
  ],
  FLOAT_FIELDS: [
    'layer_thickness',
  ],
  ADDITIVE: [
    'base_material',
    'layer_thickness',
    'model',
    'source_line_item',
    'support_material',
  ],
};

export const LABEL_MODAL_CONTAINER = {
  FIELDS: [
    'color',
    'description',
    'name',
  ],
};

export const ANATOMICAL_MODEL_CONTAINER = {
  FIELDS: [
    'uuid',
    'name',
    'description',
    'version',
    'scale_to_print',
    'medical_record_number',
    'case_id',
    'accession_number',
    'date_sent',
  ],
  NULL_FIELDS: [
    'description',
    'version',
    'scale_to_print',
    'medical_record_number',
    'case_id',
    'accession_number',
  ],
  DATE_FIELDS: [
    'date_sent',
  ],
  INTEGER_FIELDS: [
    'version',
  ],
};

export const ANATOMICAL_MODEL_ASSEMBLY_MODAL_CONTAINER = {
  FIELDS: [
    'uuid',
    'uri',
    'name',
    'assembly_type',
    'printing_tech',
  ],
};

export const ANATOMICAL_MODEL_PART_MODAL_CONTAINER = {
  FIELDS: [
    'uuid',
    'uri',
    'name',
    'finish',
    'to_be_printed',
    'hollow_shells',
    'solid_infill',
    'flexibility',
    'color',
    'color_opacity',
    'printer_anatomy_family',
    'printer_anatomy_element',
    'printer_anatomy_property_name',
    'printer_anatomy_property_value',
  ],
  FLOAT_FIELDS: [
    'color_opacity',
    'hollow_shells',
  ],
};

export const MODEL_LIBRARY_CREATE_CONTAINER = {
  FIELDS: [
    'name',
    'type',
    'base_material',
    'layer_thickness',
    'file_unit',
    'default_customer_id',
  ],
  NULL_FIELDS: [
    'base_material',
  ],
  ADDITIVE: [
    'base_material',
    'layer_thickness',
    'model',
    'source_line_item',
    'support_material',
  ],
};

export const PREP_TASK_MODAL_CONTAINER = {
  FIELDS: [
    'uuid',
    'uri',
    'name',
    'shortname',
    'edit_group',
    'user_estimated_work_time',
    'user_estimated_dwell_time',
    'description',
    'copy',
  ],
};

export const PREP_TASK_RECORD_MODAL_CONTAINER = {
  FIELDS: [
    'uuid',
    'status',
  ],
};

export const SERVICE_PROVIDER_CONTAINER = {
  FIELDS: [
    'id',
    'uri',
    'uuid',
    'name',
    'address',
    'email',
    'website',
    'phone_number',
    'shipping_tracking',
    'bureau',
  ],
};

export const CONVERSION = {
  FIELDS: [
    'id',
    'uuid',
    'currency',
    'value',
    'bureau',
  ],
  FLOAT_FIELDS: [
    'value',
  ],
};

export const LOCATION_CONTAINER = {
  FIELDS: [
    'id',
    'uri',
    'uuid',
    'name',
    'phone',
    'bureau',
    'address',
    'contact',
    'countries_served',
  ],
};

export const MATERIAL_CONTAINER = {
  FIELDS: [
    'id',
    'uri',
    'uuid',
    'name',
    'description',
    'color',
    'color_opacity',
    'type',
    'manufacturer',
    'units',
    'density',
    'cost',
    'third_party_fulfillment',
    'bureau',
    'external_datasheet_url',
    'external_id',
    'material_family',
    'manufacturing_process',
    'printer_types_selected',
  ],
  FLOAT_FIELDS: [
    'cost',
    'density',
  ],
  NULL_FIELDS: [
    'density',
    'color',
    'external_datasheet_url',
    'external_id',
    'material_family',
    'manufacturing_process',
  ],
  STRING_FIELDS: [
    'color',
  ],
};

export const MATERIAL_LOT_CONTAINER = {
  FIELDS: [
    'name',
    'status',
    'notes',
    'delivery_id',
    'uuid',
  ],
};

export const MATERIAL_LOT_NEW_CONTAINER = {
  FIELDS: [
    'name',
    'material',
    'location',
    'delivery_id',
    'actual_delivery_date',
    'estimated_delivery_date',
    'expiration_date',
    'number_of_containers',
    'size_of_container',
  ],
  NULL_FIELDS: [
    'actual_delivery_date',
    'estimated_delivery_date',
    'expiration_date',
    'sub_location',
  ],
  DATE_FIELDS: [
    'actual_delivery_date',
    'estimated_delivery_date',
    'expiration_date',
  ],
  INTEGER_FIELDS: [
    'number_of_containers',
  ],
  FLOAT_FIELDS: [
    'size_of_container',
  ],
};

export const PERMANENT_CONTAINER = {
  FIELDS: [
    'uri',
    'name',
    'material_restrictions',
    'location',
    'sub_location',
  ],
  FLOAT_FIELDS: [],
  NULL_FIELDS: [],
  STRING_FIELDS: [],
};

export const POST_PROCESSOR_TYPE_CONTAINER = {
  FIELDS: [
    'id',
    'uuid',
    'name',
    'description',
    'materials',
    'manufacturer',
    'cost',
    'duration',
    'edit_group',
    'special_type',

    'batching_strategy',
    'build_volume',
    'build_volume.x',
    'build_volume.y',
    'build_volume.z',
    'number_of_parts',
    'min_packing_distance',
    'in_progress_max',

    'laborType',
    'workstation_duration',

    'overhead_cost_per_run',
    'overhead_cost_per_piece_in_run',
    'integrated',
  ],
  NULL_FIELDS: [
    'build_volume.x',
    'build_volume.y',
    'build_volume.z',
    'min_packing_distance',
    'number_of_parts',
    'edit_group',

    'special_type',
  ],
  FLOAT_FIELDS: [
    'cost',
    'build_volume.x',
    'build_volume.y',
    'build_volume.z',
    'min_packing_distance',
    'number_of_parts',
    'in_progress_max',
  ],
};

export const TOOLING_STOCK_TYPE_CONTAINER = {
  FIELDS: [
    'id',
    'uuid',
    'name',
    'description',
    'service_provider',
    'refurbish_after',
    'refurbish_required_info',
  ],
  NULL_FIELDS: [
    'description',
    'service_provider',
    'refurbish_required_info',
  ],
  FLOAT_FIELDS: [
    'refurbish_after',
  ],
};

export const PRINTER_TYPE_CONTAINER = {
  FIELDS: [
    'id',
    'uri',
    'uuid',
    'name',
    'description',
    'manufacturer',
    'build_volume',
    'build_volume.x',
    'build_volume.y',
    'build_volume.d',
    'build_volume.z',
    'time_before_print',
    'time_after_print',
    'materials',
    'infill_strategies',
    'support_strategies',
    'running_cost_per_hour',
    'print_cost_scale_factor',
    'material_cost_scale_factor',
    'constant_overhead',
    'overhead_per_line_item',
    'build_packer_type',
    'min_packing_distance',
    'manufacturing_process',
    'edit_group',
    'material_mode',
    'laborType',
    'integrated',
  ],
  NULL_FIELDS: [
    'manufacturing_process',
    'edit_group',
  ],
  FLOAT_FIELDS: [
    'constant_overhead',
    'min_packing_distance',
    'overhead_per_line_item',
    'running_cost_per_hour',
  ],
  INTEGER_FIELDS: [
    'print_cost_scale_factor',
    'material_cost_scale_factor',
    'build_volume.x',
    'build_volume.y',
    'build_volume.z',
    'build_volume.d',
  ],
};

export const SHIPMENT_ADDRESS_MODAL_CONTAINER = {
  FIELDS: [
    'name',
    'address',
    'phone_number',
    'email',
  ],
};

export const SHIPMENT_CONTAINER = {
  FIELDS: [
    'name',
    'estimated_shipment_date',
    'estimated_delivery_date',
    'actual_shipment_date',
    'actual_delivery_date',
    'actual_cost',
    'status',
    'shipping_partner',
    'tracking_number',
    'uuid',
    'currency',
  ],
  NULL_FIELDS: [
    'estimated_shipment_date',
    'estimated_delivery_date',
    'actual_shipment_date',
    'actual_delivery_date',
  ],
  DATE_FIELDS: [
    'estimated_shipment_date',
    'estimated_delivery_date',
    'actual_shipment_date',
    'actual_delivery_date',
  ],
};

export const SHIPPING_CONTAINER = {
  FIELDS: [
    'id',
    'uuid',
    'name',
    'description',
    'region',
    'cost',
    'bureau',
    'uri',
    'batching_strategy',
    'build_volume',
    'build_volume.x',
    'build_volume.y',
    'build_volume.z',
    'min_packing_distance',
    'number_of_parts',
  ],
  NULL_FIELDS: [
    'build_volume.x',
    'build_volume.y',
    'build_volume.z',
    'min_packing_distance',
    'number_of_parts',
  ],
  FLOAT_FIELDS: [
    'cost',
    'build_volume.x',
    'build_volume.y',
    'build_volume.z',
    'min_packing_distance',
  ],
  INTEGER_FIELDS: [
    'number_of_parts',
  ],
};

export const STOCK_CONTAINER = {
  FIELDS: [
    'uuid',
    'material',
    'location',
    'status',
    'quantity',
    'stock_min',
    'stock_max',
    'on_order_quantity',
    'track_quantity',
  ],
  FLOAT_FIELDS: [
    'stock_min',
    'stock_max',
    'on_order_quantity',
    'quantity',
  ],
};

export const SERVICE_PROVIDER_JOB_CONTAINER = {
  FIELDS: [
    'id',
    'uri',
    'uuid',
    'currency',
    'cost',
    'due_at',
    'line_item',
    'order',
    'service_provider',
    'provider_notes',
    'shipping_tracking',
    'status',
  ],
  NULL_FIELDS: [
    'cost',
    'due_at',
  ],
  FLOAT_FIELDS: [
    'cost',
  ],
  READONLY_FIELDS: [
    // Not available for update
    'line_item',
    'order',
    'service_provider',
  ],
};

export const PREP_WORKFLOW_MODAL_CONTAINER = {
  FIELDS: [
    'uuid',
    'uri',
    'name',
    'description',
    'tasks_in_workflow',
    'source_workflow',
  ],
  READONLY_FIELDS: [
    'source_workflow',
  ],
  IGNORED_FIELDS: [
    'key',
  ],
};

export const ADMIN_SETTINGS_CONTAINER = {
  FIELDS: [
    'autologout_after',
    'mes_base_format',
    'default_currency',
    'hourly_design_cost',
    'default_workflow',
    'default_prep_workflow',
    'notification_order_created',
    'notification_line_items_confirmed',
    'notification_order_scheduled',
    'notification_order_started_printing',
    'notification_print_error',
    'notification_order_completed',
    'order_quote_notes_placeholder',
    'contactless_logon_enabled',
    'contactless_logon_timeout',
    'uri',
    // Hidden under `anatomical-model` feature
    'default_magnet_assembly_workflow',
    'default_glue_assembly_workflow',
    'custom_helpdesk_uri',
    'barcode_default_format',
    'email_sender_name',
    'email_reply_to',
  ],
  NULL_FIELDS: [
    'hourly_design_cost',
    'default_workflow',
  ],
  FLOAT_FIELDS: [
    'hourly_design_cost',
    'contactless_logon_timeout',
  ],
  INTEGER_FIELDS: [
    'autologout_after',
  ],
  ARRAY_FIELDS: [
    'notification_custom_emails',
  ],
  READONLY_FIELDS: [
    'mes_base_format',
    'default_currency',
    'default_prep_workflow',
    'helpdesk_type',
  ],
  BOOLEAN_VALUES: [
    'contactless_logon_enabled',
  ],
};

export const WORK_CHECKLIST_CONTAINER = {
  FIELDS: [
    'description',
    'name',
    'work_instructions',
    'uri',
    'additional_instruction_url',
  ],
};

export const WORKFLOW_CONTAINER = {
  FIELDS: [
    'id',
    'uuid',
    'name',
    'labels',
    'description',
    'region',
    'cost',
    'type',
  ],
  STRING_FIELDS: [
    'description',
  ],
};

export const SUB_LOCATIONS_FIELDS_MODAL = {
  FIELDS: [
    'name',
    'description',
  ],
  STRING_FIELDS: [
    'description',
  ],
};

export const SUB_LOCATIONS_SENSOR_MODAL_FORM = {
  FIELDS: [
    'serial_number',
    'frequency',
    'state',
  ],
};

export const MANUFACTURER_FORM = {
  FIELDS: [
    'name',
    'contact.name',
    'contact.phone',
    'support.name',
    'support.phone',
    'address',
    'notes',
  ],
  NULL_FIELDS: [
    'contact.name',
    'contact.phone',
    'support.name',
    'support.phone',
    'address',
    'notes',
  ],
};

export const TOOLING_STOCK_FORM = {
  FIELDS: [
    'name',
    'type',
    'location',
    'sub_location',
    'notes',
  ],
  NULL_FIELDS: [
    'notes',
  ],
};

export const TOOLING_STOCK_ACTION_FORM = {
  FIELDS: [
    'action_type',
    'notes',
  ],
  NULL_FIELDS: [
    'notes',
  ],
};

export const TOOLING_STOCK_ACTION_METADATA_FORM = {
  FIELDS_BY_ACTION: {
    [TOOLING_STOCK_ACTION_TYPES.MAKE_READY_FOR_USE]: ['destination_location', 'destination_sub_location'],
    [TOOLING_STOCK_ACTION_TYPES.RELOCATE]: ['destination_location', 'destination_sub_location'],
    [TOOLING_STOCK_ACTION_TYPES.COMPLETE_REFURBISH]: ['test_data'],
    [TOOLING_STOCK_ACTION_TYPES.RECORD_TESTS]: ['test_results'],
  },

  NULL_FIELDS_BY_ACTION: {
    [TOOLING_STOCK_ACTION_TYPES.MAKE_READY_FOR_USE]: ['destination_sub_location'],
    [TOOLING_STOCK_ACTION_TYPES.RELOCATE]: ['destination_sub_location'],
  },
};

export const workStepDetailsReadOnlyFields = [
  'operation',
  'estimated_cost_per_instance',
  'material_cost_per_instance',
  'updated',
  'updated_by',
];
