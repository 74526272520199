import React from 'react';
import PropTypes from 'prop-types';
import { API_RESOURCES } from 'rapidfab/constants';
import Actions from 'rapidfab/actions';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import { getUUIDResource } from 'rapidfab/selectors';
import { connect } from 'react-redux';
import ProductInfo from 'rapidfab/components/Product/ProductInfo';
import { productResourceType } from 'rapidfab/types';

const ProductInfoContainer = ({ product, onProductConfirm, onSubmit }) => (
  <ProductInfo
    product={product}
    onProductConfirm={onProductConfirm}
    onSubmit={onSubmit}
  />
);

ProductInfoContainer.propTypes = {
  product: productResourceType.isRequired,
  onProductConfirm: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

const mapStateToProps = (state, { productUri }) => {
  const product = getUUIDResource(state, extractUuid(productUri));

  return {
    product,
  };
};

const mapDispatchToProps = (dispatch, { productUri }) => {
  const submitProduct =
    payload => dispatch(Actions.Api.nautilus[API_RESOURCES.PRODUCT].put(extractUuid(productUri), payload));
  return {
    onSubmit: payload => submitProduct(payload),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductInfoContainer);
