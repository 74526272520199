import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  getUsersByUri,
  getBureau,
  getCurrentUserRole,
  isFeatureEnabled,
} from 'rapidfab/selectors';
import { USER_ROLES, FEATURES, API_RESOURCES } from 'rapidfab/constants';
import { Card } from 'react-bootstrap';
import { FormattedMessage } from 'rapidfab/i18n';
import OwnerPanelHeader from 'rapidfab/components/records/model_library/owner/OwnerPanelHeader';
import EditOwner from 'rapidfab/components/records/model_library/owner/EditOwner';
import { getEndpointFromURI } from 'rapidfab/utils/uriUtils';
import _values from 'lodash/values';
import { modelLibraryResourceType, bureauResourceType, userResourceType } from 'rapidfab/types';

const OwnerPanel = ({
  usersByUri,
  bureau,
  modelLibrary,
  currentUserRole,
  isHawkingUser,
  onUpdate,
  isStanleyXUser,
  isDigitalDesignWarehouseFeatureEnabled,
}) => {
  const isManager = currentUserRole === USER_ROLES.MANAGER;
  // bureau is owner if /bureau/ endpoint in modelLibrary.owner URI
  const isBureauOwner = getEndpointFromURI(modelLibrary.owner).endpointName === API_RESOURCES.BUREAU;
  const owner = isBureauOwner ? bureau : usersByUri[modelLibrary.owner];

  const [isEditingMode, setIsEditingMode] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [newOwnerUri, setNewOwnerUri] = useState(modelLibrary.owner);

  const onSave = async ownerUri => {
    if (ownerUri !== modelLibrary.owner) {
      setIsSaving(true);
      try {
        await onUpdate({ owner: ownerUri, uuid: modelLibrary.uuid });
      } finally {
        setIsEditingMode(false);
        setIsSaving(false);
      }
    } else {
      setIsEditingMode(false);
    }
  };

  const onPublish = () => onSave(bureau.uri);
  const onSubmitForm = event => {
    event.preventDefault();
    onSave(newOwnerUri);
  };

  return (
    <form onSubmit={onSubmitForm}>
      <Card className="m-b" bg="light">
        <Card.Header className="pd-exp">
          <OwnerPanelHeader
            isManager={isManager}
            isSaving={isSaving}
            isHawkingUser={isHawkingUser}
            isEditingMode={isEditingMode}
            setIsEditingMode={setIsEditingMode}
            isStanleyXUser={isStanleyXUser}
            isDigitalDesignWarehouseFeatureEnabled={isDigitalDesignWarehouseFeatureEnabled}
          />
        </Card.Header>
        <Card.Body>
          {isEditingMode
            ? (
              <EditOwner
                isBureauOwner={isBureauOwner}
                modelLibrary={modelLibrary}
                users={_values(usersByUri)}
                newOwnerUri={newOwnerUri}
                setNewOwnerUri={setNewOwnerUri}
                onPublish={onPublish}
              />
            ) : (
              <div>
                <b className="spacer-right">
                  <FormattedMessage id="field.owner" defaultMessage="Owner" />:
                </b>
                {owner ? owner.name : <FormattedMessage id="notAvailable" defaultMessage="N/A" />}
              </div>
            )}
        </Card.Body>
      </Card>
    </form>
  );
};
OwnerPanel.propTypes = {
  usersByUri: PropTypes.objectOf(userResourceType).isRequired,
  bureau: bureauResourceType.isRequired,
  modelLibrary: modelLibraryResourceType.isRequired,
  currentUserRole: PropTypes.string.isRequired,
  isHawkingUser: PropTypes.bool.isRequired,
  isStanleyXUser: PropTypes.bool.isRequired,
  onUpdate: PropTypes.func.isRequired,
  isDigitalDesignWarehouseFeatureEnabled: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  usersByUri: getUsersByUri(state),
  bureau: getBureau(state),
  currentUserRole: getCurrentUserRole(state),
  isHawkingUser: isFeatureEnabled(state, FEATURES.HAWKING_DEPLOYMENT) ||
    isFeatureEnabled(state, FEATURES.AUTHENTISE_PDM),
  isStanleyXUser: isFeatureEnabled(state, FEATURES.STANLEY_X_DEPLOYMENT),
});

export default connect(mapStateToProps)(OwnerPanel);
