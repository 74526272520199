import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Col, ListGroup, ListGroupItem, Card, Row } from 'react-bootstrap';

import { getRouteUUIDResource, getRunsForOrder } from 'rapidfab/selectors';

import Loading from 'rapidfab/components/Loading';
import { RUN_STATUS_MAP } from 'rapidfab/mappings';
import { API_RESOURCES, RUN_STATUSES } from 'rapidfab/constants';
import { runType } from 'rapidfab/types/resources';
import _sortBy from 'lodash/sortBy';

const Header = runs => {
  const complete = runs
    .filter(run => run.status === RUN_STATUSES.COMPLETE)
    .length.toString();
  const total = (runs ? runs.length : 0).toString();

  return (
    <FormattedMessage
      id="record.runCompleteCount"
      defaultMessage="Runs - {complete} / {total} complete"
      values={{
        complete,
        total,
      }}
    />
  );
};

const RunItem = ({ run }) => (
  <ListGroupItem>
    <Row>
      <Col
        xs={9}
      >
        <div
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          <a
            href={`#/records/run/${run.uuid}`}
            className="text-truncate text-nowrap"
          >
            {run.workstation_name} ({run.workstation_type_name})
          </a>
        </div>
      </Col>
      <Col xs={3}>
        <FormattedMessage {...RUN_STATUS_MAP[run.status]} />
      </Col>
    </Row>
  </ListGroupItem>
);

RunItem.propTypes = {
  run: runType.isRequired,
};

const OrderRuns = ({ fetching, runs, bg = 'dark' }) => {
  if (fetching) {
    return (
      <Card>
        <Card.Header>Runs</Card.Header>
        <Card.Body>
          <Loading />
        </Card.Body>
      </Card>
    );
  }
  if (runs.length) {
    return (
      <Card bg={bg} className="m-b">
        <Card.Header className="pd-exp inverse">{Header(runs)}</Card.Header>
        <div className="card-body-wrapper">
          <ListGroup className="panel--scrollable" fill>
            <ListGroupItem className="header-item" key="header">
              <Row>
                <Col xs={9}>Workstation (Type)</Col>
                <Col xs={3}>Status</Col>
              </Row>
            </ListGroupItem>

            {_sortBy(runs, 'created').map(run => <RunItem key={run.id} run={run} />)}
          </ListGroup>
        </div>
      </Card>
    );
  }
  return (
    <Card bg={bg} className="m-b">
      <Card.Header className="pd-exp inverse">
        <FormattedMessage id="plan.runs" defaultMessage="Runs" />
      </Card.Header>
      <div className="card-body-wrapper">
        <Card.Body>
          <FormattedMessage
            id="record.noRunsMessage"
            defaultMessage="Order not assigned to any runs yet"
          />
        </Card.Body>
      </div>
    </Card>
  );
};

OrderRuns.defaultProps = {
  fetching: true,
  runs: [],
  bg: 'dark',
};

OrderRuns.propTypes = {
  fetching: PropTypes.bool,
  runs: PropTypes.arrayOf(PropTypes.shape({})),
  bg: PropTypes.string,
};

const mapStateToProps = state => {
  const order = getRouteUUIDResource(state);
  const runs = getRunsForOrder(state, order);

  const { fetching } = state.ui.nautilus[API_RESOURCES.RUN].list;

  return {
    fetching,
    runs,
  };
};

export default connect(mapStateToProps)(OrderRuns);
