import React, { useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import Actions from 'rapidfab/actions';
import Alert from 'rapidfab/utils/alert';
import { getBureauDefaultCurrency, getUUIDResource } from 'rapidfab/selectors';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import { useDispatch, useSelector } from 'react-redux';
import { hhmmss, parseHhmmss } from 'rapidfab/utils/timeUtils';
import _clone from 'lodash/clone';
import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import { API_RESOURCES, LINE_ITEM_COMPOSITION_TYPES } from 'rapidfab/constants';
import EditEstimationTimeModal from 'rapidfab/components/modals/EditEstimationTimeModal';
import { FormLabel, FormControl, FormGroup, Button, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FormattedMessage } from 'rapidfab/i18n';
import { CustomFormControlCost } from 'rapidfab/components/formTools';
import { getLineItemEstimatesTypeObjectKey } from 'rapidfab/utils/lineItemUtils';

const EditLineItemEstimationModal = ({ lineItemUri, onClose, hideFinancial }) => {
  const lineItem = useSelector(state => getUUIDResource(state, extractUuid(lineItemUri)));
  const isSubmitting = useSelector(state => state.ui.nautilus[API_RESOURCES.LINE_ITEM].put.fetching);
  const currency = useSelector(getBureauDefaultCurrency);
  const workflowTypeEstimatesKey = getLineItemEstimatesTypeObjectKey(lineItem);

  const [estimates, setEstimates] = useState(() => ({
    estimatesPrintTime: hhmmss(lineItem[workflowTypeEstimatesKey].printing_time || 0, true),
    estimatesBureauCost: lineItem.estimates.total_cost || 0,
    estimatesBureauPrice: lineItem.estimates.total_price || 0,
    additionalCosts: lineItem.additional_costs || [],
  }));

  const dispatch = useDispatch();

  const submitEstimates = (estimates, lineItem) => {
    const estimatedPrintSeconds = dayjs.duration(parseHhmmss(estimates.estimatesPrintTime)).asSeconds();
    const payload = {
      estimates: {},
      [workflowTypeEstimatesKey]: {},
      additional_costs: _map(estimates.additionalCosts, ({ cost_name, cost_value }) => ({
        cost_name, cost_value: Number(cost_value),
      })),
    };

    // do not resubmit unless edited. When these are submitted, auto-recalculate price stops
    const parsedPrintingTime = Number.parseInt(estimatedPrintSeconds, 10);
    const lineItemPrintingTime = lineItem[workflowTypeEstimatesKey].printing_time || 0;
    if (parsedPrintingTime !== lineItemPrintingTime) {
      payload[workflowTypeEstimatesKey].printing_time = parsedPrintingTime;
    }

    // do not resubmit unless edited. When these are submitted, auto-recalculate price stops
    const parsedBureauCost = Number.parseFloat(estimates.estimatesBureauCost);
    if (parsedBureauCost !== lineItem.estimates.total_cost) {
      payload.estimates.total_cost = parsedBureauCost;
    }

    // do not resubmit unless edited. When these are submitted, auto-recalculate price stops
    const parsedBureauPrice = Number.parseFloat(estimates.estimatesBureauPrice);
    if (parsedBureauPrice !== lineItem.estimates.total_price) {
      payload.estimates.total_price = parsedBureauPrice;
    }

    if (_isEmpty(payload.estimates)) delete payload.estimates;

    dispatch(Actions.Api.nautilus[API_RESOURCES.LINE_ITEM].put(lineItem.uuid, payload)).then(() => {
      Alert.success(
        <FormattedMessage
          id="toaster.estimationsUpdated"
          defaultMessage="Estimations successfully updated."
        />,
      );
      onClose();
    });
  };

  const onAddAdditionalCost = () => {
    const newAdditionalCost = { cost_name: '', cost_value: 0 };
    setEstimates({
      ...estimates,
      additionalCosts: [...estimates.additionalCosts, newAdditionalCost],
    });
  };

  const onRemoveAdditionalCost = indexToRemove => {
    const newAdditionalCosts = _clone(estimates.additionalCosts);
    newAdditionalCosts.splice(indexToRemove, 1);
    setEstimates({ ...estimates, additionalCosts: newAdditionalCosts });
  };

  const handleInputChange = event => {
    const { target: { name, value } } = event;
    setEstimates({
      ...estimates,
      [name]: value,
    });
  };

  const handleAdditionalCostInputChange = (event, index) => {
    const newAdditionalCosts = _clone(estimates.additionalCosts);
    newAdditionalCosts[index][event.target.name] = event.target.value;
    setEstimates({ ...estimates, additionalCosts: newAdditionalCosts });
  };

  const onSubmit = event => {
    event.preventDefault();
    submitEstimates(estimates, lineItem);
  };

  // Assembly Workflows have no Printing step
  const showPrintTimeField = lineItem.composition_type !== LINE_ITEM_COMPOSITION_TYPES.ASSEMBLY;

  const renderExtraFields = () => {
    if (hideFinancial) return null;
    return (
      <Row>
        <Col xs={12}>
          <FormGroup className="form-group">
            <FormLabel htmlFor="estimatesBureauCost">
              <FormattedMessage
                id="overrideEstimationBureauCost"
                defaultMessage="Override the estimated bureau cost"
              />:
              <OverlayTrigger
                placement="right"
                overlay={(
                  <Tooltip>
                    This will override the estimated
                    <em className="spacer-left spacer-right">bureau custom</em>
                    cost for producing all pieces in this line-item
                  </Tooltip>
                )}
              >
                <FontAwesomeIcon className="spacer-left spacer-right" icon={faCircleInfo} />
              </OverlayTrigger>
            </FormLabel>
            <CustomFormControlCost
              id="estimatesBureauCost"
              name="estimatesBureauCost"
              type="number"
              value={estimates.estimatesBureauCost}
              onChange={handleInputChange}
              currency={currency}
            />
          </FormGroup>
          <FormGroup className="form-group">
            <FormLabel for="estimatesBureauCost">
              <FormattedMessage
                id="overrideEstimationsBureauPrice"
                defaultMessage="Override the estimated bureau price"
              />:
              <OverlayTrigger
                placement="right"
                overlay={(
                  <Tooltip>
                    This will override the estimated total price the
                    bureau will charge for producing all pieces in this line item.
                  </Tooltip>
                )}
              >
                <FontAwesomeIcon className="spacer-left spacer-right" icon={faCircleInfo} />
              </OverlayTrigger>
            </FormLabel>
            <CustomFormControlCost
              id="estimatesBureauPrice"
              name="estimatesBureauPrice"
              type="number"
              value={estimates.estimatesBureauPrice}
              onChange={handleInputChange}
              currency={currency}
            />
          </FormGroup>
          <FormLabel>
            <FormattedMessage
              id="field.additionalCostsPerPiece"
              defaultMessage="Additional Costs Per Piece"
            />:
          </FormLabel>
          {estimates.additionalCosts.map(({ cost_name, cost_value }, index) => (
            /* eslint-disable-next-line react/no-array-index-key */
            <Row key={index} className="d-flex align-items-center mb-1">
              <Col className="flex-1">
                <FormGroup>
                  <FormControl
                    placeholder="Description"
                    name="cost_name"
                    value={cost_name}
                    onChange={event => handleAdditionalCostInputChange(event, index)}
                    required
                  />
                </FormGroup>
              </Col>
              <Col className="flex-1">
                <FormGroup>
                  <CustomFormControlCost
                    name="cost_value"
                    type="number"
                    value={cost_value}
                    onChange={event => handleAdditionalCostInputChange(event, index)}
                    currency={currency}
                    required
                  />
                </FormGroup>
              </Col>
              <Col className="flex-initial" style={{ width: '40px' }}>
                <Button
                  variant="danger"
                  size="xs"
                  onClick={() => onRemoveAdditionalCost(index)}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </Button>
              </Col>
            </Row>
          ))}
          <Button
            className="pull-right mt-2"
            variant="primary"
            onClick={onAddAdditionalCost}
          >
            <FormattedMessage id="button.addAdditionalCost" defaultMessage="Add Additional Cost" />
          </Button>
        </Col>
      </Row>
    );
  };

  return (
    <EditEstimationTimeModal
      show
      showPrintTimeField={showPrintTimeField}
      printTime={estimates.estimatesPrintTime}
      onChange={handleInputChange}
      submit={onSubmit}
      onHide={onClose}
      loading={isSubmitting}
      extraFields={renderExtraFields()}
    />
  );
};

EditLineItemEstimationModal.propTypes = {
  lineItemUri: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  hideFinancial: PropTypes.bool.isRequired,
};

export default EditLineItemEstimationModal;
