import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import Loading from 'rapidfab/components/Loading';
import {
  API_RESOURCES,
  COMMON_BACKGROUND_JOB_STATUSES,
} from 'rapidfab/constants';
import Actions from 'rapidfab/actions';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import { connect } from 'react-redux';
import {
  getAnatomicalModelSummaryReportForPieceUri,
  getOrderPackingSlipForOrder,
  getShipmentPackingSlipForShipment,
} from 'rapidfab/selectors';
import Alert from 'rapidfab/utils/alert';
import { getEndpointFromURI } from 'rapidfab/utils/uriUtils';

/*
 * Supported only for:
 * - order packing slip
 * - shipment packing slip
 * - anatomical model summary report.
 *
 * Important: document response format must be the same for all entities
 * "Document" means just type of file, don't be confused with /document/ service.
 */
const DownloadDocumentButton = ({
  requestedDocument,
  documentEndpointName,
  onRequestDocument,
  buttonContent,
  resourceUri,
  ...otherProps
}) => {
  const [isRequestedDocumentDownloading, setIsRequestedDocumentDownloading] = useState(false);

  const documentStatus = requestedDocument && requestedDocument.status;

  useEffect(() => {
    if (isRequestedDocumentDownloading && documentStatus) {
      switch (documentStatus) {
        case COMMON_BACKGROUND_JOB_STATUSES.ERROR:
          Alert.error('Error downloading packing slip');
          setIsRequestedDocumentDownloading(false);
          break;
        case COMMON_BACKGROUND_JOB_STATUSES.COMPLETE:
          window.location = requestedDocument.content;
          setIsRequestedDocumentDownloading(false);
          break;
        default:
          break;
      }
    }
  }, [documentStatus]);

  useEffect(() => {
    if (isRequestedDocumentDownloading) {
      onRequestDocument();
    }
  }, [isRequestedDocumentDownloading]);

  const requestDocument = () => {
    setIsRequestedDocumentDownloading(true);
  };

  return (
    <Button
      disabled={isRequestedDocumentDownloading}
      bsStyle="success"
      bsSize="small"
      onClick={requestDocument}
      {...otherProps}
    >
      {isRequestedDocumentDownloading && <Loading className="spacer-right" inline />}
      {buttonContent}
    </Button>
  );
};

DownloadDocumentButton.propTypes = {
  requestedDocument: PropTypes.shape({
    order: PropTypes.string,
    bureau: PropTypes.string,
    uri: PropTypes.string,
    content: PropTypes.string,
    created: PropTypes.string,
    status: PropTypes.oneOf(Object.values(COMMON_BACKGROUND_JOB_STATUSES)),
  }),
  // eslint-disable-next-line react/no-unused-prop-types
  resourceUri: PropTypes.string.isRequired, // Used in mapDispatchToProps
  onRequestDocument: PropTypes.func.isRequired,
  documentEndpointName: PropTypes.oneOf([
    API_RESOURCES.ORDER_PACKING_SLIP,
    API_RESOURCES.SHIPMENT_PACKING_SLIP,
    API_RESOURCES.ANATOMICAL_MODEL_SUMMARY_REPORT,
  ]).isRequired,
  buttonContent: PropTypes.node.isRequired,
};

DownloadDocumentButton.defaultProps = {
  requestedDocument: null,
};

function mapStateToProps(state, ownProps) {
  const {
    documentEndpointName,
    resourceUri,
  } = ownProps;

  let requestedDocument;

  /*
   * Important: Always use sorting by "created" column
   *  in your selector to make sure that latest up-to-date document is
   *  used in `requestedDocument`
   */
  switch (documentEndpointName) {
    case API_RESOURCES.ORDER_PACKING_SLIP:
      requestedDocument = getOrderPackingSlipForOrder(state, resourceUri);
      break;
    case API_RESOURCES.SHIPMENT_PACKING_SLIP:
      requestedDocument = getShipmentPackingSlipForShipment(state, resourceUri);
      break;
    case API_RESOURCES.ANATOMICAL_MODEL_SUMMARY_REPORT:
      requestedDocument = getAnatomicalModelSummaryReportForPieceUri(state, resourceUri);
      break;
    default:
      throw new TypeError('This resource is not supported');
  }

  return {
    requestedDocument,
  };
}

const mapDispatchToProps = (dispatch, { documentEndpointName, resourceUri }) => ({
  onRequestDocument: () => {
    const resourceEndpointName = getEndpointFromURI(resourceUri).endpointName;
    return dispatch(Actions.Api.nautilus[documentEndpointName].post({ [resourceEndpointName]: resourceUri }))
      .then(response => {
        const { location } = response.headers;
        const uuid = extractUuid(location);
        return dispatch(Actions.Api.nautilus[documentEndpointName].get(uuid, true));
      });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DownloadDocumentButton);
