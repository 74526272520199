import React from 'react';
import PropTypes from 'prop-types';
import { Col, ListGroup, ListGroupItem, Card, Row } from 'react-bootstrap';
import _filter from 'lodash/filter';
import _sortBy from 'lodash/sortBy';
import { THREE_M_AQUA_PRICING_CUSTOM_FIELDS } from 'rapidfab/constants';
import CustomFieldReadOnlyList from 'rapidfab/components/forms/CustomFieldReadOnlyList';

const LineItemCustomPricing = ({
  customFieldReferences,
  customFieldValues,
}) => {
  /*
   * This component is used to render only for 3M Aqua
   * custom fields from THREE_M_AQUA_PRICING_CUSTOM_FIELDS
   */
  const customPricingFieldIDs = Object.values(THREE_M_AQUA_PRICING_CUSTOM_FIELDS);

  const customPricingFields = _filter(
    customFieldReferences,
    customLineItemField => customPricingFieldIDs.includes(customLineItemField.field_id),
  );

  const fieldsOrder = [
    THREE_M_AQUA_PRICING_CUSTOM_FIELDS.ESTIMATED_PRICE_PER_PIECE,
    THREE_M_AQUA_PRICING_CUSTOM_FIELDS.RECOMMENDED_BATCH_SIZE,
    THREE_M_AQUA_PRICING_CUSTOM_FIELDS.ESTIMATED_TOTAL_PRICE,
  ];

  const sortedCustomPricingFields = _sortBy(
    customPricingFields,
    field => fieldsOrder.indexOf(field.field_id),
  );

  return (
    <>
      <Card>
        <ListGroup fill>
          <ListGroupItem>
            <Row>
              <Col xs={12} sm={12} style={{ marginTop: '8px' }}>
                Estimation**
              </Col>
            </Row>
          </ListGroupItem>

          <CustomFieldReadOnlyList
            withListGroupItemWrapper
            customFieldReferences={sortedCustomPricingFields}
            customFieldValues={customFieldValues}
          />
        </ListGroup>
      </Card>

      <p className="text-justify">
        ** Quoted prices are for estimation purposes only.
        Pricing is impacted by part geometry and order quantity.
        Final pricing will be determined after the design has been reviewed
        and requirements are established.
        The quoted price includes design review & consultation, part support structure design,
        support removal, and delivery of finished product.
      </p>

      <p className="text-justify">
        Recommended batch size is based on the maximum number of parts
        that fit within a build volume. Number of batches
        needed to deliver requested quantity could include additional parts.
      </p>
    </>
  );
};

LineItemCustomPricing.propTypes = {
  customFieldValues: PropTypes.arrayOf(PropTypes.shape({
    custom_field: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
  customFieldReferences: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default LineItemCustomPricing;
