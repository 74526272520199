import React from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Row } from 'react-bootstrap';
import { getShortUUID } from 'rapidfab/utils/uuidUtils';
import { FormattedMessage } from 'rapidfab/i18n';

const AssemblyLayout = ({
  uri,
  prepWorkflowPanel,
  estimatesAndActuals,
  lineItemEditForm,
  documentsPanel,
  piecesPanel,
  assemblyPartsPanel,
}) => (
  <Card bg="dark" className="m-b" id={uri}>
    <Card.Header className="pd-exp accent">
      <FormattedMessage id="assembly" defaultMessage="Sub-Assembly" /> {getShortUUID(uri)}
    </Card.Header>
    <div className="card-body-wrapper-accent">
      <Card.Body>
        <Row>
          {prepWorkflowPanel && (
            <Col md={12} className="p-b-md">
              {prepWorkflowPanel}
            </Col>
          )}
          <Col xs={12} md={6}>
            <Row>
              <Col
                xs={12}
                lg={{
                  span: 10,
                  offset: 1,
                }}
              >
                {estimatesAndActuals}
                {documentsPanel}
                {piecesPanel}
              </Col>
            </Row>
          </Col>

          <Col xs={12} md={6}>
            <Row>
              <Col xs={12}>
                {lineItemEditForm}
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={12}>
            {assemblyPartsPanel}
          </Col>
        </Row>
      </Card.Body>
    </div>
  </Card>
);

AssemblyLayout.propTypes = {
  uri: PropTypes.string.isRequired,
  lineItemEditForm: PropTypes.node.isRequired,
  documentsPanel: PropTypes.node.isRequired,
  prepWorkflowPanel: PropTypes.node,
  estimatesAndActuals: PropTypes.node.isRequired,
  piecesPanel: PropTypes.node.isRequired,
  assemblyPartsPanel: PropTypes.node.isRequired,
};

AssemblyLayout.defaultProps = {
  prepWorkflowPanel: null,
};

export default AssemblyLayout;
