import React from 'react';
import PropTypes from 'prop-types';
import { Col, ListGroupItem, Row } from 'react-bootstrap';
import { CUSTOM_FIELD_FORMATS } from 'rapidfab/constants';
import _keyBy from 'lodash/keyBy';
import _get from 'lodash/get';
import FormattedLocalizedCost from 'rapidfab/components/FormattedLocalizedCost';

const CustomFieldReadOnlyList = ({
  customFieldReferences,
  customFieldValues,
  withListGroupItemWrapper,
}) => {
  /*
   * This component is designed to render some read-only fields
   * Usually it's needed for some smart custom fields which are
   * prefilled with values on backend.
   *
   * Only fields which are needed to be rendered are needed to be provided in
   * `customFields` props
   */
  const customFieldReferencesByURI = _keyBy(customFieldValues, 'custom_field');

  const Content = customFieldReferences.map(customFieldReference => {
    // Only non-nullable values are suitable for default value
    const customFieldDefinitionDefaultValue = customFieldReference.default_value || '';

    const customFieldValue = _get(
      customFieldReferencesByURI,
      [customFieldReference.uri, 'value'],
      customFieldDefinitionDefaultValue, // Default value if field value is not exist yet
    );

    return (
      <ListGroupItem>
        <Row>
          <Col xs={12} sm={8} style={{ marginTop: '8px' }}>
            {customFieldReference.field_name}
          </Col>
          <Col xs={12} sm={4} className="text-right">
            {
              customFieldReference.format === CUSTOM_FIELD_FORMATS.CURRENCY && (
                <FormattedLocalizedCost
                  value={customFieldValue}
                />
              )
            }

            {/* TODO Replace this negation when new formatter or customized type is added */}
            {
              customFieldReference.format !== CUSTOM_FIELD_FORMATS.CURRENCY &&
                customFieldValue
            }

            {
              customFieldReference.meta && customFieldReference.meta.unit && (
                <span>&nbsp;{ customFieldReference.meta.unit }</span>
              )
            }
          </Col>
        </Row>
      </ListGroupItem>
    );
  },
  );

  if (withListGroupItemWrapper) {
    return (
      <ListGroupItem>
        {Content}
      </ListGroupItem>
    );
  }

  return Content;
};

CustomFieldReadOnlyList.defaultProps = {
  withListGroupItemWrapper: false,
};

CustomFieldReadOnlyList.propTypes = {
  customFieldReferences: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  customFieldValues: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  withListGroupItemWrapper: PropTypes.bool,
};

export default CustomFieldReadOnlyList;
