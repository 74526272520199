export const PRINT_TYPES = {
  PRODUCT: 'product',
  SPECIMEN: 'specimen',
  WASTE: 'waste',
  TOOLING: 'tooling',
};

export const MODEL_TYPES = {
  STL: 'stl',
};

export const DESIGN_FILE_TYPES = {
  SINGLE_MESH: 'single-mesh',
  ANATOMICAL_MODEL: 'anatomical-model',
};

export const PRODUCT_TYPES = {
  // Product type is a copy of Design File type for now
  ...DESIGN_FILE_TYPES,
};

export const LINE_ITEM_COMPOSITION_TYPES = {
  SINGLE_MESH_PRODUCT: 'single-mesh-product', // Regular 1 model product line item
  CO_PRINT: 'co-print', // co-printed multi-mesh line item (main co-print assembly line item)
  CO_PRINT_PART: 'co-print-part', // co-printed multi-mesh line item part
  ASSEMBLY: 'assembly', // main glue/magnet assembly line item
  ASSEMBLY_PART: 'assembly-part', // glue/magnet assembly part line item
};

export const ASSEMBLY_META_ASSEMBLING_TYPES = {
  NONE: 'None', // Means `Co-Print`
  GLUED: 'Glued',
  MAGNET: 'Magnet',
};

export const MODAL_TEMPLATES = {
  CANCEL_OR_DELETE: 'cancel-or-delete',
  YES_OR_NO: 'yes-or-no',
  UPLOAD_FILE: 'upload-file',
  TEXT_ENTRY: 'text-entry',
};

export const DDW_PAYMENT_SYSTEM_TYPE = {
  MANUAL: 'manual',
  STRIPE: 'stripe',
};

export const IDENTITY_PROVIDER_TYPE = {
  SAML: 'SAML',
  LDAPS: 'LDAPS',
};
