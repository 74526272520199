import React from 'react';
import { Route, Routes } from 'react-router-dom';
import PropTypes from 'prop-types';
import JeniClusterContainer from 'rapidfab/containers/records/JeniClusterContainer';
import SlicerConfigurationContainer from 'rapidfab/components/records/slicer_configuration/SlicerConfigurationContainer';
import ToolingStockQRContainer from 'rapidfab/containers/records/ToolingStockQRContainer';
import { FEATURES } from '../constants';
import PrivateRoute from './PrivateRoute';
import { ROUTER_PERMISSION } from '../constantsRouter';
import conversion from '../containers/records/conversion';
import Manufacturer from '../containers/records/manufacturer';
import Location from '../containers/records/location';
import ServiceProviderContainer from '../containers/organize/ServiceProviderContainer';
import ShippingContainer from '../containers/records/ShippingContainer';
import Material from '../containers/records/material';
import PrinterType from '../containers/records/printerType';
import PostProcessorType from '../containers/records/postProcessorType';
import WorkflowContainer from '../containers/records/WorkflowContainer';
import StockContainer from '../containers/records/StockContainer';
import ToolingStockContainer from '../containers/records/ToolingStockContainer';
import PrinterContainer from '../containers/records/PrinterContainer';
import PostProcessorContainer from '../containers/records/PostProcessorContainer';
import OrderNew from '../containers/records/order/new';
import OrderEdit from '../containers/records/order/edit';
import RunRecordContainer from '../containers/records/run/RunRecordContainer';
import RunNewContainer from '../containers/records/run/RunNewContainer';
import PieceContainer from '../containers/records/PieceContainer';
import ShipmentContainer from '../containers/records/ShipmentContainer';
import CaptureContainer from '../containers/records/CaptureContainer';
import QrPrinterContainer from '../containers/qr/PrinterContainer';
import PostProcessorService from '../containers/records/PostProcessorService';
import Print from '../containers/records/print';
import AnatomicalModelContainer from '../containers/AnatomicalModel/AnatomicalModelContainer';
import RunRecordScheduleContainer from '../containers/records/run/RunRecordScheduleContainer';
import NotFound from '../components/404';

const RecordsRoutes = ({ session }) => (
  <Routes>
    <Route
      path="/conversion"
      element={(
        <PrivateRoute
          session={session}
          component={conversion}
          permission={ROUTER_PERMISSION.BUREAU_UNRESTRICTED}
          features={
            [FEATURES.MANUFACTURING_EXECUTION_SYSTEM]
          }
        />
      )}
    />
    <Route
      path="/conversion/:uuid"
      element={(
        <PrivateRoute
          session={session}
          component={conversion}
          permission={ROUTER_PERMISSION.BUREAU_UNRESTRICTED}
          features={
            [FEATURES.MANUFACTURING_EXECUTION_SYSTEM]
          }
        />
      )}
    />
    <Route
      path="/manufacturer"
      element={(
        <PrivateRoute
          session={session}
          component={Manufacturer}
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={
            { [FEATURES.HAWKING_DEPLOYMENT]: false }
          }
        />
      )}
    />
    <Route
      path="/manufacturer/:uuid"
      element={(
        <PrivateRoute
          session={session}
          component={Manufacturer}
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={
            { [FEATURES.HAWKING_DEPLOYMENT]: false }
          }
        />
      )}
    />
    <Route
      path="/location"
      element={(
        <PrivateRoute
          session={session}
          component={Location}
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={
            { [FEATURES.HAWKING_DEPLOYMENT]: false }
          }
        />
      )}
    />
    <Route
      path="/location/:uuid"
      element={(
        <PrivateRoute
          session={session}
          component={Location}
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={
            { [FEATURES.HAWKING_DEPLOYMENT]: false }
          }
        />
      )}
    />
    <Route
      path="/service-provider"
      element={(
        <PrivateRoute
          session={session}
          component={() => <ServiceProviderContainer type="work" />}
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={
            [FEATURES.SERVICE_PROVIDERS]
          }
        />
      )}
    />
    <Route
      path="/service-provider/:uuid"
      element={(
        <PrivateRoute
          session={session}
          component={() => <ServiceProviderContainer type="work" />}
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={
            [FEATURES.SERVICE_PROVIDERS]
          }
        />
      )}
    />
    <Route
      path="/vendor-service-provider"
      element={(
        <PrivateRoute
          session={session}
          component={() => <ServiceProviderContainer type="vendor" />}
          permission={ROUTER_PERMISSION.MANAGER}
          features={
            [FEATURES.VENDOR_SERVICE_PROVIDER]
          }
        />
      )}
    />
    <Route
      path="/vendor-service-provider/:uuid"
      element={(
        <PrivateRoute
          session={session}
          component={() => <ServiceProviderContainer type="vendor" />}
          permission={ROUTER_PERMISSION.MANAGER}
          features={
            [FEATURES.VENDOR_SERVICE_PROVIDER]
          }
        />
      )}
    />
    <Route
      path="/shipping"
      element={(
        <PrivateRoute
          session={session}
          component={ShippingContainer}
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={
            [FEATURES.MANUFACTURING_EXECUTION_SYSTEM]
          }
        />
      )}
    />
    <Route
      path="/shipping/:uuid"
      element={(
        <PrivateRoute
          session={session}
          component={ShippingContainer}
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={
            [FEATURES.MANUFACTURING_EXECUTION_SYSTEM]
          }
        />
      )}
    />
    <Route
      path="/material"
      element={(
        <PrivateRoute
          session={session}
          component={Material}
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={
            [FEATURES.MANUFACTURING_EXECUTION_SYSTEM]
          }
        />
      )}
    />
    <Route
      path="/material/:uuid"
      element={(
        <PrivateRoute
          session={session}
          component={Material}
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={
            [FEATURES.MANUFACTURING_EXECUTION_SYSTEM]
          }
        />
      )}
    />
    <Route
      path="/printer-type"
      element={(
        <PrivateRoute
          session={session}
          component={PrinterType}
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={
            {
              [FEATURES.HAWKING_DEPLOYMENT]: false,
            }
          }
        />
      )}
    />
    <Route
      path="/printer-type/:uuid"
      element={(
        <PrivateRoute
          session={session}
          component={PrinterType}
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={
            {
              [FEATURES.HAWKING_DEPLOYMENT]: false,
            }
          }
        />
      )}
    />
    <Route
      path="/post-processor-type"
      element={(
        <PrivateRoute
          session={session}
          component={PostProcessorType}
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={
            [FEATURES.MANUFACTURING_EXECUTION_SYSTEM]
          }
        />
      )}
    />
    <Route
      path="/post-processor-type/:uuid"
      element={(
        <PrivateRoute
          session={session}
          component={PostProcessorType}
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={
            [FEATURES.MANUFACTURING_EXECUTION_SYSTEM]
          }
        />
      )}
    />
    <Route
      path="/workflow"
      element={(
        <PrivateRoute
          session={session}
          component={WorkflowContainer}
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={
            [FEATURES.MANUFACTURING_EXECUTION_SYSTEM]
          }
        />
      )}
    />
    <Route
      path="/workflow/:uuid"
      element={(
        <PrivateRoute
          session={session}
          component={WorkflowContainer}
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={
            [FEATURES.MANUFACTURING_EXECUTION_SYSTEM]
          }
        />
      )}
    />
    <Route
      path="/stock"
      element={(
        <PrivateRoute
          session={session}
          component={StockContainer}
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={
            [FEATURES.MANUFACTURING_EXECUTION_SYSTEM]
          }
        />
      )}
    />
    <Route
      path="/stock/:uuid"
      element={(
        <PrivateRoute
          session={session}
          component={StockContainer}
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={
            [FEATURES.MANUFACTURING_EXECUTION_SYSTEM]
          }
        />
      )}
    />
    <Route
      path="/tool/:uuid"
      element={(
        <PrivateRoute
          session={session}
          component={ToolingStockContainer}
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={
            [FEATURES.TOOLING_STOCK]
          }
        />
      )}
    />
    <Route
      path="/tool/:uuid/qr"
      element={(
        <PrivateRoute
          session={session}
          hideLayout
          component={ToolingStockQRContainer}
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={
            [FEATURES.TOOLING_STOCK]
          }
        />
      )}
    />
    <Route
      path="/printer"
      element={(
        <PrivateRoute
          session={session}
          component={PrinterContainer}
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={
            {
              [FEATURES.HAWKING_DEPLOYMENT]: false,
            }
          }
        />
      )}
    />
    <Route
      path="/printer/:uuid"
      element={(
        <PrivateRoute
          session={session}
          component={PrinterContainer}
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={
            {
              [FEATURES.HAWKING_DEPLOYMENT]: false,
            }
          }
        />
      )}
    />
    <Route
      path="/post-processor"
      element={(
        <PrivateRoute
          session={session}
          component={PostProcessorContainer}
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={
            [FEATURES.MANUFACTURING_EXECUTION_SYSTEM]
          }
        />
      )}
    />
    <Route
      path="/post-processor/:uuid"
      element={(
        <PrivateRoute
          session={session}
          component={PostProcessorContainer}
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={
            [FEATURES.MANUFACTURING_EXECUTION_SYSTEM]
          }
        />
      )}
    />
    <Route
      path="/order"
      element={(
        <PrivateRoute
          session={session}
          component={OrderNew}
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={
            [FEATURES.MANUFACTURING_EXECUTION_SYSTEM]
          }
        />
      )}
    />
    <Route
      path="/order/:uuid"
      element={(
        <PrivateRoute
          session={session}
          component={OrderEdit}
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={
            [FEATURES.MANUFACTURING_EXECUTION_SYSTEM]
          }
        />
      )}
    />
    <Route
      path="/run"
      element={(
        <PrivateRoute
          session={session}
          component={RunNewContainer}
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={
            {
              [FEATURES.HAWKING_DEPLOYMENT]: false,
            }
          }
        />
      )}
    />
    <Route
      path="/run/:uuid"
      element={(
        <PrivateRoute
          session={session}
          component={RunRecordContainer}
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={
            {
              [FEATURES.HAWKING_DEPLOYMENT]: false,
            }
          }
        />
      )}
    />
    <Route
      path="/run/:uuid/schedule"
      element={(
        <PrivateRoute
          session={session}
          component={RunRecordScheduleContainer}
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={
            {
              [FEATURES.HAWKING_DEPLOYMENT]: false,
            }
          }
        />
      )}
    />
    <Route
      path="/piece/:uuid"
      element={(
        <PrivateRoute
          session={session}
          component={PieceContainer}
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={
            {
              [FEATURES.HAWKING_DEPLOYMENT]: false,
            }
          }
        />
      )}
    />
    <Route
      path="/shipment/:uuid"
      element={(
        <PrivateRoute
          session={session}
          component={ShipmentContainer}
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          decidingFeatures={[
            FEATURES.SHIPMENT_FOR_ORDER,
            FEATURES.SERVICE_PROVIDER_AT_STEPS,
          ]}
          features={
            {
              [FEATURES.HAWKING_DEPLOYMENT]: false,
            }
          }
        />
      )}
    />
    <Route
      path="/capture/:uuid"
      element={(
        <PrivateRoute
          session={session}
          component={CaptureContainer}
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={
            {
              [FEATURES.HAWKING_DEPLOYMENT]: false,
            }
          }
        />
      )}
    />
    <Route
      path="/printer/:uuid/qr"
      element={(
        <PrivateRoute
          session={session}
          hideLayout
          component={QrPrinterContainer}
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={
            [FEATURES.MATERIAL_MANAGEMENT]
          }
        />
      )}
    />
    <Route
      path="/post-processor-service/:uuid"
      element={(
        <PrivateRoute
          session={session}
          component={PostProcessorService}
          permission={ROUTER_PERMISSION.LOGGED_IN_ONLY}
        />
      )}
    />
    <Route
      path="/post-processor-service"
      element={(
        <PrivateRoute
          session={session}
          component={PostProcessorService}
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
        />
      )}
    />
    <Route
      path="/print/:uuid"
      element={(
        <PrivateRoute
          session={session}
          component={Print}
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={
            {
              [FEATURES.HAWKING_DEPLOYMENT]: false,
            }
          }
        />
      )}
    />
    <Route
      path="/assembly"
      element={(
        <PrivateRoute
          session={session}
          component={AnatomicalModelContainer}
          features={[FEATURES.ANATOMICAL_MODEL]}
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
        />
      )}
    />
    <Route
      path="/assembly/:uuid"
      element={(
        <PrivateRoute
          session={session}
          component={AnatomicalModelContainer}
          features={[FEATURES.ANATOMICAL_MODEL]}
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
        />
      )}
    />
    <Route
      path="/jeni-cluster/:uuid"
      element={(
        <PrivateRoute
          session={session}
          component={JeniClusterContainer}
          features={[FEATURES.JENI_CLUSTER]}
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
        />
      )}
    />
    <Route
      path="/jeni-cluster"
      element={(
        <PrivateRoute
          session={session}
          component={JeniClusterContainer}
          features={[FEATURES.JENI_CLUSTER]}
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
        />
      )}
    />
    <Route
      path="/slicer/:uuid"
      element={(
        <PrivateRoute
          session={session}
          component={SlicerConfigurationContainer}
          features={[FEATURES.AUTO_CREATE_SLICE]}
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
        />
      )}
    />
    <Route
      path="*"
      element={<NotFound />}
    />

  </Routes>
);

RecordsRoutes.propTypes = {
  session: PropTypes.shape({}).isRequired,
};

export default RecordsRoutes;
