import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  FormGroup,
  FormLabel,
  FormControl,
  Form as BSForm,
} from 'react-bootstrap';
import _isEmpty from 'lodash/isEmpty';
import { FormattedMessage } from 'react-intl';
import SelectSingle from 'rapidfab/components/forms/SelectSingle';
import Tooltip from 'rapidfab/components/Tooltip';
import { locationResourceType, materialResourceType, finalFormInputTypes } from 'rapidfab/types';
import { STOCK_STATUS_MAP } from 'rapidfab/mappings';
import { FormattedMessageMappingOption } from 'rapidfab/i18n';
import { FormattedMaterialUnitsSuffix } from 'rapidfab/components/FormattedMaterialUnits';

import { Field, FormSpy } from 'react-final-form';
import { FormControlSelect } from 'rapidfab/components/formTools';

const StockFields = ({
  locations,
  materials,
  material,
  initialFormValues,
  setSelectedMaterial,
  materialWarning,
  setMaterialWarning,
  isReadOnly,
}) => {
  const [form, setForm] = useState(initialFormValues);
  const singleMaterial = _isEmpty(materials);

  const selectedMaterial = materials.find(
    currentMaterial => currentMaterial.uri === form?.values?.material,
  ) || material;

  if (form?.values?.material && !singleMaterial) {
    setSelectedMaterial(selectedMaterial);
    setMaterialWarning(false);
  }

  return (
    <Row>
      <Col xs={12}>

        <FormGroup controlId="uxMaterial" className="form-group">
          <FormLabel>
            <FormattedMessage
              id="field.material"
              defaultMessage="Material"
            />: *
          </FormLabel>

          <Field
            name="material"
            initialValue={initialFormValues?.material || selectedMaterial?.uri}
            render={props => (

              <>
                <SelectSingle
                  placeholder="Select a Material"
                  name={props.input.name}
                  className={materialWarning ? 'selectMaterialWarning' : ''}
                  data={!singleMaterial ? materials : [material]}
                  value={props.input.value}
                  handleOnChange={props.input.onChange}
                  required
                  imitateOnChangeEvent
                  disabled={isReadOnly}
                />
                {materialWarning && (<span className="text-warning mt-1">Please select the Material first</span>)}
              </>

            )}

          />
        </FormGroup>

        <FormGroup controlId="uxLocation" className="form-group">
          <FormLabel>
            <FormattedMessage
              id="field.location"
              defaultMessage="Location"
            />: *
          </FormLabel>
          <Field
            name="location"
            initialValue={initialFormValues?.location}
            render={props => (
              <FormControlSelect
                {...props.input}
                required
                disabled={isReadOnly}
              >
                <option key="placeholder" value="" selected disabled>
                  Select a Location
                </option>
                {locations.map(location => (
                  <option key={location.uri} value={location.uri}>
                    {location.name}
                  </option>
                ))}
              </FormControlSelect>
            )}
          />
        </FormGroup>
        <FormGroup controlId="uxStatus" className="form-group">
          <FormLabel>
            <FormattedMessage id="field.status" defaultMessage="Status" />:
          </FormLabel>
          <Field
            name="status"
            render={props => (
              <FormControlSelect
                {...props.input}
                required
                disabled={isReadOnly}
              >
                {Object.keys(STOCK_STATUS_MAP).map(status => (
                  <FormattedMessageMappingOption
                    key={status}
                    mapping={STOCK_STATUS_MAP}
                    value={status}
                  />
                ))}
              </FormControlSelect>
            )}
          />
        </FormGroup>
        {form?.values?.track_quantity && (
          <FormGroup controlId="uxQuantity" className="form-group">
            <FormLabel>
              <FormattedMessage
                id="field.quantity"
                defaultMessage="Quantity"
              />
              {!!selectedMaterial && <FormattedMaterialUnitsSuffix units={selectedMaterial.units} />}: *
            </FormLabel>
            <Field
              name="quantity"
              type="number"
              initialValue={initialFormValues?.quantity}
              render={props => (
                <FormControl
                  {...props.input}
                  required
                  min={0}
                  step="0.01"
                  disabled={isReadOnly}
                />
              )}
            />
          </FormGroup>
        )}
        <FormGroup controlId="uxTrackQuantity" className="d-flex align-items-center">
          <Field
            type="checkbox"
            name="track_quantity"
            initialValue={initialFormValues?.track_quantity}
            render={props => (
              <BSForm.Check
                {...props.input}
                disabled={isReadOnly}
                label={(
                  <FormattedMessage
                    id="field.track_quantity"
                    defaultMessage="Track Quantity"
                  />
                )}
              />
            )}
          />
          <FormattedMessage
            id="record.materialStock.trackQuantityTooltip"
            defaultMessage="When this is enabled stock levels will be checked and users will receive stock below minimum level notifications. Users will not get a notification if this is disabled."
          >
            {text => (
              <Tooltip id="trackQuantityTooltip" placement="right">{text}</Tooltip>
            )}
          </FormattedMessage>
        </FormGroup>
        <FormSpy
          subscription={{ values: true }}
          onChange={values => setForm(values)}
        />
      </Col>
    </Row>
  );
};

StockFields.defaultProps = {
  materials: [],
  material: {},
};

StockFields.propTypes = {
  initialFormValues: PropTypes.shape({
    material: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    uuid: PropTypes.string.isRequired,
    quantity: PropTypes.number.isRequired,
    stock_max: PropTypes.number.isRequired,
    stock_min: PropTypes.number.isRequired,
    on_order_quantity: PropTypes.number.isRequired,
    track_quantity: PropTypes.bool.isRequired,
  }).isRequired,
  locations: PropTypes.arrayOf(locationResourceType).isRequired,
  materials: PropTypes.arrayOf(materialResourceType),
  material: PropTypes.shape({}),
  input: finalFormInputTypes.isRequired,
  setSelectedMaterial: PropTypes.func.isRequired,
  materialWarning: PropTypes.bool.isRequired,
  setMaterialWarning: PropTypes.func.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
};

export default StockFields;
