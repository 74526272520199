import React from 'react';
import PropTypes from 'prop-types';
import { assemblyPartMetaResourceType, assemblyMetaResourceType, lineItemResourceType } from 'rapidfab/types';
import { Table } from 'react-bootstrap';
import { ASSEMBLY_META_ASSEMBLING_TYPES } from 'rapidfab/constants';
import _get from 'lodash/get';
import { FormattedMessage } from 'react-intl';
import { ASSEMBLY_META_ASSEMBLING_TYPE_MAP } from 'rapidfab/mappings';

const Assemblies = ({ lineItemsByUri, assemblyMeta, assemblyPartMetaByAssemblyMetaUri }) => (
  <Table bordered>
    <thead>
      <tr>
        <th>Assembly</th>
        <th>Type</th>
        <th>Line Items</th>
      </tr>
    </thead>
    <tbody>
      {assemblyMeta.map(assemblyMetaItem => {
        const lineItem = lineItemsByUri[assemblyMetaItem.root_line_item] || {};
        const assemblyMetaParts = assemblyPartMetaByAssemblyMetaUri[assemblyMetaItem.uri] || [];
        const partLineItemNames = assemblyMetaParts.map(({ part_line_item: partLineItemUri }) =>
          _get(lineItemsByUri, [partLineItemUri, 'name']),
        );
        return (
          <tr key={`${assemblyMetaItem.uuid}`}>
            <td>{lineItem.name}</td>
            <td>
              {
                assemblyMetaItem.assembling_type === ASSEMBLY_META_ASSEMBLING_TYPES.NONE
                  ? <FormattedMessage id="coPrint" defaultMessage="Co-Print" />
                  : <FormattedMessage {...ASSEMBLY_META_ASSEMBLING_TYPE_MAP[assemblyMetaItem.assembling_type]} />
              }
            </td>
            <td>{partLineItemNames.join(', ')}</td>
          </tr>
        );
      })}
    </tbody>
  </Table>
);

Assemblies.propTypes = {
  lineItemsByUri: PropTypes.objectOf(lineItemResourceType).isRequired,
  assemblyMeta: PropTypes.arrayOf(assemblyMetaResourceType).isRequired,
  assemblyPartMetaByAssemblyMetaUri: PropTypes.objectOf(PropTypes.arrayOf(assemblyPartMetaResourceType)).isRequired,
};

export default Assemblies;
