import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  getAssemblyMetaForProduct,
  getAssemblyPartMetaForProduct,
  getDesignFilesForProduct,
  getFeatures,
  getLineItemsByUri,
  getLineItemsForProduct,
  getModelsByUri,
  getUUIDResource,
  isCurrentUserRestricted,
  getAssemblyPartMetaByAssemblyMetaUri,
} from 'rapidfab/selectors';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import {
  API_RESOURCES,
  DESIGN_FILE_STATUSES,
} from 'rapidfab/constants';
import Loading from 'rapidfab/components/Loading';
import {
  assemblyPartMetaResourceType,
  assemblyMetaResourceType,
  designFileResourceType,
  lineItemResourceType,
  productResourceType,
} from 'rapidfab/types';
import ProductWrapper from 'rapidfab/components/Product/ProductWrapper';
import { loadLineItemsWithRelatedDataForOrder } from 'rapidfab/dispatchers/order';
import DesignFileInfo from 'rapidfab/components/Product/DesignFileInfo';
import { Col, Row } from 'react-bootstrap';
import Assemblies from 'rapidfab/components/Product/Assemblies';
import AssemblyParts from 'rapidfab/components/Product/AssemblyParts';
import ProductInfoContainer from 'rapidfab/containers/product/ProductInfoContainer';
import Actions from 'rapidfab/actions';
import Alert from 'react-s-alert';
import { FormattedMessage } from 'rapidfab/i18n';

const AnatomicalModelProductContainer = ({
  fetching,
  assemblyDataFetching,
  lineItemsByUri,
  assemblyMeta,
  assemblyPartMeta,
  assemblyPartMetaByAssemblyMetaUri,
  // readOnlyLineItemsOrCallback,
  deleteProduct,
  product,
  designFile,
  loadLineItemsWithRelatedDataForProduct,
  isRestrictedUser,
  features,
  refreshLineItems,
  setLineItems,
  duplicateProduct,
  children,
  isNewAnatomicalModel,
}) => {
  const [previousDesignFileStatus, setPreviousDesignFileStatus] = useState(designFile.status);
  const [isDuplicating, setIsDuplicating] = useState(false);

  useEffect(() => {
    if (isNewAnatomicalModel) {
      loadLineItemsWithRelatedDataForProduct(product.order, isRestrictedUser, features);
    }

    if (
      // Ignore cases when design file was loaded from server with `processed` status
      previousDesignFileStatus !== designFile.status
      && designFile.status === DESIGN_FILE_STATUSES.PROCESSED) {
      // !!!This case is for Product creation only!!!
      // Once design file is processed - we are ready to load line items with all the related data
      loadLineItemsWithRelatedDataForProduct(product.order, isRestrictedUser, features);
    }
    setPreviousDesignFileStatus(designFile.status);
  }, [designFile.status]);

  const showAssemblyBlock = assemblyDataFetching || !!assemblyMeta.length;

  const onDuplicate = async () => {
    try {
      setIsDuplicating(true);
      await duplicateProduct(product.uuid);
    } catch (error) {
      Alert.error(error.message);
      return false;
    } finally {
      setIsDuplicating(false);
      refreshLineItems();
      Alert.success(
        <FormattedMessage
          id="toaster.product.duplicated"
          defaultMessage="Product {productItemUuid} was duplicated successfully."
          values={{ productItemUuid: product.uuid }}
        />,
      );
    }
    return true;
  };

  return (
    <ProductWrapper
      product={product}
      deleteProduct={deleteProduct}
      refreshLineItems={refreshLineItems}
      setLineItems={setLineItems}
      onDuplicate={onDuplicate}
      isDuplicating={isDuplicating}
    >
      <Row>
        {
          showAssemblyBlock && (
            <Col md={8}>
              {
                assemblyDataFetching
                  ? (<Loading className="m-b" />)
                  : (
                    <>
                      <Assemblies
                        lineItemsByUri={lineItemsByUri}
                        assemblyMeta={assemblyMeta}
                        assemblyPartMetaByAssemblyMetaUri={assemblyPartMetaByAssemblyMetaUri}
                      />
                      <AssemblyParts
                        assemblyPartMeta={assemblyPartMeta}
                        lineItemsByUri={lineItemsByUri}
                      />
                    </>
                  )
              }
            </Col>
          )
        }
        <Col md={4}>
          <ProductInfoContainer productUri={product.uri} />
          <DesignFileInfo designFile={designFile} />
        </Col>
      </Row>
      {fetching && <Loading className="m-b" />}
      {children}
    </ProductWrapper>
  );
};

AnatomicalModelProductContainer.propTypes = {
  fetching: PropTypes.bool.isRequired,
  assemblyDataFetching: PropTypes.bool.isRequired,
  product: productResourceType.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  productUri: PropTypes.string.isRequired, // Used in mapStateToProps
  // readOnlyLineItemsOrCallback: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  deleteProduct: PropTypes.func.isRequired,
  loadLineItemsWithRelatedDataForProduct: PropTypes.func.isRequired,
  designFile: designFileResourceType.isRequired,
  lineItemsByUri: PropTypes.objectOf(lineItemResourceType).isRequired,
  assemblyMeta: PropTypes.arrayOf(assemblyMetaResourceType).isRequired,
  assemblyPartMeta: PropTypes.arrayOf(assemblyPartMetaResourceType).isRequired,
  assemblyPartMetaByAssemblyMetaUri: PropTypes.objectOf(PropTypes.arrayOf(assemblyPartMetaResourceType)).isRequired,
  isRestrictedUser: PropTypes.bool.isRequired,
  features: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  refreshLineItems: PropTypes.func.isRequired,
  setLineItems: PropTypes.func.isRequired,
  duplicateProduct: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  isNewAnatomicalModel: PropTypes.bool,
};

AnatomicalModelProductContainer.defaultProps = {
  // readOnlyLineItemsOrCallback: false,
  isNewAnatomicalModel: false,
};

const mapStateToProps = (state, { productUri }) => {
  const product = getUUIDResource(state, extractUuid(productUri));
  const designFiles = getDesignFilesForProduct(state, product);
  // In the current implementation, product will have only 1 design file
  const designFile = designFiles[0] || {};

  const lineItemsByUri = getLineItemsByUri(state);
  const modelsByUri = getModelsByUri(state);
  const assemblyMeta = getAssemblyMetaForProduct(state, product);
  const assemblyPartMeta = getAssemblyPartMetaForProduct(state, product);
  const assemblyPartMetaByAssemblyMetaUri = getAssemblyPartMetaByAssemblyMetaUri(state);

  const anyProductDetailsFetching = state.ui.nautilus[API_RESOURCES.LINE_ITEM].list.fetching
    || state.ui.nautilus[API_RESOURCES.MODEL].list.fetching;

  const anyAssemblyDataFetching = state.ui.nautilus[API_RESOURCES.ASSEMBLY_META].list.fetching
    || state.ui.nautilus[API_RESOURCES.ASSEMBLY_PART_META].list.fetching;

  // Line Items are used here only for `is fetching` check below
  // Rendering is based on Assemblies Meta (Parts) info
  const lineItems = getLineItemsForProduct(state, product);

  // Prevent showing `loading` state for cases, when some other product data is loading
  const fetching =
    // When any product related data API request is in progress
    anyProductDetailsFetching
    // and current product has no line items loaded
    && !lineItems.length;

  // Prevent showing `loading` state for cases, when some other product assembly data is loading
  const assemblyDataFetching =
    // When any product related assembly data API request is in progress
    anyAssemblyDataFetching
    // and current product has no assembly data loaded
    && (!assemblyMeta.length || !assemblyPartMeta.loading);

  const isRestrictedUser = isCurrentUserRestricted(state);
  const features = getFeatures(state);

  return {
    fetching,
    assemblyDataFetching,
    product,
    designFile,
    isRestrictedUser,
    features,
    lineItemsByUri,
    modelsByUri,
    assemblyMeta,
    assemblyPartMeta,
    assemblyPartMetaByAssemblyMetaUri,
  };
};

const mapDispatchToProps = dispatch => ({
  loadLineItemsWithRelatedDataForProduct: (orderUri, isRestrictedUser, features) =>
    // TODO: Load data based on Product instead of Order
    loadLineItemsWithRelatedDataForOrder(dispatch, orderUri, isRestrictedUser, features),
  duplicateProduct: uuid => dispatch(Actions.Api.nautilus.product.clone(uuid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AnatomicalModelProductContainer);
