import SuccessCheckmarkAnimation from 'rapidfab/components/SuccessCheckmarkAnimation';
import React from 'react';
import PropTypes from 'prop-types';
import Actions from 'rapidfab/actions';
import BreadcrumbNav from 'rapidfab/components/BreadcrumbNav';
import Feature from 'rapidfab/components/Feature';
import { orderResourceType } from 'rapidfab/types';
import FormattedLocalizedCost from 'rapidfab/components/FormattedLocalizedCost';
import OrderQuote from 'rapidfab/components/records/order/edit/OrderQuote';
import ReviewPreviewOrder from 'rapidfab/components/records/order/restricted/review/ReviewPreviewOrder';
import Stepper from 'rapidfab/components/stepper/Stepper';
import {
  API_RESOURCES,
  COMMENT_RELATED_TABLE_NAMES,
  FEATURES,
  ORDER_UNLOCKED_STATUSES,
  RESTRICTED_USER_ORDER_STEPS,
  RESTRICTED_USER_ORDER_STEP_REVIEW_ORDER,
  ROUTES, DOCUMENT_RELATED_TABLE_NAMES, ORDER_STATUS,
} from 'rapidfab/constants';
import CommentsContainer from 'rapidfab/containers/CommentsContainer';
import OrderProductsContainer from 'rapidfab/containers/OrderProductsContainer';
import * as Selectors from 'rapidfab/selectors';
import { extractUuid, getShortUUID } from 'rapidfab/utils/uuidUtils';
import { getRouteURI, prepareStepperURI } from 'rapidfab/utils/uriUtils';
import isOrderQuoteAvailable from 'rapidfab/utils/isOrderQuoteAvailable';
import { getShoppingCartItemsTotalPrice, getCartItemMaterial } from 'rapidfab/utils/shoppingCartUtils';
import { Badge, Button, Card, Col, Container, Dropdown, Row, SplitButton, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getPaymentStatusData } from 'rapidfab/utils/paymentUtils';
import Loading from 'rapidfab/components/Loading';
import { faClone, faExternalLink, faRefresh } from '@fortawesome/free-solid-svg-icons';
import _isEmpty from 'lodash/isEmpty';
import { DDW_PAYMENT_STATUSES } from 'rapidfab/constants/statuses';
import Documents from 'rapidfab/components/records/Documents';
import COMMENT_RESOURCE_TYPES from 'rapidfab/constants/CommentResourceType';

const EditButton = ({ order, onDuplicate, isRobozeBureauOrderFieldsFeatureEnabled }) => (
  <SplitButton
    size="sm"
    variant="success"
    title={`Edit This ${isRobozeBureauOrderFieldsFeatureEnabled ? 'Request' : 'Order'}`}
    href={getRouteURI(ROUTES.ORDER_RESTRICTED_EDIT, { uuid: order.uuid })}
  >
    <Dropdown.Item eventKey={0} onClick={() => onDuplicate(order.uuid)}>
      <FontAwesomeIcon icon={faClone} />{' '}
      <FormattedMessage id="button.duplicate" defaultMessage="Duplicate" />
    </Dropdown.Item>
  </SplitButton>
);
EditButton.propTypes = {
  order: orderResourceType.isRequired,
  onDuplicate: PropTypes.func.isRequired,
  isRobozeBureauOrderFieldsFeatureEnabled: PropTypes.bool.isRequired,
};

const ReorderButton = ({ onDuplicate, order }) => (
  <Button size="sm" variant="success" onClick={() => onDuplicate(order.uuid)}>
    Reorder
  </Button>
);
ReorderButton.propTypes = {
  order: orderResourceType.isRequired,
  onDuplicate: PropTypes.func.isRequired,
};

const ReviewOrder = ({
  users,
  locationsAvailable,
  order,
  isOrderBusinessSegmentFeatureEnabled,
  isBoeingOrderFieldsFeatureEnabled,
  is3MOrderFieldsFeatureEnabled,
  orderQuoteFeatureEnabled,
  orderQuoteSpecialProcessingFeatureEnabled,
  onDuplicate,
  handleCreatePayment,
  shoppingCart,
  shoppingCartItems,
  materials,
  modelLibrariesByUri,
  payment: orderPayment,
  isPaymentSubmitting,
  refreshPaymentApiData,
  isPaymentLoading,
  isStripeIntegrationFeatureEnabled,
  isRobozeBureauOrderFieldsFeatureEnabled,
}) => {
  const orderQuoteAvailable = isOrderQuoteAvailable(
    order,
    orderQuoteFeatureEnabled,
    orderQuoteSpecialProcessingFeatureEnabled,
  );

  const isOrderUnlocked = ORDER_UNLOCKED_STATUSES.includes(order?.status);

  const renderReviewHeaderText = () => {
    const hasCorrespondingOrderStatus = [
      ORDER_STATUS.NEW,
      ORDER_STATUS.PENDING,
      ORDER_STATUS.IN_PREPARATION].includes(order?.status);

    const reviewHeaderText = isRobozeBureauOrderFieldsFeatureEnabled && hasCorrespondingOrderStatus
      ? 'Review Request for Quotation'
      : 'Order Submitted';
    const reviewDescriptionText = isRobozeBureauOrderFieldsFeatureEnabled && hasCorrespondingOrderStatus
      ? 'Your request for quotation has been successfully submitted.'
      : 'Your order has been successfully submitted.';

    return (
      <div className="d-flex align-items-center mb20">
        <SuccessCheckmarkAnimation />
        <div className="spacer-left">
          <h2>{reviewHeaderText}</h2>
          <p className="mb0">{reviewDescriptionText}</p>
        </div>
      </div>
    );
  };

  return (
    <Container fluid>
      <BreadcrumbNav
        breadcrumbs={[
          'orders',
          order.id
            ? `${order.name} (${order.customer_id || getShortUUID(order.uuid)})`
            : '',
        ]}
      />

      <Stepper
        steps={prepareStepperURI(RESTRICTED_USER_ORDER_STEPS, { uuid: order.uuid })}
        activeStep={RESTRICTED_USER_ORDER_STEP_REVIEW_ORDER}
      />

      {shoppingCart && (
        <Feature
          featureNames={[
            FEATURES.PAYMENT_SYSTEM,
          ]}
        >
          <Card
            bg="dark"
            id="ddwPaymentProcessingCheckoutSection"
          >
            <Card.Header
              className="d-flex align-items-start justify-content-between"
            >
              <div className="d-flex align-items-start">
                <h2>Payment</h2>
                <Badge
                  className="ml15 badge badge-md"
                  bg={getPaymentStatusData(orderPayment).bg}
                >
                  {getPaymentStatusData(orderPayment).message}
                </Badge>
              </div>
              <Button
                bg="primary"
                size="xs"
                className="pull-right"
                onClick={refreshPaymentApiData}
                disabled={isPaymentLoading}
              >
                {isPaymentLoading ? <Loading /> : <FontAwesomeIcon icon={faRefresh} />}
              </Button>
            </Card.Header>
            {shoppingCartItems
              && (
                <Card.Body>
                  <Table style={{ color: '#cfd2da' }} id="costBreakdownTable" className="pd-exp inverse bg-dark">
                    <thead>
                      <tr>
                        <th className="text-left">
                          <FormattedMessage id="field.name" defaultMessage="Name" />
                        </th>
                        <th className="text-left">
                          <FormattedMessage id="field.material" defaultMessage="Material" />
                        </th>
                        <th className="text-left">
                          <FormattedMessage id="field.quantity" defaultMessage="Quantity" />
                        </th>
                        <th className="text-left">Price Per Unit</th>
                        <th className="text-left">
                          <FormattedMessage id="line_item.quote_price_factor.price" defaultMessage="Price" />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {shoppingCartItems &&
                      shoppingCartItems.filter(item => item.price_per)
                        .map(cartItem => {
                          const cartItemModelLibrary = modelLibrariesByUri[cartItem.source];
                          const cartItemMaterial = getCartItemMaterial(cartItem, cartItemModelLibrary, materials);

                          return (
                            <tr>
                              <td>{modelLibrariesByUri[cartItem.source]?.name}</td>
                              <td>
                                <Link
                                  to={getRouteURI(ROUTES.MATERIALS, null, { uuid: extractUuid(cartItemMaterial?.uri) },
                                    true)}
                                >
                                  {cartItemMaterial?.name || 'N/A'}
                                </Link>
                              </td>
                              <td>{cartItem.quantity}</td>
                              <td>
                                <FormattedLocalizedCost value={cartItem.price_per} />
                              </td>
                              <td>
                                <FormattedLocalizedCost value={cartItem.price_per * cartItem.quantity} />
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                    <thead />
                    <tbody>
                      <tr>
                        <td colSpan={3} />
                        {shoppingCartItems && (
                          <td>
                            <b>
                              <FormattedMessage
                                id="total"
                                defaultMessage="Total"
                              />
                            </b>
                          </td>
                        )}
                        <td>
                          <FormattedLocalizedCost
                            value={getShoppingCartItemsTotalPrice(shoppingCartItems.filter(item => item.price_per))}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                  {isStripeIntegrationFeatureEnabled
                    && orderPayment?.status === DDW_PAYMENT_STATUSES.READY
                    && !_isEmpty(orderPayment?.checkout_url)
                    && (
                      <p>If you have not already been redirected, please&nbsp;
                        <a target="_blank" rel="noopener noreferrer" href={orderPayment?.checkout_url}>
                          click here&nbsp;
                          <FontAwesomeIcon icon={faExternalLink} />
                        </a>&nbsp;
                        to access the stripe checkout page.
                      </p>
                    )}
                </Card.Body>
              )}
            <Card.Footer>
              <Button
                className="pull-right"
                variant="success"
                bg="success"
                onClick={handleCreatePayment}
                // If there is no `orderPayment`, 'Checkout' is enabled so they can create one.
                disabled={orderPayment || isPaymentSubmitting}
              >
                {isPaymentSubmitting ? <Loading /> : 'Checkout'}
              </Button>
            </Card.Footer>
          </Card>
        </Feature>
      )}

      <div className="jumbotron mt15">
        {renderReviewHeaderText()}
        <p>
          <Button className="m-r btn-default" size="sm" href={getRouteURI(ROUTES.ORDERS)}>
            Return to {isRobozeBureauOrderFieldsFeatureEnabled ? 'List' : 'Orders'}
          </Button>
          {!isRobozeBureauOrderFieldsFeatureEnabled ? (
            <Button
              className="m-r btn-default"
              size="sm"
              href={getRouteURI(ROUTES.ORDER_NEW)}
            >
              Create New Order
            </Button>
          ) : (
            <Button
              className="m-r btn-default"
              size="sm"
              href={getRouteURI(ROUTES.DDW_RESTRICTED_MY_LIBRARY)}
            >
              Return to Digital Design Warehouse
            </Button>
          )}
          {isOrderUnlocked ? (
            <EditButton
              order={order}
              onDuplicate={onDuplicate}
              isRobozeBureauOrderFieldsFeatureEnabled={isRobozeBureauOrderFieldsFeatureEnabled}
            />
          ) : (
            <ReorderButton order={order} onDuplicate={onDuplicate} />
          )}
        </p>

        <hr />

        <Row>
          <Col xs={12} md={7}>
            <ReviewPreviewOrder
              isRestricted
              fields={order}
              locationsAvailable={locationsAvailable}
              users={users}
              isOrderBusinessSegmentFeatureEnabled={isOrderBusinessSegmentFeatureEnabled}
              isBoeingOrderFieldsFeatureEnabled={isBoeingOrderFieldsFeatureEnabled}
              is3MOrderFieldsFeatureEnabled={is3MOrderFieldsFeatureEnabled}
              isRobozeBureauOrderFieldsFeatureEnabled={isRobozeBureauOrderFieldsFeatureEnabled}
            />
          </Col>
          <Feature featureName={FEATURES.ORDER_QUOTE_AVAILABLE_FOR_RESTRICTED}>
            <Col xs={12} md={5}>
              {
                orderQuoteAvailable &&
                <OrderQuote />
              }
            </Col>
          </Feature>
        </Row>
      </div>

      <Feature
        featureNames={[FEATURES.ORDER_DOCUMENTS, FEATURES.RESTRICTED_USER_ORDER_DOCUMENTS]}
        featureNamesEnforceAll
      >
        <Documents
          relatedTable={DOCUMENT_RELATED_TABLE_NAMES.ORDER}
          relatedUUID={extractUuid(order.uri)}
        />
      </Feature>

      <CommentsContainer
        resourceTableName={COMMENT_RELATED_TABLE_NAMES.ORDER}
        resourceUUID={order.uuid}
        resourceType={COMMENT_RESOURCE_TYPES.ORDER}
      />
      <OrderProductsContainer
        orderUri={order.uri}
        readOnlyLineItemsOrCallback
      />
    </Container>
  );
};
ReviewOrder.defaultProps = {
  order: {},
};

ReviewOrder.propTypes = {
  order: orderResourceType,
  users: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  locationsAvailable: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isOrderBusinessSegmentFeatureEnabled: PropTypes.bool.isRequired,
  isBoeingOrderFieldsFeatureEnabled: PropTypes.bool.isRequired,
  is3MOrderFieldsFeatureEnabled: PropTypes.bool.isRequired,
  orderQuoteFeatureEnabled: PropTypes.bool.isRequired,
  orderQuoteSpecialProcessingFeatureEnabled: PropTypes.bool.isRequired,
  onDuplicate: PropTypes.func.isRequired,
  handleCreatePayment: PropTypes.func.isRequired,
  shoppingCartItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  materials: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  modelLibrariesByUri: PropTypes.shape({}).isRequired,
  shoppingCart: PropTypes.shape({}).isRequired,
  payment: PropTypes.shape({
    status: PropTypes.string,
    checkout_url: PropTypes.string,
  }).isRequired,
  isPaymentSubmitting: PropTypes.bool.isRequired,
  refreshPaymentApiData: PropTypes.func.isRequired,
  isPaymentLoading: PropTypes.bool.isRequired,
  isStripeIntegrationFeatureEnabled: PropTypes.bool.isRequired,
  isRobozeBureauOrderFieldsFeatureEnabled: PropTypes.bool.isRequired,
};

const mapStateToProps = state => {
  const order = Selectors.getRouteUUIDResource(state);
  const users = Selectors.getUsersByUri(state);
  const boeingOrderFieldsFeature = Selectors.isFeatureEnabled(state, FEATURES.BOEING_ORDER_FIELDS);
  const threeMOrderFieldsFeature = Selectors.isFeatureEnabled(
    state,
    FEATURES.THREE_M_MAIN_BUREAU_ORDER_FIELDS,
  );

  return {
    order,
    users,
    locationsAvailable: Selectors.getLocationsByUri(state),
    isOrderBusinessSegmentFeatureEnabled: Selectors.isFeatureEnabled(
      state, FEATURES.ORDER_BUSINESS_SEGMENT,
    ),
    is3MOrderFieldsFeatureEnabled: threeMOrderFieldsFeature,
    isBoeingOrderFieldsFeatureEnabled: boeingOrderFieldsFeature,
    orderQuoteFeatureEnabled: Selectors.isFeatureEnabled(state, FEATURES.ORDER_QUOTE),
  };
};

const mapDispatchToProps = dispatch => ({
  onDuplicate: uuid => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.ORDER].clone(uuid))
      .then(response => {
        const { headers: { location } } = response;
        const duplicatedOrderUUID = extractUuid(location);
        window.location.hash = getRouteURI(ROUTES.ORDER_RESTRICTED_EDIT, { uuid: duplicatedOrderUUID });
      })
      .catch(error => console.error(error));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ReviewOrder);
