import React, { useState } from 'react';
import { productResourceType } from 'rapidfab/types';
import {
  PRODUCT_MODEL_FILE_SOURCE_MAP,
  PRODUCT_STATUS_MAP,
} from 'rapidfab/mappings';
import { Field, Form } from 'react-final-form';
import ProductConfirmButton from 'rapidfab/components/Product/ProductConfirmButton';
import PropTypes from 'prop-types';
import StatusColorCode from 'rapidfab/components/StatusColorCode';
import { PRODUCT_PRIOR_CONFIRM_STAGE_STATUSES, PRODUCT_STATUSES, STATUS_COLOR_CODE_TYPES } from 'rapidfab/constants';
import MappedColumn from 'rapidfab/components/RecordList/MappedColumn';
import { Card, FormControl } from 'react-bootstrap';
import FormRow from 'rapidfab/components/FormRow';
import _pick from 'lodash/pick';
import SaveButton from 'rapidfab/components/SaveButton';
import { FormattedMessageMappingOption } from 'rapidfab/i18n';
import { FormControlSelect } from 'rapidfab/components/formTools';
import Alert from 'rapidfab/utils/alert';

const ProductInfo = ({ product, onSubmit }) => {
  const [isConfirming, setIsConfirming] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const fields = {
    modelFileSource: 'model_file_source',
    modelNumber: 'model_number',
  };
  const formInitialValues = _pick(product, Object.values(fields));

  const isPriorProductionStage = PRODUCT_PRIOR_CONFIRM_STAGE_STATUSES.includes(product.status);

  const submitWithValidation = payload => {
    setIsSubmitting(true);
    if (payload[fields.modelNumber].length !== 8) {
      const errorMessage = 'Model Number Must be exactly 8 characters.';
      Alert.error(errorMessage);
      setIsSubmitting(false);
      // Final Form expects and object in case of validation error
      return {
        [fields.modelNumber]: errorMessage,
      };
    }

    return onSubmit(payload)
      .then(() => Alert.success('Product Info successfully saved.'))
      .finally(() => setIsSubmitting(false));
  };

  return (
    <Form
      initialValues={formInitialValues}
      onSubmit={submitWithValidation}
    >
      {({ handleSubmit, dirty, form }) => (
        <form onSubmit={handleSubmit}>
          <Card bg="dark" className="mb15">
            <Card.Header
              className="pd-exp inverse d-flex align-items-center justify-content-between position-sticky z-index-1000 w-100"
            >
              Product info
              <div className="pull-right">
                <SaveButton size="sm" isSaving={isSubmitting} disabled={isConfirming} />
              </div>
            </Card.Header>
            <div className="card-body-wrapper">
              <Card.Body>
                <FormRow id="field.status" defaultMessage="Status">
                  <StatusColorCode status={product.status} type={STATUS_COLOR_CODE_TYPES.PRODUCT} />{' '}
                  <MappedColumn mapping={PRODUCT_STATUS_MAP} value={product.status} />{' '}
                  <div className="pull-right">
                    <ProductConfirmButton
                      product={product}
                      onConfirm={event => {
                        setIsConfirming(true);
                        form.change('status', PRODUCT_STATUSES.CONFIRMED);
                        handleSubmit(event).finally(() =>
                          setIsConfirming(false),
                        );
                      }}
                      isConfirming={isConfirming}
                      disabled={isSubmitting}
                      isFormDirty={dirty}
                    />
                  </div>
                </FormRow>
                <FormRow id="modelFileSource" defaultMessage="Model File Source">
                  <Field name={fields.modelFileSource}>
                    {({ input }) => (
                      <FormControlSelect
                        id={`modelFileSource-${product.uuid}`}
                        {...input}
                      >
                        {Object.keys(PRODUCT_MODEL_FILE_SOURCE_MAP).map(modelFileSource => (
                          <FormattedMessageMappingOption
                            mapping={PRODUCT_MODEL_FILE_SOURCE_MAP}
                            value={modelFileSource}
                          />
                        ))}
                      </FormControlSelect>
                    )}
                  </Field>
                </FormRow>
                <FormRow id="modelNumber" defaultMessage="Model Number">
                  <Field
                    name={fields.modelNumber}
                  >
                    {({ input }) => (
                      <FormControl {...input} maxLength="8" disabled={!isPriorProductionStage} />
                    )}
                  </Field>
                </FormRow>
              </Card.Body>
            </div>
          </Card>
        </form>
      )}
    </Form>
  );
};

ProductInfo.propTypes = {
  product: productResourceType.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ProductInfo;
