import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { assemblyPartMetaResourceType, lineItemResourceType, materialTypeResourceType, modelResourceType } from 'rapidfab/types';
import { Col, InputGroup, Row, Image } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Field, Form } from 'react-final-form';
import _pick from 'lodash/pick';
import SelectSingle from 'rapidfab/components/forms/SelectSingle';
import VisibleFor from 'rapidfab/components/VisibleFor';
import Fa from 'react-fontawesome';
import SaveButton from 'rapidfab/components/SaveButton';
import FormRow from 'rapidfab/components/FormRow';
import Alert from 'rapidfab/utils/alert';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import { ROUTES } from 'rapidfab/constants';
import getLineItemFormOptions from 'rapidfab/utils/lineItemFormOptions';
import AssemblyPartMetaContextTooltip
  from 'rapidfab/components/records/order/edit/LineItem/AssemblyPartMetaContextTooltip';
import AssemblyPartSettingsField from 'rapidfab/components/records/order/edit/LineItem/AssemblyPartSettingsField';
import { getSnapshotFromLineItem } from 'rapidfab/utils/lineItemUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const MaterialAddons = ({ materialUri, materialsByUri, currentMaterialUri, hasChanged }) => {
  let selectedMaterial = currentMaterialUri && materialsByUri[currentMaterialUri];

  if (hasChanged) {
    selectedMaterial = materialUri && materialsByUri[materialUri];
  }

  if (!selectedMaterial) {
    return null;
  }
  const { color: materialColor, external_datasheet_url: externalDatasheetUrl } = selectedMaterial;
  const materialDetailsUri =
    externalDatasheetUrl
    || getRouteURI(ROUTES.MATERIAL_EDIT, { uuid: selectedMaterial.uuid });

  return (
    <>
      {materialColor && (
        <InputGroup.Text>
          <div
            style={{
              margin: '0 auto',
              width: 20,
              height: 20,
              backgroundColor: materialColor,
            }}
          />
        </InputGroup.Text>
      )}
      <VisibleFor unrestricted>
        <InputGroup.Text style={{ minWidth: '62px' }}>
          <a
            href={materialDetailsUri}
            target="_blank"
            rel="noopener noreferrer"
          >
            Go <Fa name="external-link" />
          </a>
        </InputGroup.Text>
      </VisibleFor>
    </>
  );
};

MaterialAddons.propTypes = {
  materialUri: PropTypes.string,
  currentMaterialUri: PropTypes.string,
  materialsByUri: PropTypes.objectOf(materialTypeResourceType).isRequired,
  hasChanged: PropTypes.bool.isRequired,
};

MaterialAddons.defaultProps = {
  materialUri: null,
  currentMaterialUri: null,
};

const CoPrintPartRow = ({
  lineItem,
  assemblyPartMeta,
  model,
  onSubmit,
  baseMaterials,
  supportMaterials,
  materialBase,
  materialSupport,
  materialsByUri,
  features,
  isReadOnly,
  isUserRestricted,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [baseChanged, setBaseChanged] = useState(false);
  const [supportChanged, setSupportChanged] = useState(false);

  const fields = {
    base_material: 'materials.base',
    support_material: 'materials.support',
  };
  const snapshot = useMemo(() => getSnapshotFromLineItem(lineItem, model), [lineItem, model]);

  const SNAPSHOT_STATE = {
    LOADING: 'LOADING',
  };

  const options = getLineItemFormOptions({
    features,
    compositionType: lineItem.composition_type,
    isReadOnly,
    isNormalUser: !isUserRestricted,
    // Work Instructions are not allowed for Co-Print parts (no UI for this as well)
    workInstructionsExist: false,
    // Custom Workflow is not allowed for Co-Print parts (no UI for this as well)
    isCustomWorkflow: false,
  });

  const submitHandler = payload => {
    setIsSubmitting(true);

    const modifiedPayload = {
      additive: {
        materials: {
          ...payload.materials,
        },
      },
    };

    onSubmit(modifiedPayload)
      .then(() => {
        Alert.success(
          <FormattedMessage
            id="toaster.coPrint.part.saved"
            defaultMessage="Co-Print part successfully saved."
          />,
        );
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const parseEmptyStringAsNull = value => value || null;
  const formInitialValues = _pick(lineItem, Object.values(fields));

  return (
    <Form
      initialValues={formInitialValues}
      onSubmit={submitHandler}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col xs={3}>
              {
                snapshot === SNAPSHOT_STATE.LOADING ?
                  <FontAwesomeIcon icon={faSpinner} spin />
                  : (model && snapshot
                    ? <Image className="part_image" src={snapshot} />
                    : <FormattedMessage id="notAvailable" defaultMessage="N/A" />)
              }
            </Col>
            <Col xs={9}>
              <FormRow id="field.name" defaultMessage="Name">
                {lineItem.name}
              </FormRow>
              <FormRow id="field.baseMaterial" defaultMessage="Base Material">
                <Field
                  name={fields.base_material}
                  parse={parseEmptyStringAsNull}
                >
                  {({ input }) => (
                    <InputGroup className="w100 d-flex flex-nowrap">
                      <SelectSingle
                        data={baseMaterials}
                        imitateOnChangeEvent
                        handleOnChange={input.onChange}
                        disabled={!options.baseMaterial.editable}
                        currentValue={materialBase}
                        handleMaterialChangeState={setBaseChanged}
                        {...input}
                      />
                      <MaterialAddons
                        materialUri={input.value}
                        materialsByUri={materialsByUri}
                        currentMaterialUri={materialBase}
                        hasChanged={baseChanged}
                      />
                    </InputGroup>
                  )}
                </Field>
              </FormRow>
              <FormRow id="field.supportMaterial" defaultMessage="Support Material">
                <Field
                  name={fields.support_material}
                  parse={parseEmptyStringAsNull}
                >
                  {({ input }) => (
                    <InputGroup className="w100">
                      <SelectSingle
                        data={supportMaterials}
                        handleOnChange={input.onChange}
                        disabled={!options.supportMaterial.editable}
                        currentValue={materialSupport}
                        handleMaterialChangeState={setSupportChanged}
                        {...input}
                        imitateOnChangeEvent
                      />
                      <MaterialAddons
                        materialUri={input.value}
                        materialsByUri={materialsByUri}
                        currentMaterialUri={materialSupport}
                        hasChanged={supportChanged}
                      />
                    </InputGroup>
                  )}
                </Field>
              </FormRow>
              <AssemblyPartSettingsField assemblyPartMeta={assemblyPartMeta} />
              <AssemblyPartMetaContextTooltip assemblyPartMeta={assemblyPartMeta} />
              <div className="pull-right">
                <SaveButton isSaving={isSubmitting} />
              </div>
            </Col>
          </Row>
        </form>
      )}
    </Form>
  );
};

CoPrintPartRow.propTypes = {
  lineItem: lineItemResourceType.isRequired,
  model: modelResourceType,
  assemblyPartMeta: assemblyPartMetaResourceType,
  onSubmit: PropTypes.func.isRequired,
  baseMaterials: PropTypes.arrayOf(materialTypeResourceType).isRequired,
  supportMaterials: PropTypes.arrayOf(materialTypeResourceType).isRequired,
  materialBase: PropTypes.string.isRequired,
  materialSupport: PropTypes.string.isRequired,
  materialsByUri: PropTypes.objectOf(materialTypeResourceType).isRequired,
  features: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  isUserRestricted: PropTypes.bool.isRequired,
};

CoPrintPartRow.defaultProps = {
  model: null,
  assemblyPartMeta: null,
};

export default CoPrintPartRow;
