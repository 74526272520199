import { isFeatureEnabled } from 'rapidfab/utils/featureFlagUtils';
import { FEATURES, LINE_ITEM_COMPOSITION_TYPES, LINE_ITEM_DESIGN_STAGE_STATUSES } from 'rapidfab/constants';

const merckNotesPlaceholder = '"the part must be made of X material". "need part to be solvent resistant", "clear material needed", "Part needs to be air tight", etc.';

export default ({
  features,
  compositionType,
  status,
  isNormalUser = false,
  isReadOnly = false,
  workInstructionsExist = false,
  isCustomWorkflow = false,
  isRobozeBureauOrderFieldsUser = false,
}) => {
  const isSintaviaUser = isFeatureEnabled(features, FEATURES.SINTAVIA_ORDER_FIELDS);
  const isDanfossUser = isFeatureEnabled(features, FEATURES.ORDER_BUSINESS_SEGMENT);
  const isMerckUser = isFeatureEnabled(features, FEATURES.MERCK_ORDER_FIELDS);
  const isBoeingUser = isFeatureEnabled(features, FEATURES.BOEING_ORDER_FIELDS);
  const is3MAquaUser = isFeatureEnabled(features, FEATURES.THREE_M_AQUA_ORDER_FIELDS);
  const is3MUser = isFeatureEnabled(features, FEATURES.THREE_M_MAIN_BUREAU_ORDER_FIELDS);
  const isOerlikonUser = isFeatureEnabled(features, FEATURES.OERLIKON_ORDER_FIELDS);

  const isDesignStage = LINE_ITEM_DESIGN_STAGE_STATUSES.includes(status);

  const isLineItemExtendedDetailsFeatureEnabled = isFeatureEnabled(features, FEATURES.LINE_ITEM_EXTENDED_DETAILS);

  const isSingleMesh = compositionType === LINE_ITEM_COMPOSITION_TYPES.SINGLE_MESH_PRODUCT;
  const isAssemblyPart = compositionType === LINE_ITEM_COMPOSITION_TYPES.ASSEMBLY_PART;
  const isCoPrintPart = compositionType === LINE_ITEM_COMPOSITION_TYPES.CO_PRINT_PART;

  const isLineItemWithModel = (isSingleMesh || isAssemblyPart || isCoPrintPart);

  const modelUnitsFieldFlag =
    isLineItemWithModel
    && (isNormalUser || isSintaviaUser || isDanfossUser || is3MUser);

  const showLayerThickness = (isNormalUser || isSintaviaUser) && !isOerlikonUser && isLineItemWithModel;
  const showInfillStrategy = isNormalUser && !isSintaviaUser && !isBoeingUser;
  const showSupportStrategy = isNormalUser && !isSintaviaUser && !isBoeingUser;
  const showDesignHours = isNormalUser && !isBoeingUser;

  return {
    printable: {
      show: (isMerckUser || isBoeingUser)
        ? isNormalUser && isLineItemWithModel
        : isLineItemWithModel,
    },
    partName: {
      show: isLineItemExtendedDetailsFeatureEnabled,
      editable: !isReadOnly && isLineItemExtendedDetailsFeatureEnabled && isSingleMesh,
    },
    status: {
      editable: !isReadOnly && isNormalUser,
    },
    modelFileUnits: {
      show: modelUnitsFieldFlag,
      required: modelUnitsFieldFlag,
      editable: !isReadOnly && modelUnitsFieldFlag,
    },
    modelUserUnits: {
      show: modelUnitsFieldFlag,
      required: modelUnitsFieldFlag,
      editable: !isReadOnly && modelUnitsFieldFlag,
    },
    replaceModel: {
      show: !isReadOnly && isSingleMesh,
    },
    layerThickness: {
      show: showLayerThickness,
      editable: !isReadOnly && showLayerThickness,
    },
    traveler: {
      show: isNormalUser || isSintaviaUser,
    },
    customerID: {
      show: isLineItemExtendedDetailsFeatureEnabled,
      editable: !isReadOnly && isLineItemExtendedDetailsFeatureEnabled && isSingleMesh,
      required: false,
      id: 'partNumber',
      defaultMessage: 'Part Number',
    },
    quantity: {
      show: true,
      editable: !isReadOnly && isSingleMesh && isDesignStage,
    },
    baseMaterial: {
      show: (isNormalUser || isSintaviaUser || is3MAquaUser ||
        is3MUser || isReadOnly || (isBoeingUser && !isNormalUser)) && isLineItemWithModel,
      editable: (isNormalUser || isSintaviaUser || is3MUser || is3MAquaUser) && !isReadOnly && isLineItemWithModel,
    },
    supportMaterial: {
      show: ((isNormalUser || isReadOnly) && !isSintaviaUser) && isLineItemWithModel && !(
        isRobozeBureauOrderFieldsUser && !isNormalUser
      ),
      editable: !isReadOnly && isLineItemWithModel,
    },
    workflow: {
      show: (isNormalUser || isSintaviaUser || isReadOnly || (isBoeingUser && isNormalUser)) &&
        !(isRobozeBureauOrderFieldsUser && !isNormalUser),
      editable: !isReadOnly && !workInstructionsExist && !isCustomWorkflow,
      required: true,
    },
    infillStrategy: {
      show: showInfillStrategy,
      editable: !isReadOnly && showInfillStrategy,
    },
    supportStrategy: {
      show: showSupportStrategy,
      editable: !isReadOnly && showSupportStrategy,
    },
    notes: {
      show: isNormalUser || isMerckUser || isBoeingUser || is3MUser
        || is3MAquaUser || isOerlikonUser,
      editable: !isReadOnly,
      required: false,
      placeholder: (isMerckUser && !isNormalUser) ? merckNotesPlaceholder : '',
    },
    designHours: {
      show: showDesignHours,
      editable: !isReadOnly && showDesignHours,
    },
    materialUsed: {
      show:
        (((!isMerckUser && isReadOnly && !isNormalUser) || (is3MAquaUser && isNormalUser)) && isLineItemWithModel) &&
        !(isRobozeBureauOrderFieldsUser && !isNormalUser),
    },
    bureauCost: {
      show: !isMerckUser && !is3MAquaUser && isReadOnly && !isNormalUser && !(
        isRobozeBureauOrderFieldsUser && !isNormalUser
      ),
      id: 'estimates.bureauCost',
      defaultMessage: 'Bureau Cost',
    },
    discountByQuantity: {
      // TODO: To be deleted in ch33971 since not needed
      show: false,
      editable: !isReadOnly && isSingleMesh,
      overlay: [
        { quantity: 5, percentage: 5 },
        { quantity: 10, percentage: 8 },
        { quantity: 50, percentage: 10 },
      ],
    },
  };
};
