import React, { useMemo } from 'react';
import { assemblyPartMetaResourceType } from 'rapidfab/types';
import { FormControl } from 'react-bootstrap';
import _map from 'lodash/map';
import _get from 'lodash/get';
import _camelCase from 'lodash/camelCase';
import FormRow from 'rapidfab/components/FormRow';

const AssemblyPartSettingsField = ({ assemblyPartMeta }) => {
  const originalData = _get(assemblyPartMeta, 'context.original_data', {});
  const getContextDataRowValue = (value, prefix) => {
    if (value === null) {
      return 'null';
    }
    if (Array.isArray(value)) {
      return `[${value.map(valueItem => getContextDataRowValue(valueItem, prefix)).join(', ')}]`;
    }
    if (typeof value === 'object') {
      // eslint-disable-next-line no-use-before-define
      return `\r\n${formatObject(value, `  ${prefix}`)}\r\n`;
    }
    if (typeof value === 'boolean') {
      return value ? 'True' : 'False';
    }
    return value;
  };

  const getContextDataRow = (value, key, prefix) => {
    const contextKey = _camelCase(key);
    return `${prefix}${contextKey}: ${getContextDataRowValue(value, prefix)}`;
  };

  const formatObject = (data, prefix) =>
    _map(data, (value, key) => getContextDataRow(value, key, prefix)).join('\r\n');

  const partSettings = useMemo(
    () => formatObject(originalData, ''),
    [JSON.stringify(originalData)],
  );

  return (
    <FormRow id="partSettings" defaultMessage="Part Settings">
      <FormControl
        style={{ resize: 'vertical', cursor: 'text' }}
        rows="6"
        name="partSettings"
        value={partSettings}
        disabled
        as="textarea"
      />
    </FormRow>
  );
};

AssemblyPartSettingsField.propTypes = {
  assemblyPartMeta: assemblyPartMetaResourceType,
};

AssemblyPartSettingsField.defaultProps = {
  assemblyPartMeta: null,
};

export default AssemblyPartSettingsField;
