// Object with supported entities with colored status code
// Used internally (nautilus doesn't have this constant) mostly for StatusColorCode component
export const STATUS_COLOR_CODE_TYPES = {
  BOEING_FIELD_STATUS: 'boeing_field_status',
  BOEING_FINANCE_REVIEW_STATUS: 'boeing_finance_review_status',
  YES_NO: 'yes_no',
  ORDER_STATUS: 'order_status',
  RUN_STATUS: 'run_status',
  SERVICE_PROVIDER_JOB: 'service_provider_job',
  SHIPMENT: 'shipment',
  PREP_TASK_RECORD_STATUS: 'prep_task_record_status',
  COMMENT_ACTION_STATUS: 'comment_action_status',
  STOCK_STATUS: 'stock_status',
  PRODUCT: 'product',
  BATCH_STATUS: 'batch_status',
  DOWNTIME_STATUS: 'downtime',
  LINE_ITEM_STATUS: 'line_item',
};

export const COLORS = {
  PURPLE: '#9F86FF',
  BLUE: '#1CA8DD',
  YELLOW: '#E4D836',
  ORANGE: '#FFA500',
  GRAY: '#555555',
  GREEN: '#1BC98E',
  RED: '#E64759',
  WHITE: '#FFFFFF',
  BLACK: '#000000',
};

export const PIECE_COLORS = {
  DARK_BLUE: '#1c49dd',
  LIGHT_GRAY: '#9b9b9b',
  DARK_RED: '#a00101',
};

export const CHART_COLORS = {
  RED: 'rgb(255, 99, 132)',
  ORANGE: 'rgb(255, 159, 64)',
  YELLOW: 'rgb(255, 205, 86)',
  GREEN: 'rgb(75, 192, 192)',
  BLUE: 'rgb(54, 162, 235)',
  PURPLE: 'rgb(153, 102, 255)',
  GREY: 'rgb(201, 203, 207)',
};

export const TEXT_COLOR_CONTRAST = {
  '#9F86FF': '#FFF', // PURPLE
  '#1CA8DD': '#FFF', // BLUE
  '#E4D836': '#000', // YELLOW
  '#FFA500': '#000', // ORANGE
  '#555555': '#FFF', // GRAY
  '#1BC98E': '#FFF', // GREEN
  '#E64759': '#FFF', // RED
  '#FFFFFF': '#000', // WHITE
  '#000000': '#FFF', // BLACK
};

export const ICON_COLORS = {
  ORANGE: '#E6933B',
  GREEN: '#009F10',
  BLUE: '#2B78E4',
  GREY: '#51586a',
};

export const DEFAULT_LABEL_COLOR = COLORS.WHITE;

export const DEFAULT_MATERIAL_TYPE_COLOR = COLORS.BLACK;
