import React, { Component } from 'react';
import PropTypes from 'prop-types';
import RunItemRow, { TYPES } from 'rapidfab/components/records/run/RunItemRow';
import { LINE_ITEM_STATUS, ROUTES } from 'rapidfab/constants';
import Alert from 'rapidfab/utils/alert';
import { lineItemResourceType } from 'rapidfab/types';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import { extractUuid } from 'rapidfab/utils/uuidUtils';

export default class RunLineItem extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(event, lineItem) {
    if (![
      LINE_ITEM_STATUS.CONFIRMED,
      LINE_ITEM_STATUS.PRINTING,
      LINE_ITEM_STATUS.POST_PROCESSING,
      LINE_ITEM_STATUS.SHIPPING,
    ].includes(lineItem?.status)) {
      event.preventDefault();
      Alert.error(`Sub-Assembly status must be set to "confirmed" for order: ${getRouteURI(ROUTES.ORDER_EDIT, { uuid: extractUuid(lineItem.order) })}`);
    } else {
      const {
        setFirstItem,
        onShiftToggle,
      } = this.props;
      if (event.shiftKey) {
        onShiftToggle(lineItem.pieces);
      } else {
        setFirstItem(lineItem.pieces);
      }
    }
  }

  render() {
    const {
      lineItem,
      selected,
      pieces,
      labelRelationshipsForLineItems,
    } = this.props;
    return (
      <RunItemRow
        rowItem={lineItem}
        lineItem={lineItem}
        selected={selected}
        handleClick={this.handleClick}
        pieces={pieces}
        type={TYPES.LINE_ITEM}
        labelRelationshipsForLineItems={labelRelationshipsForLineItems}
      />
    );
  }
}

RunLineItem.propTypes = {
  lineItem: lineItemResourceType.isRequired,
  pieces: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selected: PropTypes.bool.isRequired,
  onShiftToggle: PropTypes.func.isRequired,
  setFirstItem: PropTypes.func.isRequired,
  labelRelationshipsForLineItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};
