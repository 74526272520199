import React from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Row } from 'react-bootstrap';
import SmoothCollapse from 'react-smooth-collapse';

const AssemblyPartLayout = ({
  uri,
  modalThreeScene,
  estimatesAndActuals,
  lineItemDetails,
  lineItemEditForm,
  piecesPanel,
  headerPanel,
  expanded,
}) => (
  <Card bg="dark" className="m-b" id={uri}>
    <Card.Header
      className="pd-exp inverse d-flex align-items-center justify-content-between position-sticky z-index-1000 w-100"
    >
      {headerPanel}
    </Card.Header>
    <SmoothCollapse expanded={expanded}>
      <div className="card-body-wrapper">
        <Card.Body>
          <Row>
            <Col xs={12} md={6}>
              <Row>
                <Col
                  xs={{
                    span: 10,
                    offset: 1,
                  }}
                  lg={{
                    span: 6,
                    offset: 1,
                  }}
                >
                  {modalThreeScene}
                </Col>
                <Col lg={{ span: 4 }}>
                  {lineItemDetails}
                </Col>
                <Col
                  xs={12}
                  lg={{
                    span: 10,
                    offset: 1,
                  }}
                >
                  {estimatesAndActuals}
                  {piecesPanel}
                </Col>
              </Row>
            </Col>

            <Col xs={12} md={6}>
              <Row>
                <Col xs={12}>
                  {lineItemEditForm}
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </div>
    </SmoothCollapse>
  </Card>
);

AssemblyPartLayout.propTypes = {
  uri: PropTypes.string.isRequired,
  lineItemDetails: PropTypes.node.isRequired,
  lineItemEditForm: PropTypes.node.isRequired,
  modalThreeScene: PropTypes.node,
  estimatesAndActuals: PropTypes.node,
  piecesPanel: PropTypes.node,
  headerPanel: PropTypes.node.isRequired,
  expanded: PropTypes.bool.isRequired,
};

AssemblyPartLayout.defaultProps = {
  modalThreeScene: null,
  estimatesAndActuals: null,
  piecesPanel: null,
};

export default AssemblyPartLayout;
