import React from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Row } from 'react-bootstrap';
import SmoothCollapse from 'react-smooth-collapse';

const SingleMeshLayout = ({
  uri,
  prepWorkflowPanel,
  modalThreeScene,
  netfabbPanel,
  estimatesAndActuals,
  workstepQuoteDetailsPanel,
  serviceProvidersPanel,
  customPricingPanel,
  lineItemDetails,
  lineItemEditForm,
  documentsPanel,
  piecesPanel,
  headerPanel,
  expanded,
  displayWarningStyling,
  hideFinancial,
  lazyLoadRef,
  debugPanel,
}) => (
  <Card ref={lazyLoadRef} bg="dark" className="m-b" id={uri} data-cy="line-item-edit-form-container">
    {debugPanel}
    <Card.Header
      className="pd-exp inverse d-flex align-items-center justify-content-between position-sticky z-index-1000 w-100"
      style={{ top: 56 }}
    >
      {headerPanel}
    </Card.Header>
    <SmoothCollapse expanded={expanded}>
      <div className={displayWarningStyling ? 'card-body-wrapper border-warning' : 'card-body-wrapper'}>
        <Card.Body>
          <Row>
            {prepWorkflowPanel && (
              <Col md={12} className="p-b-md">
                {prepWorkflowPanel}
              </Col>
            )}
            <Col xs={12} md={6}>
              <Row>
                <Col
                  xs={{
                    span: 10,
                    offset: 1,
                  }}
                  lg={{
                    span: 6,
                    offset: 1,
                  }}
                >
                  {modalThreeScene}
                </Col>
                <Col lg={{ span: 4 }}>
                  {lineItemDetails}
                </Col>
              </Row>
              {netfabbPanel && (
                <Row>
                  <Col
                    xs={12}
                    lg={{
                      span: 10,
                      offset: 1,
                    }}
                  >
                    {netfabbPanel}
                  </Col>
                </Row>
              )}
              {estimatesAndActuals && (
                <Row>
                  <Col
                    xs={12}
                    lg={{
                      span: 10,
                      offset: 1,
                    }}
                  >
                    {estimatesAndActuals}
                  </Col>
                </Row>
              )}
              {workstepQuoteDetailsPanel && !hideFinancial && (
                <Row>
                  <Col
                    xs={12}
                    lg={{
                      span: 10,
                      offset: 1,
                    }}
                  >
                    {workstepQuoteDetailsPanel}
                  </Col>
                </Row>
              )}
              {serviceProvidersPanel && (
                <Row>
                  <Col
                    xs={12}
                    lg={{
                      span: 10,
                      offset: 1,
                    }}
                  >
                    {serviceProvidersPanel}
                  </Col>
                </Row>
              )}
              {customPricingPanel && (
                <Row className="mt30">
                  <Col
                    xs={12}
                    lg={{
                      span: 10,
                      offset: 1,
                    }}
                  >
                    {customPricingPanel}
                  </Col>
                </Row>
              )}
            </Col>

            <Col xs={12} md={6}>
              <Row>
                <Col xs={12}>
                  {expanded && lineItemEditForm}
                </Col>
              </Row>
              <Row>
                <Col xs={{
                  span: 9,
                  offset: 3,
                }}
                >
                  {documentsPanel}
                </Col>
              </Row>
              {piecesPanel && (
                <Row>
                  <Col xs={{
                    span: 9,
                    offset: 3,
                  }}
                  >
                    {piecesPanel}
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </Card.Body>
      </div>
    </SmoothCollapse>
  </Card>
);

SingleMeshLayout.propTypes = {
  uri: PropTypes.string.isRequired,
  lineItemDetails: PropTypes.node.isRequired,
  lineItemEditForm: PropTypes.node.isRequired,
  documentsPanel: PropTypes.node.isRequired,
  prepWorkflowPanel: PropTypes.node,
  modalThreeScene: PropTypes.node,
  netfabbPanel: PropTypes.node,
  estimatesAndActuals: PropTypes.node,
  workstepQuoteDetailsPanel: PropTypes.node,
  serviceProvidersPanel: PropTypes.node,
  customPricingPanel: PropTypes.node,
  piecesPanel: PropTypes.node,
  headerPanel: PropTypes.node.isRequired,
  expanded: PropTypes.bool.isRequired,
  displayWarningStyling: PropTypes.bool,
  hideFinancial: PropTypes.bool,
  lazyLoadRef: PropTypes.func.isRequired,
  debugPanel: PropTypes.node,
};

SingleMeshLayout.defaultProps = {
  prepWorkflowPanel: null,
  modalThreeScene: null,
  netfabbPanel: null,
  estimatesAndActuals: null,
  workstepQuoteDetailsPanel: null,
  serviceProvidersPanel: null,
  customPricingPanel: null,
  piecesPanel: null,
  debugPanel: null,
  displayWarningStyling: false,
  hideFinancial: false,
};

export default SingleMeshLayout;
