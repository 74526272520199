import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _find from 'lodash/find';
import _filter from 'lodash/filter';
import _indexOf from 'lodash/indexOf';
import _map from 'lodash/map';
import { connect } from 'react-redux';
import Actions from 'rapidfab/actions';
import * as Selectors from 'rapidfab/selectors';
import { extractUuid } from 'rapidfab/reducers/makeApiReducers';
import {
  DOCUMENT_RELATED_TABLE_NAMES,
  WORKFLOW_TYPES,
  DESIGN_REVIEW_MATERIAL_NAME,
  FEATURES,
  LINE_ITEM_COMPOSITION_TYPES,
  LINE_ITEM_STATUS,
  API_RESOURCES,
  MODEL_UNITS,
  EXTERNAL_PRINT_ANALYSIS_VENDOR,
  PAGINATION_IGNORE_DEFAULT_LIMIT,
} from 'rapidfab/constants';
import { createOrReplaceArray } from 'rapidfab/utils/arrayUtils';
import getLineItemFormOptions from 'rapidfab/utils/lineItemFormOptions';
import getInitialCustomFieldValues from 'rapidfab/utils/getInitialCustomFieldValues';
import { materialTypeResourceType, modelResourceType, orderResourceType, productResourceType } from 'rapidfab/types';
import { getModelLibrariesByUri, getRouteUUIDResource, getFeatures, getUUIDResource } from 'rapidfab/selectors';
import Alert from 'rapidfab/utils/alert';
import { FormattedMessage } from 'react-intl';
import AddLineItemPresentation from './AddLineItemPresentation';

class AddLineItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      baseMaterial: '',
      partName: null,
      customerId: null,
      notes: null,
      quantity: '',
      submitting: false,
      supportMaterial: '',
      workflow: '',
      supportStrategy: null,
      infillStrategy: null,
      customFieldValues: [],
      documents: [],
      modelFileUnits: this.props.modelUri ? this.props.modelUri.file_unit : MODEL_UNITS.AUTOMATIC,
      modelUserUnits: this.props.modelUri ? this.props.modelUri.user_unit : MODEL_UNITS.AUTOMATIC,
      options: this.getFormOptions(),
      progressTimer: 0,
      workflowsFetchMoreState: { offset: 0, count: 1 },
    };

    this.handleDocumentDelete = this.handleDocumentDelete.bind(this);
    this.handleDocumentUploads = this.handleDocumentUploads.bind(this);
    this.handleDocumentChange = this.handleDocumentChange.bind(this);
    this.setDefaultProductValues = this.setDefaultProductValues.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.onCustomFieldChange = this.onCustomFieldChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onReloadPrints = this.onReloadPrints.bind(this);
    this.getMoreWorkflows = this.getMoreWorkflows.bind(this);
  }

  componentDidMount() {
    const { customLineItemFieldReferences } = this.props;
    this.onInitializeWorkflows();

    this.resetForm();
    this.setDefaultProductValues();

    if (customLineItemFieldReferences.length) {
      this.initCustomFieldValues();
    }

    this.getBaseMaterialWorkflows();
  }

  componentDidUpdate(prevProps) {
    const {
      isUserRestricted,
      isOrderBusinessSegmentFeatureEnabled,
    } = this.props;

    if (!this.state.baseMaterial && this.getBaseMaterials().length) {
      this.resetBaseMaterialField();
    }

    if (
      isUserRestricted !== prevProps.isUserRestricted ||
      isOrderBusinessSegmentFeatureEnabled !== prevProps.isOrderBusinessSegmentFeatureEnabled
    ) {
      const options = this.getFormOptions();
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ options });
    }

    if (this.props.customLineItemFieldReferences.length !== prevProps.customLineItemFieldReferences.length) {
      this.initCustomFieldValues();
    }
  }

  handleDocumentChange(event) {
    const documents = this.state.documents.slice();
    documents.push(event.target.files[0]);
    this.setState({ documents });
  }

  handleDocumentDelete(index) {
    const documents = this.state.documents.slice();
    documents.splice(index, 1);
    this.setState({ documents });
  }

  handleDocumentUploads(lineItemResponse, documents) {
    const { dispatch } = this.props;
    const documentPromises = _map(documents, async document => {
      const uuid = extractUuid(lineItemResponse.headers.location);

      const documentPostResponse = await dispatch(
        Actions.Api.nautilus[API_RESOURCES.DOCUMENT].post({
          name: document.name,
          related_uuid: uuid,
          related_table_name: DOCUMENT_RELATED_TABLE_NAMES.LINE_ITEM,
        }),
      );
      const uploadDocument = documentPostResponse.headers.location;
      const uploadDocumentLocation = documentPostResponse.headers.uploadLocation;
      dispatch(Actions.Api.nautilus[API_RESOURCES.DOCUMENT].get(extractUuid(uploadDocument)));
      await dispatch(Actions.UploadModel.upload(uploadDocumentLocation, document));
    });

    return Promise.all(documentPromises).then(() => lineItemResponse);
  }

  handleInputChange(event) {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    /* check at least ONE workflow exists for selected material,
    after user has selected a base material. */
    if (name === 'baseMaterial') {
      this.getBaseMaterialWorkflows();
    }

    this.setState({ [name]: value });
  }

  handleUpdateModelUnits() {
    const { modelFileUnits, modelUserUnits } = this.state;
    const { dispatch, modelUri } = this.props;

    // If no_model_upload mode - no need to send this request as we have no model at all.
    if ((!modelFileUnits && !modelUserUnits) || !modelUri) {
      return;
    }

    dispatch(
      Actions.Api.nautilus[API_RESOURCES.MODEL].put(extractUuid(modelUri), {
        file_unit: !modelFileUnits ? null : modelFileUnits,
        user_unit: !modelUserUnits ? null : modelUserUnits,
      })).then(() => dispatch(Actions.Api.nautilus[API_RESOURCES.MODEL].get(extractUuid(modelUri))));
  }

  async onReloadPrints(queryParams) {
    const { dispatch } = this.props;
    try {
      await dispatch(Actions.Api.nautilus[API_RESOURCES.PIECE].clear('list'));
      await dispatch(Actions.Api.nautilus[API_RESOURCES.PIECE].list(
        queryParams,
        { limit: PAGINATION_IGNORE_DEFAULT_LIMIT },
      )).then(piecesResponse => {
        const currentPieces = piecesResponse.json.resources;
        const pieceUris = _map(currentPieces, 'uri');
        dispatch(
          Actions.Api.nautilus[API_RESOURCES.PRINT].list(
            { piece: pieceUris },
            { limit: PAGINATION_IGNORE_DEFAULT_LIMIT },
            {},
            {},
            true,
          ),
        );
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  async onSubmit(event) {
    event.preventDefault();

    const {
      autorun,
      baseMaterial,
      partName,
      customerId,
      notes,
      quantity,
      supportMaterial,
      workflow,
      supportStrategy,
      infillStrategy,
      customFieldValues,
      documents,
    } = this.state;

    const { bureau, dispatch, order, modelUri, product, isNebumindFeatureEnabled } = this.props;

    const payload = {
      autorun,
      bureau: bureau.uri,
      notes,
      priority: order.priority, // currently line items will inherit the priority of the order
      quantity: Number.parseInt(quantity, 10),
      workflow: workflow || null,
      additive: {
        infill_strategy: infillStrategy,
        materials: {
          base: baseMaterial,
          support: supportMaterial,
        },
        model: modelUri,
        no_model_upload: !modelUri,
        support_strategy: supportStrategy,
      },
      custom_field_values: customFieldValues,
      name: partName,
      customer_id: customerId,
      product: product.uri,
      order: product.order,
    };

    if (isNebumindFeatureEnabled) {
      payload.additive.external_analysis = EXTERNAL_PRINT_ANALYSIS_VENDOR.NEBUMIND;
    }
    if (!payload.additive.materials.support) delete payload.additive.materials.support;

    this.setState({ submitting: true });

    if (this.props.highlightedItems) {
      const shouldBeHighlighted = this.props.highlightedItems.map(highlighted => {
        if (highlighted.uri !== product.uri) {
          return highlighted;
        }
        return null;
      }).filter(item => item);

      if (shouldBeHighlighted) {
        this.props.setHighlightedItems([...shouldBeHighlighted]);
      }
    }

    dispatch(Actions.Api.nautilus[API_RESOURCES.LINE_ITEM].post(payload)).then(lineItemResponse => {
      const createdLineItemUri = lineItemResponse?.headers?.location;
      if (!createdLineItemUri) {
        return Alert.error(
          <FormattedMessage
            id="toaster.error.lineItem.creation"
            defaultMessage="Error in creation of the line item. Please try again."
          />);
      }
      const lineItemUuid = extractUuid(createdLineItemUri);

      return Promise.all([
        this.handleDocumentUploads(lineItemResponse, documents),
        dispatch(Actions.Api.nautilus[API_RESOURCES.LINE_ITEM].get(lineItemUuid)),
        dispatch(Actions.Api.nautilus[API_RESOURCES.LINE_ITEM_QUOTE].list({ line_item: createdLineItemUri })),
        this.handleUpdateModelUnits(),
        this.onReloadPrints({ line_item: createdLineItemUri }),
      ]);
    }).finally(() => {
      this.setState({ submitting: false });
    });
    return true;
  }

  onCustomFieldChange(_, value) {
    const { customFieldValues } = this.state;

    const customFieldValuesReplaced = createOrReplaceArray(
      customFieldValues,
      { custom_field: value.customFieldReferenceUri },
      { value: value.value },
    );

    this.setState({ customFieldValues: customFieldValuesReplaced });
  }

  onInitializeWorkflows(limit = 100) {
    const { dispatch } = this.props;
    return dispatch(Actions.Api.nautilus[API_RESOURCES.WORKFLOW].list({
      include_custom_workflows: true,
    }, { limit }));
  }

  getFormOptions() {
    const {
      isUserRestricted,
      features,
      isOrderBusinessSegmentFeatureEnabled,
      isBoeingOrderFieldsFeatureEnabled,
      is3MOrderFieldsFeatureEnabled,
    } = this.props;

    return getLineItemFormOptions({
      features,
      isNormalUser: !isUserRestricted,
      // Add Line item form is now used for Single Mesh line items only
      compositionType: LINE_ITEM_COMPOSITION_TYPES.SINGLE_MESH_PRODUCT,
      status: LINE_ITEM_STATUS.NEW,
      isDanfossUser: isOrderBusinessSegmentFeatureEnabled,
      isBoeingUser: isBoeingOrderFieldsFeatureEnabled,
      is3MUser: is3MOrderFieldsFeatureEnabled,
    });
  }

  getBaseMaterials() {
    const {
      baseMaterialsByLocationUri,
      baseMaterials,
      order: { location },
    } = this.props;
    if (!location) {
      return _filter(baseMaterials, ({ name }) => name !== DESIGN_REVIEW_MATERIAL_NAME);
    }
    return _filter(baseMaterialsByLocationUri[location], ({ name }) => name !== DESIGN_REVIEW_MATERIAL_NAME);
  }

  async getMoreWorkflows(limit = 100) {
    const { workflowsFetchMoreState } = this.state;
    const { dispatch } = this.props;
    const response = await dispatch(Actions.Api.nautilus[API_RESOURCES.WORKFLOW].list(
      { include_custom_workflows: true },
      { limit, offset: workflowsFetchMoreState.offset }, {}, { sort: 'name' }, true),
    );
    this.setState({
      workflowsFetchMoreState: {
        offset: workflowsFetchMoreState.offset + limit,
        count: response?.json.meta?.count || 0,
      },
    });
  }

  setDefaultProductValues() {
    /* If the model was selected from Model Library ->
    we will set the default: "Base Material", "Support Material", "Workflow" from the Model Library Default Values */
    const { modelLibrariesByUri, product } = this.props;
    const currentModelLibrary = modelLibrariesByUri[product?.model_library];

    if (currentModelLibrary) {
      this.setState({ supportMaterial: currentModelLibrary.additive.support_material,
        workflow: currentModelLibrary.workflow,
        baseMaterial: currentModelLibrary.additive.base_material });
    }
  }

  getBaseMaterialWorkflows() {
    const { workflows } = this.props;
    const { baseMaterial } = this.state;

    if (!baseMaterial) {
      return [];
    }

    // (Production Workflows for selected Base Material only)
    const result = _filter(workflows, workflowItem => workflowItem.materials.includes(baseMaterial));
    return result;
  }

  initCustomFieldValues() {
    const { customFieldValues } = this.state;
    const { customLineItemFieldReferences } = this.props;

    const updatedCustomLineItemFieldValues = getInitialCustomFieldValues(
      customLineItemFieldReferences, customFieldValues,
    );

    this.setState({ customFieldValues: updatedCustomLineItemFieldValues });
  }

  resetForm() {
    const filteredBaseMaterials = this.getBaseMaterials();

    this.setState({
      baseMaterial: filteredBaseMaterials[0] ? filteredBaseMaterials[0].uri : null,
      partName: null,
      customerId: null,
      quantity: '1',
      supportMaterial: '',
      workflow: '',
      notes: '',
      documents: [],
    });
  }

  resetBaseMaterialField() {
    // Update basematerial based on the checkbox value
    const baseMaterialFiltered = this.getBaseMaterials();

    this.setState({ baseMaterial: baseMaterialFiltered[0] ? baseMaterialFiltered[0].uri : null });
  }

  resetWorkflowField() {
    const { options } = this.state;
    const { workflows } = this.props;

    if (!options.workflow.show) {
      // Not needed to update this field
      // if production workflow field is disabled for user
      return false;
    }

    const workflow = options.workflow.show && workflows[0] && workflows[0].uri;
    this.setState({ workflow });
    return true;
  }

  render() {
    const {
      props,
      state,
      handleDocumentDelete,
      handleDocumentChange,
      handleInputChange,
      onSubmit,
      onCustomFieldChange,
      getMoreWorkflows,
    } = this;

    const {
      baseMaterials,
      processSteps,
      workflows,
      printerTypes,
      supportStrategy,
      supportStrategies,
      infillStrategy,
      infillStrategies,
      model,
      isProsperFeatureEnabled,
      isPowderWorkflow,
    } = this.props;

    const {
      baseMaterial,
      workflow,
      documents,
      progressTimer,
      modelFileUnits,
      modelUserUnits,
      workflowsFetchMoreState,
    } = this.state;

    const baseMaterialsFiltered = this.getBaseMaterials();

    let availableSupportStrategies = [];
    let availableInfillStrategies = [];

    let canSelectStrategies = false;
    let prosperProcessSteps = [];

    // Firstly, there must be selected base material which can be printer via prosper integration
    if (baseMaterial !== undefined) {
      const selectedBaseMaterial = _find(baseMaterials, { uri: baseMaterial });
      if ((selectedBaseMaterial && selectedBaseMaterial?.is_prosper_integration_available) && isProsperFeatureEnabled) {
        canSelectStrategies = true;
      }
    }

    // Second, production workflow will be also available to print
    // Workflow => process_step => printer_type.is_prosper_integration_available must be true
    if (workflow === undefined || workflow === null) {
      canSelectStrategies = false;
    }

    if (canSelectStrategies) {
      const selectedWorkflow = _find(workflows, { uri: workflow });

      if (selectedWorkflow !== undefined) {
        const workflowProcessSteps = selectedWorkflow.process_steps;
        prosperProcessSteps = _filter(
          processSteps, o => _indexOf(workflowProcessSteps, o.uri) !== -1 &&
            o.additive.is_prosper_integration_available === true,
        );

        canSelectStrategies = prosperProcessSteps.length > 0 && isProsperFeatureEnabled;
      }
    }

    if (canSelectStrategies) {
      const processStepURI = prosperProcessSteps[0]?.workstation_type_uri;
      const printerType = _find(printerTypes, { uri: processStepURI });

      availableSupportStrategies = _filter(
        supportStrategies,
        o => _indexOf(printerType.support_strategies, o.uri) !== -1,
      );

      availableInfillStrategies = _filter(
        infillStrategies,
        o => _indexOf(printerType.infill_strategies, o.uri) !== -1,
      );
    }

    if (!availableSupportStrategies.length && this.state.supportStrategy) {
      this.setState({ supportStrategy: null });
    }

    if (!availableInfillStrategies.length && this.state.infillStrategy) {
      this.setState({ infillStrategy: null });
    }

    if (availableSupportStrategies.length && !this.state.supportStrategy) {
      this.setState({ supportStrategy: availableSupportStrategies[0].uri });
    }

    if (availableInfillStrategies.length && !this.state.infillStrategy) {
      this.setState({ infillStrategy: availableInfillStrategies[0].uri });
    }

    // For Powder Workflow we do not need to filter workflows by Material support.
    const filteredWorkflows = isPowderWorkflow ? workflows : this.getBaseMaterialWorkflows();

    return (
      <AddLineItemPresentation
        {...props}
        {...state}
        model={model}
        workflows={filteredWorkflows}
        baseMaterials={baseMaterialsFiltered}
        handleDocumentDelete={handleDocumentDelete}
        handleDocumentChange={handleDocumentChange}
        handleInputChange={handleInputChange}
        onCustomFieldChange={onCustomFieldChange}
        onSubmit={onSubmit}
        modelFileUnits={modelFileUnits}
        modelUserUnits={modelUserUnits}
        canSelectStrategies={canSelectStrategies}
        supportStrategies={availableSupportStrategies}
        supportStrategy={supportStrategy}
        infillStrategies={availableInfillStrategies}
        infillStrategy={infillStrategy}
        documents={documents}
        progressTimer={progressTimer}
        isPowderWorkflow={isPowderWorkflow}
        onFetchMoreWorkflows={workflowsFetchMoreState.offset < workflowsFetchMoreState.count ? getMoreWorkflows : null}
      />
    );
  }
}

AddLineItem.defaultProps = {
  customLineItemFieldReferences: [],
};

AddLineItem.propTypes = {
  baseMaterials: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  baseMaterialsByLocationUri: PropTypes.objectOf(PropTypes.arrayOf(materialTypeResourceType)).isRequired,
  modelLibrariesByUri: PropTypes.shape({}),
  bureau: PropTypes.shape({
    uri: PropTypes.string,
  }).isRequired,
  isUserRestricted: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  notes: PropTypes.string,
  order: orderResourceType.isRequired,
  supportMaterials: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  workflow: PropTypes.string,
  workflows: PropTypes.arrayOf(PropTypes.shape({
    uri: PropTypes.number.isRequired,
  })).isRequired,
  processSteps: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  printerTypes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  supportStrategy: PropTypes.string,
  supportStrategies: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  infillStrategy: PropTypes.string,
  infillStrategies: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  customLineItemFieldReferences: PropTypes.arrayOf(PropTypes.shape({})),
  isSintaviaOrderFieldsFeatureEnabled: PropTypes.bool.isRequired,
  isOrderBusinessSegmentFeatureEnabled: PropTypes.bool.isRequired,
  productUri: PropTypes.string.isRequired,
  product: productResourceType.isRequired,
  modelUri: PropTypes.string,
  model: modelResourceType,
  features: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isBoeingOrderFieldsFeatureEnabled: PropTypes.bool.isRequired,
  is3MOrderFieldsFeatureEnabled: PropTypes.bool.isRequired,
  setHighlightedItems: PropTypes.func.isRequired,
  highlightedItems: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.instanceOf(Array),
  ]).isRequired,
  isNebumindFeatureEnabled: PropTypes.bool.isRequired,
  isProsperFeatureEnabled: PropTypes.bool.isRequired,
  isPowderWorkflow: PropTypes.bool.isRequired,
  isPOCUKOrderFieldsFeatureEnabled: PropTypes.bool.isRequired,
};

AddLineItem.defaultProps = {
  supportStrategy: null,
  infillStrategy: null,
  notes: null,
  workflow: null,
  modelUri: null,
  model: null,
  modelLibrariesByUri: {},
};

const mapStateToProps = (state, { modelUri, productUri }) => {
  const bureau = Selectors.getBureau(state);
  const {
    base: baseMaterials,
    support: supportMaterials,
  } = Selectors.getBaseAndSupportMaterials(state);
  const baseMaterialsByLocationUri = Selectors.getBaseMaterialsByLocationUri(state);
  const locations = Selectors.getLocations(state);
  const isPowderWorkflowFeatureEnabled = Selectors.isFeatureEnabled(state, FEATURES.POWDER_WORKFLOW);
  const workflows = Selectors.getAvailableWorkflowsOfType(
    state,
    isPowderWorkflowFeatureEnabled ?
      [WORKFLOW_TYPES.ADDITIVE_MANUFACTURING, WORKFLOW_TYPES.POWDER_MANUFACTURING] :
      WORKFLOW_TYPES.ADDITIVE_MANUFACTURING,
  );
  const isUserRestricted = Selectors.isCurrentUserRestricted(state);
  const processSteps = Selectors.getProcessSteps(state);
  const printerTypes = Selectors.getPrinterTypes(state);
  const supportStrategies = Selectors.getSupportStrategies(state);
  const infillStrategies = Selectors.getInfillStrategies(state);
  const order = getRouteUUIDResource(state);
  const submitting = state.ui.nautilus[API_RESOURCES.LINE_ITEM].post.fetching;
  const fetching = [state.ui.nautilus[API_RESOURCES.WORKFLOW].list.fetching].some(Boolean);
  const customLineItemFieldReferences = Selectors.getCustomLineItemFieldReferences(state);
  const customOrderFieldReferences = Selectors.getCustomOrderFieldReferences(state);
  const sintaviaOrderFieldsFeature = Selectors.isFeatureEnabled(
    state,
    FEATURES.SINTAVIA_ORDER_FIELDS,
  );
  const boeingOrderFieldsFeature = Selectors.isFeatureEnabled(state, FEATURES.BOEING_ORDER_FIELDS);
  const isProsperFeatureEnabled = Selectors.isFeatureEnabled(state, FEATURES.EXTERNAL_PRODUCTION_ESTIMATE);
  const threeMOrderFieldsFeature = Selectors.isFeatureEnabled(
    state,
    FEATURES.THREE_M_MAIN_BUREAU_ORDER_FIELDS,
  );
  const orderBusinessSegmentFeatureEnabled = Selectors.isFeatureEnabled(
    state,
    FEATURES.ORDER_BUSINESS_SEGMENT,
  );
  const isNebumindFeatureEnabled = Selectors.isFeatureEnabled(state, FEATURES.NEBUMIND);
  const isPOCUKOrderFieldsFeatureEnabled = Selectors.isFeatureEnabled(state, FEATURES.POC_UK_ORDER_FIELDS);

  const model = modelUri && getUUIDResource(state, extractUuid(modelUri));
  const product = getUUIDResource(state, extractUuid(productUri));
  const modelLibrariesByUri = getModelLibrariesByUri(state);

  return {
    model,
    product,
    modelLibrariesByUri,
    baseMaterials,
    baseMaterialsByLocationUri,
    bureau,
    isUserRestricted,
    order,
    submitting,
    fetching,
    supportMaterials,
    workflows,
    locations,
    processSteps,
    printerTypes,
    supportStrategies,
    infillStrategies,
    customLineItemFieldReferences,
    customOrderFieldReferences,
    isSintaviaOrderFieldsFeatureEnabled: sintaviaOrderFieldsFeature,
    isOrderBusinessSegmentFeatureEnabled: orderBusinessSegmentFeatureEnabled,
    features: getFeatures(state),
    isBoeingOrderFieldsFeatureEnabled: boeingOrderFieldsFeature,
    is3MOrderFieldsFeatureEnabled: threeMOrderFieldsFeature,
    isPowderWorkflow: isPowderWorkflowFeatureEnabled,
    isNebumindFeatureEnabled,
    isProsperFeatureEnabled,
    isPOCUKOrderFieldsFeatureEnabled,
  };
};

export default connect(mapStateToProps)(AddLineItem);
