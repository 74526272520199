import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Actions from 'rapidfab/actions';
import { API_RESOURCES, CASTOR_COSTING_STATUSES } from 'rapidfab/constants';
import CastorCosting from 'rapidfab/components/hawking/CastorCosting';
import {
  getCastorCostingForModelLibrary,
  getCastorCostingConfigsByCastorCostingUri,
} from 'rapidfab/selectors';
import { loadCastorCostingConfig } from 'rapidfab/dispatchers/castorCosting';
import { modelLibraryResourceType } from '../../types';

const CastorCostingContainer = props => {
  const { uri: modelLibraryUri } = props.modelLibrary;

  const {
    isIntegrationCastorFeatureEnabled,
    setAddToCartModalVisible,
    handleSaveShoppingCart,
    isRobozeBureauOrderFieldsFeatureEnabled,
  } = props;
  const [isCastorConfirmationModalVisible, setIsCastorConfirmationModalVisible] = useState(false);

  const plainCastorGotoOncloseUrl = `${window.location.origin}/${window.location.hash}`;
  const castorGotoOncloseUrl = encodeURIComponent(plainCastorGotoOncloseUrl);
  const castorCosting = useSelector(state => getCastorCostingForModelLibrary(state, modelLibraryUri));
  const castorCostingConfigsByCastorCostingUri = useSelector(state => getCastorCostingConfigsByCastorCostingUri(state));
  const fetching = useSelector(state => state.ui.nautilus[API_RESOURCES.CASTOR_COSTING].list.fetching
    || state.ui.nautilus[API_RESOURCES.CASTOR_COSTING_CONFIG].list.fetching);
  const shoppingCartFetching = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.SHOPPING_CART].list.fetching ||
    state.ui.nautilus[API_RESOURCES.SHOPPING_CART].post.fetching ||
    state.ui.nautilus[API_RESOURCES.SHOPPING_CART].put.fetching ||
    state.ui.nautilus[API_RESOURCES.SHOPPING_CART_ITEM].post.fetching ||
    state.ui.nautilus[API_RESOURCES.SHOPPING_CART_ITEM].list.fetching ||
    state.ui.nautilus[API_RESOURCES.SHOPPING_CART_ITEM].put.fetching);

  let castorCostingConfigs = [];
  if (castorCosting) {
    castorCostingConfigs = castorCostingConfigsByCastorCostingUri[castorCosting.uri] || [];
  }

  const dispatch = useDispatch();

  const onInitialize = currentModelLibraryUri => loadCastorCostingConfig(dispatch, currentModelLibraryUri);

  const fetchCastorCosting = async (currentModelLibraryUri, modelUri) => {
    await dispatch(Actions.Api.nautilus[API_RESOURCES.CASTOR_COSTING].post({
      model: modelUri,
      'model-library': currentModelLibraryUri,
    }));
  };
  const fetchCastorCostingConfig = async uuid => {
    await dispatch(Actions.Api.nautilus[API_RESOURCES.CASTOR_COSTING]
      .put(uuid, { status: CASTOR_COSTING_STATUSES.NEW }));
  };

  const deleteCastorCosting = uuid => dispatch(Actions.Api.nautilus[API_RESOURCES.CASTOR_COSTING].delete(uuid));

  const deleteCastorCostingConfig = uuid =>
    dispatch(Actions.Api.nautilus[API_RESOURCES.CASTOR_COSTING_CONFIG].delete(uuid));

  useEffect(() => {
    if (castorCosting && modelLibraryUri) {
      loadCastorCostingConfig(dispatch, modelLibraryUri);
    }
  }, [castorCosting?.status, castorCosting?.castor_new_config_url, castorCosting?.notes]);

  useEffect(() => {
    onInitialize(modelLibraryUri);
  }, [modelLibraryUri]);

  return (
    <CastorCosting
      {...props}
      fetching={fetching}
      shoppingCartFetching={shoppingCartFetching}
      castorCosting={castorCosting}
      castorCostingConfigs={castorCostingConfigs}
      deleteCastorCosting={deleteCastorCosting}
      deleteCastorCostingConfig={deleteCastorCostingConfig}
      fetchCastorCostingConfig={fetchCastorCostingConfig}
      fetchCastorCosting={fetchCastorCosting}
      castorGotoOncloseUrl={castorGotoOncloseUrl}
      isRobozeBureauOrderFieldsFeatureEnabled={isRobozeBureauOrderFieldsFeatureEnabled}
      isIntegrationCastorFeatureEnabled={isIntegrationCastorFeatureEnabled}
      setAddToCartModalVisible={setAddToCartModalVisible}
      castorCartModal={{ isCastorConfirmationModalVisible, setIsCastorConfirmationModalVisible }}
      handleSaveShoppingCart={handleSaveShoppingCart}
    />
  );
};

CastorCostingContainer.defaultProps = {
  modelUri: null,
};

CastorCostingContainer.propTypes = {
  modelLibrary: modelLibraryResourceType.isRequired,
  model: PropTypes.shape({}).isRequired,
  modelUri: PropTypes.string,
  isIntegrationCastorFeatureEnabled: PropTypes.bool.isRequired,
  setAddToCartModalVisible: PropTypes.func.isRequired,
  handleSaveShoppingCart: PropTypes.func.isRequired,
  isRobozeBureauOrderFieldsFeatureEnabled: PropTypes.bool.isRequired,
};

export default CastorCostingContainer;
