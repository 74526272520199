import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { runPieceResourceType } from 'rapidfab/types';
import RunItemRow, { TYPES } from 'rapidfab/components/records/run/RunItemRow';
import { LINE_ITEM_STATUS, ROUTES } from 'rapidfab/constants';
import Alert from 'rapidfab/utils/alert';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import { getRouteURI } from 'rapidfab/utils/uriUtils';

export default class RunPieceItem extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(event, piece, order) {
    if (![
      LINE_ITEM_STATUS.CONFIRMED,
      LINE_ITEM_STATUS.PRINTING,
      LINE_ITEM_STATUS.POST_PROCESSING,
      LINE_ITEM_STATUS.SHIPPING,
    ].includes(piece.line_item_status)) {
      event.preventDefault();
      Alert.error(`Sub-Assembly status must be set to "confirmed" for order: ${getRouteURI(ROUTES.ORDER_EDIT, { uuid: extractUuid(order) })}.`);
    } else {
      const {
        setFirstItem,
        onShiftToggle,
      } = this.props;
      if (event.shiftKey) {
        onShiftToggle(piece);
      } else {
        setFirstItem(piece);
      }
    }
  }

  render() {
    const {
      piece,
      selected,
      labelRelationshipForCurrentPiece,
    } = this.props;

    return (
      <RunItemRow
        rowItem={piece}
        lineItem={piece.lineItem}
        selected={selected}
        handleClick={this.handleClick}
        type={TYPES.PIECE}
        labelRelationshipsForLineItems={labelRelationshipForCurrentPiece ? [labelRelationshipForCurrentPiece] : []}
      />
    );
  }
}

RunPieceItem.propTypes = {
  piece: runPieceResourceType.isRequired,
  selected: PropTypes.bool.isRequired,
  onShiftToggle: PropTypes.func.isRequired,
  setFirstItem: PropTypes.func.isRequired,
  labelRelationshipForCurrentPiece: PropTypes.shape({
    label: PropTypes.shape({}),
  }).isRequired,
};
