import _get from 'lodash/get';
import { API_RESOURCES } from 'rapidfab/constants';

export const getStateAssignments = state => state.api.nautilus[API_RESOURCES.ASSIGNMENT];
export const getStateCustomFields = state => state.api.nautilus[API_RESOURCES.CUSTOM_FIELD];
export const getStateDocuments = state => state.api.nautilus[API_RESOURCES.DOCUMENT];
export const getStateModels = state => state.api.nautilus[API_RESOURCES.MODEL];
export const getStateMemberships = state => state.api.nautilus[API_RESOURCES.MEMBERSHIPS];
// DO NOT USE next api resource, you must use getStateUISession (see reason below):
export const getStateSessions = state => state.api.nautilus[API_RESOURCES.SESSIONS];
export const getStateUsers = state => state.api.nautilus[API_RESOURCES.USERS];
export const getStatePermissions = state => state.api.nautilus[API_RESOURCES.PERMISSIONS];
export const getStateLayout = state => state.api.nautilus[API_RESOURCES.LAYOUT];
export const getStateLocations = state => state.api.nautilus[API_RESOURCES.LOCATION];
export const getStateLocationSettings = state => state.api.nautilus[API_RESOURCES.LOCATION_SETTINGS];
export const getStateProcessStep = state => state.api.nautilus[API_RESOURCES.PROCESS_STEP];
export const getStatePostProcessorTypes = state =>
  state.api.nautilus[API_RESOURCES.POST_PROCESSOR_TYPE];
export const getStatePostProcessors = state =>
  state.api.nautilus[API_RESOURCES.POST_PROCESSOR];
export const getStateProcessProviders = state => state.api.nautilus[API_RESOURCES.PROCESS_PROVIDER];
export const getStateShipmentPackingSlips = state => state.api.nautilus[API_RESOURCES.SHIPMENT_PACKING_SLIP];
export const getStateManufacturers = state => state.api.nautilus[API_RESOURCES.MANUFACTURER];
export const getStateShippings = state => state.api.nautilus[API_RESOURCES.SHIPPING];
export const getStateShipments = state => state.api.nautilus[API_RESOURCES.SHIPMENT];
export const getStateWorkflows = state => state.api.nautilus[API_RESOURCES.WORKFLOW];
export const getStateWorkInstructions = state => state.api.nautilus[API_RESOURCES.WORK_INSTRUCTION];
export const getStateWorkInstructionsReports = state => state.api.nautilus[API_RESOURCES.WORK_INSTRUCTION_REPORT];
export const getStatePrepWorkflows = state => state.api.nautilus[API_RESOURCES.PREP_WORKFLOW];
export const getStatePrepWorkflowRecords = state => state.api.nautilus[API_RESOURCES.PREP_WORKFLOW_RECORD];
export const getStatePrepTasks = state => state.api.nautilus[API_RESOURCES.PREP_TASK];
export const getStatePrepTaskRecords = state => state.api.nautilus[API_RESOURCES.PREP_TASK_RECORD];
export const getStateTraceabilityReports = state =>
  state.api.nautilus[API_RESOURCES.TRACEABILITY_REPORT];
export const getStateMaterialBatchTraceabilityReports = state =>
  state.api.nautilus[API_RESOURCES.MATERIAL_BATCH_TRACEABILITY_REPORT];
export const getStateConversions = state =>
  state.api.nautilus[API_RESOURCES.CURRENCY_CONVERSION];
export const getStateRunTransformations = state =>
  state.api.nautilus[API_RESOURCES.RUN_TRANSFORMATION];

export const getStateGroups = state => state.api.nautilus[API_RESOURCES.GROUPS];
export const getStateBureaus = state => state.api.nautilus[API_RESOURCES.BUREAU];
export const getStateBatch = state => state.api.nautilus[API_RESOURCES.MATERIAL_BATCH];
export const getStateBureauBrandings = state => state.api.nautilus[API_RESOURCES.BUREAU_BRANDING];
export const getStateBureauSettings = state => state.api.nautilus[API_RESOURCES.BUREAU_SETTINGS];
export const getStateBureauIntakeSettings = state => state.api.nautilus[API_RESOURCES.BUREAU_INTAKE_SETTINGS];
export const getStateComments = state => state.api.nautilus[API_RESOURCES.COMMENT];
export const getStateFeatures = state => state.api.nautilus[API_RESOURCES.FEATURE];
export const getStateEvents = state => state.api.nautilus[API_RESOURCES.EVENT];
export const getStateMaterials = state => state.api.nautilus[API_RESOURCES.MATERIAL];
export const getStateMaterialContainers = state => state.api.nautilus[API_RESOURCES.MATERIAL_CONTAINER];
export const getStateModelLibraries = state => state.api.nautilus[API_RESOURCES.MODEL_LIBRARY];
export const getStateLibrary = state => state.api.nautilus[API_RESOURCES.LIBRARY];
export const getStateProspers = state => state.api.nautilus[API_RESOURCES.PROSPER];
export const getStateRoles = state => state.api.nautilus[API_RESOURCES.ROLE];
export const getStateStocks = state => state.api.nautilus[API_RESOURCES.STOCK];

export const getStateToolLinks = state => state.api.nautilus[API_RESOURCES.TOOLING_LINK];
export const getStateToolingStocks = state => state.api.nautilus[API_RESOURCES.TOOLING_STOCK];
export const getStateToolingStockActions = state => state.api.nautilus[API_RESOURCES.TOOLING_STOCK_ACTION];
export const getStateOrders = state => state.api.nautilus[API_RESOURCES.ORDER];
export const getStateOrderQuotes = state =>
  state.api.nautilus[API_RESOURCES.ORDER_QUOTE];
export const getStateLabor = state => state.api.nautilus[API_RESOURCES.LABOR];
export const getStateLineItems = state => state.api.nautilus[API_RESOURCES.LINE_ITEM];
export const getStateLineItemActuals = state => state.api.nautilus[API_RESOURCES.LINE_ITEM_ACTUALS];
export const getStateLineItemsWithAvailableForRunPrints = state =>
  state.api.nautilus[API_RESOURCES.LINE_ITEM_WITH_AVAILABLE_FOR_RUN_PRINTS];
export const getStateLineItemQuotes = state => state.api.nautilus[API_RESOURCES.LINE_ITEM_QUOTE];
export const getStateMaterialLots = state => state.api.nautilus[API_RESOURCES.MATERIAL_LOT];
export const getStateMaterialBatches = state => state.api.nautilus[API_RESOURCES.MATERIAL_BATCH];
export const getStateMaterialBatchActions = state => state.api.nautilus[API_RESOURCES.MATERIAL_BATCH_ACTION];
export const getStatePermanentContainerActions = state => state.api.nautilus[API_RESOURCES.MATERIAL_CONTAINER_ACTION];
export const getStateMaterialPurchaseOrders = state => state.api.nautilus[API_RESOURCES.MATERIAL_PURCHASE_ORDER];
export const getStateNetfabbLineItems = state => state.api.nautilus[API_RESOURCES.LINE_ITEM_NETFABB];
export const getStateForgeToken = state => state.api.nautilus[API_RESOURCES.OAUTH_TOKEN_AUTODESK_FORGE];
export const getStateOrderPackingSlips = state => state.api.nautilus[API_RESOURCES.ORDER_PACKING_SLIP];
export const getStatePrints = state => state.api.nautilus[API_RESOURCES.PRINT];
export const getStatePrinters = state => state.api.nautilus[API_RESOURCES.PRINTER];
export const getStatePrinterTypes = state => state.api.nautilus[API_RESOURCES.PRINTER_TYPE];
export const getStateToolingStockTypes = state => state.api.nautilus[API_RESOURCES.TOOLING_STOCK_TYPE];
export const getStateRuns = state => state.api.nautilus[API_RESOURCES.RUN];

export const getStateRunActuals = state => state.api.nautilus[API_RESOURCES.RUN_ACTUALS];
export const getStateCreateRuns = state => state.api.nautilus[API_RESOURCES.CREATE_RUNS];
export const getStateRunEstimates = state => state.api.nautilus[API_RESOURCES.SCHEDULE_RUNS];
export const getStateSpecimens = state => state.api.nautilus[API_RESOURCES.SPECIMEN];
export const getStateServiceProviders = state =>
  state.api.nautilus[API_RESOURCES.SERVICE_PROVIDER];
export const getStateServiceProviderOrders = state =>
  state.api.nautilus[API_RESOURCES.SERVICE_PROVIDER_V1_ORDER];
export const getStateServiceProviderJobs = state =>
  state.api.nautilus[API_RESOURCES.SERVICE_PROVIDER_JOB];
export const getStateModelers = state => state.api.nautilus[API_RESOURCES.MODELER];
export const getStateModelerTypes = state => state.api.nautilus[API_RESOURCES.MODELER_TYPE];
export const getStateUploadModel = state => state.uploadModel;
export const getStateFileUpload = state => state.fileLoader;
export const getStateCreateRunProgress = state => state.createRunProgress;
export const getStateLabels = state => state.api.nautilus[API_RESOURCES.LABEL];
export const getStateLabelRelationships = state => state.api.nautilus[API_RESOURCES.LABEL_RELATIONSHIP];
export const getStateLocationFilter = state => state.locationFilter[API_RESOURCES.LOCATION];
export const getStateSubLocationFilter = state => state.locationFilter.subLocation;
export const getStateTraceabilityReportFilters = state => state.traceabilityReportFilters.filters;
export const getStateSubmittedOrders = state => state.submittedOrders.orders;
export const getStateSelectedCurrency = state => state.dynamicCurrency.selectedCurrency;
export const getStateEventStreamDisconnected = state => state.eventStream.disconnected;
export const getStateDowntimes = state => state.api.nautilus[API_RESOURCES.DOWNTIME];
export const getStateBuilds = state => state.api.nautilus[API_RESOURCES.BUILD];
export const getStateBuildFiles = state => state.api.nautilus[API_RESOURCES.BUILD_FILE];
export const getStateInfillStrategies = state => state.api.nautilus[API_RESOURCES.INFILL_STRATEGY];
export const getStateSupportStrategies = state => state.api.nautilus[API_RESOURCES.SUPPORT_STRATEGY];
export const getStateProsperPrinterMaterial = state => state.api.nautilus[API_RESOURCES.PROSPER_PRINTER_MATERIAL];
export const getStateWorkChecklistLinkings = state => state.api.nautilus[API_RESOURCES.WORK_CHECKLIST_LINKING];
export const getStatePieces = state => state.api.nautilus[API_RESOURCES.PIECE];
export const getStatePieceActuals = state => state.api.nautilus[API_RESOURCES.PIECE_ACTUALS];
export const getStateCustomGroups = state => state.api.nautilus[API_RESOURCES.CUSTOM_GROUP];
export const getStateCaptures = state => state.api.nautilus[API_RESOURCES.CAPTURE];
export const getStateProducts = state => state.api.nautilus[API_RESOURCES.PRODUCT];
export const getStateDesignFiles = state => state.api.nautilus[API_RESOURCES.DESIGN_FILE];

export const getStateAssemblyMeta = state => state.api.nautilus[API_RESOURCES.ASSEMBLY_META];
export const getStateAssemblyPartMeta = state => state.api.nautilus[API_RESOURCES.ASSEMBLY_PART_META];
export const getStateAnatomicalModelSummaryReports =
    state => state.api.nautilus[API_RESOURCES.ANATOMICAL_MODEL_SUMMARY_REPORT];

export const getStateCastorCostings = state => state.api.nautilus[API_RESOURCES.CASTOR_COSTING];
export const getStateCastorCostingConfigs = state => state.api.nautilus[API_RESOURCES.CASTOR_COSTING_CONFIG];
export const getStateZverseConversions = state => state.api.nautilus[API_RESOURCES.ZVERSE_CONVERSION];
export const getStateExpertConversions = state => state.api.nautilus[API_RESOURCES.EXPERT_CONVERSION];
export const getStateBuildsLibraries = state => state.api.nautilus[API_RESOURCES.BUILD_FILE_LIBRARY];
export const getStateMaterialTestOperations = state => state.api.nautilus[API_RESOURCES.MATERIAL_TEST_OPERATION];
export const getStateMaterialTestOperationLinkings = state =>
  state.api.nautilus[API_RESOURCES.MATERIAL_TEST_OPERATION_LINKING];
export const getStateMaterialTestInstructions = state => state.api.nautilus[API_RESOURCES.MATERIAL_TEST_INSTRUCTION];
export const getStateMaterialTestInstructionReports = state =>
  state.api.nautilus[API_RESOURCES.MATERIAL_TEST_INSTRUCTION_REPORT];
export const getStateMaterialTestUnits = state => state.api.nautilus[API_RESOURCES.MATERIAL_TEST_UNIT];
export const getStateMaterialTestPanels = state => state.api.nautilus[API_RESOURCES.MATERIAL_TEST_PANEL];
// Temporary selector. Will be removed once functionality is no longer needed
export const getStateAnatomicalModels = state => state.api.nautilus[API_RESOURCES.ANATOMICAL_MODEL];
export const getStateAnatomicalModelAssemblies = state => state.api.nautilus[API_RESOURCES.ANATOMICAL_MODEL_ASSEMBLY];
export const getStateAnatomicalModelParts = state => state.api.nautilus[API_RESOURCES.ANATOMICAL_MODEL_PART];
export const getStateSamples = state => state.api.nautilus[API_RESOURCES.SAMPLE];
export const getStateShoppingCarts = state => state.api.nautilus[API_RESOURCES.SHOPPING_CART];
export const getStateShoppingCartItems = state => state.api.nautilus[API_RESOURCES.SHOPPING_CART_ITEM];
export const getStateMSNavImportFiles = state => state.api.nautilus[API_RESOURCES.MSNAV_IMPORT_FILE];
export const getStateMSNavWorkflows = state => state.api.nautilus[API_RESOURCES.MSNAV_IMPORT_WORKFLOW];
export const getStateMSNavWorkflowToWorkflows =
state => state.api.nautilus[API_RESOURCES.MSNAV_IMPORT_MSNAV_WORKFLOW_TO_WORKFLOW];
export const getStateCarousels = state => state.api.nautilus[API_RESOURCES.CAROUSEL];
export const getStatePayments = state => _get(state.ui.nautilus[API_RESOURCES.PAYMENT].list, 'json', null);
// Get the latest msnav event published to the event stream.
export const getStateEventStreamMSNavLatest = state => state.eventStream.msnav;
export const getStateEventStreamCreateRunLatest = state => state.eventStream.create_run_updates;

export const getStatePreferencesUserSettings = state => state.preferences.userSettings;

// AHTUNG! Next constants are using RAW data endpoint
// If you change it, please be careful with what are you doing
export const getStateUIMaterialGenealogy = state => _get(state.ui.nautilus[API_RESOURCES.MATERIAL_GENEALOGY].get, 'json', null);
// Session.uri === user_uri, so this object is always overlapped by user object in the `api` store
export const getStateUISession = state => _get(state.ui.nautilus[API_RESOURCES.SESSIONS].get, 'json', null);

// Naming is changed from how the endpoint is called
// since `ForResource` is a way of naming for the final selectors used in logic
// and `groups` part is added since each of the `resources` is actually a list grouped as an object
export const getStateUIWorkChecklistLinkingsGroups =
  // `work-checklists-for-resource` API allow `LIST` requests only
  state => _get(state.ui.nautilus[API_RESOURCES.WORK_CHECKLISTS_FOR_RESOURCE], 'list.json.resources');

export const getStateModelRotation = state => state.modelRotation;
export const getStateDebugModeUserInfo = state => state.debugModeUserInfo;

export const getStateGuidelinesForWorkInstruction = state => state.api.nautilus[API_RESOURCES.FOR_WORK_INSTRUCTION];
export const getStateGuidelinesForProcessStep = state => state.api.nautilus[API_RESOURCES.FOR_PROCESS_STEP];
export const getStateGuidelineSuggestions = state => state.api.nautilus[API_RESOURCES.FOR_LINE_ITEM];
export const getStateNamedExpressions = state => state.api.nautilus[API_RESOURCES.NAMED_EXPRESSION];
export const getStateWorkSchedule = state => state.api.nautilus[API_RESOURCES.WORK_SCHEDULE];
export const getStateScheduleRuns = state => state.api.nautilus[API_RESOURCES.SCHEDULE_RUNS];
export const getStateRunMaterial = state => state.api.nautilus[API_RESOURCES.RUN_MATERIAL];
export const getStateLineItemWorkstepEstimate = state => state.api.nautilus[API_RESOURCES.LINE_ITEM_WORKSTEP_ESTIMATE];
export const getStateLineItemWorkstepCostEstimate = state =>
  state.api.nautilus[API_RESOURCES.LINE_ITEM_WORKSTEP_COST_ESTIMATE];
export const getStateSubLocations = state => state.api.nautilus[API_RESOURCES.SUB_LOCATION];
export const getStockSummary = state => state.api.nautilus[API_RESOURCES.STOCK_SUMMARY];
export const getStateSensorResources = state => state.api.nautilus[API_RESOURCES.SENSOR];
export const getStateSensorDataResources = state => state.api.nautilus[API_RESOURCES.SENSOR_DATA];
export const getStateMaterialBatchSensorData = state => state.api.nautilus[API_RESOURCES.MATERIAL_BATCH_SENSOR_DATA];
export const getStateSlicerConfig = state => state.api.nautilus[API_RESOURCES.SLICER_CONFIGURATION_RAW];
export const getStateSlicedModels = state => state.api.nautilus[API_RESOURCES.BUILD_FILE];
export const getStateSecureFileCheckouts = state => state.api.nautilus[API_RESOURCES.SECURE_FILE_CHECKOUT];
export const getStateSecureCheckoutDirectories = state => state.api.nautilus[API_RESOURCES.SECURE_CHECKOUT_DIRECTORY];
export const getStateModelerPrintingPresets = state => state.api.nautilus[API_RESOURCES.MODELER_PRINTING_PRESET];
export const getZipFiles = state => state.api.nautilus[API_RESOURCES.ZIPFILE];
export const getStateBureauNotifications = state => state.api.nautilus[API_RESOURCES.BUREAU_NOTIFICATION];
export const getStateThreadsMessages = state => state.api.nautilus[API_RESOURCES.THREADS_MESSAGES];
export const getStateRunWithSchedules = state => state.api.nautilus[API_RESOURCES.RUN_WITH_SCHEDULE];
