import {
  faArrowDownShortWide,
  faArrowTurnDown,
  faCalendar,
  faCircle,
  faCube, faExternalLink,
  faObjectGroup,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import Loading from 'rapidfab/components/Loading';
import ResourceIconTooltip from 'rapidfab/components/ResourceIconTooltip';
import TruncatedTitle from 'rapidfab/components/TruncatedTitle';
import { ROUTES } from 'rapidfab/constants';
import { resetRcTooltipInnerStyle } from 'rapidfab/constants/styles';
import { FormattedDate, FormattedMessage } from 'rapidfab/i18n';
import { LINE_ITEM_STATUS_COLOR_CODE_MAPPING, LINE_ITEM_STATUS_MAP } from 'rapidfab/mappings';
import { lineItemResourceType, modelResourceType, workflowResourceType } from 'rapidfab/types';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import RCTooltip from 'rc-tooltip';
import React from 'react';
import { Col, Image, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const MiniCards = ({ lineItem, workflow, material, model, isLoading }) => {
  const scrollToLineItem = (id, event) => {
    event.preventDefault();
    event.stopPropagation();
    // eslint-disable-next-line unicorn/prefer-query-selector
    const element = document.getElementById(id);
    if (!element) return;

    element.parentNode.parentNode.classList.remove('hidden');

    // Store the original border color and border width
    const originalBorderColor = window.getComputedStyle(element).getPropertyValue('border-color');
    const originalBorderWidth = window.getComputedStyle(element).getPropertyValue('border-width');

    element.scrollIntoView();

    // Change the border color to green and increases the thickness to show correct lineitem
    element.style.borderColor = LINE_ITEM_STATUS_COLOR_CODE_MAPPING.complete;
    element.style.borderWidth = 'thick';

    // Set a timeout to change the border color back to the original after 2 seconds
    setTimeout(() => {
      element.style.borderColor = originalBorderColor;
      element.style.borderWidth = originalBorderWidth;
    }, 2000); // (2 seconds)
  };

  const snapshot = model?.snapshot_content;

  const renderMaterialTooltip = (material, type) => {
    const { name, uri } = material ?? {};
    if (!name) return null;
    const tooltipTitle = type === 'base' ? 'Base Material' : 'Support Material';
    return (
      <RCTooltip
        placement="top"
        id="materialTooltip"
        destroyTooltipOnHide
        overlayInnerStyle={resetRcTooltipInnerStyle}
        mouseLeaveDelay={0.4}
        overlay={(
          <div className="materialTooltip">
            <p className="materialPrinterTypeTooltipTitle mb0">
              <strong>{tooltipTitle}:</strong> {name}
              {uri && (
                <Link
                  to={getRouteURI(ROUTES.MATERIALS, null, { uuid: extractUuid(uri) }, true)}
                  target="_blank"
                >
                  <FontAwesomeIcon className="spacer-left" icon={faExternalLink} />
                </Link>
              )}
            </p>
          </div>
        )}
      >
        <div
          className="spacer-right"
          style={{
            width: 15,
            height: 15,
            backgroundColor: material.color,
          }}
        />
      </RCTooltip>
    );
  };

  return (
    isLoading ? <Loading /> : (
      <Col xl={6} lg={12} className="m-b" md={12} sm={12}>
        <div className="LineItemCard">
          <Row>
            <Col>
              <Row className="mb-2">
                <Col xs={6} sm={6} lg={12} className="d-flex">
                  <div>
                    <ResourceIconTooltip
                      faIcon={faCube}
                      iconClassNames="spacer-right"
                      text="Line Item"
                    />
                  </div>
                  <div className="w-full">
                    <div className="d-flex align-items-center justify-content-between">
                      <div>
                        <TruncatedTitle
                          title={lineItem.name}
                          maxTextLength={30}
                          titleClassName="font-weight-400"
                          inline
                        />
                      </div>
                      <div>
                        <OverlayTrigger
                          placement="top"
                          overlay={(
                            <Tooltip>
                              <FormattedMessage
                                id={LINE_ITEM_STATUS_MAP[lineItem.status]?.id}
                                defaultMessage={LINE_ITEM_STATUS_MAP[lineItem.status]?.message}
                              />
                            </Tooltip>
                          )}
                        >
                          <FontAwesomeIcon
                            icon={faCircle}
                            style={{ color: LINE_ITEM_STATUS_COLOR_CODE_MAPPING[lineItem.status] }}
                          />
                        </OverlayTrigger>
                      </div>
                    </div>

                    <p className="m-0 font-weight-300 font-size-12">
                      Qty: {lineItem.quantity}
                    </p>
                  </div>

                </Col>
              </Row>
              <Row className="d-flex align-items-center">
                <Col xs={6} lg={4}>
                  {snapshot ? (
                    <Col lg={3} className="d-flex align-self-center">
                      <OverlayTrigger
                        placement="top"
                        overlay={(
                          <Tooltip className="LineItemCardPieceSnapshotTooltipPreview">
                            <Image className="LineItemCardPieceSnapshotTooltip" src={snapshot} />
                          </Tooltip>
                        )}
                      >
                        <Image className="rounded-circle LineItemCardPieceSnapshot" src={snapshot} />
                      </OverlayTrigger>
                    </Col>
                  ) : null}

                </Col>
                <Col xs={6} lg={8}>
                  {workflow && (
                    <div>
                      <ResourceIconTooltip
                        faIcon={faArrowDownShortWide}
                        iconClassNames="spacer-right LineItemCardIcon"
                        text="Workflow"
                      />
                      <TruncatedTitle
                        title={workflow.name}
                        maxTextLength={20}
                        titleClassName="font-weight-400"
                        titleLinkToProps={[
                          ROUTES.WORKFLOW_EDIT,
                          { uuid: extractUuid(lineItem.workflow) },
                          {},
                          true,
                        ]}
                        inline
                      />

                    </div>
                  )}
                  <div className="mb3">
                    <ResourceIconTooltip
                      faIcon={faCalendar}
                      iconClassNames="spacer-right LineItemCardIcon"
                      text="Created"
                    />
                    <FormattedDate value={lineItem.created} template="LL" />
                  </div>
                  <div className="d-flex flex-row">
                    <ResourceIconTooltip
                      faIcon={faObjectGroup}
                      iconClassNames="spacer-right LineItemCardIcon"
                      text={material.base && material.support ? 'Materials' : 'Material'}
                    />
                    {material.base && renderMaterialTooltip(material.base, 'base')}
                    {material.support && renderMaterialTooltip(material.support, 'support')}
                  </div>
                </Col>
              </Row>

            </Col>
          </Row>
          <FontAwesomeIcon
            data-cy="line-item-carousel-navigator-arrow"
            className="LineItemCardArrow"
            icon={faArrowTurnDown}
            onClick={
              event => scrollToLineItem(extractUuid(lineItem.uri), event)
            }
          />
        </div>
      </Col>
    )

  );
};

MiniCards.defaultProps = {
  model: null,
  workflow: null,
};

MiniCards.propTypes = {
  lineItem: lineItemResourceType.isRequired,
  workflow: workflowResourceType,
  material: PropTypes.shape({
    color: PropTypes.string,
    support: PropTypes.shape({
      color: PropTypes.string,
      name: PropTypes.string,
    }),
    base: PropTypes.shape({
      color: PropTypes.string,
      name: PropTypes.string,
    }),
  }).isRequired,
  model: modelResourceType,
  isLoading: PropTypes.bool.isRequired,
};

export default MiniCards;
