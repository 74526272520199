import { createSelector } from 'reselect';
import { getStateResources, getPredicate } from 'rapidfab/selectors/helpers/base';
import { getProcessSteps } from 'rapidfab/selectors/processStep';
import { getProcessProviders } from 'rapidfab/selectors/processProvider';
import * as baseStateSelectors from 'rapidfab/selectors/baseStateSelectors';
import { getServiceProviders } from 'rapidfab/selectors/serviceProvider';
import { getWorkflows } from 'rapidfab/selectors/workflow';
import {
  LINE_ITEM_COMPOSITION_TYPES,
  PROCESS_PROVIDER_STATUSES,
  WORKFLOW_TYPES,
  PROCESS_STEP_SHIPPING_DIRECTION,
  WORKFLOW_USAGE_STATES,
} from 'rapidfab/constants';
import _filter from 'lodash/filter';
import _map from 'lodash/map';
import _reduce from 'lodash/reduce';
import _reject from 'lodash/reject';
import _isEmpty from 'lodash/isEmpty';
import _keyBy from 'lodash/keyBy';

export const getProcessStepsForWorkflow = createSelector(
  [getPredicate, getProcessSteps],
  (workflow, processSteps) => {
    if (!workflow) {
      return [];
    }
    const steps = _filter(processSteps, ps => ps.workflows?.includes(workflow.uri));
    const stepsByUri = _keyBy(steps, 'uri');
    return workflow.process_steps.map(psUri => stepsByUri[psUri]).filter(Boolean);
  },
);

export const getOutsourceProcessStepsForWorkflow = createSelector(
  [getPredicate, getProcessSteps],
  (workflowUri, processSteps) => _filter(
    // Outsource process step is the one that has `is_service=true` and has no related steps.
    processSteps, ps => ps.workflows?.includes(workflowUri) && ps.is_service &&
      ![
        PROCESS_STEP_SHIPPING_DIRECTION.SERVICE_PROVIDER_TO_BUREAU,
        PROCESS_STEP_SHIPPING_DIRECTION.BUREAU_TO_SERVICE_PROVIDER,
      ].includes(ps.shipping_direction),
  )
    .map(ps => {
      const step_position = ps.step_positions[ps.workflows.indexOf(workflowUri)];
      return {
        ...ps,
        step_position,
      };
    }),
);

export const getServiceProvidersByOutsourceProcessStepUriForWorkflow = createSelector(
  [
    getOutsourceProcessStepsForWorkflow,
    getServiceProviders,
    getProcessProviders,
  ],
  (
    outsourceProcessSteps,
    serviceProviders,
    processProviders,
  ) =>
    _reduce(
      outsourceProcessSteps,
      (result, outsourceProcessStep) => {
        const newResult = result;

        // Find related provider-processors based on workstation URI
        const processProvidersForProcessStep = _filter(
          processProviders,
          { post_processor: outsourceProcessStep.workstation },
        );

        // Get all selected service providers for the current outsource process step
        const serviceProviderUrisForProcessStep = _map(
          // Filter to only active ones
          _filter(processProvidersForProcessStep, { status: PROCESS_PROVIDER_STATUSES.ACTIVE }),
          'service_provider',
        );

        // Add all service providers for the current step, keyed by step URI
        newResult[outsourceProcessStep.uri] = _filter(
          serviceProviders,
          serviceProvider => serviceProviderUrisForProcessStep.includes(serviceProvider.uri),
        );
        return newResult;
      },
      {},
    ),
);

export const getNonCustomWorkflows = createSelector(
  [getWorkflows],
  workflows => _filter(
    workflows,
    workflow =>
      !workflow.line_item && (!workflow.pieces || workflow.pieces.length === 0),
  ),
);

export const getNonCustomSpecimenAndAdditiveWorkflows = createSelector(
  [getNonCustomWorkflows],
  workflows => _filter(
    workflows,
    ({ type }) => [WORKFLOW_TYPES.ADDITIVE_MANUFACTURING, WORKFLOW_TYPES.SPECIMEN].includes(type),
  ),
);

export const getNonCustomAssemblyWorkflows = createSelector(
  [getNonCustomWorkflows],
  workflows => _filter(
    workflows,
    { type: WORKFLOW_TYPES.ASSEMBLY },
  ),
);

export const getWorkflowsAlphabetized = createSelector(
  [getWorkflows],
  workflows => workflows.sort((a, b) => a.name?.localeCompare(b.name)),
);

export const getAllWorkflowsForLineItem = createSelector(
  [getPredicate, getWorkflowsAlphabetized],
  (lineItem, workflows) => {
    if (!lineItem) {
      return [];
    }
    return _filter(workflows, workflow => (
      (
        // Ignore custom line item production workflow
        !workflow.line_item
        // Piece workflows
        && _isEmpty(workflow.pieces)
        // And archived ones
        && workflow.usage_state !== WORKFLOW_USAGE_STATES.ARCHIVED
      )
      // unless current line item uses this workflow
      || workflow.uri === lineItem.workflow
    ));
  },
);

export const getAvailableAdditiveWorkflowsForLineItem = createSelector(
  [getAllWorkflowsForLineItem],
  workflows => _filter(workflows, { type: WORKFLOW_TYPES.ADDITIVE_MANUFACTURING }),
);

export const getAvailableAssemblyWorkflowsForLineItem = createSelector(
  [getAllWorkflowsForLineItem],
  workflows => _filter(workflows, { type: WORKFLOW_TYPES.ASSEMBLY }),
);

export const getSpecimenWorkflows = createSelector(
  [baseStateSelectors.getStateWorkflows, getStateResources],
  (uuids, resources) => {
    const workflowFields = _map(uuids, uuid => resources[uuid]);
    return _filter(workflowFields, ['type', 'specimen']);
  },
);

export const getWorkflowsOfType = createSelector(
  [getPredicate, getWorkflowsAlphabetized],
  (type, workflows) => {
    /* Handle if an array is passed into `type` because we may want powder data too. */
    if (Array.isArray(type)) {
      return _filter(workflows, workflow => (
        type.includes(workflow.type) && !workflow.line_item && _isEmpty(workflow.pieces)
      ));
    }

    // Ignore custom line item and piece-level edited production workflows
    return _filter(workflows, workflow => (
      type === workflow.type && !workflow.line_item && _isEmpty(workflow.pieces)
    ));
  },
);

export const getAvailableWorkflowsOfType = createSelector(
  [getWorkflowsOfType],
  workflowsByType => _reject(
    workflowsByType,
    { usage_state: WORKFLOW_USAGE_STATES.ARCHIVED },
  ),
);

export const getAvailableWorkflowsForLineItem = createSelector(
  [getAvailableAssemblyWorkflowsForLineItem, getAvailableAdditiveWorkflowsForLineItem, getPredicate],
  (availableAssemblyWorkflowsForLineItem, availableAdditiveWorkflowsForLineItem, lineItem) => {
    const compositionType = lineItem && lineItem.composition_type;
    switch (compositionType) {
      case LINE_ITEM_COMPOSITION_TYPES.SINGLE_MESH_PRODUCT:
      case LINE_ITEM_COMPOSITION_TYPES.ASSEMBLY_PART:
      case LINE_ITEM_COMPOSITION_TYPES.CO_PRINT:
        return availableAdditiveWorkflowsForLineItem;
      case LINE_ITEM_COMPOSITION_TYPES.ASSEMBLY:
        return availableAssemblyWorkflowsForLineItem;
      case LINE_ITEM_COMPOSITION_TYPES.CO_PRINT_PART:
        // Workflow Selector will not be used for Co-Print-Part.
        // So returning empty list as an edge-case
        return [];
      default:
        return [];
    }
  },
);
