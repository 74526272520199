import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Draggable } from 'react-drag-and-drop';
import { extractUuid } from 'rapidfab/reducers/makeApiReducers';
import { FormattedLength, FormattedMessage } from 'rapidfab/i18n';
import { getRawAndFormattedFillPercentage } from 'rapidfab/utils/printBedFill';
import { LINE_ITEM_COMPOSITION_TYPES } from 'rapidfab/constants';
import { pieceResourceType, printerTypeResourceType } from 'rapidfab/types';
import { getLineItemWorkflowTypeObjectKey } from 'rapidfab/utils/lineItemUtils';

export default class ActivePiece extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(event, piece) {
    const { setFirstItem, onShiftToggle } = this.props;
    if (event.shiftKey) {
      onShiftToggle(piece);
    } else {
      setFirstItem(piece);
    }
  }

  render() {
    const { piece, printer, selected } = this.props;
    const {
      lineItem: {
        composition_type: compositionType,
        // Available for Co-Print Line items only
        modelsForCoPrint,
      },
    } = piece;
    const isCoPrintLineItem = compositionType === LINE_ITEM_COMPOSITION_TYPES.CO_PRINT;
    const pieceLineItemWorkflowTypeKey = getLineItemWorkflowTypeObjectKey(piece.lineItem);
    const models = isCoPrintLineItem ? modelsForCoPrint : [piece.lineItem[pieceLineItemWorkflowTypeKey].model];
    const { formatted: formattedBedFillPercentage } = getRawAndFormattedFillPercentage(
      models, printer && printer.printer_type,
      printer && printer.printer_type,
    );

    return (
      <Draggable type="buildplatepiece" className={`${this.props.isDragAndDropHovering ? 'run-new-drag-n-drop-highlight' : ''}`} data={piece.uri}>
        <tr
          onClick={event => this.handleClick(event, piece)}
          className={selected ? 'selected-row ' : null}
          style={{ border: 'none' }}
        >
          <td>
            <a href={`#/records/order/${extractUuid(piece.order)}`}>
              {piece.order_name} ({piece.lineItem.name})
            </a>
          </td>
          <td>
            <FormattedLength
              length={piece.lineItem[pieceLineItemWorkflowTypeKey].model.size.x}
              roundPrecision={2}
              lengthUnits={piece.lineItem[pieceLineItemWorkflowTypeKey].model.file_unit}
            />
            {' × '}
            <FormattedLength
              length={piece.lineItem[pieceLineItemWorkflowTypeKey].model.size.y}
              roundPrecision={2}
              lengthUnits={piece.lineItem[pieceLineItemWorkflowTypeKey].model.file_unit}
            />
          </td>
          <td>
            {
              models[0]
                ? formattedBedFillPercentage
                : (<FormattedMessage id="notAvailable" defaultMessage="N/A" />)
            }
          </td>
        </tr>
      </Draggable>
    );
  }
}

ActivePiece.propTypes = {
  piece: pieceResourceType.isRequired,
  printer: printerTypeResourceType.isRequired,
  selected: PropTypes.bool.isRequired,
  setFirstItem: PropTypes.func.isRequired,
  onShiftToggle: PropTypes.func.isRequired,
  isDragAndDropHovering: PropTypes.bool.isRequired,
};
