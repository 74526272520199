import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _find from 'lodash/find';
import _map from 'lodash/map';
import _reduce from 'lodash/reduce';
import { PRIORITIES } from 'rapidfab/constants';
import { getRawAndFormattedFillPercentage } from 'rapidfab/utils/printBedFill';
import { Row, Card, ButtonToolbar, Button, Col } from 'react-bootstrap';
import { Droppable } from 'react-drag-and-drop';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'rapidfab/i18n';
import Loading from 'rapidfab/components/Loading';
import { modelResourceType, printerTypeResourceType } from 'rapidfab/types';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import ActivePiece from './ActivePiece';
import ActivePiecesMetadata from './ActivePiecesMetaData';
import { getLineItemWorkflowTypeObjectKey } from '../../../utils/lineItemUtils';

export default class ActivePiecesList extends Component {
  constructor(props) {
    super(props);
    this.droppableRef = React.createRef();
    this.state = {
      shiftStart: props.shiftStart,
      overrideBuildPlateChecks: false,
    };

    this.onShiftToggle = this.onShiftToggle.bind(this);
    this.setFirstItem = this.setFirstItem.bind(this);
    this.onToggleOverrideBuildPlateChecks = this.onToggleOverrideBuildPlateChecks.bind(this);
  }

  onShiftToggle(piece) {
    const { pieces, onSelectGroup } = this.props;
    let activePrintsArray = [];

    if (this.state.shiftStart !== null) {
      const indexEnd = pieces.findIndex(x => x.uuid === piece.uuid);

      const shiftStart = Math.min(this.state.shiftStart, indexEnd);
      const shiftEnd = Math.max(this.state.shiftStart, indexEnd);
      activePrintsArray = pieces.slice(shiftStart, shiftEnd + 1);

      this.setState({ shiftStart: null }, () => {
        onSelectGroup(activePrintsArray);
      });
    }
  }

  onToggleOverrideBuildPlateChecks() {
    const { overrideBuildPlateChecks } = this.state;
    this.setState({ overrideBuildPlateChecks: !overrideBuildPlateChecks });
  }

  setFirstItem(piece) {
    const { pieces, onSelect, selected } = this.props;
    const { shiftStart } = this.state;
    let indexStart = pieces.findIndex(x => x.uuid === piece.uuid);
    const itemIsSelected = selected.findIndex(x => x.uuid === piece.uuid);

    onSelect(pieces[indexStart]);

    if (itemIsSelected > -1 || indexStart === shiftStart) {
      indexStart = null;
    }

    this.setState({ shiftStart: indexStart });
  }

  getSpecimenModels() {
    /**
     * Returns array of all models used with specimens.
     * Also, multiplies with specimen.quantity and quantity of process_steps
     * related to production workflow
     *
     * Example:
     * Workflow1
     * Workflow2
     *
     * Specimen1(workflow=Workflow1, quantity=2, model=X)
     * Specimen2(workflow=Workflow2, quantity=1, model=Y)
     * Specimen3(workflow=Workflow1, quantity=3, model=Z)
     *
     * It will return (2 + 1 + 5) models
     * [X, X, Y, Z, Z, Z,]
     *
     * @return {array} Array with model objects
     * */

    const {
      modelLibrariesByUri,
      modelsByUri,
      specimens,
    } = this.props;

    if (!specimens.length) {
      return [];
    }

    return _reduce(specimens, (result, specimen) => {
      const modelLibraryURI = specimen.model_library;
      const modelLibrary = modelLibrariesByUri[modelLibraryURI];
      const model = modelsByUri[modelLibrary.additive.model];

      for (let index = 0; index < specimen.quantity; index++) {
        result.push(model);
      }
      return result;
    }, []);
  }

  render() {
    const {
      modelLibrariesByUri,
      pieces,
      printer,
      selected,
      onChangeRunName,
      onSelect,
      onActivate,
      onSave,
      name,
      creatingRun,
      specimens,
      priority,
      isAnyModelTooLargeForPrinter,
      startDate,
      dueDate,
      primaryRunDuration,
      onChangePrintTime,
      estWeight,
      onChangeEstWeight,
      setDragAndDropHoverState,
      isDragAndDropHovering,
      labelRelationships,
      isGeneralMFGLanguageEnabled,
      isLineItemsMode,
      exportControlledPiecesForLineItem,
    } = this.props;

    const { overrideBuildPlateChecks } = this.state;
    const pieceModels = pieces.map(piece => {
      const workflowTypeKey = getLineItemWorkflowTypeObjectKey(piece.lineItem);
      return piece.lineItem[workflowTypeKey].model;
    });
    const specimenModels = this.getSpecimenModels();
    const {
      formatted: formattedFillPercentage,
      value: fillPercentage,
    } = getRawAndFormattedFillPercentage(
      [...pieceModels, ...specimenModels],
      printer && printer.printer_type,
    );

    const isBuildFillExceeded = fillPercentage > 100;
    const isBuildPlateExceeded = isBuildFillExceeded || isAnyModelTooLargeForPrinter;

    const isSaveButtonDisabled = !pieces.length
        || creatingRun
        || (isBuildPlateExceeded && !overrideBuildPlateChecks);

    const renderPiecesMessage = isGeneralMFGLanguageEnabled => {
      if (!printer) {
        return (
          <p className="p-a-md">
            <FormattedMessage
              id="selectPrinterToGetStarted"
              defaultMessage="Please select {machineType} to get started"
              values={{ machineType: isGeneralMFGLanguageEnabled ? 'production device' : 'printer' }}
            />
          </p>
        );
      }

      if (!pieces.length) {
        return (
          <p className={`p-a-md m-b-0 ${isDragAndDropHovering ? 'run-new-drag-n-drop-highlight' : ''}`}>
            <FormattedMessage
              id="addPiecesInstruction"
              defaultMessage='Please Drag and Drop pieces from the right or click "Add Pieces" button to fill the Build Plate'
            />
          </p>

        );
      }
      return null;
    };

    return (
      <Card bg="dark">
        <Card.Header className="pd-exp inverse">
          <Row className="panel_heading">
            <Col xs={6}>
              <span>Build Plate</span>
            </Col>
          </Row>
        </Card.Header>
        <div className="card-body-wrapper">
          <Card.Body>
            <ActivePiecesMetadata
              pieces={pieces}
              priority={priority}
              name={name}
              onChangeRunName={onChangeRunName}
              overrideBuildPlateChecks={overrideBuildPlateChecks}
              onToggleOverrideBuildPlateChecks={this.onToggleOverrideBuildPlateChecks}
              isBuildPlateExceeded={isBuildPlateExceeded}
              formattedFillPercentage={formattedFillPercentage}
              startDate={startDate}
              dueDate={dueDate}
              primaryRunDuration={primaryRunDuration}
              estWeight={estWeight}
              onChangeEstWeight={onChangeEstWeight}
              onChangePrintTime={onChangePrintTime}
              printer={printer}
              labelRelationships={labelRelationships}
              isLineItemsMode={isLineItemsMode}
              exportControlledPiecesForLineItem={exportControlledPiecesForLineItem}
              isGeneralMFGLanguageEnabled={isGeneralMFGLanguageEnabled}
            />

            <Row className="piecesCardBodyRow">
              <Droppable
                ref={this.droppableRef}
                types={['buildplatepiece', 'buildplatelineitem']}
                onDrop={onActivate}
                className="p-r-0 p-l-0"
                onDragEnter={event => {
                  event.preventDefault();
                  if (this.droppableRef) {
                    setDragAndDropHoverState(true);
                  }
                }}
                onDragOver={event => {
                  event.preventDefault();
                  if (this.droppableRef && !this.props.isDragAndDropHovering) {
                    setDragAndDropHoverState(true);
                  }
                }}
                onDragLeave={event => {
                  event.preventDefault();
                  if (this.droppableRef.current.droppable.current.contains(event.target)) {
                    setDragAndDropHoverState(false);
                  }
                }}
              >
                <table
                  className="table table-bordered table-fixed table-hover m-b-0 no-outer-border"
                  id="buildPlateTable"
                >
                  <thead className="text-start">
                    <tr>
                      <td>Order</td>
                      <td>Size</td>
                      <td>Build Fill</td>
                    </tr>
                  </thead>
                  <tbody
                    style={{ height: 'auto', maxHeight: 'inherit', border: 'none' }}
                    className="bordered-tbody no-scrollbar"
                  >
                    {renderPiecesMessage(isGeneralMFGLanguageEnabled)}
                    {_map(pieces, piece => (
                      <ActivePiece
                        key={piece.uuid}
                        selected={!!_find(selected, ['uri', piece.uri])}
                        piece={piece}
                        onSelect={onSelect}
                        printer={printer}
                        onShiftToggle={this.onShiftToggle}
                        setFirstItem={this.setFirstItem}
                        isDragAndDropHovering={isDragAndDropHovering}
                      />
                    ))}
                  </tbody>
                </table>
              </Droppable>
            </Row>
            <ButtonToolbar className="pull-right mb15 mt15">
              <Button
                size="large"
                onClick={() => onSave(overrideBuildPlateChecks)}
                disabled={isSaveButtonDisabled}
                variant="success"
              >
                {
                  creatingRun ?
                    <Loading inline />
                    :
                    <FontAwesomeIcon icon={faSave} />
                }
                {' '}
                <FormattedMessage id="button.saveRun" defaultMessage="Save Run" />
              </Button>
            </ButtonToolbar>

            {
              specimens && specimens.length > 0 && (
                <Row>
                  <table className="table table-bordered table-fixed table-hover m-b-0 no-outer-border">
                    <thead>
                      <tr>
                        <td>Specimen</td>
                        <td>Quantity</td>
                        <td>Notes</td>
                      </tr>
                    </thead>
                    <tbody
                      style={{ height: 'auto', maxHeight: 'inherit' }}
                      className="bordered-tbody"
                    >
                      {
                        _map(specimens, specimen => {
                          const modelLibrary = modelLibrariesByUri[specimen.model_library];
                          return (
                            <tr>
                              <td>{modelLibrary.name}</td>
                              <td>{specimen.quantity}</td>
                              <td>{modelLibrary.notes}</td>
                            </tr>
                          );
                        })
                      }
                    </tbody>
                  </table>
                </Row>
              )
            }
          </Card.Body>
        </div>
      </Card>
    );
  }
}

ActivePiecesList.defaultProps = {
  onSelect: () => true,
  printer: {},
  creatingRun: false,
  onChangeRunName: () => true,
  onChangePrintTime: () => true,
  onChangeEstWeight: () => true,
  modelLibrariesByUri: {},
  modelsByUri: {},
  specimens: [],
  priority: PRIORITIES.NORMAL,
  shiftStart: null,
  estWeight: null,
};

ActivePiecesList.propTypes = {
  pieces: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  printer: PropTypes.shape({
    // Patched with printer type resource in selectors
    printer_type: printerTypeResourceType,
  }),
  priority: PropTypes.number,
  name: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  dueDate: PropTypes.string.isRequired,
  primaryRunDuration: PropTypes.number.isRequired,
  estWeight: PropTypes.number,
  selected: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  modelsByUri: PropTypes.objectOf(
    PropTypes.shape({}),
  ),
  modelLibrariesByUri: PropTypes.objectOf(
    PropTypes.shape({
      additive: PropTypes.shape({
        model: modelResourceType,
      }),
    }),
  ),
  specimens: PropTypes.arrayOf(PropTypes.shape({})),
  onSelect: PropTypes.func,
  creatingRun: PropTypes.bool,
  onSelectGroup: PropTypes.func.isRequired,
  onActivate: PropTypes.func.isRequired,
  onChangeRunName: PropTypes.func,
  onChangePrintTime: PropTypes.func,
  onChangeEstWeight: PropTypes.func,
  onSave: PropTypes.func.isRequired,
  isAnyModelTooLargeForPrinter: PropTypes.bool.isRequired,
  shiftStart: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.number]),
  setDragAndDropHoverState: PropTypes.func.isRequired,
  isDragAndDropHovering: PropTypes.bool.isRequired,
  labelRelationships: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isGeneralMFGLanguageEnabled: PropTypes.bool.isRequired,
  isLineItemsMode: PropTypes.bool.isRequired,
  exportControlledPiecesForLineItem: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};
