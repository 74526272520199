import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Alert from 'rapidfab/utils/alert';
import _map from 'lodash/map';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _keyBy from 'lodash/keyBy';
import _isEmpty from 'lodash/isEmpty';
import _indexOf from 'lodash/indexOf';

import * as Selectors from 'rapidfab/selectors';
import {
  LINE_ITEM_STATUS,
  LINE_ITEM_PRODUCTION_STAGE_STATUSES,
  FEATURES,
  MODEL_LIBRARY_TYPES,
  MODEL_ACTIONS,
  MODEL_LAYER_THICKNESS_SETTINGS,
  MODEL_UNITS,
  PAGINATION_IGNORE_DEFAULT_LIMIT,
  API_RESOURCES,
  MODEL_TYPES,
  FILE_EXTENSIONS,
  GUIDELINE_API_RESOURCES,
  ORDER_SHIPPING_GROUPING_OPTIONS,
  USER_ROLES,
} from 'rapidfab/constants';
import Actions from 'rapidfab/actions';
import { extractUuid, getShortUUID } from 'rapidfab/utils/uuidUtils';
import getInitialCustomFieldValues from 'rapidfab/utils/getInitialCustomFieldValues';
import { createOrReplaceArray } from 'rapidfab/utils/arrayUtils';
import {
  lineItemResourceType,
  lineItemDefaultValues,
  orderResourceType,
  shippingResourceType,
} from 'rapidfab/types';
import LineItemEditForm from 'rapidfab/components/records/order/edit/LineItemEditForm';
import { FormattedMessage, useIntl } from 'react-intl';
import userSort from 'rapidfab/utils/userSort';
import reactSelectUserFormatter from 'rapidfab/utils/reactSelectUserFormatter';

const LineItemEditFormContainer = props => {
  const intl = useIntl();
  const {
    lineItem,
    modelUpload,
    onSubmitComplete,
    handleFileChange,
    handleFileRemove,
    componentDidAppearOnScreen,
    workflowTypeKey,
  } = props;
  const {
    base: baseMaterials,
    support: supportMaterials,
  } = useSelector(Selectors.getBaseAndSupportMaterials);
  const baseMaterialsByLocationUri = useSelector(Selectors.getBaseMaterialsByLocationUri);
  const model = useSelector(state => Selectors.getModelForLineItem(state, props.lineItem));
  const compatibleWorkflowUrisByMaterialUris = useSelector(Selectors.getWorkflowUrisByMaterialUri);
  const compatibleWorkflowUrisByShippingUris = useSelector(Selectors.getWorkflowUrisByShippingUri);
  const modelLibraries = useSelector(Selectors.getModelLibraries);
  const workflows = useSelector(state => Selectors.getAvailableWorkflowsForLineItem(state, props.lineItem));
  const isRecalculationTriggerEnabled =
    useSelector(state => Selectors.isFeatureEnabled(state, FEATURES.RECALCULATION_TRIGGER));
  const orderUuid = useSelector(Selectors.getRouteUUID);
  const order = useSelector(state => Selectors.getUUIDResource(state, orderUuid));
  const lineItems = useSelector(state => Selectors.getLineItemsForOrder(state, order));
  const processSteps = useSelector(Selectors.getProcessSteps);
  const printerTypes = useSelector(Selectors.getPrinterTypes);
  const printerTypesForLineItemMaterial = useSelector(state => Selectors.getPrinterTypesForLineItem(state, lineItem));
  const supportedPrintersForLineItem = useSelector(Selectors.getPrinters);
  const supportStrategies = useSelector(Selectors.getSupportStrategies);
  const infillStrategies = useSelector(Selectors.getInfillStrategies);
  const uploadModel = useSelector(Selectors.getUploadModel);
  const isCurrentUserRestricted = useSelector(Selectors.isCurrentUserRestricted);
  const customLineItemFieldReferences = useSelector(Selectors.getCustomLineItemFieldReferences);
  const customOrderFieldReferences = useSelector(Selectors.getCustomOrderFieldReferences);
  const shippingsByUri = useSelector(Selectors.getShippingsByUri);
  const pieces = useSelector(state => Selectors.getPiecesForLineItem(state, props.lineItem));
  const anyPiecesHaveModifiedWorkflow = pieces?.some(piece => piece?.workflow !== lineItem?.workflow);
  const users = useSelector(Selectors.getUsers).sort(userSort);
  const usersByUris = useSelector(Selectors.getUsersByUri);

  const isDebugModeEnabled = useSelector(Selectors.getIsDebugModeEnabled);
  const isMeshHealingFeatureEnabled = useSelector(state => Selectors.isFeatureEnabled(state, FEATURES.MESH_HEALING));
  const isShipmentForOrderFeatureEnabled = useSelector(state =>
    Selectors.isFeatureEnabled(state, FEATURES.SHIPMENT_FOR_ORDER));
  const orderBusinessSegmentFeatureEnabled = useSelector(state => Selectors.isFeatureEnabled(
    state,
    FEATURES.ORDER_BUSINESS_SEGMENT,
  ));
  const isDigitalDesignWarehouseFeatureEnabled = useSelector(state => Selectors.isFeatureEnabled(
    state,
    FEATURES.DIGITAL_DESIGN_WAREHOUSE,
  ));
  const isRobozeBureauOrderFieldsFeatureEnabled = useSelector(state => Selectors.isFeatureEnabled(
    state,
    FEATURES.ROBOZE_GIGAFACTORY_BUREAU_ORDER_FIELDS,
  ));
  const isAdditiveWorkflowFeatureEnabled = useSelector(state => Selectors.isFeatureEnabled(
    state,
    FEATURES.ADDITIVE_WORKFLOW,
  ));
  const isPowderWorkflowFeatureEnabled = useSelector(state => Selectors.isFeatureEnabled(
    state,
    FEATURES.POWDER_WORKFLOW,
  ));

  const boeingOrderFieldsFeature = useSelector(state =>
    Selectors.isFeatureEnabled(state, FEATURES.BOEING_ORDER_FIELDS));

  const threeMOrderFieldsFeature = useSelector(state => Selectors.isFeatureEnabled(
    state,
    FEATURES.THREE_M_MAIN_BUREAU_ORDER_FIELDS,
  ));

  const restrictedUploadModelLibraryOnlyFeature = useSelector(state => Selectors.isFeatureEnabled(
    state,
    FEATURES.RESTRICTED_USER_UPLOAD_FROM_MODEL_LIBRARY_ONLY,
  ));
  const workChecklistLinkings = useSelector(state =>
    Selectors.getRelatedWorkChecklistLinking(state, props.lineItem.uri));

  const isCADToSTLConversionFeatureEnabled = useSelector(state => Selectors.isFeatureEnabled(
    state,
    FEATURES.NATIVE_CAD_TO_STL_CONVERSION,
  ));

  const isStanleyXDeploymentFeatureEnabled = useSelector(state => Selectors.isFeatureEnabled(
    state,
    FEATURES.STANLEY_X_DEPLOYMENT,
  ));

  const is3dpcOrderFieldsFeatureEnabled = useSelector(state => Selectors.isFeatureEnabled(
    state,
    FEATURES.THREEDPC_ORDER_FIELDS,
  ));

  const isGuidelineEngineFeatureEnabled = useSelector(state => Selectors.isFeatureEnabled(
    state,
    FEATURES.GUIDELINES_ENGINE,
  ));

  const isUserRestricted = useSelector(Selectors.isCurrentUserRestricted);
  const bureauSettings = useSelector(Selectors.getBureauSettings);

  const fetchingGuidelineSuggestionsForLineItem = useSelector(state =>
    state.ui.nautilus[GUIDELINE_API_RESOURCES.FOR_LINE_ITEM].get.fetching,
  );

  const fetchingProcessSteps = useSelector(state => state.ui.nautilus[API_RESOURCES.PROCESS_STEP].list.fetching);
  const fetchingPieces = useSelector(state => state.ui.nautilus[API_RESOURCES.PIECE].list.fetching);
  const usersFetching = useSelector(state => state.ui.nautilus[API_RESOURCES.USERS].list.fetching);
  const fetchingWorkflow = useSelector(state => state.ui.nautilus[API_RESOURCES.WORKFLOW].list.fetching);
  const isPiecesOrWorkflowfetching = fetchingPieces || fetchingWorkflow;

  const comments = useSelector(Selectors.getComments);
  const documents = useSelector(Selectors.getDocuments);
  const features = useSelector(Selectors.getFeatures);
  const assemblyPartMeta = useSelector(
    state => Selectors.getAssemblyPartMetaForLineItem(state, props.lineItem),
  );

  const currentUserRole = useSelector(Selectors.getCurrentUserRole);
  const isManager = currentUserRole === USER_ROLES.MANAGER;

  const selected = {
    assemblyPartMeta,
    users,
    baseMaterials,
    baseMaterialsByLocationUri,
    customLineItemFieldReferences,
    customOrderFieldReferences,
    compatibleWorkflowUrisByMaterialUris,
    compatibleWorkflowUrisByShippingUris,
    model,
    modelLibraries,
    orderUuid,
    order,
    supportMaterials,
    workflows,
    workChecklistLinkings,
    processSteps,
    printerTypes,
    supportStrategies,
    infillStrategies,
    uploadModel,
    pieces,
    isDebugModeEnabled,
    isRecalculationTriggerEnabled,
    isCurrentUserRestricted,
    isOrderBusinessSegmentFeatureEnabled: orderBusinessSegmentFeatureEnabled,
    isBoeingOrderFieldsFeatureEnabled: boeingOrderFieldsFeature,
    is3MOrderFieldsFeatureEnabled: threeMOrderFieldsFeature,
    isRestrictedUploadModelLibraryFeatureEnabled: restrictedUploadModelLibraryOnlyFeature,
    isUserRestricted,
    isShipmentForOrderFeatureEnabled,
    shippingsByUri,
    isCADToSTLConversionFeatureEnabled,
    isStanleyXDeploymentFeatureEnabled,
    is3dpcOrderFieldsFeatureEnabled,
    comments,
    documents,
    isGuidelineEngineFeatureEnabled,
    fetchingGuidelineSuggestionsForLineItem,
    fetchingProcessSteps,
    features,
    isAdditiveWorkflowFeatureEnabled,
    isPowderWorkflowFeatureEnabled,
    printerTypesForLineItemMaterial,
    supportedPrintersForLineItem,
    isMeshHealingFeatureEnabled,
    isRobozeBureauOrderFieldsFeatureEnabled,
  };

  const assignedUser = usersByUris[lineItem?.assigned_user];

  const lineItemWorkflow = lineItem?.workflow;
  const [baseMaterial, setBaseMaterial] = useState(lineItem[workflowTypeKey]?.materials.base);
  const [supportMaterial, setSupportMaterial] = useState(lineItem[workflowTypeKey]?.materials.support);
  const [lineItemOwner, setLineItemOwner] = useState(reactSelectUserFormatter(assignedUser));

  const [fetchedUsers, setFetchedUsers] = useState(false);
  const [autorun, setAutorun] = useState(lineItem.autorun);
  const [customFieldValues, setCustomFieldValues] = useState(lineItem.custom_field_values);
  const [layerThickness, setLayerThickness] = useState(lineItem[workflowTypeKey]?.layer_thickness
    || `${is3dpcOrderFieldsFeatureEnabled ?
      MODEL_LAYER_THICKNESS_SETTINGS.THREEDPC_DEFAULT :
      MODEL_LAYER_THICKNESS_SETTINGS.DEFAULT}`);
  const [notes, setNotes] = useState(lineItem.notes);
  const [noModelUpload, setNoModelUpload] = useState(lineItem[workflowTypeKey]?.no_model_upload);
  const [quantity, setQuantity] = useState(lineItem.quantity.toString());
  const [discountByQuantity, setDiscountByQuantity] = useState(lineItem.discount_by_quantity);
  const [status, setStatus] = useState(lineItem.status);
  const [workflow, setWorkflow] = useState(lineItemWorkflow);
  const [modelFileUnits, setModelFileUnits] = useState(model ? model.file_unit : MODEL_UNITS.AUTOMATIC);
  const [modelUserUnits, setModelUserUnits] = useState(model ? model.user_unit : MODEL_UNITS.AUTOMATIC);

  const [partName, setPartName] = useState(lineItem.name);
  const [customerId, setCustomerId] = useState(lineItem.customer_id);

  const [showCompleteWarningModal, setShowCompleteWarningModal] = useState(false);
  const [showRecalculationLineItemModal, setShowRecalculationLineItemModal] = useState(false);

  const [isModelUploading, setIsModelUploading] = useState(false);
  const [isLineItemSubmitting, setIsLineItemSubmitting] = useState(false);
  const [isLineItemDuplicating, setIsLineItemDuplicating] = useState(false);
  const [modalTextPending, setModalTextPending] = useState(false);
  const [supportStrategy, setSupportStrategy] = useState(lineItem[workflowTypeKey]?.support_strategy);
  const [infillStrategy, setInfillStrategy] = useState(lineItem[workflowTypeKey]?.infill_strategy);
  const [canSelectStrategies, setCanSelectStrategies] = useState(false);
  const [isEstimationsShouldBeUpdated, setIsEstimationsShouldBeUpdated] = useState(false);
  const [updateEstimates, setUpdateEstimates] = useState(null);
  const [designHours, setDesignHours] = useState(lineItem.design_time / 60 / 60);
  const [workflowsFetchMoreState, setWorkflowsFetchMoreState] = useState({ offset: 0, count: 1 });
  const [modelLibraryModalVisible, setModelLibraryModalVisible] = useState(false);

  const [guidelineSuggestionsForLineItem, setGuidelineSuggestionsForLineItem] = useState([]);

  const state = {
    fetchedUsers,
    autorun,
    isLineItemSubmitting,
    isLineItemDuplicating,
    modalTextPending,
    canSelectStrategies,
    noModelUpload,
    baseMaterial,
    customFieldValues,
    layerThickness,
    notes,
    partName,
    customerId,
    quantity,
    discountByQuantity,
    designHours,
    status,
    supportMaterial,
    lineItemOwner,
    workflow,
    supportStrategy,
    infillStrategy,
    updateEstimates,
    isEstimationsShouldBeUpdated,
    showCompleteWarningModal,
    showRecalculationLineItemModal,
    isModelUploading,
    isPiecesOrWorkflowfetching,
    modelUserUnits,
    modelFileUnits,
  };

  const initCustomFieldValues = () => {
    const updatedCustomLineItemFieldValues = getInitialCustomFieldValues(
      customLineItemFieldReferences,
      customFieldValues,
    );
    setCustomFieldValues(updatedCustomLineItemFieldValues);
  };

  const dispatch = useDispatch();

  const onFetchSupportedPrintersForLineItem = () => (
    dispatch(Actions.Api.nautilus[API_RESOURCES.PRINTER].list({
      printer_type: _map(printerTypesForLineItemMaterial, 'uri'),
    }))
  );

  const onReloadPrints = queryParams => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.PIECE].clear('list'));
    dispatch(Actions.Api.nautilus[API_RESOURCES.PIECE].list(
      queryParams,
      { limit: PAGINATION_IGNORE_DEFAULT_LIMIT },
    )).then(piecesResponse => {
      const currentPieces = piecesResponse.json.resources;
      const pieceUris = _map(currentPieces, 'uri');
      dispatch(
        Actions.Api.nautilus[API_RESOURCES.PRINT].list(
          { piece: pieceUris },
          { limit: PAGINATION_IGNORE_DEFAULT_LIMIT },
          {},
          {},
          true,
        ),
      );
    });
  };

  useEffect(() => {
    if (customLineItemFieldReferences) {
      initCustomFieldValues();
    }
  }, [customLineItemFieldReferences]);

  useEffect(() => {
    setStatus(lineItem.status);
  }, [lineItem.status]);

  useEffect(() => {
    if (assignedUser) {
      setLineItemOwner(reactSelectUserFormatter(assignedUser));
    }
  }, [assignedUser]);

  useEffect(() => {
    setPartName(lineItem.name);
  }, [lineItem.name]);

  useEffect(() => {
    if (lineItemWorkflow) setWorkflow(lineItemWorkflow);
  }, [JSON.stringify(lineItem)]);

  useEffect(() => {
    if (model) {
      setModelFileUnits(model.file_unit);
      setModelUserUnits(model.user_unit);
    }
  }, [model?.file_unit, model?.user_unit]);

  useEffect(() => {
    if (!isRecalculationTriggerEnabled) {
      setIsEstimationsShouldBeUpdated(true);
    }
  }, [
    baseMaterial,
    supportMaterial,
    infillStrategy,
    supportStrategy,
    modelUpload,
    modelFileUnits,
    modelUserUnits,
    workflow,
  ]);

  useEffect(() => {
    initCustomFieldValues();
  }, [customLineItemFieldReferences.length]);

  const handleCloseCompleteWarningModal = () => {
    setShowCompleteWarningModal(false);
  };

  const handleCloseRecalculationLineItemModal = () => {
    setShowRecalculationLineItemModal(false);
  };

  const handleInputChange = event => {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    if (status === LINE_ITEM_STATUS.PENDING &&
      value === LINE_ITEM_STATUS.COMPLETE) {
      setShowCompleteWarningModal(true);
      setModalTextPending(true);
      return;
    }

    if ((status === LINE_ITEM_STATUS.PRINTING ||
        status === LINE_ITEM_STATUS.POST_PROCESSING ||
        status === LINE_ITEM_STATUS.SHIPPING) &&
      value === LINE_ITEM_STATUS.COMPLETE) {
      showCompleteWarningModal(true);
      return;
    }

    if (name === 'workflow') {
      props.onWorkflowChange(value);
    }

    switch (name) {
      case 'partName':
        setPartName(value);
        break;
      case 'customerId':
        setCustomerId(value);
        break;
      case 'status':
        setStatus(value);
        break;
      case 'lineItemOwner':
        setLineItemOwner(value);
        break;
      case 'modelFileUnits':
        setModelFileUnits(value);
        break;
      case 'modelUserUnits':
        setModelUserUnits(value);
        break;
      case 'layerThickness':
        setLayerThickness(value);
        break;
      case 'quantity':
        setQuantity(value);
        break;
      case 'discountByQuantity':
        setDiscountByQuantity(value);
        break;
      case 'infill_strategy':
        setInfillStrategy(value);
        break;
      case 'support_strategy':
        setSupportStrategy(value);
        break;
      case 'notes':
        setNotes(value);
        break;
      case 'designHours':
        setDesignHours(value);
        break;
      case 'baseMaterial':
        setBaseMaterial(value);
        break;
      case 'supportMaterial':
        setSupportMaterial(value);
        break;
      case 'autorun':
        setAutorun(value);
        break;
      case 'workflow':
        setWorkflow(value);
        break;
      case 'noModelUpload':
        setNoModelUpload(value);
        break;
      default:
        break;
    }
  };

  const handleLayerThicknessBlur = event => {
    const { value } = event.target;
    const number = Number.parseFloat(value);
    if (number > MODEL_LAYER_THICKNESS_SETTINGS.MAX) {
      setLayerThickness(`${MODEL_LAYER_THICKNESS_SETTINGS.MAX}`);
    } else if (number < MODEL_LAYER_THICKNESS_SETTINGS.MIN) {
      setLayerThickness(`${MODEL_LAYER_THICKNESS_SETTINGS.MIN}`);
    }
  };

  const handleModelDownload = useCallback((modelUri, contentUriKey) => {
    dispatch(Actions.DownloadModel.fetchModel(modelUri)).then(response => {
      const currentModel = response.json;
      const modelName = contentUriKey === 'conversion_original_content'
        ? currentModel.conversion_original_filename
        : currentModel.name;

      dispatch(
        Actions.DownloadModel.downloadContent(
          modelName,
          currentModel[contentUriKey],
        ),
      );
    });
  }, [dispatch]);

  const onDuplicate = useCallback(currentQuantity => {
    setIsLineItemDuplicating(true);

    return dispatch(Actions.Api.nautilus[API_RESOURCES.LINE_ITEM].clone(lineItem.uuid, { quantity: currentQuantity }))
      .finally(() => {
        setIsLineItemDuplicating(false);
      });
  }, [dispatch, lineItem.uuid]);

  const onSubmit = async () => {
    let modelPayload;

    const selectedWorkflow = _find(workflows, { uri: workflow });

    if (!selectedWorkflow) {
      Alert.warning('Workflow is required. Please select a workflow.');
      return;
    }

    /* Parent API calls to process-step caps-off at 5,000 entries, which possibly excludes the entries
    related to this workflow. The following API call ensures the process-steps are fetched the belong to
    the current workfow. */
    const processStepResponse = await dispatch(Actions.Api.nautilus[API_RESOURCES.PROCESS_STEP]
      .list({ uri: selectedWorkflow.process_steps }, {}, {}, {}, true));
    const processStepsForCurrentWorkflow = processStepResponse.json?.resources;

    // TODO: Commnented out code for quick fix to solve saving in co-prints.
    // if (!isCurrentUserRestricted) {
    //   // Base material may be empty (e.g. for Assembly or Co-Print Line Item)
    //   const selectedMaterial = _find(baseMaterials, { uri: baseMaterial });

    //   // get workflow compatible with the base material
    //   const compatibleWorkflowUris = compatibleWorkflowUrisByMaterialUris[baseMaterial];

    //   // note: only restricted users don't have the option to select a workflow
    //   if ((!compatibleWorkflowUris?.length || !compatibleWorkflowUris.includes(workflow))
    //     && selectedWorkflow) {
    //     Alert.error(
    //       `Material/Workflow Conflict: Cannot update line item with requested
    //       Material and Workflow combination because ${selectedWorkflow.name} does
    //       not support ${selectedMaterial.name}. Please review the Workstation Type
    //       page, for the Workflow's first step, to confirm the requested material is
    //       included in its materials list.`,
    //     );
    //     return;
    //   }
    // }

    if (processStepsForCurrentWorkflow?.length
      && isShipmentForOrderFeatureEnabled
      && order?.shipping_grouping === ORDER_SHIPPING_GROUPING_OPTIONS.BY_ORDER
      && LINE_ITEM_PRODUCTION_STAGE_STATUSES.includes(status)
      && order.shipping?.uri) {
      const compatibleWorkflowUris = compatibleWorkflowUrisByShippingUris[order.shipping.uri];
      const selectedOrderShipping = shippingsByUri[order.shipping.uri];

      if (!compatibleWorkflowUris.includes(workflow) && selectedWorkflow && selectedOrderShipping) {
        Alert.error(
          `Shipping/Workflow Conflict: Cannot confirm line item with the requested
          Workflow and Shipping combination because ${selectedWorkflow.name} does
          not match ${selectedOrderShipping.name}.`,
        );
        return;
      }
    }

    if (isEstimationsShouldBeUpdated && updateEstimates === null) {
      setShowRecalculationLineItemModal(true);
      return;
    }

    // if a model file is uploaded create a modelPayload
    if (modelUpload) {
      const modelFileNameParts = modelUpload.name.split('.');
      const extension = modelFileNameParts.pop().toLowerCase();
      const fileNameWithoutExtension = modelFileNameParts.join('.');
      modelPayload = {
        // Hardcoding it to `.stl` (lowercase) always
        // (since STL is the only format for model files for now)
        name: `${fileNameWithoutExtension}.${FILE_EXTENSIONS.STL}`,
        file_unit: modelFileUnits === MODEL_UNITS.AUTOMATIC ? null : modelFileUnits,
        user_unit: modelUserUnits === MODEL_UNITS.AUTOMATIC ? null : modelUserUnits,
        type: MODEL_TYPES.STL,
      };
      if (extension !== FILE_EXTENSIONS.STL) {
        // For any non-STL extension we need to provide conversion type and original name
        // TODO: Using extension as an original type. We might need to replace it with extension-to-type mapping
        modelPayload.conversion_original_type = extension;
        modelPayload.conversion_original_filename = modelUpload.name;
      }
    }

    const payload = {
      autorun,
      bureau: lineItem.bureau,
      additive: {
        layer_thickness: Number.parseFloat(layerThickness),
        materials: {
          base: baseMaterial,
          support: supportMaterial || null,
        },
        /* We need to include this value to the payload only if
           we are sending PUT request to /line-item and transform form:
           no_model_upload true -> to false, otherwise, no need to include
           this value in the payload on PUT request. */
        ...((!noModelUpload && lineItem[workflowTypeKey]?.no_model_upload)
          && { no_model_upload: noModelUpload }),
      },
      notes,
      name: partName,
      assigned_user: lineItemOwner ? lineItemOwner.value : null,
      customer_id: customerId,
      quantity: Number.parseInt(quantity, 10),
      workflow,
      custom_field_values: customFieldValues,
    };

    // Remove base material from the payload when it has not been updated.
    if (lineItem[workflowTypeKey]?.materials.base === baseMaterial) {
      delete payload.additive?.materials.base;
    }

    // Remove support material from the payload when it has not been updated.
    if (lineItem[workflowTypeKey]?.materials.support === supportMaterial) {
      delete payload.additive?.materials.support;
    }

    if (updateEstimates !== null) {
      payload.is_relevant_estimation = updateEstimates;
    }

    if (status !== lineItem.status) {
      payload.status = status;
    }

    if (designHours) {
      // Convert hours to seconds
      payload.design_time = Number.parseFloat(designHours) * 60 * 60;
    }

    if (canSelectStrategies) {
      payload.additive.support_strategy = supportStrategy;
      payload.additive.infill_strategy = infillStrategy;
    }

    // Update model file_unit and/or user_unit in case any of those was changed
    if (
      model && !modelUpload && (modelUserUnits !== model.user_unit
        || modelFileUnits !== model.file_unit)
    ) {
      dispatch(
        Actions.Api.nautilus[API_RESOURCES.MODEL].put(extractUuid(lineItem[workflowTypeKey]?.model), {
          file_unit: modelFileUnits === MODEL_UNITS.AUTOMATIC ? null : modelFileUnits,
          user_unit: modelUserUnits === MODEL_UNITS.AUTOMATIC ? null : modelUserUnits,
        }),
      ).then(onSubmitComplete)
        .finally(() => dispatch(Actions.Api.nautilus[API_RESOURCES.MODEL]
          .get(extractUuid(lineItem[workflowTypeKey]?.model))));
      return;
    }

    setIsLineItemSubmitting(true);

    if (!modelUpload || noModelUpload) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.LINE_ITEM].put(lineItem.uuid, payload)).then(() => {
        setIsLineItemSubmitting(false);
        if (order.quote_required) {
          dispatch(Actions.Api.nautilus[API_RESOURCES.LINE_ITEM_QUOTE].list());
        }
        onReloadPrints({ line_item: lineItem.uri });
        Alert.success(intl.formatMessage(
          { id: 'toaster.lineItem.successfullyUpdated', defaultMessage: 'Line Item successfully updated.' }));
      }).catch(() => {
        setIsLineItemSubmitting(false);
        // Alert with error also will be handled by main app component
      });
    } else {
      dispatch(Actions.UploadModel.uploadProgress(0));
      dispatch(
        Actions.Api.nautilus[API_RESOURCES.MODEL].post(modelPayload),
      )
        .then(args => {
          const { location, uploadLocation } = args.headers;
          payload.additive.model = location;
          return dispatch(Actions.Api.nautilus[API_RESOURCES.LINE_ITEM].put(lineItem.uuid, payload))
            .then(() => {
              setIsModelUploading(true);
              setIsLineItemSubmitting(false);
              dispatch(Actions.Api.nautilus[API_RESOURCES.MODEL].get(extractUuid(location), true));
              return dispatch(
                Actions.UploadModel.upload(uploadLocation, modelUpload),
              );
            })
            .finally(() => {
              setIsModelUploading(false);
              setIsLineItemSubmitting(false);
              onSubmitComplete();
            });
        });
    }
  };

  const onDelete = () =>
    // TODO: This needs to be adjusted, once there are more than 1 line item for product
    dispatch(Actions.Api.nautilus[API_RESOURCES.PRODUCT].delete(extractUuid(lineItem.product))).then(() => {
      dispatch(Actions.Api.nautilus[API_RESOURCES.LINE_ITEM].remove(extractUuid(lineItem.uri)));
    });

  const onReloadChecklistLinkings = () =>
    dispatch(Actions.Api.nautilus[API_RESOURCES.WORK_CHECKLIST_LINKING].list({ related_uri: lineItem.uri }));

  const onMeshHealRepair = currentModel => {
    const payload = {
      action: MODEL_ACTIONS.MESH_HEAL_VIA_AUTHENTISE,
    };

    dispatch(Actions.Api.nautilus[API_RESOURCES.MODEL].put(currentModel.uuid, payload)).then(response => {
      const replacedModelURI = response.headers.location;
      const lineItemPayload = { additive: { model: replacedModelURI } };

      dispatch(Actions.Api.nautilus[API_RESOURCES.LINE_ITEM].put(lineItem.uuid, lineItemPayload))
        .then(() =>
          // model must be loaded only after line-item update because model
          // relations between parent and child model is created when line item is updated.
          dispatch(Actions.Api.nautilus[API_RESOURCES.MODEL].get(extractUuid(replacedModelURI), true, true)),
        )
        .then(() => {
          Alert.success(<FormattedMessage
            id="toaster.model.addedToQueue"
            defaultMessage="The model was successfully added to the queue. Process may take some time."
          />);
        });
    });
  };

  const onReloadPrintsForOrder = orderUri => {
    onReloadPrints({ order: orderUri });
  };

  const onCustomFieldChange = (_, value) => {
    const customFieldValuesReplaced = createOrReplaceArray(
      customFieldValues,
      { custom_field: value.customFieldReferenceUri },
      { value: value.value },
    );

    setCustomFieldValues(customFieldValuesReplaced);
  };

  const submitToModelLibrary = (currentModel, modelLibrary) => {
    if (modelLibrary) {
      // window.location.hash = getRouteURI(ROUTES.MODEL_LIBRARY, {}, { uuid: modelLibrary.uuid });
      setModelLibraryModalVisible(true);
      return;
    }

    const payload = {
      additive: {
        base_material: baseMaterial,
        layer_thickness: Number.parseFloat(layerThickness),
        model: currentModel.uri,
        support_material: supportMaterial,
        // Keys enabled for Stanley-X.
        ...isStanleyXDeploymentFeatureEnabled && { source_line_item: lineItem.uri },
      },
      name: `Model from Line Item ${getShortUUID(lineItem.uuid)}`,
      notes,
      type: MODEL_LIBRARY_TYPES.PRODUCT,
      workflow,
    };
    dispatch(Actions.Api.nautilus[API_RESOURCES.MODEL_LIBRARY].post(payload)).then(response => {
      Alert.success(<FormattedMessage
        id="toaster.modelLibrary.created"
        defaultMessage="Model Library successfully created"
      />);
      dispatch(Actions.Api.nautilus[API_RESOURCES.MODEL_LIBRARY].list({ uri: response.headers.location }));
    }).catch(() => {
      // Looks like this model library may be already exist
      // Refresh model library to handle this model
      dispatch(Actions.Api.nautilus[API_RESOURCES.MODEL_LIBRARY].list({ 'additive.model': currentModel.uri }));
    });
  };

  const submitConfirmModal = () => {
    setShowCompleteWarningModal(false);
  };

  const submitRecalculatePriceModal = () => {
    setUpdateEstimates(true);
    setShowRecalculationLineItemModal(false);
  };

  const submitLeavePriceModal = () => {
    setUpdateEstimates(false);
    setShowRecalculationLineItemModal(false);
  };

  useEffect(() => {
    if ([false, true].includes(updateEstimates) && showRecalculationLineItemModal === false) {
      onSubmit();
    }
  }, [updateEstimates, showRecalculationLineItemModal]);

  const onFetchMoreWorkflows = async () => {
    const limit = 100;
    const response = await dispatch(Actions.Api.nautilus[API_RESOURCES.WORKFLOW].list(
      { include_custom_workflows: true },
      { limit, offset: workflowsFetchMoreState.offset }, {}, { sort: 'name' }, true),
    );
    setWorkflowsFetchMoreState(previous => (
      { offset: previous.offset + limit, count: response?.json.meta?.count || 0 }
    ));
  };

  useEffect(() => {
    /* On digital design warehouse, if there are no workflows, fetch workflows to fill the
    cached data with 100 entries, then set the initial workflow value to default workflow or the first entry. */
    if (isDigitalDesignWarehouseFeatureEnabled && _isEmpty(lineItemWorkflow) && !workflow && isUserRestricted) {
      if (!workflows.length) {
        onFetchMoreWorkflows();
      }
      if (workflows.length) {
        const firstWorkflowUri = workflows[0].uri;
        if (bureauSettings?.default_workflow) {
          setWorkflow(
            workflows.find(wf => wf.uri === bureauSettings?.default_workflow)?.uri || firstWorkflowUri,
          );
          return;
        }
        setWorkflow(firstWorkflowUri);
      }
    }
  }, [JSON.stringify(workflows.map(wf => wf.uri))]);

  const modelLibraryByModel = _keyBy(modelLibraries, 'additive.model');
  const currentModelLibrary = modelLibraryByModel[lineItem[workflowTypeKey]?.model];

  const onFetchDataForModelLibraries = () => {
    const modelLibraryUuid = currentModelLibrary?.uuid;

    const commentsForModelLibrary = comments.filter(comment =>
      comment.related_uuid === modelLibraryUuid);
    const documentsForModelLibrary = documents.filter(document =>
      document.related_uuid === modelLibraryUuid);

    if (modelLibraries.length) {
      if (commentsForModelLibrary.length === 0) {
        // eslint-disable-next-line no-unused-expressions
        dispatch(Actions.Api.nautilus[API_RESOURCES.COMMENT].list(
          { related_uuid: modelLibraryUuid },
          {},
          {},
          {},
          commentsForModelLibrary.length === 0,
        ));
      }

      if (!documentsForModelLibrary.length) {
        dispatch(Actions.Api.nautilus[API_RESOURCES.DOCUMENT].list(
          { related_uuid: modelLibraryUuid },
          {},
          {},
          {},
          commentsForModelLibrary.length === 0,
        ));
      }
    }
  };

  useEffect(() => {
    if (currentModelLibrary && componentDidAppearOnScreen) {
      onFetchDataForModelLibraries();
    }
  }, [componentDidAppearOnScreen]);

  const getGuidelineSuggestionsForLineItem = async () => {
    const forLineItemResponse = await dispatch(Actions.Api.nautilus[GUIDELINE_API_RESOURCES.FOR_LINE_ITEM]
      .get(lineItem.uuid, true));
    if (forLineItemResponse) {
      setGuidelineSuggestionsForLineItem(forLineItemResponse.json?.resources);
    }
  };

  useEffect(() => {
    if (isGuidelineEngineFeatureEnabled && lineItem.workflow) {
      getGuidelineSuggestionsForLineItem();
    }
  }, [isGuidelineEngineFeatureEnabled, lineItem.workflow, lineItem.uuid]);

  const {
    readOnly,
    isRestrictedUploadModelLibraryFeatureEnabled,
  } = props;

  const baseMaterialURI = baseMaterials.find(
    material => material.uri === baseMaterial,
  );

  const baseMaterialFormUrl = baseMaterialURI ?
    `/#/inventory/materials?uuid=${extractUuid(baseMaterialURI.uri)}` : '';

  const baseMaterialColor = baseMaterialURI ? baseMaterialURI.color : null;
  const baseMaterialUrl = baseMaterialURI && baseMaterialURI.external_datasheet_url ?
    baseMaterialURI.external_datasheet_url :
    baseMaterialFormUrl;

  const supportMaterialURI = supportMaterials.find(
    material => material.uri === supportMaterial,
  );

  const supportMaterialFormUrl = supportMaterialURI ?
    `/#/inventory/materials?uuid=${extractUuid(supportMaterialURI.uri)}` : '';

  const supportMaterialColor = supportMaterialURI ? supportMaterialURI.color : null;
  const supportMaterialUrl = supportMaterialURI && supportMaterialURI.external_datasheet_url ?
    supportMaterialURI.external_datasheet_url :
    supportMaterialFormUrl;

  let availableSupportStrategies = [];
  let availableInfillStrategies = [];

  let canStrategiesBeSelected = false;
  let prosperProcessSteps = [];

  // Firstly, there must be selected base material which can be printer via prosper integration
  if (baseMaterial && baseMaterial.is_prosper_integration_available) {
    canStrategiesBeSelected = true;
  }

  // Second, production workflow will be also available to print
  // Workflow => process_step => printer_type.is_prosper_integration_available must be true
  if (workflow === undefined || workflow === null) {
    canStrategiesBeSelected = false;
  }

  if (canSelectStrategies) {
    const selectedWorkflow = _find(workflows, { uri: workflow });

    if (selectedWorkflow !== undefined) {
      const workflowProcessSteps = selectedWorkflow.process_steps;
      prosperProcessSteps = _filter(
        processSteps, o => _indexOf(workflowProcessSteps, o.uri) !== -1 &&
          o.additive.is_prosper_integration_available === true,
      );

      canStrategiesBeSelected = prosperProcessSteps.length > 0;
    }
  }

  if (canSelectStrategies) {
    const processStepURI = prosperProcessSteps[0]?.workstation_type_uri;
    const printerType = _find(printerTypes, { uri: processStepURI });

    availableSupportStrategies = _filter(
      supportStrategies,
      o => _indexOf(printerType.support_strategies, o.uri) !== -1,
    );

    availableInfillStrategies = _filter(
      infillStrategies,
      o => _indexOf(printerType.infill_strategies, o.uri) !== -1,
    );
  }

  if (canSelectStrategies !== canStrategiesBeSelected) {
    setCanSelectStrategies(canStrategiesBeSelected);
  }

  if (availableSupportStrategies.length && !supportStrategy) {
    setSupportStrategy(availableSupportStrategies[0].uri);
  }

  if (availableInfillStrategies.length && !infillStrategy) {
    setInfillStrategy(availableInfillStrategies[0].uri);
  }

  let allowFileChange = true;

  if (isRestrictedUploadModelLibraryFeatureEnabled) {
    allowFileChange = !isCurrentUserRestricted;
  }

  const handleGetLineItemOwners = async () => {
    if (!Array.isArray(lineItems) || !lineItems.length) {
      return null;
    }

    // Extract assigned_user URIs and filter out duplicates using Set
    const assignedUsersSet = new Set(
      lineItems.map(lineItem => lineItem?.assigned_user).filter(Boolean),
    );

    // Convert the Set back to an array
    const assignedUsers = [...assignedUsersSet];

    if (assignedUsers.length) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.USERS].list({ uri: assignedUsers }));
    }

    return null;
  };

  const fetchAllUsers = useCallback(() => {
    if (fetchedUsers) return null;
    return dispatch(Actions.Api.nautilus[API_RESOURCES.USERS].list(
      (isManager ? { archived: false } : {}), { limit: PAGINATION_IGNORE_DEFAULT_LIMIT },
    ))
      .then(() => setFetchedUsers(true));
  }, [dispatch, fetchedUsers, isManager]);

  useEffect(() => {
    handleGetLineItemOwners();
  }, []);

  return (
    <LineItemEditForm
      {...props}
      {...state}
      {...selected}
      baseMaterial={baseMaterial}
      baseMaterialColor={baseMaterialColor}
      baseMaterialUrl={baseMaterialUrl}
      handleFileChange={handleFileChange}
      handleFileRemove={handleFileRemove}
      handleInputChange={handleInputChange}
      handleCloseCompleteWarningModal={handleCloseCompleteWarningModal}
      handleCloseRecalculationLineItemModal={handleCloseRecalculationLineItemModal}
      handleLayerThicknessBlur={handleLayerThicknessBlur}
      handleModelDownload={handleModelDownload}
      modelLibraries={modelLibraryByModel}
      onDelete={onDelete}
      onSubmit={onSubmit}
      onDuplicate={onDuplicate}
      onCustomFieldChange={onCustomFieldChange}
      onMeshHealRepair={onMeshHealRepair}
      onReloadPrints={onReloadPrintsForOrder}
      onReloadChecklistLinkings={onReloadChecklistLinkings}
      partName={partName}
      customerId={customerId}
      supportMaterialColor={supportMaterialColor}
      supportMaterialUrl={supportMaterialUrl}
      canSelectStrategies={canSelectStrategies}
      supportStrategies={availableSupportStrategies}
      infillStrategies={availableInfillStrategies}
      submitConfirmModal={submitConfirmModal}
      submitRecalculatePriceModal={submitRecalculatePriceModal}
      submitLeavePriceModal={submitLeavePriceModal}
      submitToModelLibrary={submitToModelLibrary}
      readOnly={readOnly}
      usersFetching={usersFetching}
      allowFileChange={allowFileChange}
      onFetchMoreWorkflows={
        workflowsFetchMoreState.offset < workflowsFetchMoreState.count ? onFetchMoreWorkflows : null
      }
      modelLibraryModal={{ modelLibraryModalVisible, setModelLibraryModalVisible }}
      guidelineSuggestionsForLineItem={guidelineSuggestionsForLineItem}
      anyPiecesHaveModifiedWorkflow={anyPiecesHaveModifiedWorkflow}
      onFetchSupportedPrintersForLineItem={onFetchSupportedPrintersForLineItem}
      userFetchingForLazySelect={fetchAllUsers}
    />
  );
};

LineItemEditFormContainer.defaultProps = {
  lineItem: lineItemDefaultValues,
  // Model can be null, when `no_model_upload` is true
  model: null,
  readOnly: null,
};

LineItemEditFormContainer.propTypes = {
  orderUuid: PropTypes.string.isRequired,
  baseMaterials: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  customLineItemFieldReferences: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  dispatch: PropTypes.func.isRequired,
  lineItem: lineItemResourceType,
  model: PropTypes.shape({
    uri: PropTypes.string,
    name: PropTypes.string,
    user_unit: PropTypes.string,
    file_unit: PropTypes.string,
  }),
  modelLibraries: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  supportMaterials: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  uploadModel: PropTypes.shape({
    uploading: PropTypes.bool,
  }).isRequired,
  workflows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  processSteps: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  printerTypes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  supportStrategies: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  infillStrategies: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  readOnly: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  isRecalculationTriggerEnabled: PropTypes.bool.isRequired,
  isCurrentUserRestricted: PropTypes.bool.isRequired,
  workChecklistLinkings: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isRestrictedUploadModelLibraryFeatureEnabled: PropTypes.bool.isRequired,
  onWorkflowChange: PropTypes.func.isRequired,
  compatibleWorkflowUrisByMaterialUris: PropTypes.objectOf(PropTypes.string).isRequired,
  compatibleWorkflowUrisByShippingUris: PropTypes.objectOf(PropTypes.string).isRequired,
  pieces: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isShipmentForOrderFeatureEnabled: PropTypes.bool.isRequired,
  order: orderResourceType.isRequired,
  shippingsByUri: PropTypes.objectOf(shippingResourceType).isRequired,
  putMfgOrientation: PropTypes.bool.isRequired,
  rotation: PropTypes.shape({}).isRequired,
  modelUpload: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  onSubmitComplete: PropTypes.func.isRequired,
  handleFileChange: PropTypes.func.isRequired,
  handleFileRemove: PropTypes.func.isRequired,
  componentDidAppearOnScreen: PropTypes.bool.isRequired,
  workflowTypeKey: PropTypes.string.isRequired,
};

// export default memo(LineItemEditFormContainer);
export default LineItemEditFormContainer;
